import { LoaderFunction, useLoaderData } from "react-router-dom";
import { decodeToken } from "react-jwt";
import SignatureCanvas from 'react-signature-canvas'
import { Button, Col, Row } from "react-bootstrap";
import { useRef, useState } from "react";
import { AviTracerApi } from "../../avitracerApi";
import { Crew } from "../../models/crew";
import { Organization } from "../../models/organization";

export const requestInfoCrewLoader: LoaderFunction = async (params) => {

  const url = params.request.url;

  const jwtToken = url.split("?token=")[1];

  const info: any = decodeToken(jwtToken);

  if (info.type !== "crew") {
    return null;
  }

  const crew = await AviTracerApi.crewRequestInfoGetData(jwtToken);

  return { crew, jwt: jwtToken, organization: info.organization };
};

function RequestInfoCrewPage() {

  const { crew, organization, jwt } = useLoaderData() as { crew: Crew; organization: Organization; jwt: string };

  const signatureRef = useRef<SignatureCanvas>(null);

  const [currentPage, setCurrentPage] = useState<"info" | "signature" | "thankyou">("info");

  const [saving, setSaving] = useState<boolean>(false);
  
  const savePressed = () => {
    setSaving(true);
    const save = async () => {
      const signatureDataUrl = signatureRef.current!.getTrimmedCanvas().toDataURL();
      await AviTracerApi.crewRequestInfoSubmit(signatureDataUrl, jwt);
      setCurrentPage("thankyou");
    };
    save();
  };

  return (
    <>
      {currentPage === "signature" ? (
        <SignatureComponent signatureRef={signatureRef} saving={saving} savePressed={savePressed} />
      ) : (
        <Row className="justify-content-center pt-5">
          <Col xs={11} md={10} lg={6} className="border rounded-lg  bg-white">
            {currentPage === "thankyou" && <ThankYouComponent />}
            {currentPage === "info" && <InfoComponent crew={crew} organization={organization} setCurrentPage={setCurrentPage} />}
          </Col>
        </Row>
      )}
    </>
  );
}

export default RequestInfoCrewPage

interface InfoComponentProps {
  crew: Crew;
  organization: Organization;
  setCurrentPage: (page: "info" | "signature" | "thankyou") => void;
}

function InfoComponent({ crew, organization, setCurrentPage }: InfoComponentProps) {
  return (
    <>
      <Row className="justify-content-center pt-5 pb-3">
        <Col xs={10}>
          <h3>{organization.name} kindly requests you validate your information and add your signature.</h3>
          <small>
            These details are of utmost importance for the completion of the General Declaration and Form731 documents. In case your details
            are inaccurate please contact {organization.name}.
          </small>
        </Col>
      </Row>
      <Row className="justify-content-center pt-3">
        <Col xs={"10"}>
          <h4 className="pb-2">Your info</h4>
          <strong>First name:</strong> {crew.firstName}
          <br />
          <strong>Last name:</strong> {crew.lastName}
        </Col>
      </Row>
      <Row className="justify-content-center pt-4">
        <Col xs={6}>
          <div className="d-grid py-3">
            <Button
              onClick={() => {
                setCurrentPage("signature");
              }}
              variant="dark"
            >
              Continue
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
}

interface SignatureComponentProps {
  signatureRef: React.RefObject<SignatureCanvas>;
  saving: boolean;
  savePressed: () => void;
}

function SignatureComponent({ signatureRef, saving, savePressed }: SignatureComponentProps) {
  return (
    <div>
      <center>
        <h1>Sign here</h1>
      </center>
      <div className="vw-50 vh-100 ">
        <div className="bg-white w-100 h-50 bg-white border rounded-lg">
          <SignatureCanvas minWidth={2} maxWidth={5} ref={signatureRef} dotSize={3} canvasProps={{ className: "w-100 h-100" }} />
        </div>
      </div>
      <div className="fixed-bottom bg-light">
        <div className="d-flex">
          <div className="w-25 d-grid  gap-2 m-2">
            <Button
              disabled={saving}
              style={{ height: "60px" }}
              variant="light"
              className="fw-bold"
              onClick={() => {
                signatureRef.current!.clear();
              }}
            >
              Clear
            </Button>
          </div>
          <div className="w-75 d-grid gap-2 m-2">
            <Button disabled={saving} style={{ height: "60px" }} className="fw-bold" variant="dark" onClick={savePressed}>
              {saving ? "Saving..." : "Save signature"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function ThankYouComponent() {
  return (
    <Row className="justify-content-center pt-5 pb-5 text-center">
      <Col xs={10}>
        <h3>Thank you for submitting your info.</h3>
        You can now close your browser window.
      </Col>
    </Row>
  );
}