import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { Alert, Button, Col, Offcanvas, Row, Table } from "react-bootstrap";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Aircraft } from "../../../models/aircraft";
import Flight, { FlightFuelOnBoard, FlightWbLoad } from "../../../models/flight";
import { CalculatedFlightWbPoints, calculateOutOfLimitsTexts, calculateWarningOutOfLimitsTexts, generateSeatWbLoads, getCalculatedFlightWbPoints } from "../../../models/flightWb";
import AircraftWBGraph from "../aircraft/AircraftWBGraph";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { editFlightWbLoads } from "../../../store/flightSlice";
import { useAppDispatch } from "../../../store/hooks";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { AviTracerApi } from "../../../avitracerApi";
import { FuelConverter } from "../../../utils/FuelUtils";
import { useMediaQuery } from "usehooks-ts";

function FlightWbPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch()

  const loadedFlight = useLoaderData() as Flight;

  const [saving, setSaving] = useState(false);
  const [show, setShow] = useState(true);

  const aircraft = loadedFlight.aircraft
  const aircraftWbPoints = aircraft.wb!.envelopePoints;
  const aircraftLatWbPoints = aircraft.wb!.latEnvelopePoints;

  const [calculatedFlightWbPoints, setCalculatedFlightWbPoints] = useState<CalculatedFlightWbPoints>();
  const [outOfLimitsTextsElement, setOutOfLimitsTextsElement] = useState<JSX.Element>();
  const [outOfLimitsTexts, setOutOfLimitsTexts] = useState<string[]>([]);
  const [outOfLimitsWarningTextsElement, setOutOfLimitsWarningTextsElement] = useState<JSX.Element>();
  const [outOfLimitsWarningTexts, setOutOfLimitsWarningTexts] = useState<string[]>([]);


  const [wbLoads, setWbLoads] = useState<FlightWbLoad[]>();

  useEffect(() => {
    if (!wbLoads){
      if (loadedFlight.wb?.loads !== undefined && loadedFlight.wb.loads.length > 0){
        setWbLoads(loadedFlight.wb.loads);
      }else{
        setWbLoads(generateSeatWbLoads(loadedFlight))
      } 
    }
    
    if (show && wbLoads){
      const neededFuelInFuelUnit = aircraft.fuelPerHour * (loadedFlight.totalEETMins / 60)
      const neededFuelInMass = FuelConverter.toMassUnit(aircraft, neededFuelInFuelUnit);

      const wbPoints = getCalculatedFlightWbPoints(aircraft, 
        wbLoads, 
        loadedFlight.fuelOnBoard,
        Math.round(neededFuelInMass));
      
      setCalculatedFlightWbPoints(wbPoints);
    }
  }, [loadedFlight, wbLoads, show, aircraft]);

  useEffect(() => {

    if (calculatedFlightWbPoints){
      const texts = calculateOutOfLimitsTexts(calculatedFlightWbPoints, aircraft);
      const warningTexts = calculateWarningOutOfLimitsTexts(calculatedFlightWbPoints, aircraft);
      
      if (texts.length > 0){
        setOutOfLimitsTexts([texts,warningTexts].flat());
        setOutOfLimitsWarningTexts([]);
      }else{
        setOutOfLimitsTexts(texts);
        setOutOfLimitsWarningTexts(warningTexts);
      }
    }
    
  }, [ aircraftWbPoints, calculatedFlightWbPoints, aircraft])

  useEffect(() => {
    setOutOfLimitsTextsElement(
      <>
        {outOfLimitsTexts.length > 0 && (
          <Alert variant="danger">
            <div className="fw-bold d-flex flex-column">
              {outOfLimitsTexts.map((text, index) => (
                <span key={index}>• {text}</span>
              ))}
            </div>
          </Alert>
        )}
      </>
    );

  }, [outOfLimitsTexts])

  useEffect(() => {
    setOutOfLimitsWarningTextsElement(
      <>
        {outOfLimitsWarningTexts.length > 0 && (
          <Alert variant="warning">
            <div className="fw-bold d-flex flex-column">
              {outOfLimitsWarningTexts.map((text, index) => (
                <span key={index}>• {text}</span>
              ))}
            </div>
          </Alert>
        )}
      </>
    );

  }, [outOfLimitsWarningTexts])

  const moveSeatUp = (stationId: string) => {
    const index = aircraft.wb!.stations.findIndex(s => s.id === stationId);
    if (index > 0){
       const fromStation = aircraft.wb!.stations[index];
       const toStation = aircraft.wb!.stations[index - 1];

       const fromWbLoad = wbLoads!.find(l => l.station.id === fromStation.id)!;
       const toWbLoadOld = wbLoads!.find(l => l.station.id === toStation.id);
        var oldWbLoads = wbLoads!.filter(l => l.station.id !== fromStation.id && l.station.id !== toStation.id);
       if (toWbLoadOld){
          fromWbLoad.station = toStation;
          toWbLoadOld.station = fromStation;
          oldWbLoads.push(fromWbLoad);
          oldWbLoads.push(toWbLoadOld);
       }else{
          fromWbLoad.station = toStation;
          oldWbLoads.push(fromWbLoad);
       }
       setWbLoads(oldWbLoads);
    }
  }


  const moveSeatDown = (stationId: string) => {
    const index = aircraft.wb!.stations.findIndex(s => s.id === stationId);
    if (index < aircraft.wb!.stations.length - 1){
        const fromStation = aircraft.wb!.stations[index];
        const toStation = aircraft.wb!.stations[index + 1];

        const fromWbLoad = wbLoads!.find(l => l.station.id === fromStation.id)!;
        const toWbLoadOld = wbLoads!.find(l => l.station.id === toStation.id);
        var oldWbLoads = wbLoads!.filter(l => l.station.id !== fromStation.id && l.station.id !== toStation.id);
        if (toWbLoadOld){
          fromWbLoad.station = toStation;
          toWbLoadOld.station = fromStation;
          oldWbLoads.push(fromWbLoad);
          oldWbLoads.push(toWbLoadOld);
        }else{
          fromWbLoad.station = toStation;
          oldWbLoads.push(fromWbLoad);
        }
        setWbLoads(oldWbLoads);
    }
  }

  const saveAndClose = async () => {
    setSaving(true);
    setTimeout( async () => { //timeout for changing design into printMode
      if (outOfLimitsTexts.length > 0) {
        await dispatch(
          editFlightWbLoads({ flight: loadedFlight, wb: { status: "error", loads: wbLoads!, errors: outOfLimitsTexts } })
        ).unwrap();
      } else {
        await createAndUploadPdf();
        await dispatch(editFlightWbLoads({ flight: loadedFlight, wb: { status: "ok", loads: wbLoads! } })).unwrap();
      }

      setShow(false);
    }, 50);
  }

  const createAndUploadPdf = async () => {

    const input = document.getElementById('wbPdf');
    const canvas = await html2canvas(input!, {
      scale: 1,
      onclone: (document, element) => {
        element.style.setProperty("width", "1000px", "important")
      }
    })
    
    const imgData = canvas.toDataURL("image/png");

    const aspectRatio = canvas.width / canvas.height; 
    const pdf = new jsPDF("p", "mm", "a4");

    var width = pdf.internal.pageSize.getWidth();  
    var height = width / aspectRatio; 

    pdf.addImage(imgData, "PNG", 20, 20, width - 40, height - 40, undefined, "SLOW");

    const fileBlob = pdf.output("blob");

    await AviTracerApi.uploadWbPdf(loadedFlight.id!, fileBlob)
  }

  const canEditWb = loadedFlight.briefing!.status === "not-started" || loadedFlight.briefing!.status === "error"
  return (
    <Offcanvas backdrop="static" show={show} onExited={() => navigate("../")} placement={"end"} className="offcanvas-size-fullscreen-lg">
      <Offcanvas.Body>
        <Row className="justify-content-between pt-2 pb-4">
          <Col>
            <h2>{loadedFlight.flightId}</h2>
          </Col>
          <Col xs={"auto"}>
            {!canEditWb && (
              <Button variant={"dark"} style={{ width: "100px" }} onClick={async () => setShow(false)}>
                Close
              </Button>
            )}
            {canEditWb && (
              <Button variant={"dark"} disabled={saving} style={{ width: "100px" }} onClick={async () => await saveAndClose()}>
                {!saving ? "Save" : "Saving..."}
              </Button>
            )}
          </Col>
        </Row>
        <div id="wbPdf">
          {calculatedFlightWbPoints && wbLoads && show && (
            <>
              {outOfLimitsTexts.length > 0 && (
                <Row className="py-2">
                  <Col>{outOfLimitsTextsElement}</Col>
                </Row>
              )}
              {outOfLimitsWarningTexts.length > 0 && (
                <Row className="py-2">
                  <Col>{outOfLimitsWarningTextsElement}</Col>
                </Row>
              )}
              <Row>
                <Col xs={12}>
                  <WbTable
                    aircraft={aircraft}
                    wbLoads={wbLoads}
                    setWbLoads={setWbLoads}
                    fuelOnBoard={loadedFlight.fuelOnBoard}
                    calculatedFlightWbPoints={calculatedFlightWbPoints}
                    moveSeatUp={moveSeatUp}
                    moveSeatDown={moveSeatDown}
                    disabled={!canEditWb}
                    inPrintMode={saving}
                  />
                </Col>
              </Row>

              <Row className="pt-5 gy-4 justify-content-center">
                <Col xs={12} lg={6}>
                  <AircraftWBGraph aircraftWbPoints={aircraftWbPoints} calculatedFlightWbPoints={calculatedFlightWbPoints} type="lon" />
                </Col>
                {aircraftLatWbPoints && (
                  <Col xs={12} lg={6}>
                    <AircraftWBGraph
                      aircraftWbPoints={aircraftLatWbPoints}
                      calculatedFlightWbPoints={calculatedFlightWbPoints}
                      type="lat"
                    />
                  </Col>
                )}
              </Row>
            </>
          )}
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default FlightWbPage;

interface WbTableProps {
  aircraft: Aircraft
  wbLoads: FlightWbLoad[]
  setWbLoads: React.Dispatch<React.SetStateAction<FlightWbLoad[] | undefined>>
  fuelOnBoard: FlightFuelOnBoard[]
  calculatedFlightWbPoints: CalculatedFlightWbPoints
  moveSeatUp: (stationId: string) => void
  moveSeatDown: (stationId: string) => void,
  disabled: boolean
  inPrintMode: boolean
}

function WbTable({aircraft, wbLoads, setWbLoads, fuelOnBoard, calculatedFlightWbPoints, moveSeatUp, moveSeatDown, disabled, inPrintMode}: WbTableProps) {

  const columnHelper = createColumnHelper<WbTableDataRow>();

  const isLessThanMd = useMediaQuery('(max-width: 768px)');

  //Undefined when not needed to show button based on isLessThanMd
  const [showLat, setShowLat] = useState<Boolean|undefined>(undefined);

  useEffect(() => {
    if (inPrintMode){
      setShowLat(undefined);
    }else{
      if (isLessThanMd){
        setShowLat(false);
      }else{
        setShowLat(undefined);
      }
    }
  }, [isLessThanMd, inPrintMode])

  const [data, setData] = useState<WbTableDataRow[]>([])

  useEffect(() => {
    setData([
      {
        stationId: "emptyMass",
        isSeat: false,
        isEditable: false,
        positionName: "BEW",
        name: "Empty Weight",
        mass: aircraft.wb!.emptyMass.mass,
        arm: aircraft.wb!.emptyMass.arm,
        moment: aircraft.wb!.emptyMass.mass * aircraft.wb!.emptyMass.arm,
        latArm: aircraft.wb!.emptyMass.latArm ?? 0,
        latMoment: aircraft.wb!.emptyMass.mass * (aircraft.wb!.emptyMass.latArm ?? 0),
        rowStyle: {borderBottom: "2px solid black", borderTop: "2px solid black"},
        
      },
      ...aircraft.wb!.stations.filter(s => s.type === "seat").map((station) => ({
        stationId: station.id,
        isSeat: true,
        isEditable: false,
        positionName: station.name,
        name: wbLoads.find(l => l.station.id === station.id)?.name || "",
        mass: wbLoads.find(l => l.station.id === station.id)?.mass || 0,
        arm: station.arm,
        moment: (wbLoads.find(l => l.station.id === station.id)?.mass ?? 0) * station.arm ,
        latArm: station.latArm ?? 0,
        latMoment: (wbLoads.find(l => l.station.id === station.id)?.mass ?? 0) * (station.latArm ?? 0) ,
        rowClassName: (wbLoads.find(l => l.station.id === station.id)) ? "fw-semibold" : "fw-light",
        rowStyle: (aircraft.wb!.stations.filter(s => s.type === "seat")[aircraft.wb!.stations.filter(s => s.type === "seat").length - 1] === station) ? {borderBottom: "2px solid black"} : {},
      })),
      ...aircraft.wb!.stations.filter(s => s.type === "baggage").map((station) => ({
        stationId: station.id,
        isSeat: false,
        isEditable: true,
        onChange: (v: number) => {
          const oldWbLoads = [...wbLoads];
          const oldWbLoad = oldWbLoads.find(l => l.station.id === station.id);
          if (oldWbLoad){
            oldWbLoad.mass = v;
          }else{
            oldWbLoads.push({station: station, mass: v, name: station.name});
          }
          setWbLoads(oldWbLoads);
        },
        maxMass: station.maxMass,
        positionName: "Cargo",
        name: station.name,
        mass: wbLoads.find(l => l.station.id === station.id)?.mass || 0,
        arm: station.arm,
        moment: (wbLoads.find(l => l.station.id === station.id)?.mass ?? 0) * station.arm ,
        latArm: station.latArm ?? 0,
        latMoment: (wbLoads.find(l => l.station.id === station.id)?.mass ?? 0) * (station.latArm ?? 0) ,
        rowClassName: ((wbLoads.find(l => l.station.id === station.id)?.mass ?? 0) > 0) ? "fw-semibold" : "fw-light"    
      })),
      {
        stationId: "zeroFuel",
        isSeat: false,
        isEditable: false,
        positionName: "ZFW",
        name: "Zero Fuel Weight",
        mass: calculatedFlightWbPoints.emptyFuel.mass,
        arm: calculatedFlightWbPoints.emptyFuel.arm,
        moment: calculatedFlightWbPoints.emptyFuel.mass * calculatedFlightWbPoints.emptyFuel.arm,
        latArm: calculatedFlightWbPoints.emptyFuel.latArm ?? 0,
        latMoment: calculatedFlightWbPoints.emptyFuel.mass * calculatedFlightWbPoints.emptyFuel.latArm,
        rowStyle: {borderBottom: "2px solid black", borderTop: "2px solid black", color: "blue"},
        
      },
      ...fuelOnBoard.map((fuel) => ({
        stationId: "takeoffFuel" + fuel.station.id,
        isSeat: false,
        isEditable: false,
        positionName: "Fuel",
        name: fuel.station.name,
        mass: fuel.mass,
        arm: fuel.station.arm,
        moment: fuel.mass * fuel.station.arm,
        latArm: fuel.station.latArm ?? 0,
        latMoment: fuel.mass * (fuel.station.latArm ?? 0),
      })),
      {
        stationId: "takeoffWeight",
        isSeat: false,
        isEditable: false,
        positionName: "TOW",
        name: "Take Off Weight",
        mass: calculatedFlightWbPoints.takeoff.mass,
        arm: calculatedFlightWbPoints.takeoff.arm,
        moment: calculatedFlightWbPoints.takeoff.mass * calculatedFlightWbPoints.takeoff.arm,
        latArm: calculatedFlightWbPoints.takeoff.latArm ?? 0,
        latMoment: calculatedFlightWbPoints.takeoff.mass * calculatedFlightWbPoints.takeoff.latArm,
        rowStyle: { borderTop: "2px solid black", color: "green"},
      },
      {
        stationId: "landingWeight",
        isSeat: false,
        isEditable: false,
        positionName: "LDGW",
        name: "Landing Weight",
        mass: calculatedFlightWbPoints.landing.mass,
        arm: calculatedFlightWbPoints.landing.arm,
        moment: calculatedFlightWbPoints.landing.mass * calculatedFlightWbPoints.landing.arm,
        latArm: calculatedFlightWbPoints.landing.latArm ?? 0,
        latMoment: calculatedFlightWbPoints.landing.mass * calculatedFlightWbPoints.landing.latArm,
        rowStyle: {borderBottom: "2px solid black", color: "magenta"},
      },
      ...calculatedFlightWbPoints.landing.landingFuelOnBoard.map((fuel) => ({
        stationId: "landingFuel" + fuel.station.id,
        isSeat: false,
        isEditable: false,
        positionName: "Land Fuel",
        name: fuel.station.name,
        mass: fuel.mass,
        arm: fuel.station.arm,
        moment: fuel.mass * fuel.station.arm,
        latArm: fuel.station.latArm ?? 0,
        latMoment: fuel.mass * (fuel.station.latArm ?? 0),
      })),
    ])
  }, [aircraft, calculatedFlightWbPoints, fuelOnBoard, setWbLoads, wbLoads])
  

  const columns = [
    columnHelper.group({
      id: "station",
      header: "Stations",
      columns: [
        columnHelper.accessor("positionName", {
          header: "Position",
        }),
        columnHelper.accessor("name", {
          header: "Name",
          cell: (info) => (
            <Row className="g-0 justify-content-between">
              <Col>{info.getValue()}</Col>
              {info.row.original.isSeat && info.row.original.mass > 0 && (
                <>
                  <Col xs={"auto"}>
                    {aircraft.wb!.stations.filter((s) => s.type === "seat")[0]
                      .id !== info.row.original.stationId && !disabled && (
                      <Button
                        size="sm"
                        style={{
                          left: "0px",
                          "--bs-btn-padding-y": "0.0rem",
                          "--bs-btn-padding-x": "0.40rem",
                          "--bs-btn-font-size": "0.80rem",
                          "--bs-btn-border-radius": "0.25rem",
                        }}
                        variant="outline-dark"
                        onClick={() => moveSeatUp(info.row.original.stationId)}
                      >
                        <FontAwesomeIcon icon={faCaretUp} />
                      </Button>
                    )}

                    {aircraft.wb!.stations
                      .filter((s) => s.type === "seat")
                      .reverse()[0].id !== info.row.original.stationId && !disabled && (
                      <Button
                        size="sm"
                        className="pl-2"
                        style={{
                          right: "0px",
                          "--bs-btn-padding-y": "0.0rem",
                          "--bs-btn-padding-x": "0.40rem",
                          "--bs-btn-font-size": "0.80rem",
                          "--bs-btn-border-radius": "0.25rem",
                        }}
                        variant="outline-dark"
                        onClick={() =>
                          moveSeatDown(info.row.original.stationId)
                        }
                      >
                        <FontAwesomeIcon icon={faCaretDown} />
                      </Button>
                    )}
                  </Col>
                </>
              )}
            </Row>
          ),
        }),
        columnHelper.accessor("mass", {
          header: "Weight",
          cell: (info) => (
            <>
              {info.row.original.isEditable && (
              <Row>
                <Col>
                  <input style={{width: "50px"}} 
                  disabled={disabled}
                  type="number" 
                  min={0} 
                  max={info.row.original.maxMass!} 
                  onBlur={(e) => {
                    const value = parseInt(e.target.value);
                    if (isNaN(value)) {
                      info.row.original.onChange!(0);
                    } else {
                      info.row.original.onChange!(value);
                    }
                  }}
                  defaultValue={info.row.original.mass}
                  ></input>
                </Col>
              </Row>
              )}
              {!info.row.original.isEditable && <>{info.getValue()}</>}
            </>
          ),
        }),
      ],
    })
  ];

  if (showLat === undefined || !showLat) {
    columns.push(
      columnHelper.group({
        id: "longitudinal",
        header: () => {
          return (
            <div className="">
              <div>Longitudinal</div>
              {aircraft.wb!.latEnvelopePoints && !inPrintMode && (
                <Button
                  className="d-md-none px-0 py-0"
                  variant="link"
                  onClick={() => {
                    setShowLat(true);
                  }}
                >
                  Show Lat
                </Button>
              )}
            </div>
          );
        },
        columns: [
          columnHelper.accessor("arm", {
            header: "Arm",
          }),
          columnHelper.accessor("moment", {
            header: "Moment",
            cell: (info) => (
              <>
                {Math.round(info.getValue())}
              </>
            ),
          }),
        ],
      })
    );
  }


  if (aircraft.wb!.latEnvelopePoints && ( showLat === undefined || showLat)) {
    columns.push(
      columnHelper.group({
        id: "lateral",
        header: () => {
          return (
            <div className="">
              <div>Lateral</div>
              {!inPrintMode && (<Button
                variant="link"
                className="d-md-none px-0 py-0"
                onClick={() => {
                  setShowLat(false);
                }}
              >
                Show Lon
              </Button>)}
            </div>
          );
        },
        columns: [
          columnHelper.accessor("latArm", {
            header: "Arm",
          }),
          columnHelper.accessor("latMoment", {
            header: "Moment",
            cell: (info) => (
              <>
                {Math.round(info.getValue())}
              </>
            ),
          }),
        ],
      })
    );
  }
    
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  })

  return (
    <Table bordered>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id} colSpan={header.colSpan}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                style={cell.row.original.rowStyle}
                className={cell.row.original.rowClassName}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

type WbTableDataRow = {
  stationId: string

  positionName: string;
  name: string;
  
  mass: number;
  arm: number;
  moment: number;

  latArm: number;
  latMoment: number;

  isSeat: boolean;
  
  isEditable: boolean;
  maxMass?: number;
  onChange?: (v: number) => void;

  rowStyle?: React.CSSProperties;
  rowClassName?: string;
}