import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, getFirebaseAuthErrorMessage } from "../../firebase";
import { FirebaseError } from "firebase/app";
import { SubmitHandler, useForm } from "react-hook-form";

function Register() {
  const RegisterFormSchema = z.object({
    email: z.string().email(),
    password: z.string().min(8, { message: "Password must be at least 8 characters" }),
  });

  type RegisterFormSchemaType = z.infer<typeof RegisterFormSchema>;

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<RegisterFormSchemaType>({ resolver: zodResolver(RegisterFormSchema) });

  const onRegister: SubmitHandler<RegisterFormSchemaType> = async (data) => {
    await createUserWithEmailAndPassword(auth, data.email, data.password)
      .then((user) => {})
      .catch((error: FirebaseError) => {
        setError("root.serverError", { message: getFirebaseAuthErrorMessage(error) });
      });
  };

  const errorMessage = [errors.email, errors.password, errors.root?.serverError]
    .filter((e) => e !== undefined)
    .map((e) => e!.message)
    .map((e) => (
      <>
        {e}
        <br />
      </>
    ));

  return (
    <>
      <Container className="pt-5">
        <Row className="justify-content-center">
          <Col xs={11} md={10} lg={6} className=" border rounded-lg  bg-white">
            <Row className="justify-content-center py-5">
              <Col xs={10}>
                <h2>Register</h2>
                <Form className="py-2" onSubmit={handleSubmit(onRegister)}>
                  <Form.Group className="" controlId="email">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control type="email" {...register("email")} disabled={isSubmitting}></Form.Control>
                  </Form.Group>
                  <Form.Group className="" controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" {...register("password")} disabled={isSubmitting}></Form.Control>
                  </Form.Group>

                  {errorMessage.length > 0 && (
                    <Alert variant="danger" className="mt-2">
                      {errorMessage}
                    </Alert>
                  )}
                  <div className="d-grid g-2 pt-4">
                    <Button type="submit" className="btn-dark" disabled={isSubmitting}>
                      {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status"></span> : "Register"}
                    </Button>
                  </div>
                </Form>
                <div className="pt-2">
                  <Link
                    to={{
                      pathname: "/auth/login",
                      search: window.location.search,
                    }}
                    className="text-dark"
                  >
                    Back to Login
                  </Link>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Register;
