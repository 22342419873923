import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Outlet, useNavigate } from "react-router-dom";
import ListEntitiesHeaderComponent from "../../../components/ListEntities/ListEntitiesHeaderComponent";
import ListEntitiesTable from "../../../components/ListEntities/ListEntitiesTable";
import { Aircraft } from "../../../models/aircraft";
import { getAircraft } from "../../../store/aircraftSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import LoadingComponent from "../../../components/LoadingComponent";

interface ListAicraftPageProps {
  onAircraftSelected?: (aircraft: Aircraft) => void;
}

function ListAicraftPage({ onAircraftSelected }: ListAicraftPageProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { allAircraft, status, loading } = useAppSelector((state) => state.aircraft);

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (status === "idle") {
      dispatch(getAircraft());
    }
  }, [dispatch, status]);

  return (
    <Container fluid className="vh-100 px-0 px-md-2">
      <ListEntitiesHeaderComponent
        search={{ value: searchValue, setValue: setSearchValue }}
        title="Aircraft"
        actionBtn={{ title: "Add Aircraft", onClick: () => navigate("/aircraft/add") }}
      />

      {loading ? (
        <LoadingComponent text="Loading aircraft..." />
      ) : (
        <Row className="overflow-auto" style={{ height: "calc(100% - 140px)" }}>
          <Col>
            <ListAircraftTable data={allAircraft} searchValue={searchValue} onAircraftSelected={onAircraftSelected} />
          </Col>
        </Row>
      )}

      <Outlet />
    </Container>
  );
}

export default ListAicraftPage;

interface ListAircraftTableProps {
  data: Aircraft[];
  searchValue: string;
  onAircraftSelected?: (aircraft: Aircraft) => void;
}

function ListAircraftTable({ data, searchValue, onAircraftSelected }: ListAircraftTableProps) {
  const columnHelper = createColumnHelper<Aircraft>();

  const columns = [
    columnHelper.accessor("name", {
      header: () => "Name",
      sortingFn:"alphanumeric"
    }),
    columnHelper.accessor("registration", {
      header: () => "Reg",
      sortingFn:"alphanumeric"
    }),
    columnHelper.accessor("operatorDefaultNumber", {
      header: () => "Designator",
      sortingFn:"alphanumeric"
    }),
    columnHelper.accessor("icaoType", {
      header: () => "Type",
      sortingFn:"alphanumeric"
    }),

  ];

  return (
    <>
      <ListEntitiesTable
        data={data}
        columns={columns}
        searchValue={searchValue}
        onRowClick={onAircraftSelected}
      />
    </>
  );
}
  