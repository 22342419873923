import { EntityDetailsDisplayInfo } from "../components/EntityDetails/EntityDetailsDisplayInfo";
import { FuelConverter } from "../utils/FuelUtils";
import { Aircraft } from "./aircraft";
import AirctaftSarEquipment, { emptyAircraftSarEquipment } from "./aircraftSar";
import { Crew } from "./crew";
import { FlightFplFile } from "./flight";
import { Passenger } from "./passenger";
import { Route, RoutePoint, calculateDistanceBetweenRoutePoints, convertGeoPointToLatLon, convertLatLonToGeoPoint, getFplCoordsFromRoutePoint } from "./route";

type FlightPlan = {

    id?: string;

    aircraft?: Aircraft;

    pic?: Crew;
    sic?: Crew,
    deadheadCrew?: Crew,
    studentPilot?: Crew,

    isCostSharing?: boolean
    passengers: Passenger[];


    aircraftRegistration: string,
    flightRules: "V" | "I" | "Y" | "Z";
    flightType: "S" | "N" | "G" | "M" | "X";
    aircraftTypeIcao: string, //Aircraft ICAO Code 2-4 chars

    equipmentCodes: string,
    surveillanceEquipmentCodes: string

    departureDateTime: Date;

    departure: FlightPlanPoint;
    destination: FlightPlanPoint;
    routePoints: FlightPlanZZZZPoint[];

    altn1?: string;
    altn2?: string;

    flightId?: string,
    operatorName?: string

    cruiseSpeed: { unit: "N" | "K", value: number };
    cruiseLevel: { unit: "VFR" | "F" | "A" | "S" | "M", value: number };

    field18Remarks: string,
    sarEquipment: AirctaftSarEquipment,

    totalEETMins: number, //minutes
    enduranceMins: number, //minutes

    personsOnBoard: number,
    
    picFplDetails: string
    picNotificationEmail?: string

    //isCostSharing?: boolean

    aftnAddresses?: string[]

    fplFile: FlightFplFile
}

export type FlightPlanPoint = FlightPlanIcaoPoint | FlightPlanZZZZPoint    

export type FlightPlanIcaoPoint = {
    isIcao: true,
    icaoCode: string
    coords: string //GeoPoint
}

export type FlightPlanZZZZPoint = {
    isIcao: false,
    name: string,
    coords: string //GeoPoint
}

export default FlightPlan

export function getFlightPlanDisplayInfo(): EntityDetailsDisplayInfo<FlightPlan> {
    return {
        entityName: "Flight Plan",
        nextButtonTitle: "Save & Preview FPL",
        entityCanBeDeleted: true,
        sections:[
            {
                sectionName: "Date & Time",
                sectionId: "date",
                inputs: [
                    {
                        required: true,
                        name: "Departure Date (UTC)",
                        timeName: "Departure Time (UTC)",
                        key: "departureDateTime",
                        type: "dateTime",
                        defaultValue: getDefaultDateForFlightDateTimePicker(),
                        useUtc: true,
                        dateFlatpickrOptions: {
                            dateFormat: "d-m-Y",
                        },
                        timeFlatpickrOptions: {
                            dateFormat: "H:i",
                            time_24hr: true,
                            minuteIncrement: 5,
                        },
                        validateRules: {
                            validate: {
                                inFuture: (value) => {
                                    const now = new Date();
                                    const utcNow = new Date(
                                        now.getUTCFullYear(),
                                        now.getUTCMonth(),
                                        now.getUTCDate(),
                                        now.getUTCHours(),
                                        now.getUTCMinutes()
                                    );

                                    if ((value as Date) < utcNow) {
                                        return "Departure Date and Time must be in the future";
                                    }

                                    return true;
                                }
                            }
                        }
                    }
                ]
            },
            {
                sectionName: "Aircraft",
                sectionId: "aircraft",
                inputs: [
                    {
                        name: "Aircraft Registration",
                        key: "aircraftRegistration",
                        type: "text",
                        uppercase: true,
                        required: true,
                        validateRules: {
                            pattern: {
                                value: /^[A-Z\d]{2,7}$/,
                                message: "Invalid aircraft registration"
                            }
                        }   
                    },
                    {
                        name: "Aircraft Type ICAO",
                        key: "aircraftTypeIcao",
                        type: "text",
                        uppercase: true,
                        required: true,
                        validateRules: {
                            pattern: {
                                value: /^[A-Z\d]{2,4}$/,
                                message: "Invalid aircraft type ICAO"
                            }
                        }
                    }
                ]
            },
            {
                sectionName: "Route",
                sectionId: "route",
                inputs: [
                    {
                        name: "DEP",
                        key: "departure",
                        type: "flightPlanPoint",
                        required: true
                    },
                    {
                        name: "DEST",
                        key: "destination",
                        type: "flightPlanPoint",
                        required: true
                    },
                    {
                        name: "Route",
                        key: "routePoints",
                        type: "customRoute"
                    },
                    {
                        name: "Alternate 1",
                        key: "altn1",
                        type: "text",
                        uppercase: true
                    },
                    {
                        name: "Alternate 2",
                        key: "altn2",
                        type: "text",
                        uppercase: true
                    },
                ]
            },
            {
                sectionName: "Flight Info",
                sectionId: "flightinfo",
                inputs: [

                    {
                        name: "Flight Rules",
                        key: "flightRules",
                        type: "dropdown",
                        options: [
                            { value: "V", label: "(V) VFR" },
                            { value: "I", label: "(I) IFR" },
                            { value: "Y", label: "(Y) IFR->VFR" },
                            { value: "Z", label: "(Z) VFR->IFR" },
                        ],
                        required: true
                    },
                    {
                        name: "Type of Flight",
                        key: "flightType",
                        type: "dropdown",
                        options: [
                            { value: "S", label: "(S) Scheduled" },
                            { value: "N", label: "(N) Non-Scheduled" },
                            { value: "G", label: "(G) General" },
                            { value: "M", label: "(M) Military" },
                            { value: "X", label: "(X) Other" },
                        ],
                        required: true
                    },
                    {
                        name: "Cruising Speed",
                        key: "cruiseSpeed",
                        type: "dropdownInput",
                        options: [
                            { value: "N", label: "(N) Knots" },
                            { value: "K", label: "(K) Km/h" },
                        ],
                        required: true
                    },
                    {
                        name: "Cruising Level",
                        key: "cruiseLevel",
                        type: "dropdownInput",
                        options: [
                            { value: "VFR", label: "(V) VFR", hardcodedInputValue: "VFR" },
                            { value: "F", label: "(F) Fl.Lvl (100ft)" },
                            { value: "A", label: "(A) Alt. (100ft)" },
                            { value: "S", label: "(S) Std. Lvl (Dm)" },
                            { value: "M", label: "(M) Metric (Dm)" },
                        ],
                        required: true
                    },
                    {
                        name: "Total EET (mins)",
                        key: "totalEETMins",
                        type: "time-autoset",
                        getCalculatedValue: (fplan) => {
                            if (fplan.cruiseSpeed.value === 0 || fplan.departure.coords === "" || fplan.destination.coords === ""){
                                return 0
                            }
                            var mins: number
                            var distanceNM = calculateDistanceBetweenRoutePoints(
                                getRoutePointFromFlightPlanPoint(fplan.departure),
                                getRoutePointFromFlightPlanPoint(fplan.destination),
                                fplan.routePoints.filter((p) => p.coords !== "").map((p) => getRoutePointFromFlightPlanZZZZPoint(p))
                            )
                            if (fplan.cruiseSpeed.unit === "N") {
                                mins = distanceNM / (fplan.cruiseSpeed.value / 60)
                            } else {
                                mins = distanceNM / ((fplan.cruiseSpeed.value * 0.539) / 60)
                            }
                            
                            //Round up to next 5 mins
                            return Math.ceil(Math.round(mins) / 5) * 5

                        },
                        required: true,
                        validateRules: {
                            min: {
                                value: 5,
                                message: "Total EET must be at least 5 mins"
                            }
                        }
                    },
                    {
                        name: "Endurance (mins)",
                        key: "enduranceMins",
                        type: "time-autoset",
                        getCalculatedValue: (fplan) => { 
                            if (!fplan.aircraft){
                                return 0
                            }
                            var totalFuelInFuelUnit = undefined
                            if (fplan.aircraft.wb){
                                const totalFuelInMass = fplan.aircraft.wb.stations.filter((s) => s.type === "fuel").map((s) => s.maxMass).reduce((a,b) => a+b, 0)
                                totalFuelInFuelUnit = FuelConverter.toFuelUnit(fplan.aircraft, totalFuelInMass)
                            }else if (fplan.aircraft.totalFuelCapacity){
                                totalFuelInFuelUnit = fplan.aircraft.totalFuelCapacity
                            }
                            if (totalFuelInFuelUnit === undefined){
                                return 0
                            }
                            
                            const fuelPerHour = fplan.aircraft.fuelPerHour
                            const perMin = fuelPerHour / 60
                            const totalMins = totalFuelInFuelUnit / perMin

                            //Round up to next 5 mins
                            return Math.ceil(Math.round(totalMins)/5)*5
                        },
                        required: true,
                        validateRules: {
                            min: {
                                value: 5,
                                message: "Endurance must be at least 5 mins"
                            }
                        }
                    }

                ]
            },
            {
                sectionName: "Operator",
                sectionId: "operator",
                inputs: [
                    {
                        name: "Operator Name",
                        key: "operatorName",
                        type: "text",
                        uppercase: true,
                        required: false
                    },
                    {
                        name: "Flight ID",
                        key: "flightId",
                        type: "text",
                        uppercase: true,
                        required: false,
                        validateRules: {
                            pattern: {
                                value: /^([A-Z\d]{2,7})$/,
                                message: "Invalid Flight ID"
                            }
                        }
                    },
                ]
            },
            {
                sectionName: "SAR",
                sectionId: "sar",
                inputs: [
                    {
                        name: "Equipment Codes",
                        key: "equipmentCodes",
                        type: "text",
                        uppercase: true,
                        required: true,
                        validateRules: {
                            pattern: {
                                value: /^((A|B|C|D|E1|E2|E3|F|G|H|I|J1|J2|J3|J4|J5|J6|J7|K|L|M1|M2|M3|O|P1|P2|P3|P4|P5|P6|P7|P8|P9|R|S|T|U|V|W|X|Y|Z)+)$|^N$/,
                                message: "Invalid equipment code"
                            }
                        }
                    },
                    {
                        name: "Surveillance Equipment Codes",
                        key: "surveillanceEquipmentCodes",
                        type: "text",
                        uppercase: true,
                        required: true,
                        validateRules: {
                            pattern: {
                                value: /^((A|C|E|H|I|L|P|S|X|B1|B2|U1|U2|V1|V2|D1|G1)+)$|^N$/,
                                message: "Invalid surveillance equipment code"
                            }
                        }
                    },
                    {
                        name: "Survival Equipment",
                        key: "sarEquipment",
                        type: "aircraftSar",
                        required: true
                    },
                    {
                        name: "Persons on board",
                        key: "personsOnBoard",
                        type: "number",
                        required: true,
                        validateRules: {
                            min: {
                                value: 1,
                                message: "Must have at least 1 person on board"
                            }
                        }
                    },
                    {
                        name: "Field 18 Remarks RMK/",
                        key: "field18Remarks",
                        type: "text",
                        uppercase: true,
                        multiline: true,
                        validateRules: {
                            pattern: {
                                value: /^([0-9]|[ '()+,=?./]|[A-Z])+$/,
                                message: "Invalid characters. Supported characters are 0-9, A-Z, space, '()+,=?./"
                            }
                        }
                    }
                ]
            },
            {
                sectionName: "PIC",
                sectionId: "pic",
                inputs: [
                    {
                        name: "PIC Name and Telephone for Flight Plan",
                        key: "picFplDetails",
                        type: "text",
                        uppercase: true,
                        required: true,
                        validateRules: {
                            pattern: {
                                value: /^([0-9]|[ '()+,=.]|[A-Z]){1,50}$/,
                                message: "Invalid pic. Supported characters are 0-9, A-Z, space, '()+,=."
                            }
                        }
                    },
                    {
                        name: "PIC Email for Notifications",
                        key: "picNotificationEmail",
                        type: "text",
                        uppercase: false,
                        required: false,
                        validateRules: {
                            pattern: {
                                value: /^([a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})?$/,
                                message: "Invalid email address"
                            }
                        }
                    }
                ]
            },
            {
                sectionName: "AFTN Addresses",
                sectionId: "aftn",
                inputs: [
                    {
                        name: "AFTN Addresses",
                        key: "aftnAddresses",
                        type: "aftnAddresses"
                    }
                ]
            },
            {
                sectionName: "Crew (GenDec only)",
                sectionId: "gendec",
                inputs: [
                    {
                        name: "PIC",
                        key: "pic",
                        type: "crew",
                    },
                    {
                        name: "SIC",
                        key: "sic",
                        type: "crew",
                    },
                    {
                        name: "Deadhead Crew",
                        key: "deadheadCrew",
                        type: "crew",
                    },
                    {
                        name: "Student Pilot",
                        key: "studentPilot",
                        type: "crew",
                    },
                ]
            },
            {
                sectionName: "Passengers (GenDec only)",
                sectionId: "passengers",
                inputs: [
                    {
                        name: "Passengers",
                        key: "passengers",
                        type: "passengers",
                        requiredPassengerFields: ["lastName", "weight"]
                    },
                    {
                        name: "Is Cost Sharing flight",
                        key: "isCostSharing",
                        type: "boolean"
                    }
                ]
            }

        ]
    }
}

function getDefaultDateForFlightDateTimePicker() {
    const now = new Date();
    const d = new Date(now.getTime() + 25 * 60 * 1000);
    d.setMinutes(Math.ceil(d.getMinutes() / 5) * 5);
    return d;
}

export type FlightPlanQuickAdd = {
    id?: string; //not used
    
    aircraft?: Aircraft;
    flightId?: string,
    flightIdType?: "reg" | "aircraft" | "crew" | "custom",

    pic?: Crew
    passengers?: Passenger[]
    route?: Route
}

export function getFlightPlanAddDisplayInfo(): EntityDetailsDisplayInfo<FlightPlanQuickAdd> {
    return {
        entityName: "Quick Flight Plan",
        hardcodedTitle: "Quick FPL",
        nextButtonTitle: "Next",
        sections:[
            {
                sectionName: "Aircraft",
                sectionId: "aircraft",
                shouldDisplay: (q) => q.aircraft === undefined,
                inputs: [
                    {
                        name: "Aircraft",
                        key: "aircraft",
                        type: "aircraft",
                    }
                ]
            },
            {
                sectionName: "Aircraft",
                sectionId: "aircraft",
                shouldDisplay: (q) => q.aircraft !== undefined,
                inputs: [
                    {
                        name: "Aircraft",
                        key: "aircraft",
                        type: "aircraft",
                    },
                    {
                        name: "Flight ID",
                        key: "flightId",
                        flightIdTypeKey: "flightIdType",
                        type: "flightId"
                    }
                ]
            },
            {
                sectionName: "Crew",
                sectionId: "crew",
                inputs: [
                    {
                        name: "PIC",
                        key: "pic",
                        type: "crew",
                    },
                ]
            },
            {
                sectionName: "Route",
                sectionId: "route",
                inputs: [
                    {
                        name: "Route",
                        key: "route",
                        type: "route",
                    },
                ]
            },
            {
                sectionName: "Passengers (GenDec only)",
                sectionId: "passengers",
                inputs: [
                    {
                        name: "Passengers",
                        key: "passengers",
                        requiredPassengerFields: ["lastName", "weight"],
                        type: "passengers"
                    }
                ]
            }
        ]
    }
}

export function createFlightPlanFromFlightPlanQuickAdd(q: FlightPlanQuickAdd): FlightPlan {
    return {
        aircraftRegistration: q.aircraft?.registration.replace("-","") ?? "",
        flightRules: "V",
        flightType: "G",
        aircraftTypeIcao: q.aircraft?.icaoType ?? "",
        equipmentCodes: q.aircraft?.equipmentCodes ?? "",
        surveillanceEquipmentCodes: q.aircraft?.surveillanceEquipmentCodes ?? "",
        departureDateTime: getDefaultDateForFlightDateTimePicker(),
        departure: getFPLPointFromRoutePoint(q.route?.departure) ?? "",
        destination: getFPLPointFromRoutePoint(q.route?.destination) ?? "",
        routePoints: q.route?.waypoints.map((p) => ({isIcao: false, name: p.name, coords: getFplCoordsFromRoutePoint(p)})) ?? [],
        altn1: q.route?.altn1 ?? "",
        altn2: q.route?.altn2 ?? "",
        flightId: q.flightIdType !== "reg" ? q.flightId : undefined,
        operatorName: q.aircraft?.operatorFullName ?? "", 
        cruiseSpeed: q.aircraft?.cruiseSpeed ?? { unit: "N", value: 90 },
        cruiseLevel: { unit: "VFR", value: 0 },
        field18Remarks: "",
        sarEquipment: q.aircraft?.sarEquipment ?? emptyAircraftSarEquipment,
        totalEETMins: 0,
        enduranceMins: 0,
        personsOnBoard: 1 + (q.passengers?.length ?? 0),
        picFplDetails: q.pic?.flightPlanPicDetails ?? "",
        picNotificationEmail: q.pic?.email ?? "",
        aircraft: q.aircraft,
        pic: q.pic,
        passengers: q.passengers ?? [],
        aftnAddresses: q.route?.aftnAddresses,
        fplFile:{
            status: "error",
            error: "FPL not ready"
        }
    }
}

export const getFPLPointFromRoutePoint = function getFPLPointFromRoutePoint(routePoint?: RoutePoint): FlightPlanPoint {
    if (!routePoint) {
        return {isIcao: true, icaoCode: "", coords: ""}
    }

    if (routePoint.name.match(/^[A-Z]{4}$/)) {
        return {isIcao: true, icaoCode: routePoint.name, coords: convertLatLonToGeoPoint(routePoint.lat, routePoint.lon)}
    }else{
        return {
            isIcao: false,
            name: routePoint.name,
            coords: getFplCoordsFromRoutePoint(routePoint)
        }
    }
}

export const getRoutePointFromFlightPlanZZZZPoint = (p: FlightPlanZZZZPoint): RoutePoint => {
    return {
        name: p.name,
        ...convertGeoPointToLatLon(p.coords)
    }
}

export const getRoutePointFromFlightPlanPoint = function getRoutePointFromFlightPlanPoint(fplPoint: FlightPlanPoint): RoutePoint {
    return {
        name: fplPoint.isIcao ? fplPoint.icaoCode : fplPoint.name,
        ...convertGeoPointToLatLon(fplPoint.coords),
    };
}

export const getDisplayableTextFromFlightPlanPoint = (point: FlightPlanPoint) => {
    if (point.isIcao) {
        return point.icaoCode
    } else if (point.isIcao === false) {
        return point.name
    } else{
        throw new Error("Invalid FlightPlanPoint")
    }
}