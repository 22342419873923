import { Button, Col, Container, Modal, Offcanvas, Row } from "react-bootstrap"
import EntityDetailsPage from "../../components/EntityDetails/EntityDetailsPage";
import ToolsFlight from "../../models/toolsFlight";
import { useEffect, useMemo, useRef, useState } from "react";
import { EntityDisplayInfo } from "../../components/EntityDetails/EntityDetailsDisplayInfo";
import { AviTracerApi } from "../../avitracerApi";
import moment from "moment";
import { getDisplayableTextFromFlightPlanPoint, getRoutePointFromFlightPlanPoint } from "../../models/flightPlan";
import SignatureCanvas from 'react-signature-canvas'
import { RoutePoint, calculateDistanceBetweenRoutePoints } from "../../models/route";
import LoadingComponent from "../../components/LoadingComponent";
import EntityRouteMapComponent from "../../components/EntityDetails/flight/EntityRouteMapComponent";
import { useSearchParams } from "react-router-dom";
import { getPassengerFullName } from "../../models/passenger";

function FlightDocsToolsPage() {

  const [flight, setFlight] = useState<ToolsFlight>(() => {
    if (
      localStorage.getItem("tools-flight-flightdocs-date") &&
      moment(parseInt(localStorage.getItem("tools-flight-flightdocs-date")!)).isBefore(moment().subtract(2, "days"))
    ) {
      localStorage.removeItem("tools-flight-flightdocs-date");
      localStorage.removeItem("tools-flight-flightdocs");
    }

    if (localStorage.getItem("tools-flight-flightdocs")) {
      return JSON.parse(localStorage.getItem("tools-flight-flightdocs")!);
    } else {
      return {} as unknown as ToolsFlight;
    }
  });

  const [showSignupModal, setShowSignupModal] = useState<boolean>(false);

  const [inEditMode, setInEditMode] = useState<boolean>(false);

  useEffect(() => {
    localStorage.setItem("tools-flight-flightdocs", JSON.stringify(flight));
    localStorage.setItem("tools-flight-flightdocs-date", Date.now().toString());
  }, [flight]);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has("signup")){
      setShowSignupModal(true);
    }else{
      setShowSignupModal(false);
    }
  }, [searchParams, setShowSignupModal])

  const showSignupModalPressed = () => {
    searchParams.set("signup", "tools");
    setSearchParams(searchParams);
  };

  const signatureRef = useRef<SignatureCanvas>(null);
  const [signatureSaveAction, setSignatureSaveAction] = useState<(() => void) | null>(null);
  const [signatureName, setSignatureName] = useState<string>("");
  const [downloading, setDownloading] = useState<"form731" | "gendec" | "icaofpl" | undefined>(undefined);

  const downloadPressed = async (docName: "form731" | "gendec" | "icaofpl") => {
    setDownloading(docName);

    const pdfData = await AviTracerApi.getToolsPdfDoc(flight, docName);
    const pdfBlob = new Blob([pdfData], { type: "application/pdf" });
    var pdfURL = window.URL.createObjectURL(pdfBlob);

    const link = document.createElement("a");
    link.download = flight.registration + "-" + moment(flight.departureDateTime).utc().format("DD-MM-YY-HHmm") + "-" + docName + ".pdf";
    link.href = pdfURL;
    link.target = "_blank";
    document.body.appendChild(link);

    link.click();
    setDownloading(undefined);
  };

  const [weatherImageData, setWeatherImageData] = useState<Blob | undefined>();
  const [showWeatherModal, setShowWeatherModal] = useState<boolean>(false);

  const getWeatherPressed = async () => {
    setShowWeatherModal(true);
    if (!weatherImageData) {
      const weatherData = await AviTracerApi.getToolsGramet(flight);

      setWeatherImageData(weatherData);
    }
  };

  useEffect(() => {
    setWeatherImageData(undefined);
  }, [flight.departure, flight.destination, flight.routePoints, flight.cruiseLevel, flight.departureDateTime]);

  const dateValue: DocumentRowValueType = useMemo(() => {
    return {
      name: "Date",
      value: moment(flight.departureDateTime).format("DD/MM/YYYY"),
    };
  }, [flight.departureDateTime]);

  const aircraftValue: DocumentRowValueType = useMemo(() => {
    return {
      name: "Aircraft",
      value: flight.registration?.length > 0 ? flight.registration : undefined,
    };
  }, [flight.registration]);

  const routeValue: DocumentRowValueType = useMemo(() => {
    return {
      name: "Route",
      value:
        flight.departure && flight.destination
          ? getDisplayableTextFromFlightPlanPoint(flight.departure) + " - " + getDisplayableTextFromFlightPlanPoint(flight.destination)
          : undefined,
    };
  }, [flight.departure, flight.destination]);

  const waypointsValue: DocumentRowValueType = useMemo(() => {
    return {
      name: "Waypoints",
      value:
        flight.routePoints?.length > 0 ? flight.routePoints.map((p) => getDisplayableTextFromFlightPlanPoint(p)).join(" ➜ ") : undefined,
    };
  }, [flight.routePoints]);

  const picValue: DocumentRowValueType = useMemo(() => {
    return {
      name: "PIC",
      value: flight.crew?.pic?.lastName ? [flight.crew.pic.lastName, flight.crew.pic.firstName].filter((e) => e).join(" ") : undefined,
    };
  }, [flight.crew?.pic?.lastName, flight.crew?.pic?.firstName]);

  const passengersValue: DocumentRowValueType = useMemo(() => {
    return {
      name: "Passengers",
      isOptional: true,
      value:
        flight.passengers?.length > 0
          ? flight.passengers
              .map((p) => {
                return getPassengerFullName(p);
              })
              .join(", ")
          : undefined,
    };
  }, [flight.passengers]);

  const otherCrewValue: DocumentRowValueType = useMemo(() => {
    return {
      name: "Other crew",
      isOptional: true,
      value:
        flight.crew?.other?.lastName && flight.crew?.other?.function
          ? [flight.crew.other.lastName, flight.crew.other.firstName]
              .filter((e) => e)
              .join(" ")
              .concat(" (", flight.crew.other.function, ")")
          : undefined,
    };
  }, [flight.crew?.other?.lastName, flight.crew?.other?.firstName, flight.crew?.other?.function]);

  const isCostSharingValue: DocumentRowValueType = useMemo(() => {
    return {
      name: "Cost sharing",
      isOptional: true,
      value: flight.isCostSharing !== undefined ? (flight.isCostSharing ? "Yes" : "No") : undefined,
    };
  }, [flight.isCostSharing]);

  const distanceValue: DocumentRowValueType = useMemo(() => {
    if (flight.departure && flight.destination && flight.routePoints?.length > 0) {
      const distanceNm = calculateDistanceBetweenRoutePoints(
        getRoutePointFromFlightPlanPoint(flight.departure),
        getRoutePointFromFlightPlanPoint(flight.destination),
        flight.routePoints.map((p) => getRoutePointFromFlightPlanPoint(p))
      );

      return {
        name: "Distance",
        isOptional: true,
        value: `${distanceNm} nm`,
      };
    } else {
      var errorElements = [];
      if (!flight.departure) {
        errorElements.push("Departure");
      }
      if (!flight.destination) {
        errorElements.push("Destination");
      }
      if (!flight.routePoints || flight.routePoints?.length === 0) {
        errorElements.push("Waypoints");
      }

      return {
        name: "Distance",
        error: "Missing " + errorElements.join(", "),
        isOptional: true,
        value: undefined,
      };
    }
  }, [flight.departure, flight.destination, flight.routePoints]);

  const totalTimeValue: DocumentRowValueType = useMemo(() => {
    if (flight.totalEETMins && flight.cruiseSpeed) {
      const totalEETmins = flight.totalEETMins;
      return {
        name: "Total time",
        isOptional: true,
        value: `${Math.floor(totalEETmins / 60)}:${`${totalEETmins % 60}`.padStart(2, "0")} (${totalEETmins} mins) @ ${
          flight.cruiseSpeed.value
        } ${flight.cruiseSpeed.unit === "N" ? "kn" : "km/h"}`,
      };
    } else {
      var errorElements = [];
      if (!flight.cruiseSpeed) {
        errorElements.push("Cruise speed");
      }
      if (errorElements.length === 0) {
        if (!flight.departure) {
          errorElements.push("Departure");
        }
        if (!flight.destination) {
          errorElements.push("Destination");
        }
        if (!flight.routePoints || flight.routePoints?.length === 0) {
          errorElements.push("Waypoints");
        }
      }

      return {
        name: "Total time",
        isOptional: true,
        error: "Missing " + errorElements.join(", "),
        value: undefined,
      };
    }
  }, [flight.cruiseSpeed, flight.departure, flight.destination, flight.routePoints, flight.totalEETMins]);

  const enduranceValue: DocumentRowValueType = useMemo(() => {
    if (flight.enduranceMins) {
      const enduranceMins = flight.enduranceMins;
      return {
        name: "Endurance",
        isOptional: true,
        value: `${Math.floor(enduranceMins / 60)}:${`${enduranceMins % 60}`.padStart(2, "0")} (${enduranceMins} mins) @ ${
          flight.fuelPerHour
        } ${flight.fuelUnit}/h`,
      };
    } else {
      var errorElements = [];
      if (!flight.fuelOnBoard) {
        errorElements.push("Fuel on board");
      }
      if (!flight.fuelPerHour) {
        errorElements.push("Fuel per hour");
      }

      return {
        name: "Endurance",
        isOptional: true,
        error: "Missing " + errorElements.join(", "),
        value: undefined,
      };
    }
  }, [flight.enduranceMins, flight.fuelOnBoard, flight.fuelPerHour, flight.fuelUnit]);

  const fuelRequiredValue: DocumentRowValueType = useMemo(() => {
    if (
      flight.fuelOnBoard &&
      flight.fuelPerHour &&
      flight.departure &&
      flight.destination &&
      flight.routePoints?.length > 0 &&
      flight.cruiseSpeed
    ) {
      const distanceNm = calculateDistanceBetweenRoutePoints(
        getRoutePointFromFlightPlanPoint(flight.departure),
        getRoutePointFromFlightPlanPoint(flight.destination),
        flight.routePoints.map((p) => getRoutePointFromFlightPlanPoint(p))
      );

      const totalEETDecimal =
        flight.cruiseSpeed.unit === "N" ? distanceNm / flight.cruiseSpeed.value : distanceNm / (flight.cruiseSpeed.value * 0.54);

      const fuelRequired = Math.round(totalEETDecimal * flight.fuelPerHour);

      return {
        name: "Fuel required",
        isOptional: true,
        value: `${fuelRequired} ${flight.fuelUnit}`,
      };
    } else {
      var errorElements = [];
      if (!flight.fuelOnBoard) {
        errorElements.push("Fuel on board");
      }
      if (!flight.fuelPerHour) {
        errorElements.push("Fuel per hour");
      }
      if (!flight.cruiseSpeed) {
        errorElements.push("Cruise speed");
      }
      if (errorElements.length === 0) {
        if (!flight.departure) {
          errorElements.push("Departure");
        }
        if (!flight.destination) {
          errorElements.push("Destination");
        }
        if (!flight.routePoints || flight.routePoints?.length === 0) {
          errorElements.push("Waypoints");
        }
      }

      return {
        name: "Fuel required",
        isOptional: true,
        error: "Missing " + errorElements.join(", "),
        value: undefined,
      };
    }
  }, [
    flight.fuelOnBoard,
    flight.fuelPerHour,
    flight.departure,
    flight.destination,
    flight.routePoints,
    flight.cruiseSpeed,
    flight.fuelUnit,
  ]);

  const aircraftTypeValue: DocumentRowValueType = useMemo(() => {
    return {
      name: "Aircraft type",
      value: flight.aircraftTypeIcao && flight.aircraftTypeIcao?.length > 0 ? flight.aircraftTypeIcao : undefined,
    };
  }, [flight.aircraftTypeIcao]);

  const cruiseSpeedValue: DocumentRowValueType = useMemo(() => {
    return {
      name: "Cruise speed",
      value:
        flight.cruiseSpeed && flight.cruiseSpeed?.value > 0
          ? `${flight.cruiseSpeed.value} ${flight.cruiseSpeed.unit === "N" ? "kn" : "km/h"}`
          : undefined,
    };
  }, [flight.cruiseSpeed]);

  const cruiseLevelValue: DocumentRowValueType = useMemo(() => {
    return {
      name: "Cruise level",
      isOptional: true,
      value: flight.cruiseLevel ? `${flight.cruiseLevel.value} ${flight.cruiseLevel.unit}` : undefined,
    };
  }, [flight.cruiseLevel]);

  const alternateOneValue: DocumentRowValueType = useMemo(() => {
    return {
      name: "Alternate",
      isOptional: true,
      value: flight.altn1 && flight.altn1?.length > 0 ? flight.altn1 : undefined,
    };
  }, [flight.altn1]);

  useEffect(() => {
    if (flight.departure && flight.destination && flight.routePoints?.length > 0 && flight.cruiseSpeed) {
      const distanceNm = calculateDistanceBetweenRoutePoints(
        getRoutePointFromFlightPlanPoint(flight.departure),
        getRoutePointFromFlightPlanPoint(flight.destination),
        flight.routePoints.map((p) => getRoutePointFromFlightPlanPoint(p))
      );

      flight.totalEETMins = Math.round(
        (flight.cruiseSpeed.unit === "N" ? distanceNm / flight.cruiseSpeed.value : distanceNm / (flight.cruiseSpeed.value * 0.54)) * 60
      );
    } else {
      flight.totalEETMins = undefined;
    }
  }, [flight.departure, flight.destination, flight.routePoints, flight.cruiseSpeed, flight]);

  useEffect(() => {
    if (flight.fuelOnBoard && flight.fuelPerHour) {
      flight.enduranceMins = Math.round((flight.fuelOnBoard / flight.fuelPerHour) * 60);
    } else {
      flight.enduranceMins = undefined;
    }
  }, [flight.fuelOnBoard, flight.fuelPerHour, flight]);

  const [mapRoutePoints, setMapRoutePoints] = useState<(RoutePoint & { isStop: boolean })[]>([]);

  useEffect(() => {
    if (flight.departure && flight.destination) {
      const flightPlanPoints = [flight.departure, ...(flight.routePoints ?? []), flight.destination];

      setMapRoutePoints(
        flightPlanPoints.map((p) => {
          return { isStop: false, ...getRoutePointFromFlightPlanPoint(p) };
        })
      );
    }
  }, [flight.departure, flight.destination, flight.routePoints]);

  return (
    <>
      {signatureSaveAction !== null && (
        <SignatureModal signatureRef={signatureRef} savePressed={signatureSaveAction} name={signatureName} />
      )}

      <Row className="pb-5 pt-2 justify-content-center text-center">
        <Col xs={12}>
          <h1 className="fw-bold">Prepare your next Flight</h1>
        </Col>
      </Row>
      <Row className="gx-0 gy-4">
        <Col xs={12} lg={8}>
          <Row className="gx-0 mx-1 rounded border">
            <Col xs={12} md={6}>
              <EntityRouteMapComponent routePoints={mapRoutePoints} mapHeight="400px" />
            </Col>
            <Col xs={12} md={6}>
              <div className="h-100 px-4 py-3 d-flex flex-column justify-content-between">
                <div className="d-flex justify-content-between">
                  <h3 className="fw-bold">Your flight</h3>
                  <Button variant="light" onClick={showSignupModalPressed}>
                    Import route
                  </Button>
                </div>
                <Row>
                  {[dateValue, aircraftValue, routeValue, picValue, passengersValue, waypointsValue].map((v) => {
                    return (
                      <Col xs={12} key={v.name}>
                        <div className="d-flex align-items-center ">
                          <DotComponent isOk={v.value !== undefined} isOptional={v.isOptional} />
                          <div>
                            {v.name}: <strong>{v.value ?? "Not set"}</strong>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
                <Row className="pt-3 gy-2">
                  <Col xs={{ span: 8, order: "last" }} md={{ span: 12, order: "first" }}>
                    <div className="d-grid">
                      <Button variant="dark" className="py-3 fw-bold" onClick={() => setInEditMode(true)}>
                        Edit Flight Details
                      </Button>
                    </div>
                  </Col>
                  <Col xs={4} md={12}>
                    <div className="d-grid">
                      <Button variant="light" className="py-3 " onClick={() => setFlight({} as unknown as ToolsFlight)}>
                        Clear
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>

        <Col xs={12} lg={4}>
          <Row className="gx-0 mx-1 rounded border h-100">
            <Col xs={12}>
              <Row className="ps-4 py-3">
                <Col>
                  <h3 className="fw-bold">Signatures</h3>
                  <SignatureRowComponent
                    personFunction="PIC"
                    fullName={[flight.crew?.pic?.lastName, flight.crew?.pic?.firstName].filter((e) => e).join(" ")}
                    signatureOk={flight.crew?.pic?.signatureBase64 !== undefined}
                    signatureRef={signatureRef}
                    setSignatureName={setSignatureName}
                    setSignatureSaveAction={setSignatureSaveAction}
                    setFlight={setFlight}
                    saveTo="pic"
                  />
                  {flight.passengers?.map((p, index) => {
                    return (
                      <div key={p.id!}>
                        <hr className="my-2" />
                        <SignatureRowComponent
                          personFunction="PAX"
                          fullName={getPassengerFullName(p)}
                          signatureOk={p.signatureBase64 !== undefined}
                          signatureRef={signatureRef}
                          setSignatureName={setSignatureName}
                          setSignatureSaveAction={setSignatureSaveAction}
                          setFlight={setFlight}
                          saveTo={{ paxId: p.id! }}
                        />
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <DocumentRowComponent
        title="General Declaration (GenDec)"
        assetPath="/assets/brief/tools/gendec-thumb.jpeg"
        button={{
          text: "Download PDF",
          isDownloading: downloading === "gendec",
          onClick: () => downloadPressed("gendec"),
        }}
        values={[dateValue, aircraftValue, picValue, routeValue, passengersValue, otherCrewValue]}
      />
      <DocumentRowComponent
        title="Form 731 (Greece only)"
        assetPath="/assets/brief/tools/form731-thumb.jpeg"
        button={{
          text: "Download PDF",
          isDownloading: downloading === "form731",
          onClick: () => downloadPressed("form731"),
        }}
        values={[dateValue, aircraftValue, picValue, routeValue, passengersValue, isCostSharingValue]}
      />

      <DocumentRowComponent
        title="Flight Plan"
        assetPath="/assets/brief/tools/flightplan-thumb.jpeg"
        button={{
          text: "Download PDF",
          isDownloading: downloading === "icaofpl",
          onClick: () => downloadPressed("icaofpl"),
        }}
        secondButton={{
          text: "File FPL",
          onClick: showSignupModalPressed,
        }}
        values={[
          dateValue,
          aircraftValue,
          routeValue,
          waypointsValue,
          picValue,
          aircraftTypeValue,
          cruiseSpeedValue,
          alternateOneValue,
          totalTimeValue,
          enduranceValue,
        ]}
      />

      <DocumentRowComponent
        title="Enroute weather"
        assetPath="/assets/brief/tools/weather-thumb.jpeg"
        button={{
          text: "Get weather",
          isDownloading: false,
          onClick: getWeatherPressed,
        }}
        values={[dateValue, aircraftValue, routeValue, waypointsValue, picValue, aircraftTypeValue, cruiseSpeedValue, cruiseLevelValue]}
      />

      <DocumentRowComponent
        assetPath="/assets/brief/tools/fuel-thumb.jpeg"
        title="Fuel Planning"
        values={[distanceValue, totalTimeValue, enduranceValue, fuelRequiredValue]}
      />

      <DocumentRowComponent
        assetPath="/assets/brief/tools/wb-thumb.jpeg"
        title="Weight & Balance"
        button={{
          text: "Calculate WB",
          isDownloading: false,
          onClick: showSignupModalPressed,
        }}
        values={[dateValue, aircraftValue, aircraftTypeValue, routeValue, waypointsValue, picValue, otherCrewValue, passengersValue]}
      />

      <DocumentRowComponent
        assetPath="/assets/brief/tools/metar-thumb.jpeg"
        title="METAR & TAF"
        button={{
          text: "Get Codes",
          isDownloading: false,
          onClick: showSignupModalPressed,
        }}
        values={[dateValue, routeValue, waypointsValue, cruiseLevelValue]}
      />
      <DocumentRowComponent
        assetPath="/assets/brief/tools/notam-thumb.jpeg"
        title="NOTAMs"
        button={{
          text: "Get NOTAMs",
          isDownloading: false,
          onClick: showSignupModalPressed,
        }}
        values={[dateValue, routeValue, waypointsValue, cruiseLevelValue]}
      />

      <DocumentRowComponent
        assetPath="/assets/brief/tools/boardingpass-thumb.jpeg"
        title="Boarding Passes"
        button={{
          text: "Get Passes",
          isDownloading: false,
          onClick: showSignupModalPressed,
        }}
        values={[dateValue, aircraftValue, routeValue, picValue, passengersValue]}
      />

      <Modal size="xl" show={showWeatherModal} fullscreen={"lg-down"}>
        <Modal.Header closeButton onHide={() => setShowWeatherModal(false)}>
          <Modal.Title>Enroute Weather (GRAMET)</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {weatherImageData ? (
            <>
              <img width={"100%"} src={URL.createObjectURL(weatherImageData)} alt="weather" />
              <small className="fw-light">GRAMET provided by autorouter.com</small>
            </>
          ) : (
            <div className="pb-5">
              <LoadingComponent text="Loading..." />
            </div>
          )}
        </Modal.Body>
        {weatherImageData && (
          <Modal.Footer>
            <Button size="sm" variant="link" onClick={() => window.open("https://www.autorouter.aero/wiki/gramet/", "_blank")}>
              GRAMET Legend
            </Button>
          </Modal.Footer>
        )}
      </Modal>

      <div style={{ height: "80px" }} />

        <SignupModalComponent showSignupModal={showSignupModal} searchParams={searchParams} setSearchParams={setSearchParams} />


      {inEditMode && (
        <EntityDetailsPage
          entity={flight}
          displayInfo={EntityDisplayInfo.ToolsFlight()}
          handleClose={() => setInEditMode(false)}
          dispatchSaveEntity={(e) => {
            setFlight(e);
            setInEditMode(false);
            return Promise.resolve(e);
          }}
        />
      )}
    </>
  );
}

export default FlightDocsToolsPage

interface SignupModalComponentProps {
  showSignupModal: boolean;
  searchParams: URLSearchParams;
  setSearchParams: React.Dispatch<React.SetStateAction<URLSearchParams>>;
}

function SignupModalComponent({ showSignupModal, searchParams, setSearchParams }: SignupModalComponentProps) {
  return (
    <Offcanvas show={showSignupModal} placement="end" className="offcanvas-size-fullscreen-md">
      <Offcanvas.Header
        closeButton
        onHide={() => {
          searchParams.delete("signup");
          setSearchParams(searchParams);
        }}
      >
        <Offcanvas.Title></Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Container>
          <Row className="pb-3 justify-content-center">
            <Col xs={10} className="text-center">
              <div className="d-flex flex-column">
                <h2 className="fw-bold">BRIEF by AviTracer</h2>
                <h3>Plan, File, Fly!</h3>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={10} md={7}>
              <img src="/assets/brief/tools/brief-platform.png" alt="Brief Platform" className="w-100" />
            </Col>
          </Row>
          <Row className="pt-5 justify-content-center">
            <Col xs={"auto"}>
              <Button variant="dark" className="fw-bold" href="https://brief.avitracer.com/auth/register" target="_blank">
                Sign up now
              </Button>
            </Col>
            <Col xs={"auto"}>
              <Button variant="light" href="https://avitracer.com/brief" target="_blank">
                Learn more
              </Button>
            </Col>
          </Row>

          <Row className="pt-4 justify-content-begin">
            <Col xs={12}>
              <ul className="py-3">
                <li>
                  <strong>Reuse</strong> Aircraft, Crew and Pax
                </li>
                <li>
                  Full <strong>Briefing Pack PDF</strong> (GenDec, NOTAMs, Weather, NavLog, etc.)
                </li>
                <li>
                  <strong>File your FPLs</strong> using AFTN.
                </li>
                <li>
                  Personalized <strong>Boarding Passes</strong>
                </li>
                <li>
                  <strong>Import</strong> your route from <strong>Foreflight, AirNavPro.</strong>
                </li>
                <li>
                  Set <strong>intermediate stops</strong> using our interactive aviation map
                </li>
                <li>
                  <strong>Weight and Balance</strong>
                </li>
                <li>Autocalculate AFTN Addresses based on your route.</li>
                <li>and more...</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

interface SignatureModalProps {
  signatureRef: React.RefObject<SignatureCanvas>;
  savePressed: () => void;
  name: string
}

function SignatureModal({ signatureRef, savePressed, name }: SignatureModalProps) {
  const saving = false;

  return (
    <Modal show size="xl">
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <h3>{name}</h3>
            </Col>
          </Row>
          <Row>
            <div className="vh-50">
              <div className="bg-white w-100 bg-white border rounded-lg" style={{ height: "50vh" }}>
                <SignatureCanvas minWidth={2} maxWidth={5} ref={signatureRef} dotSize={3} canvasProps={{ className: "w-100 h-100" }} />
              </div>
            </div>
            <div className="pt-4">
              <div className="d-flex">
                <div className="w-25 d-grid  gap-2 m-2">
                  <Button
                    disabled={saving}
                    style={{ height: "60px" }}
                    variant="light"
                    className="fw-bold"
                    onClick={() => {
                      signatureRef.current!.clear();
                    }}
                  >
                    Clear
                  </Button>
                </div>
                <div className="w-75 d-grid gap-2 m-2">
                  <Button disabled={saving} style={{ height: "60px" }} className="fw-bold" variant="dark" onClick={savePressed}>
                    {saving ? "Saving..." : "Save signature"}
                  </Button>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}


interface SignatureRowComponentProps {
  personFunction: string;
  fullName: string;
  signatureOk: boolean;

  signatureRef: React.RefObject<SignatureCanvas>;

  setSignatureName: (name: string) => void;
  setSignatureSaveAction: (action: (() => void) | null) => void;

  setFlight: React.Dispatch<React.SetStateAction<ToolsFlight>>
  saveTo: "pic" | {paxId: string};
}

function SignatureRowComponent({ personFunction, fullName, signatureOk, signatureRef, setSignatureName, setSignatureSaveAction, setFlight, saveTo }: SignatureRowComponentProps) {
  return (
    <>
      <div className="d-flex align-items-center justify-content-between py-0">
        <div className="d-flex align-items-center">
          <DotComponent isOk={signatureOk} />
          <div>
            {personFunction}: <strong>{fullName}</strong>
          </div>
        </div>
        <Button
          className="me-4"
          variant="light"
          onClick={() => {
            setSignatureName(`${personFunction}: ${fullName}`);
            setSignatureSaveAction(() => {
              return () => {
                setFlight((oldFlight) => {
                  if (saveTo === "pic") {
                    return {
                      ...oldFlight,
                      crew: {
                        ...oldFlight.crew,
                        pic: {
                          ...oldFlight.crew?.pic,
                          signatureBase64: signatureRef.current?.isEmpty()
                            ? undefined
                            : signatureRef.current!.getTrimmedCanvas().toDataURL(),
                        },
                      },
                    };
                  } else {
                    const { paxId } = saveTo;
                    return {
                      ...oldFlight,
                      passengers: oldFlight.passengers?.map((passenger) => {
                        if (passenger.id === paxId) {
                          return {
                            ...passenger,
                            signatureBase64: signatureRef.current?.isEmpty()
                              ? undefined
                              : signatureRef.current?.getTrimmedCanvas().toDataURL(),
                          };
                        } else {
                          return passenger;
                        }
                      }),
                    };
                  }
                });
                setSignatureSaveAction(null);
              };
            });
          }}
        >
          Sign
        </Button>
      </div>
    </>
  );
}


type DocumentRowValueType = {name: string, value?: string, isOptional?: boolean, error?: string}

interface DocumentRowComponentProps {
  title: string;
  values: DocumentRowValueType[];
  assetPath: string;
  button?: {
    text: string;
    isDownloading: boolean;
    onClick: () => void;
  };
  secondButton?: {
    text: string;
    onClick: () => void;
  }
}

function DocumentRowComponent({ title, assetPath, values, button, secondButton }: DocumentRowComponentProps){

  const missingInfo = values.filter((v) => v.value === undefined && !v.isOptional).length > 0

  return (
    <Row className="gx-0 pt-4">
      <Col xs={12} className="">
        <Row className="mx-1 rounded border align-items-center h-100">
          <Col xs={12} lg={4} className="d-none d-lg-block rounded bg-light h-100 position-relative overflow-hidden">
            <div className="d-flex align-items-center justify-content-center h-100 p-2">
              <img src={assetPath} alt={title} className="w-100" />
            </div>
          </Col>
          <Col xs={12} lg={4} className="d-lg-none rounded bg-light">
            <div className="d-flex align-items-end justify-content-center h-100 p-2">
              <img src={assetPath} alt={title} className="w-50" />
            </div>
          </Col>

          <Col xs={12} sm={8} md={8} lg={6}>
            <div className="mx-3">
              <div className="d-flex flex-column justify-content-end h-25 pt-4">
                <h3 className="fw-bold">{title}</h3>
              </div>
              <Row className=" pb-3">
                {values.map((v) => {
                  return (
                    <Col xs={12} key={v.name}>
                      <div className="d-flex align-items-center ">
                        <DotComponent isOk={v.value !== undefined} isOptional={v.isOptional} />
                        <div>
                          {v.name}: <strong>{v.value ?? v.error ?? "Not set"}</strong>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Col>
          <Col xs={12} md={3} lg={2}>
            {button && (
              <div className="d-grid pb-2">
                <Button disabled={missingInfo || button.isDownloading} variant="light" size={"lg"} onClick={button.onClick}>
                  {button.isDownloading ? <>Preparing</> : <>{button.text}</>}
                </Button>
              </div>
            )}
            {secondButton && (
              <div className="d-grid pb-2">
                <Button disabled={missingInfo} variant="light" size={"lg"} onClick={secondButton.onClick}>
                  {secondButton.text}
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}


function DotComponent({isOk,isOptional}: {isOk: boolean, isOptional?: boolean}) {
  return (
    <div className={"me-1 ".concat(isOk ? "bg-success" : ((isOptional) ? "bg-warning" :"bg-danger"))} style={{ minWidth: "10px", minHeight:"10px", maxWidth: "10px", maxHeight:"10px", width: "10px", height: "10px", borderRadius: "50%" }} />
  );
}