import { Typeahead, TypeaheadInputMulti } from "react-bootstrap-typeahead";
import { Option, OptionHandler, TypeaheadPropsAndState } from "react-bootstrap-typeahead/types/types";
import { EntityDetailsDisplayInfoFieldCustomRoute } from "../EntityDetailsDisplayInfo";
import { Control, FieldValues, UseFormSetValue, useController} from "react-hook-form";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Token as RBTToken } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import { RoutePoint, convertGeoPointToLatLon, convertLatLonToGeoPoint, getDistanceFromLatLonInNm } from "../../../models/route";
import "../../../css/AutocompleteInput.css"
import { CupPoint, getAllCupPoints, getCupPointCoords } from "../../../utils/CupPointUtils";
import FlightPlan, { FlightPlanZZZZPoint } from "../../../models/flightPlan";
import EntityRouteMapComponent from "./EntityRouteMapComponent";
import { getRoutePointForIcaoAirportCode } from "../../../utils/IcaoDbUtils";
import Flight from "../../../models/flight";

interface EntityCustomRouteComponentProps<Entity extends FieldValues> {
  displayInfoInput: EntityDetailsDisplayInfoFieldCustomRoute<Entity>;
  control: Control<Entity, any>;
  allowNewCustomPoints: boolean
  flightPlanDeparture?: FlightPlan["departure"];
  flightPlanDestination?: FlightPlan["destination"];
  intermediateStops?: RoutePoint[];
  setValue?: UseFormSetValue<Flight>
}

type Point = {
  icaoCode: string; //for FPL
  name: string; //name of ZZZZ or friendly name for icao ones
  lat: number;
  lon: number
};

function EntityCustomRouteComponent<Entity extends FieldValues>({ displayInfoInput, control, allowNewCustomPoints, flightPlanDeparture, flightPlanDestination, intermediateStops, setValue }: EntityCustomRouteComponentProps<Entity> & { control: Control<Entity, any>}) {

    const {
      field: {value, onChange, ref},
      fieldState: { error },
      formState: { isSubmitting, isValid },
    } = useController({
      name: displayInfoInput.key,
      control,
      rules: {
        validate: {
          required: (v) => {
            if (displayInfoInput.required === false){
              return true
            }
            if (!v || v.length === 0) return "Required: Use DCT for routes without waypoints.";
          }
        }
      }
    });

  const [options, setOptions] = useState<Point[]>([]);

  useEffect(() => {
    const loadAsyncCupPoints = async () => {
      const cupPoints = await getAllCupPoints();
      setOptions(
        cupPoints.map((d) => ({
          icaoCode: d.code,
          name: d.name.toUpperCase(),
          ...getCupPointCoords(d),
        }))
      );
    };
    loadAsyncCupPoints();
  }, []);

  const [selected, setSelected] = useState<Point[]>(value ? (value as FlightPlanZZZZPoint[]).map((fplPoint) => {
    const cupPoint = options.find((o) => o.icaoCode === fplPoint.name);
    if (cupPoint) {
      return cupPoint
    }else{
      return {
        name: fplPoint.name,
        icaoCode: fplPoint.name,
        ...convertGeoPointToLatLon(fplPoint.coords)
      }
    }
  }) : []);

  const onMove = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const item = selected[dragIndex];

      const newSelected = selected.slice();
      newSelected.splice(dragIndex, 1);
      newSelected.splice(hoverIndex, 0, item);

      setSelected(newSelected);
    },
    [selected]
  );

  const [mapRoutePoints, setMapRoutePoints] = useState<(RoutePoint& { isStop: boolean })[]>([]);

  useEffect(() => {
    const updateRoutePoints = async () => {
      const newRoutePoints: (RoutePoint & { isStop: boolean })[] = selected.map((s, i) => ({
        name: s.icaoCode,
        lat: s.lat,
        lon: s.lon,
        isStop: intermediateStops?.some((stop) => stop.name === s.icaoCode) ?? false,
      }));

      var depPoint: (RoutePoint & { isStop: boolean }) | undefined = undefined;
      var destPoint: (RoutePoint & { isStop: boolean }) | undefined = undefined;

      //Refactor with gerRoutePointFromFlightPlanPoint
      if (flightPlanDeparture?.isIcao === true) {
        const icaoCode = flightPlanDeparture.icaoCode;
        const routePoint = await getRoutePointForIcaoAirportCode(icaoCode);
        if (routePoint) {
          depPoint = { ...routePoint, isStop: false };
        }
      } else if (flightPlanDeparture?.isIcao === false) {
        depPoint = {
          name: flightPlanDeparture.name,
          isStop: false,
          ...convertGeoPointToLatLon(flightPlanDeparture.coords),
        };
      }

      if (flightPlanDestination?.isIcao === true) {
        const icaoCode = flightPlanDestination.icaoCode;
        const routePoint = await getRoutePointForIcaoAirportCode(icaoCode);
        if (routePoint) {
          destPoint = { ...routePoint, isStop: false };
        }
      } else if (flightPlanDestination?.isIcao === false) {
        destPoint = {
          name: flightPlanDestination.name,
          isStop: false,
          ...convertGeoPointToLatLon(flightPlanDestination.coords),
        };
      }

      setMapRoutePoints(() => {
        if (depPoint && destPoint) {
          return [depPoint, ...newRoutePoints, destPoint];
        } else if (depPoint) {
          return [depPoint, ...newRoutePoints];
        } else if (destPoint) {
          return [...newRoutePoints, destPoint];
        } else {
          return newRoutePoints;
        }
      });

      onChange(
        newRoutePoints.map((r) => ({
          isIcao: false,
          name: r.name,
          coords: convertLatLonToGeoPoint(r.lat, r.lon),
        }))
      );
    };
    updateRoutePoints();
  }, [flightPlanDeparture, flightPlanDestination, onChange, options, selected, intermediateStops]);


  const insertRoutePointFromMap = useCallback(
    (point: CupPoint) => {
      const newSelected = [...selected];
      newSelected.push({
        icaoCode: point.code,
        name: point.name.toUpperCase(),
        ...getCupPointCoords(point),
      });

      setSelected(newSelected);
    },
    [selected]
  );

  const updatePassengerLegAndIntStopsAfterRemoveIfNeeded = (point: Point) => {
    if (intermediateStops && intermediateStops.some((i) => i.name === point.icaoCode)) {
      setValue!("passengersLeg.route", null as any);
      setValue!("passengersLeg", undefined);

      setValue!(displayInfoInput.intermediateStopsKey!, intermediateStops!.filter((i) => i.name !== point.icaoCode));
    }
  };
  
  const setAsIntermediateStopPoint = useCallback(
    (point: CupPoint) => {
      var oldIntStops = intermediateStops ?? [];
      const newIntStops = [...oldIntStops];
      newIntStops.push({
        name: point.code,
        ...getCupPointCoords(point),
      })
      setValue!(displayInfoInput.intermediateStopsKey!, newIntStops);
      setValue!("passengersLeg.route", null as any);
      setValue!("passengersLeg", undefined);
    },
    [displayInfoInput.intermediateStopsKey, intermediateStops, setValue]
  );

  const mapComponent = useMemo(() => {
    return (
      <EntityRouteMapComponent
        routePoints={mapRoutePoints}
        insertRoutePoint={insertRoutePointFromMap}
        setAsIntermediateStopPoint={displayInfoInput.intermediateStopsKey ? setAsIntermediateStopPoint : undefined}
      />
    );
  }, [displayInfoInput.intermediateStopsKey, insertRoutePointFromMap, mapRoutePoints, setAsIntermediateStopPoint]);

  return (
    <>
      {mapComponent}
      <div className="autocomplete-container">
        <DndProvider backend={HTML5Backend}>
          <Typeahead
            id="rawroute"
            ref={ref}
            disabled={(isValid && isSubmitting) || (displayInfoInput.disabled ?? false)}
            allowNew={allowNewCustomPoints}
            multiple
            onChange={(n) => setSelected(n as Point[])}
            labelKey={"icaoCode"}
            onBlur={(e) => {}}
            inputProps={{
              onInput: (e) => {
                const start = e.currentTarget.selectionStart;
                const end = e.currentTarget.selectionEnd;
                e.currentTarget.value = ("" + e.currentTarget.value).toUpperCase();
                e.currentTarget.setSelectionRange(start, end);
              },
            }}
            options={options.sort((a, b) => {
              var prev: { lat: number; lon: number } | undefined = undefined;
              if (selected.length === 0) {
                if (flightPlanDeparture?.isIcao === true) {
                  const icaoCode = flightPlanDeparture.icaoCode;
                  prev = options.find((v) => v.icaoCode === icaoCode);
                } else if (flightPlanDeparture?.isIcao === false) {
                  prev = convertGeoPointToLatLon(flightPlanDeparture.coords);
                }
              } else {
                prev = selected[selected.length - 1];
              }
              if (prev === undefined) {
                return 0;
              }

              const distA = getDistanceFromLatLonInNm(a.lat, a.lon, prev!.lat, prev!.lon);
              const distB = getDistanceFromLatLonInNm(b.lat, b.lon, prev!.lat, prev!.lon);

              return distA - distB;
            })}
            placeholder={selected.length ? "Add waypoint" : ""}
            filterBy={(option: Option, state: TypeaheadPropsAndState) => {
              var prev: Point | undefined = undefined;
              if (selected.length === 0) {
                if (flightPlanDeparture?.isIcao === true) {
                  const icaoCode = flightPlanDeparture.icaoCode;
                  prev = options.find((v) => v.icaoCode === icaoCode);
                }
              } else {
                prev = selected[selected.length - 1];
              }

              if (prev?.icaoCode === (option as Point).icaoCode) {
                return false;
              }
              return (
                (option as Point).icaoCode.toLowerCase().includes(state.text.toLowerCase()) ||
                (option as Point).name.toLowerCase().includes(state.text.toLowerCase())
              );
            }}
            renderInput={(inputProps, props) => (
              <TypeaheadInputMulti {...inputProps} selected={selected} className="rounded-lg">
                {selected.map((option, idx) => (
                  <Token
                    index={idx}
                    key={`${idx}${option.icaoCode}`}
                    onMove={onMove}
                    onRemove={(o) => {props.onRemove(o); updatePassengerLegAndIntStopsAfterRemoveIfNeeded(option)}}
                    option={option}
                    className={intermediateStops?.some((p) => p.name === option.icaoCode) ? "stop" : "waypoint"}
                  >
                    <>{option.icaoCode}</>
                  </Token>
                ))}
              </TypeaheadInputMulti>
            )}
            newSelectionPrefix={"Custom waypoint: "}
            renderMenuItemChildren={(option, props, index) => {
              var displayName = (option as Point).icaoCode;

              var extraName = <></>;
              if (displayName !== (option as Point).name) {
                extraName = <> - {(option as Point).name}</>;
              }

              return (
                <div key={index}>
                  <strong>{displayName}</strong> {extraName}
                </div>
              );
            }}
            selected={selected}
          />
        </DndProvider>
        <label className={selected.length > 0 ? "filled" : ""}>{"Route Waypoints"}</label>
      </div>
      {error && <small className="text-danger">{error.message}</small>}
    </>
  );
}

export default EntityCustomRouteComponent

const ItemTypes = {
  RoutePoint: "RoutePoint",
};

const Token = ({
  index,
  onMove,
  option,
  ...props
}: {
  onRemove: OptionHandler;
  children: JSX.Element;
  index: number;
  option: Point;
  onMove: (a: number, b: number) => void;
  className?: string;
}) => {
  const ref = useRef<HTMLSpanElement>(null);

  const [, drop] = useDrop({
    accept: ItemTypes.RoutePoint,
    hover(item: { index: number }, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      const clientOffset = monitor.getClientOffset()!;
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }
      onMove(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.RoutePoint,
    item: { type: ItemTypes.RoutePoint, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <span ref={ref} style={{ opacity, margin: "2px" }}>
      <RBTToken option={option} {...props} />
    </span>
  );
};
  