import { Button, Col, Offcanvas, Row } from "react-bootstrap";
import { LoaderFunction, useLoaderData, useNavigate, useParams, useRouteError } from "react-router-dom";
import { AviTracerApi } from "../../../avitracerApi";
import { Passenger } from "../../../models/passenger";
import { useState } from "react";
import InputBox from "../../../components/InputBox/InputBox";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCopyToClipboard } from "usehooks-ts";

export const flightBoardingPassesLoader: LoaderFunction = async (params) => {
  return AviTracerApi.getFlightBoardingPasses(params.params["flightId"]!);
};

function FlightBoardingPassesPage(){
    const navigate = useNavigate();
    
    const copy = useCopyToClipboard()[1];

    const loadedData = useLoaderData() as {pack: {url:string}, single:{url: string, passenger: Passenger}[]}

    const [show, setShow] = useState(true);

    let { flightId } = useParams();

    const goBack = () => {
      navigate("/flights");
    };

    const requestUpdate = async () => {
      await AviTracerApi.requestUpdateBoardingPasses(flightId as string);
    }

    return (
      <Offcanvas backdrop="static" show={show} onExited={goBack} placement={"end"} className="offcanvas-size-lg">
        <Button variant="close" className="position-absolute top-0 end-0 p-4" onClick={() => setShow(false)} />
        <Offcanvas.Body>
          <h3>Passenger Boarding Passes</h3>
          {loadedData.single.map((item) => {
            return (
              <Row key={item.passenger.id} className="pt-3">
                <Col>
                  <InputBox
                    value={item.url}
                    isDisabled={true}
                    name={item.passenger.lastName + " " + item.passenger.firstName}
                    type="text"
                    onChange={() => {}}
                  />
                </Col>
                <Col xs={"auto"}>
                  <Button variant="light" className="h-100" onClick={() => copy(item.url)}>
                    <FontAwesomeIcon icon={faCopy} size="xl" />{" "}
                    <div>
                      <small>Copy</small>
                    </div>
                  </Button>
                </Col>
              </Row>
            );
          })}
          <hr />
          <h4>Boarding Pass Pack</h4>
          <small className="text-muted">
            The boarding pass pack is a convenient collection of all passengers' boarding passes in a single link. This allows the prime
            passenger to easily import and access all the boarding passes together.
          </small>
          <Row className="pt-2">
            <Col>
              <InputBox value={loadedData.pack.url} isDisabled={true} name={"Pack"} type="text" onChange={() => {}} />
            </Col>
            <Col xs={"auto"}>
              <Button variant="light" className="h-100" onClick={() => copy(loadedData.pack.url)}>
                <FontAwesomeIcon icon={faCopy} size="xl" />{" "}
                <div>
                  <small>Copy</small>
                </div>
              </Button>
            </Col>
          </Row>
          <hr />
          <h4>Update Boarding Passes</h4>
          <small className="text-muted">
            When you make changes to a flight's departure, destination, departure time, or aircraft, you can send a notification directly to
            the passengers' mobile phones. This notification will be received by all passengers who have added their boarding passes to
            their digital wallet, ensuring that they are promptly informed about any updates or modifications to their flight.
          </small>
          <div className="pt-2">
            <Button variant="dark" onClick={requestUpdate}>
              Send notification
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    );
}

export default FlightBoardingPassesPage

export function FlightBoardingPassesErrorPage() {
  const error = useRouteError() as string;

  const [show, setShow] = useState(true);

  return (
    <Offcanvas backdrop="static" show={show}  placement={"end"}>
      <Button variant="close" className="position-absolute top-0 end-0 p-4" onClick={() => setShow(false)} />
      <Offcanvas.Body>
        <h3>Boarding Passes</h3>
        <div>{error}</div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}