import { Aircraft } from "../models/aircraft";

const toFuel = (inMass: number, inMassUnit: Aircraft["massUnit"], outFuelType: Aircraft["fuelType"], outFuelUnit: Aircraft["fuelUnit"]) => {
  
  var massInKg = inMass;
  if (inMassUnit === "lb") {
    massInKg = inMass * 0.453592;
  }
  //from kg/lb to kg/lb
  if (outFuelUnit === "kg") {
    return massInKg;
  } else if (outFuelUnit === "lb") {
    return massInKg / 0.453592;
  }

  //from kg to ltr/usgal
  var ltrs = 0;
  if (outFuelType === "avgas") {
    ltrs = massInKg * 1.346;
  } else if (outFuelType === "jetA1") {
    ltrs = massInKg * 1.245;
  }

  if (outFuelUnit === "ltr") {
    return ltrs;
  } else if (outFuelUnit === "usgal") {
    return ltrs / 3.78541;
  }
  return -1;
}

const toFuelUnit = (aircraft: Aircraft, mass: number) => {
  const fuelType = aircraft.fuelType;
  const massUnit = aircraft.massUnit;
  const fuelUnit = aircraft.fuelUnit;

  return toFuel(mass, massUnit, fuelType, fuelUnit);
};

const toMassUnit = (aircraft: Aircraft, fuel: number) => {
  const fuelType = aircraft.fuelType;
  const massUnit = aircraft.massUnit;
  const fuelUnit = aircraft.fuelUnit;

  if (fuelUnit === "kg") {
    const fuelInKg = fuel;
    if (massUnit === "kg") {
      return fuelInKg;
    } else if (massUnit === "lb") {
      return fuelInKg * 2.205;
    }
  } else if (fuelUnit === "lb") {
    const fuelInLb = fuel;
    if (massUnit === "kg") {
      return fuelInLb / 2.205;
    } else if (massUnit === "lb") {
      return fuelInLb;
    }
  }

  //from ltr/usgal to kg
  var ltrs = 0;
  if (fuelUnit === "ltr") {
    ltrs = fuel;
  } else if (fuelUnit === "usgal") {
    ltrs = fuel * 3.78541;
  }

  var massInKg = 0;
  if (fuelType === "avgas") {
    massInKg = ltrs * 0.7429;
  } else if (fuelType === "jetA1") {
    massInKg = ltrs * 0.8028;
  }

  if (massUnit === "kg") {
    return massInKg;
  } else if (massUnit === "lb") {
    return massInKg * 2.205;
  }
  return -1;
};

export const FuelConverter = {
  toFuel,
  toFuelUnit,
  toMassUnit
}