import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import FlightPlan from "../models/flightPlan";
import { createAppAsyncThunk } from "./hooks";
import { AviTracerApi } from "../avitracerApi";
import { mergeArrays } from "./flightSlice";

interface FlightPlansState {
    allFlightPlans: FlightPlan[];
    status: "idle" | "success" 
    loading: boolean
}

const initialState: FlightPlansState = {
    allFlightPlans: [],
    status: "idle",
    loading: false
}

export const getFlightPlans = createAppAsyncThunk(
    "flightPlans/fetchFlightPlans",
    async () => {
        return AviTracerApi.getFlightPlans()
    }
)

export const getFlightPlansInRange = createAppAsyncThunk(
    "flightPlans/fetchRange",
    async (range: {from: Date, to: Date}) => {
        return AviTracerApi.getFlightPlansInRange(range.from, range.to)
    }
)

export const editFlightPlan = createAppAsyncThunk(
    "flightPlans/edit",
    async (flightPlan: FlightPlan) => {
        return AviTracerApi.editFlightPlan(flightPlan)
    }
)

export const createFlightPlan = createAppAsyncThunk(
    "flightPlans/create",
    async (flightPlan: FlightPlan) => {
        return AviTracerApi.createFlightPlan(flightPlan)
    }
)

export const deleteFlightPlan = createAppAsyncThunk(
    "flightPlans/delete",
    async (flightPlan: FlightPlan) => {
        return AviTracerApi.deleteFlightPlan(flightPlan.id!)
    }
)

export const fileFlightPlanFpl = createAppAsyncThunk(
    "flightPlans/fileFpl",
    async (flightPlanId: string) => {
        return AviTracerApi.fileFlightPlanFpl(flightPlanId)
    }
)

export const cancelFlightPlanFpl = createAppAsyncThunk(
    "flightPlans/cancelFpl",
    async (flightPlanId: string) => {
        return AviTracerApi.cancelFlightPlanFpl(flightPlanId)
    }
)

export const delayFlightPlanFpl = createAppAsyncThunk(
    "flightPlans/delayFpl",
    async (params: {flightPlanId: string, newTime: Date}) => {
        return AviTracerApi.delayFlightPlanFpl(params.flightPlanId, params.newTime)
    }
)

export const flightPlansSlice = createSlice({
    name: "flightPlans",
    initialState,
    reducers: {
        resetFlightPlansForOrganizationChange: (state) => {
            state.allFlightPlans = []
            state.status = "idle"
            state.loading = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getFlightPlans.fulfilled, (state, action) => {
            state.allFlightPlans = mergeArrays(state.allFlightPlans, action.payload, "id")
            state.status = "success"
        })
        builder.addCase(getFlightPlansInRange.fulfilled, (state, action) => {
            state.allFlightPlans = mergeArrays(state.allFlightPlans, action.payload, "id")
            state.status = "success"
        })
        builder.addCase(editFlightPlan.fulfilled, (state, action) => {
            const index = state.allFlightPlans.findIndex((f) => f.id === action.payload.id)
            if (index === -1){
                state.allFlightPlans.push(action.payload)
            }else{
                state.allFlightPlans[index] = action.payload
            }
        })
        builder.addCase(createFlightPlan.fulfilled, (state, action) => {
            state.allFlightPlans.push(action.payload)
        })
        builder.addCase(deleteFlightPlan.fulfilled, (state, action) => {
            state.allFlightPlans = state.allFlightPlans.filter((f) => f.id !== action.payload.id)
        })
        builder.addCase(fileFlightPlanFpl.fulfilled, (state, action) => {
            const index = state.allFlightPlans.findIndex((f) => f.id === action.payload.id)
            if (index === -1){
                state.allFlightPlans.push(action.payload)
            }else{
                state.allFlightPlans[index] = action.payload
            }
        })
        builder.addCase(cancelFlightPlanFpl.fulfilled, (state, action) => {
            const index = state.allFlightPlans.findIndex((f) => f.id === action.payload.id)
            if (index === -1){
                state.allFlightPlans.push(action.payload)
            }else{
                state.allFlightPlans[index] = action.payload
            }
        })
        builder.addCase(delayFlightPlanFpl.fulfilled, (state, action) => {
            const index = state.allFlightPlans.findIndex((f) => f.id === action.payload.id)
            if (index === -1){
                state.allFlightPlans.push(action.payload)
            }else{
                state.allFlightPlans[index] = action.payload
            }
        })
        builder.addMatcher(isAnyOf(
            getFlightPlans.pending,
            getFlightPlansInRange.pending,
        ), (state) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(
            getFlightPlans.rejected,
            getFlightPlansInRange.rejected,
            getFlightPlans.fulfilled,
            getFlightPlansInRange.fulfilled,
        ), (state) => {
            state.loading = false
        })
    }
})

export const { resetFlightPlansForOrganizationChange } = flightPlansSlice.actions

export default flightPlansSlice.reducer
