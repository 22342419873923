import { Control, FieldValues, useController } from "react-hook-form";
import { EntityDetailsDisplayInfoFieldAircraftWbProfile, getValitationRules } from "../EntityDetailsDisplayInfo";
import ReadOnlyInputBox from "../../InputBox/ReadonlyInputBox";
import ListAicraftWbProfilesPage from "../../../pages/organization/aircraft/ListAircraftWbProfilesPage";
import { AircraftWbProfile } from "../../../models/aircraft";

interface EntityAircraftWbProfilePickerProps<Entity extends FieldValues> {
  displayInfoInput: EntityDetailsDisplayInfoFieldAircraftWbProfile<Entity>;
  setModalContent: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
  control: Control<Entity, any>;
}

function EntityAircraftWbProfilePicker({ displayInfoInput, setModalContent, control }: EntityAircraftWbProfilePickerProps<any>) {

  const {
    field: { value, onChange, ref },
    fieldState,
    formState: { isSubmitting, isValid },
  } = useController({
    name: displayInfoInput.key,
    control,
    rules: getValitationRules(displayInfoInput),
  });


  const updateProfile = (profile: AircraftWbProfile) => {
    onChange(profile.profileName);
    setModalContent(null);
  };

  return (
    <ReadOnlyInputBox
      inputRef={ref}
      value={value}
      isDisabled={(isValid && isSubmitting) || (displayInfoInput.disabled ?? false)}
      name={displayInfoInput.name}
      fieldState={fieldState}
      onClick={() => {
        setModalContent(<ListAicraftWbProfilesPage onProfileSelected={updateProfile} />);
      }}
    />
  );
}

export default EntityAircraftWbProfilePicker;
