import { EntityDetailsDisplayInfo } from "../components/EntityDetails/EntityDetailsDisplayInfo"

export type Route = {
    id?: string,
    name: string,
    departure: RoutePoint,
    destination: RoutePoint,
    waypoints: RoutePoint[],
    intermediateStops: RoutePoint[],
    distanceNM: number,

    altn1?: string;
    altn2?: string;
    
    aftnAddresses: string[],
}

export type RoutePoint = {
    name: string,
    lat: number,
    lon: number,
}

export type ImportedRoute = {
    id: string,
    name: string,
    departure: RoutePoint,
    destination: RoutePoint,
    fixes: RoutePoint[],
    intermediateStops?: RoutePoint[],
    distanceNM: number,
    aftnAddresses: string[],
}

export function getRouteDisplayInfo(): EntityDetailsDisplayInfo<Route> {
    return {
        entityName: "Route",
        hardcodedTitle: "Route",
        sections: [
            {
                sectionId: "info",
                sectionName: "Route Info",
                inputs: [
                    {
                        name: "Name",
                        key: "name",
                        type: "text"
                    },
                    {
                        name: "Distance (nm)",
                        key: "distanceNM",
                        type: "number",
                        disabled: true
                    },
                ]
            },
            {
                sectionName: "Location's",
                sectionId: "Landing sites",
                inputs: [
                    {
                        name: "Departure",
                        key: "departure.name",
                        type: "text",
                        disabled: true
                    },
                    {
                        name: "Destination",
                        key: "destination.name",
                        type: "text",
                        disabled: true
                    }
                ]
            },
            {
                sectionId: "intermediatestops",
                sectionName: "Intermediate Stops",
                shouldDisplay: (route: Route) => route.waypoints && route.waypoints.length > 0,
                inputs: [
                    {
                        name: "Intermediate Stops",
                        key: "intermediateStops",
                        type: "intermediateStop",
                        dataKey: "waypoints",
                        disabled: true
                    }
                ]
            },
            {
                sectionId: "aftnaddresses",
                sectionName: "AFTN Addresses",
                inputs: [
                    {
                        name: "AFTN Addresses",
                        key: "aftnAddresses",
                        type: "aftnAddresses",
                    }
                ]
            }

        ]
    }
}

export function getImportedRouteDisplayInfo(): EntityDetailsDisplayInfo<ImportedRoute> {
    return {
        entityName: "ImportedRoute",
        hardcodedTitle: "Create Route",
        sections: [
            {
                sectionId: "info",
                sectionName: "Route Info",
                inputs: [
                    {
                        name: "Name",
                        key: "name",
                        type: "text",
                        required: true
                    },
                    {
                        name: "Distance (nm)",
                        key: "distanceNM",
                        type: "number",
                        disabled: true

                    },
                ]
            },
            {
                sectionName: "Location's",
                sectionId: "Landing sites",
                inputs: [
                    {
                        name: "Departure",
                        key: "departure.name",
                        type: "text",
                        uppercase: true,
                        required: true,
                        validateRules: {
                            validate: {
                                maxChars: (value) =>{
                                    return (value as string).length <= 50 ? true : "Max 50 characters";
                                },
                                invalidCharacters: (value) => {
                                    return (value as string).match(/^([0-9]|[ ]|[A-Z]){1,50}$/g) ? true : "Invalid characters. Allowed characters: A-Z, 0-9 and whitespace";
                                }
                            }
                        }
                    },
                    {
                        name: "Destination",
                        key: "destination.name",
                        type: "text",
                        uppercase: true,
                        required: true,
                        validateRules: {
                            validate: {
                                maxChars: (value) =>{
                                    return (value as string).length <= 50 ? true : "Max 50 characters";
                                },
                                invalidCharacters: (value) => {
                                    return (value as string).match(/^([0-9]|[ ]|[A-Z]){1,50}$/g) ? true : "Invalid characters. Allowed characters: A-Z, 0-9 and whitespace";
                                }
                            }
                        }
                    }
                ]
            },
            {
                sectionId: "intermediatestops",
                sectionName: "Waypoints",
                shouldDisplay: (route: ImportedRoute) => route.fixes && route.fixes.length > 0,
                inputs: [
                    {
                        name: "Waypoints",
                        key: "intermediateStops",
                        type: "intermediateStop",
                        dataKey: "fixes"
                    }
                ]
            },
            {
                sectionId: "aftnaddresses",
                sectionName: "AFTN Addresses",
                inputs: [
                    {
                        name: "AFTN Addresses",
                        key: "aftnAddresses",
                        type: "aftnAddresses",
                    }
                ]
            }
        ]
    }
}

export function calculateDistanceBetweenRoutePoints(departure: RoutePoint, destination: RoutePoint, waypoints: RoutePoint[] = []) {
    const points = [departure, ...waypoints, destination];

    let totalDistance = 0;
    for (let i = 0; i < points.length - 1; i++) {
        const lat1 = points[i].lat;
        const lon1 = points[i].lon;
        const lat2 = points[i + 1].lat;
        const lon2 = points[i + 1].lon;
        totalDistance += getDistanceFromLatLonInNm(lat1, lon1, lat2, lon2);
    }
    return Math.round(totalDistance);
}

export function getDistanceFromLatLonInNm(lat1: number, lon1: number, lat2: number, lon2: number) {
    const R = 3440.06479481662; // Earth radius in nm
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
}

function toRad(v: number) {
    return v * Math.PI / 180;
}

export function getFplCoordsFromRoutePoint(routePoint: RoutePoint): string {
    const {lat, lon} = routePoint
    return convertLatLonToGeoPoint(lat, lon);
}

export function convertLatLonToGeoPoint(lat: number, lon: number): string {
    if (isNaN(lat) || isNaN(lon)) return "";
    const latDegree = Math.floor(lat);
    const lonDegree = Math.floor(lon);
    const latMinutes = Math.round((lat - latDegree) * 60);
    const lonMinutes = Math.round((lon - lonDegree) * 60);
    const latHemisphere = lat > 0 ? "N" : "S";
    const lonHemisphere = lon > 0 ? "E" : "W";
    return `${latDegree.toString().padStart(2, "0")}${latMinutes.toString().padStart(2, "0")}${latHemisphere}${lonDegree.toString().padStart(3, "0")}${lonMinutes.toString().padStart(2, "0")}${lonHemisphere}`;
}

export function convertGeoPointToLatLon(geoPoint: string): { lat: number, lon: number } {
    const latDegree = parseInt(geoPoint.substring(0, 2));
    const latMinutes = parseInt(geoPoint.substring(2, 4));
    const latHemisphere = geoPoint.substring(4, 5);
    const lonDegree = parseInt(geoPoint.substring(5, 8));
    const lonMinutes = parseInt(geoPoint.substring(8, 10));
    const lonHemisphere = geoPoint.substring(10, 11);
    const lat = latDegree + latMinutes / 60;
    const lon = lonDegree + lonMinutes / 60;
    return {lat: latHemisphere === "N" ? lat : -lat, lon: lonHemisphere === "E" ? lon : -lon};
}