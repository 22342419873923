import { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Dropdown } from "react-bootstrap";
import { EntityDetailsDisplayInfoFieldDropdownWithInput } from "../EntityDetailsDisplayInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import InputBox from "../../InputBox/InputBox";
import "../../../css/dropdown-black.css"
import { Control, FieldValues, useController } from "react-hook-form";

interface EntityDropdownWithInputProps<Entity extends FieldValues> {
    displayInfoInput: EntityDetailsDisplayInfoFieldDropdownWithInput<Entity>;
    control: Control<Entity, any>
}

function EntityDropdownWithInput<Entity extends FieldValues>({displayInfoInput, control}: EntityDropdownWithInputProps<Entity>){
    
  const {
    field,
    fieldState,
    formState: { isSubmitting, isValid },
  } = useController({
    name: displayInfoInput.key,
    control,
    rules: { validate: (value, formValues) => {
      if (value === undefined || value?.value === "") {
        if (displayInfoInput.required){
          return "Required";
        }
      }
      return true;
    } },
  });

  const {value, onChange, onBlur} = field

  const dropdownValue = value?.unit ?? displayInfoInput.options[0].value
  const inputValue = value?.value ?? ""

    const [isHardcoded, setIsHardcoded] = useState<boolean>(false)

    useEffect(() => {
      const hardcodedInputValue = displayInfoInput.options.find((option) => option.value === dropdownValue)!.hardcodedInputValue
      if (hardcodedInputValue) {
        onChange({unit: dropdownValue, value: hardcodedInputValue})
        setIsHardcoded(true);
      } else {
        if (isHardcoded) {
          onChange({unit: dropdownValue, value: ""})
        }
        setIsHardcoded(false);
      }
    },[displayInfoInput.options, dropdownValue, isHardcoded, onChange])

    return (
        <>
        <Container fluid className="p-0">
            <Row>
                <Col xs={8}>
                    <InputBox
                        isDisabled={(isValid && isSubmitting) || isHardcoded || (displayInfoInput.disabled ?? false)}
                        value={inputValue}
                        onChange={(v) => onChange({unit: dropdownValue, value: v})}
                        name={displayInfoInput.name}
                        field={{...field, onChange: (e) => {}}}
                        fieldState={fieldState}
                    />
                </Col>
                <Col xs={4}>
                    <DropdownElement
                        options={displayInfoInput.options}
                        isDisabled={(isValid && isSubmitting) || (displayInfoInput.disabled ?? false)}
                        currentValue={dropdownValue}
                        currentValueLabel={displayInfoInput.options.find((option) => option.value === dropdownValue)!.label}
                        setCurrentValue={(v) => onChange({value: inputValue, unit: v})}
                        onToggle={(isOpen) => (isOpen ? onBlur() : null)}
                    />
                </Col>
            </Row>

        </Container>
        </>
    )
}

export default EntityDropdownWithInput

interface DropdownProps {
    options: {value: string, label: string, hardcodedInputValue?: string}[]
    isDisabled: boolean;
    currentValue: string;
    currentValueLabel: string;
    setCurrentValue: (newValue: string) => void;
    onToggle?: (isOpen: boolean) => void;
}

function DropdownElement({options, isDisabled, currentValue, currentValueLabel, setCurrentValue, onToggle}: DropdownProps){

    const inputRef = useRef<HTMLInputElement>(null)

    return (
        <div
          className="input-container rounded-lg"
        >
          <Dropdown
            onSelect={(newValue) => {
              setCurrentValue(newValue!);
            }}
            onToggle={(isOpen) => {
              onToggle?.(isOpen);
              if (!isOpen) {
                setTimeout(() => {
                  inputRef.current?.blur();
                }, 100);
              }
            }}
          >
            <Dropdown.Toggle
              ref={inputRef}
              as={"input"}
              readOnly
              disabled={isDisabled}
              className="bg-transparent w-100"
              value={ currentValueLabel }
              style={{ cursor: (isDisabled) ? "auto" : "pointer" }}
            />
            <Dropdown.Menu>
              {options.map(({ value, label }) => {
                return (
                  <Dropdown.Item
                    key={value}
                    eventKey={value}
                    active={value === currentValue}
                  >
                    {label}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
            <label className={"filled"}>
            Unit
          </label>
          </Dropdown>

          <FontAwesomeIcon 
            className="position-absolute "
            style={{right: "16px", top: "50%", transform: "translateY(-50%)"}}
            icon={faCaretDown} 
          />
        </div>

    )

}