import { Player } from "@lottiefiles/react-lottie-player";
import { Button } from "react-bootstrap";

function Static404Page() {
  return (
    <div className="d-flex flex-column align-items-center pt-5">
      <h1>404</h1>
      <Player
        autoplay
        loop
        src="/assets/brief/lottie/skydive404.json"
        speed={1}
        background="transparent"
        style={{ width: "300px" }}
      />
      <strong>It looks like you are lost</strong>
      <Button variant="link" href="/">Go back to your aircraft's base</Button>
    </div>
  );
}

export default Static404Page;