import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Button, Col, Row } from "react-bootstrap";
import { ControllerFieldState, RefCallBack } from "react-hook-form";

interface ReadOnlyInputBoxProps {
    isDisabled: boolean;
    value: string;
    onClick?: () => void;
    onClickDelete?: () => void;
    name: string;
    fieldState?: ControllerFieldState
    inputRef?: RefCallBack
}

function ReadOnlyInputBox({isDisabled, value, onClick, name, onClickDelete, fieldState, inputRef}: ReadOnlyInputBoxProps) {

    const {invalid, error } = fieldState ?? {}

    const isInvalidClass = invalid ? " invalid" : ""
    
    return (
      <Row className="g-0">
        <Col>
          <div className={"input-container rounded-lg".concat(isInvalidClass)}>
            <input
              ref={inputRef}
              readOnly
              disabled={isDisabled}
              className="bg-transparent"
              value={value}
              style={{ cursor: (!isDisabled && onClick) ? "pointer" : "auto" }}
              onClick={onClick}
            />
            <label className={value && "filled"}>{name}</label>
            {!isDisabled && onClick && (
              <FontAwesomeIcon
                className="position-absolute "
                style={{
                  right: "16px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
                icon={faCaretDown}
              />
            )}
          </div>
          {error && <small className="text-danger">{error.message}</small>}
        </Col>
        {onClickDelete && value && (
          <Col xs={2} className="ps-3">
            <div className="input-container rounded-lg">
              <Button disabled={isDisabled} variant="link" onClick={onClickDelete}>
                <FontAwesomeIcon icon={faTrash} color="black" />
              </Button>
            </div>
          </Col>
        )}
      </Row>
    );
}

export default ReadOnlyInputBox;