import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import OAuth2Login from 'react-simple-oauth2-login';
import { AviTracerApi } from '../../avitracerApi';

function AutorouterAuthPage() {

  const [autorouterStatus, setAutorouterStatus] = useState< AutorouterStatus | undefined > ();

  const fetchAutorouterStatus = async () => {
    const status = await AviTracerApi.autorouterGetLinkStatus();
    setAutorouterStatus(status);
  }

  useEffect(() => {
    if (autorouterStatus === undefined) {
      fetchAutorouterStatus();
    }
  }, [autorouterStatus]);

  const onSuccess = async (data: Record<string, any>) => {
    if (data.state !== state) {
      console.error("State does not match");
      return;
    }

    await AviTracerApi.autorouterExchangeCode(data.code as string);
    setAutorouterStatus(undefined)
    
  };

  const onFailure = (response: any) => console.error(response);

  const [state, setState] = useState<string>();
  useEffect(() => {
    const randomState =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    setState(randomState);
  }, []);

  return (
    <>
      <Container className="pt-5">
        <Row>
          <Col>
            <h1>Autorouter Authorization</h1>
            <p>
              AviTracer <strong>BRIEF</strong> is using Autorouter to generate
              parts of the Flight Briefing PDF.
            <br/>
              In order to use the full features of <strong>BRIEF</strong> you
              will need to authorize AviTracer to use your Autorouter account.
            </p>
            <p>
              You can create your free autorouter account at{" "}
              <a
                href="https://www.autorouter.aero/signup"
                target={"_blank"}
                rel="noreferrer"
              >
                https://www.autorouter.aero/signup
              </a>
            </p>
          </Col>
        </Row>
        <Row className="pt-5 gy-3">
          <Col xs={12}>
            <h4>
              Status:{" "}
              {autorouterStatus === undefined && (
                <>
                  <Spinner variant="warning" animation="grow" />{" "}
                  <span className="text-warning">Checking...</span>
                </>
              )}
              {autorouterStatus?.linked && (
                <>
                  <span className="text-success">Linked</span>
                </>
              )}
              {autorouterStatus?.linked === false && (
                <span className="text-danger"> Not Linked</span>
              )}
            </h4>
            {autorouterStatus?.linked && (
              <>
                <p>
                  <b>Link Expire Date:</b>{" "}
                  {moment(autorouterStatus!.expiresAt).format("D/M/Y")}
                </p>
                You will need to re-authenticate with autorouter before the <b>Link Expire Date</b> .
              </>
            )}
          </Col>
          <Col>
            <OAuth2Login
              buttonText={
                autorouterStatus?.linked
                  ? "Re-Authenticate Autorouter"
                  : "Connect Autorouter"
              }
              authorizationUrl="https://www.autorouter.aero/authorize"
              responseType="code"
              state={state}
              clientId="avitracer_brief"
              redirectUri='https://brief.avitracer.com/autorouter/callback'
              onSuccess={onSuccess}
              onFailure={onFailure}
              render={({ onClick, buttonText }) => (
                <Button onClick={onClick}>{buttonText}</Button>
              )}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AutorouterAuthPage

export type AutorouterStatus = {
  linked: boolean;
  expiresAt?: number;
  email?: string;
}