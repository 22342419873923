import { useNavigate } from "react-router-dom";
import EntityDetailsPage from "../../../components/EntityDetails/EntityDetailsPage";
import { FlightPlanQuickAdd, createFlightPlanFromFlightPlanQuickAdd, getFlightPlanAddDisplayInfo } from "../../../models/flightPlan";

function QuickAddFlightPlanPage() {
    const navigate = useNavigate();
    
    const flightPlanAdd: FlightPlanQuickAdd = {};

    const goBack = () => {
        navigate("/fpls")
    }

    const proceedToFlightPlanDetails = async (flightPlanAdd: FlightPlanQuickAdd) => {
        navigate("/fpls/add", {state: {flightPlan: createFlightPlanFromFlightPlanQuickAdd(flightPlanAdd)}})
        return flightPlanAdd
    }

    return (
        <EntityDetailsPage
            entity={flightPlanAdd}
            handleClose={goBack}
            displayInfo={getFlightPlanAddDisplayInfo()}
            dispatchSaveEntity={proceedToFlightPlanDetails}
        />
    )
}

export default QuickAddFlightPlanPage;