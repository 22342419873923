import { Col, Container, Dropdown, Row } from "react-bootstrap";
import { Control, FieldValues, useController } from "react-hook-form";
import InputBox from "../../InputBox/InputBox";
import { EntityDetailsDisplayInfoFieldFlightId, getValitationRules } from "../EntityDetailsDisplayInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from "react";
import Flight from "../../../models/flight";

interface EntityFlightIdComponentProps<Entity extends FieldValues> {
    displayInfoInput: EntityDetailsDisplayInfoFieldFlightId<Entity>;
    control: Control<Entity, any>
    edittedFlight: Flight;
}

function EntityFlightIdComponent<Entity extends FieldValues>({control, displayInfoInput, edittedFlight}: EntityFlightIdComponentProps<Entity>) {
    
    const {
      field: { value: idValue, onChange: idOnChange, ref: idRef },
      fieldState,
      formState: { isSubmitting, isValid },
    } = useController({
      name: displayInfoInput.key,
      control,
      rules: {
        required: (displayInfoInput.required) ? "Required" : false,
        validate: (value) => {
            if (!value && !displayInfoInput.required) {
                return true
            }
            if (value.length < 2 || value.length > 7){
                return "Flight ID must be between 2 and 7 characters"
            }
            if (!value.match(/^[A-Z\d]{2,7}$/)){
                return "Flight ID must only contain uppercase letters and numbers"
            }
            return true
        }
      },
    });

    const {aircraft, pic} = edittedFlight;

    const {
        field: typeField
    } = useController({
        name: displayInfoInput.flightIdTypeKey,
        control,
        rules: getValitationRules(displayInfoInput),
    });

    useEffect(() => {
        if (aircraft && typeField.value === undefined) {
            typeField.onChange(aircraft.defaultFlightIdType ?? "reg")
        }
    }, [typeField, aircraft])

    useEffect(() => {
        if (typeField.value === "reg" && aircraft) {
            if (aircraft){
                idOnChange(aircraft.registration.replace("-", ""))
            }else{
                idOnChange("")
            } 
        }else if (typeField.value === "aircraft" ) {
            if (aircraft && aircraft.operatorCode && aircraft.operatorDefaultNumber){
                idOnChange(`${aircraft.operatorCode}${aircraft.operatorDefaultNumber}`)
            }else{
                idOnChange("")
            } 
        }else if (typeField.value === "crew") {
            if (aircraft && pic && aircraft.operatorCode && pic.operatorDefaultNumber){
                idOnChange(`${aircraft.operatorCode}${pic.operatorDefaultNumber}`)
            }else{
                idOnChange("")
            }
        }
    }, [aircraft, idOnChange, pic, typeField.value])

    const dropdownOptions = [
      {
        value: "reg",
        label: "Reg: " + (aircraft ? aircraft.registration.replace("-", "") : "N/A"),
        selectedLabel: "Registration"
      },
      {
        value: "aircraft",
        label: "A/C: " + ((aircraft && aircraft.operatorCode && aircraft.operatorDefaultNumber) ? `${aircraft.operatorCode}${aircraft.operatorDefaultNumber}` : "N/A"),
        selectedLabel: "Operator Aircraft"
      },
      {
        value: "crew",
        label: "Pic: " + ((aircraft && pic && aircraft.operatorCode && pic.operatorDefaultNumber) ? `${aircraft.operatorCode}${pic.operatorDefaultNumber}` : "N/A"),
        selectedLabel: "Operator Pic"
      },
      {
        value: "custom",
        label: "Custom",
        selectedLabel: "Custom"
      }
    ];

    const isDisabled = (isValid && isSubmitting) || (displayInfoInput.disabled ?? false);
    return (
        <Container fluid className="p-0">
            <Row>
                <Col xs={6} md={4}>
                <div
                    className="input-container rounded-lg"
                    >
                    <Dropdown
                        onSelect={(newValue) => {
                            typeField.onChange(newValue);
                        }}
                    >
                        <Dropdown.Toggle
                        as={"input"}
                        readOnly
                        disabled={isDisabled}
                        className="bg-transparent w-100"
                        value={ dropdownOptions.find((option) => option.value === typeField.value)?.selectedLabel ?? "" }
                        style={{ cursor: (isDisabled) ? "auto" : "pointer" }}
                        />
                        <Dropdown.Menu>
                        {dropdownOptions.map(({ value, label }) => {
                            return (
                            <Dropdown.Item
                                key={value}
                                eventKey={value}
                            >
                                {label}
                            </Dropdown.Item>
                            );
                        })}
                        </Dropdown.Menu>
                        <label className={"filled"}>
                        Auto Flight ID
                    </label>
                    </Dropdown>

                    <FontAwesomeIcon 
                        className="position-absolute "
                        style={{right: "16px", top: "50%", transform: "translateY(-50%)"}}
                        icon={faCaretDown} 
                    />
                    </div>
                </Col>
                <Col xs={6} md={8}>
                    <InputBox
                        inputRef={idRef}
                        isDisabled={isDisabled || (typeField.value !== "custom" && !isSubmitting)}
                        value={idValue}
                        onChange={(v) => idOnChange(v.toUpperCase())}
                        name={displayInfoInput.name}
                        uppercase={true}
                        fieldState={fieldState}
                    />
                </Col>
            </Row>

        </Container>
    )
}

export default EntityFlightIdComponent;