import { flexRender, useReactTable } from "@tanstack/react-table";
import { createColumnHelper, getCoreRowModel } from "@tanstack/table-core";
import { useEffect } from "react";
import { Button, Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import { Organization } from "../../../models/organization";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getOrganizations, selectedOrganization, selectOrganization } from "../../../store/organizationsSlice";
import { useNavigate } from "react-router-dom";

interface SideMenuSelectOrganizationModalProps {
    hideModal: () => void;
}

function SelectOrganizationModal({ hideModal }: SideMenuSelectOrganizationModalProps) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate();

  return (
    <Modal show>
      <Modal.Header>
        <p className="h5">Select Team</p>
        <Button variant="close" onClick={() => hideModal()} />
      </Modal.Header>
      <Modal.Body>
        <SelectOrganizationList
          onSelect={(org) => {
            dispatch(selectOrganization(org));
            hideModal();
          }}
        />
        <Row className="justify-content-center">
          <Col xs={8}>
            <div className="d-grid">
              <Button variant="light" className="border" onClick={() => navigate("/organization/create")}>
                Create new Team
              </Button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default SelectOrganizationModal;

interface SelectOrganizationListProps {
  onSelect: (org: Organization) => void;
}

function SelectOrganizationList({ onSelect }: SelectOrganizationListProps) {
  const dispatch = useAppDispatch();
  const organizations = useAppSelector(
    (state) => state.organizations.allOrganizations
  );
  const selectedOrg = useAppSelector(selectedOrganization);

  const columnHelper = createColumnHelper<Organization>();

  const columns = [
    columnHelper.display({
      id: "currSelect",
      cell: (row) => (
        <>
          <Form.Check
            type="radio"
            checked={row.row.original.id === selectedOrg?.id}
            readOnly
          />
        </>
      ),
    }),
    columnHelper.accessor("name", {
      header: () => "Organization Name",
      cell: (info) => info.getValue(),
    }),
  ];

  const table = useReactTable({
    data: organizations,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    dispatch(getOrganizations());
  }, [dispatch]);

  return (
    <>
      <>
        <Container>
          <Row>
            <Col>
              <Table hover>
                <tbody>
                  {table.getRowModel().rows.map((row) => (
                    <tr key={row.id} onClick={() => onSelect(row.original)}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </>
    </>
  );
}
