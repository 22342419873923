import { flexRender, getCoreRowModel, useReactTable, FilterFn, getFilteredRowModel, ColumnDef, SortingState, getSortedRowModel } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { rankItem } from '@tanstack/match-sorter-utils'
import "../../css/borderless-input.css"
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'
import { useMediaQuery } from "usehooks-ts";
import { Identifiable } from "../../models/identifiable";

export interface ListEntitiesMobileCell<Entity> {
    (data: Entity, onCellClick: () => void): JSX.Element
}

interface ListEntitiesTableProps<Entity> {
    data: Entity[]
    columns: ColumnDef<Entity, any>[],
    searchValue?: string,
    defaultSortingState?: SortingState,
    onRowClick?: (entity: Entity) => void
    mobileCellElement?: ListEntitiesMobileCell<Entity>
}
  
function ListEntitiesTable<Entity>({
  data,
  columns,
  searchValue,
  defaultSortingState,
  onRowClick,
  mobileCellElement
}: ListEntitiesTableProps<Entity>) {
  const globalEntityFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const rank = rankItem(row.getValue(columnId), value);

    return rank.passed;
  };

  const navigate = useNavigate()

  const [sorting, setSorting] = useState<SortingState>(defaultSortingState ?? [])

  const table = useReactTable({
    data,
    columns,
    state:{
      sorting
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: globalEntityFilter,
  });

  useEffect(() => {
    table.setGlobalFilter(searchValue);
  }, [table, searchValue]);

  const shouldUseMobileCell = useMediaQuery('(max-width: 768px)') && mobileCellElement !== undefined;


  return (
    <>
      {shouldUseMobileCell ? (
        <>
          {data.map((d) => {
            return (
              <div key={(d as Identifiable).id!}>
                <Container fluid className="px-0">
                  {mobileCellElement!(d, () => {
                    if (onRowClick) {
                      onRowClick(d);
                    } else {
                      navigate((d as Identifiable).id!);
                    }
                  })}
                </Container>
                <hr />
              </div>
            );
          })}
        </>
      ) : (
        <Table hover style={{ borderCollapse: "separate", borderSpacing: "0" }}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (header.column.getCanSort()) {
                        header.column.toggleSorting();
                      }
                    }}
                    style={{
                      borderTop: "none",
                      position: "sticky",
                      cursor: header.column.getCanSort() ? "pointer" : "auto",
                      userSelect: header.column.getCanSort() ? "none" : "auto",
                      top: "0",
                      zIndex: "1",
                      background: "white",
                      color: table.getState().sorting.length === 0 || header.column.getIsSorted() ? "black" : "#777",
                    }}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}{" "}
                    {header.column.getCanSort() && (
                      <>
                        {header.column.getIsSorted() === false && <FontAwesomeIcon icon={faSort} size={"xs"} />}
                        {header.column.getIsSorted() === "desc" && <FontAwesomeIcon icon={faSortDown} size={"xs"} />}
                        {header.column.getIsSorted() === "asc" && <FontAwesomeIcon icon={faSortUp} size={"xs"} />}
                      </>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                onClick={(e) => {
                  e.stopPropagation();
                  if (onRowClick) {
                    onRowClick(row.original);
                  } else {
                    navigate(row.original.id);
                  }
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="align-middle">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
}

export default ListEntitiesTable;