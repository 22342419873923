import { Button, Col, Container, Row } from "react-bootstrap";
import { Passenger, getPassengerFullName } from "../../../models/passenger";
import ListPassengersPage from "../../../pages/organization/passengers/ListPassengersPage";
import ReadOnlyInputBox from "../../InputBox/ReadonlyInputBox";
import { EntityDetailsDisplayInfoFieldPassengers } from "../EntityDetailsDisplayInfo";
import { Control, ControllerFieldState, FieldValues, Path, useController } from "react-hook-form";
import React, { useState } from "react";
import InputBox from "../../InputBox/InputBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import FlatPicker from 'react-flatpickr'
import moment from "moment";
import { useSearchParams } from "react-router-dom";

interface EntityPassengersComponentProps<Entity extends FieldValues> {
    displayInfoInput: EntityDetailsDisplayInfoFieldPassengers<Entity>;
    control: Control<Entity, any>
    setModalContent: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
    setShowCloseInModalContent: React.Dispatch<React.SetStateAction<boolean>>;
}

function EntityPassengersComponent<Entity extends FieldValues>({ displayInfoInput, control, setModalContent, setShowCloseInModalContent}: EntityPassengersComponentProps<Entity>) {
  const {
    field: { value, onChange },
    formState: { isSubmitting, isValid },
  } = useController({
    name: displayInfoInput.key,
    control,
  });

  const allPassengers: Passenger[] = (value as Passenger[]) ?? [];

  const onPassengerSelected = (passenger: Passenger) => {
    onChange(allPassengers.concat(passenger));

    setModalContent(null);
  };

  const isDisabled = (isValid && isSubmitting) || (displayInfoInput.disabled ?? false)

  return (
    <>
      <div className="pb-2">
        <Row>
          <Col>
            <AddOneTimePassengerButton
              isDisabled={isDisabled}
              setModalContent={setModalContent}
              setShowCloseInModalContent={setShowCloseInModalContent}
              onPassengersAdded={(passengers) => {
                onChange(allPassengers.concat(passengers));
              }}
              requiredFields={displayInfoInput.requiredPassengerFields}
            />
            <AddExistingPassengerButton
              isDisabled={isDisabled}
              setModalContent={setModalContent}
              onPassengerSelected={onPassengerSelected}
              showSignupModalForExistingPassengers={displayInfoInput.showSignupModalForExistingPassengers ?? false}
            />
          </Col>
        </Row>
      </div>
      {allPassengers.map((passenger, index) => {
        return (
          <div key={index} className="py-2">
            <ReadOnlyInputBox
              isDisabled={isDisabled}
              value={getPassengerFullName(passenger)}
              name={"Passenger"}
              onClick={() => {
                setShowCloseInModalContent(false);
                setModalContent(
                  <AddMultiplePassengersModal
                    existingPassengers={allPassengers}
                    savePressed={(passengers: Passenger[]) => {
                      setModalContent(null);
                      setShowCloseInModalContent(true);
                      onChange(passengers);
                    }}
                    requiredFields={displayInfoInput.requiredPassengerFields}
                  />
                );
              }}
              onClickDelete={() => {
                onChange(allPassengers.filter((p) => p.id !== passenger.id));
              }}
            />
          </div>
        );
      })}
    </>
  );
}

export default EntityPassengersComponent

interface AddExistingPassengerButtonProps {
    isDisabled: boolean;
    setModalContent: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
    onPassengerSelected: (passenger: Passenger) => void;
    showSignupModalForExistingPassengers: boolean
}

function AddExistingPassengerButton({ setModalContent, onPassengerSelected, isDisabled, showSignupModalForExistingPassengers }: AddExistingPassengerButtonProps) {
  
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Button
      disabled={isDisabled}
      variant="light"
      style={{ background: "transparent", height: "40px", borderWidth: "0px" }}
      className="fw-bold px-0 "
      onClick={() => {
        if (showSignupModalForExistingPassengers) {
          searchParams.set("signup", "pax")
          setSearchParams(searchParams)
        }else{
          setModalContent(<ListPassengersPage onPassengerSelected={onPassengerSelected} />);
        }
      }}
    >
      + Existing Passenger
    </Button>
  );
}

interface AddOneTimePassengerButtonProps {
  isDisabled: boolean;
  setModalContent: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
  setShowCloseInModalContent: React.Dispatch<React.SetStateAction<boolean>>;
  onPassengersAdded: (passengers: Passenger[]) => void;
  requiredFields: Path<Passenger>[];
  existingPassengers?: Passenger[];
}

function AddOneTimePassengerButton({isDisabled, setModalContent, setShowCloseInModalContent, onPassengersAdded, requiredFields, existingPassengers}: AddOneTimePassengerButtonProps) {

  const saveTempPassengers = (passengers: Passenger[]) => {
    setModalContent(null)
    setShowCloseInModalContent(true);
    onPassengersAdded(passengers);
  }
  
  return (
    <Button
      disabled={isDisabled}
      className="px-0 pe-3 fw-bold"
      variant="light"
      style={{ backgroundColor: "transparent", height: "40px", borderWidth: "0px" }}
      onClick={() => {
        setShowCloseInModalContent(false);
        setModalContent(<AddMultiplePassengersModal existingPassengers={existingPassengers} savePressed={saveTempPassengers} requiredFields={requiredFields} />);
      }}
    >
      + One-Time Passengers
    </Button>
  );
}

interface AddMultiplePassengersModalProps {
  savePressed: (passengers: Passenger[]) => void;
  requiredFields: Path<Passenger>[];
  existingPassengers?: Passenger[];
}

function AddMultiplePassengersModal({savePressed, existingPassengers, requiredFields}: AddMultiplePassengersModalProps) {

  const getRandomTempId = () => {
    return "TEMP_" + Math.random().toString(36).substr(2, 9);
  }

  const isTempPax = (passenger: Passenger) => {
    return passenger.id!.startsWith("TEMP_")
  }

  const [passengers, setPassengers] = useState<Passenger[]>(existingPassengers ?? [{lastName: "", firstName: "", weight: 0, id: getRandomTempId()}])

  const [erros, setErrors] = useState<Map<string, ControllerFieldState>>()

  const checkForErrorsAndSubmit = () => {

    const errors = new Map<string, ControllerFieldState>();

    passengers.forEach((passenger, index) => {
      requiredFields?.forEach((field) => {
        if (passenger[field] === ""){
          errors.set(field + "_" + index, {invalid: true, isTouched: false, isDirty: false, error: {type: "required", message: "Required"}})
        }
      })
      if (requiredFields.includes("weight") && passenger.weight === 0) {
        errors.set("weight_" + index, {invalid: true, isTouched: false, isDirty: false, error: {type: "required", message: "Required"}})
      }
    })
    if (errors.size > 0){
      setErrors(errors);
    }else{
      savePressed(passengers)
    }
    
  }

  const checkPastedText = (text: string) => {
    const matches = text.match(/(?<name>[\w -]*)\((?<weight>\d{1,3})kg\) ?/g);
    var passengers: Passenger[] = [];
    matches?.forEach((match) => {
      const m = match.match(/(?<name>[\w -]*)\((?<weight>\d{1,3})kg\)/);
      if (m?.groups) {
        const { name, weight } = m.groups;
        const [firstName, lastName] = name.split(" ", 2);
        passengers.push({ lastName, firstName, weight: parseInt(weight), id: getRandomTempId() });
      }
    });

    if (passengers.length > 0) {
      setPassengers(passengers);
      return true
    }

    return false
  };

  return (
    <Container>
      <Row className="pb-3 my-3">
        <Col xs={10}>
          {existingPassengers === undefined ?
           <h2>Add Multiple Passengers</h2> : 
           <h2>Edit Passengers</h2>}
          </Col>
        <Col xs={2}>
          <div className="d-grid">
            <Button variant="dark" onClick={checkForErrorsAndSubmit}>
              Save
            </Button>
          </div>
        </Col>
      </Row>
      {passengers.map((p, index) => (
        <Row className="py-2 gy-3" key={p.id!}>
          <Col xs={12}>
            <span className="fs-4 pe-1">Passenger {index + 1}</span>
            {!isTempPax(p) && (
              <>
                <br />
                <small>(Existing passengers can be editted from the Passengers menu.)</small>
              </>
            )}
          </Col>
          <Col xs={4}>
            <InputBox
              value={p.lastName}
              isDisabled={!isTempPax(p)}
              name={"Last Name"}
              fieldState={erros?.get("lastName_" + index)}
              onChange={(value) => {
                if (checkPastedText(value)) {
                  return;
                }
                setPassengers((old) => [...old.slice(0, index), { ...old[index], lastName: value }, ...old.slice(index + 1)]);
              }}
            />
          </Col>
          <Col xs={4}>
            <InputBox
              value={p.firstName}
              isDisabled={!isTempPax(p)}
              name={"First Name"}
              fieldState={erros?.get("firstName_" + index)}
              onChange={(value) => {
                setPassengers((old) => [...old.slice(0, index), { ...old[index], firstName: value }, ...old.slice(index + 1)]);
              }}
            />
          </Col>
          <Col xs={3}>
            <InputBox
              value={(p.weight ?? "") > 0 ? Number(p.weight).toString() : ""}
              isDisabled={!isTempPax(p)}
              name={"Weight (kg)"}
              fieldState={erros?.get("weight_" + index)}
              type={"number"}
              onChange={(value) => {
                setPassengers((old) => [...old.slice(0, index), { ...old[index], weight: value }, ...old.slice(index + 1)]);
              }}
            />
          </Col>
          <Col xs={{ span: 1 }} className="ps-3">
            <div className="input-container rounded-lg">
              <Button
                disabled={false}
                variant="link"
                onClick={() => {
                  setPassengers((old) => [...old.slice(0, index), ...old.slice(index + 1)]);
                }}
              >
                <FontAwesomeIcon icon={faTrash} color="black" />
              </Button>
            </div>
          </Col>
          <Col xs={2}>
            <InputBox
              value={p.idPassportCountryCode ?? ""}
              isDisabled={!isTempPax(p)}
              name={"ID/Passport Country"}
              onChange={(value) => {
                setPassengers((old) => [...old.slice(0, index), { ...old[index], idPassportCountryCode: value }, ...old.slice(index + 1)]);
              }}
            />
          </Col>
          <Col xs={2}>
            <InputBox
              value={p.idPassportNumber ?? ""}
              isDisabled={!isTempPax(p)}
              name={"ID/Passport Number"}
              onChange={(value) => {
                setPassengers((old) => [...old.slice(0, index), { ...old[index], idPassportNumber: value }, ...old.slice(index + 1)]);
              }}
            />
          </Col>
          <Col xs={2}>
            <InputBox
              value={p.relation ?? ""}
              isDisabled={!isTempPax(p)}
              name={"Relation (Form731)"}
              onChange={(value) => {
                setPassengers((old) => [...old.slice(0, index), { ...old[index], relation: value }, ...old.slice(index + 1)]);
              }}
            />
          </Col>
          <Col xs={2}>
            <InputBox
              value={p.form731contactDetails ?? ""}
              isDisabled={!isTempPax(p)}
              name={"Contact Details"}
              onChange={(value) => {
                setPassengers((old) => [...old.slice(0, index), { ...old[index], form731contactDetails: value }, ...old.slice(index + 1)]);
              }}
            />
          </Col>
          <Col xs={3}>
            <FlatPicker
              value={p.dob}
              onChange={(s) => {
                setPassengers((old) => [...old.slice(0, index), { ...old[index], dob: moment(s[0]).toDate() }, ...old.slice(index + 1)]);
              }}
              options={{
                static: true,
              }}
              className="form-control bg-white"
              render={({ value, ...props }, refFlatPickr) => {
                return (
                  <Row className="g-0">
                    <Col>
                      <div className={"input-container rounded-lg"}>
                        <input
                          ref={(r) => {
                            if (r !== null) {
                              refFlatPickr(r);
                            }
                          }}
                          disabled={!isTempPax(p)}
                          style={{ backgroundColor: "#F7F7F7", width: "100%", cursor: "pointer" }}
                        />
                        <label className={value ? "filled" : ""}>Date of Birth</label>
                      </div>
                    </Col>
                  </Row>
                );
              }}
            />
          </Col>
        </Row>
      ))}
      <Row className="pt-2 ">
        <Col>
          <Button
            className="fw-bold me-3 px-0"
            variant="light"
            style={{ backgroundColor: "transparent", height: "40px", borderWidth: "0px" }}
            onClick={() => {
              setPassengers((old) => [...old, { lastName: "", firstName: "", weight: 0, id: getRandomTempId() }]);
            }}
          >
            + Passenger
          </Button>
        </Col>
      </Row>
    </Container>
  );
}