import { useEffect, useRef, useState } from "react";
import { CupPoint, getAllCupPoints, getCupPointCoordsForLatLngTuple, getLatLngTupleForCupPoint, iconUrlForCupPoint, runwayDirectionTextForCupPoint, typeForCupPoint } from "../../../utils/CupPointUtils";
import { CircleMarker, LayerGroup, LayersControl, MapContainer, Marker, Polyline, Popup, TileLayer } from "react-leaflet";
import { LatLngTuple, icon } from "leaflet";
import * as Leaflet from "leaflet";
import { RoutePoint } from "../../../models/route";
import { Button } from "react-bootstrap";
import { AddManualPointComponent } from "../../../pages/organization/routes/RouteMapPage";

interface EntityRouteMapComponentProps {
  routePoints: (RoutePoint & { isStop: boolean })[];
  mapHeight?: string;
  insertRoutePoint?: (cupPoint: CupPoint) => void;
  setAsIntermediateStopPoint?: (cupPoint: CupPoint) => void;
}

function EntityRouteMapComponent({ routePoints, insertRoutePoint, setAsIntermediateStopPoint, mapHeight }: EntityRouteMapComponentProps) {
  const [points, setPoints] = useState<CupPoint[]>([]);

  const [polylines, setPolylines] = useState<JSX.Element[]>([]);

  useEffect(() => {
    //Create different polylines based on undefined lat/lon
    const acc: (RoutePoint & { isStop: boolean })[][] = [[]];
    routePoints.forEach((point, index) => {
      const currArray = acc[acc.length - 1];
      if (currArray.length === 0) {
        currArray.push(point);
      } else {
        const lastObj = currArray[currArray.length - 1];
        if (point.lat === undefined || point.lon === undefined) {
          if (lastObj.lat === undefined || lastObj.lon === undefined) {
            currArray.push(point);
          } else {
            acc.push([lastObj, point]);
          }
        } else {
          if (lastObj.lat === undefined || lastObj.lon === undefined) {
            currArray.push(point);
            acc.push([point]);
          } else {
            currArray.push(point);
          }
        }
      }
    });

    const polylines = acc.map((points, index) => {
      const containsUndefined = points.some((p) => p.lat === undefined || p.lon === undefined);
      return (
        <Polyline
          key={index}
          pathOptions={{ color: "magenta", weight: 5, dashArray: containsUndefined ? "5, 10" : undefined }}
          positions={[points.filter((p) => p.lat && p.lon).map((point) => [point.lat, point.lon] as LatLngTuple)]}
        />
      );
    });

    setPolylines(polylines);
  }, [routePoints]);

  useEffect(() => {
    const loadAsyncCupPoints = async () => {
      const cupPoints = await getAllCupPoints();
      setPoints(cupPoints);
    };
    loadAsyncCupPoints();
  }, []);

  const refMap = useRef<Leaflet.Map>(null);

  const routePointsWithCoords = routePoints.filter((p) => p.lat && p.lon);

  useEffect(() => {
    const bounds = new Leaflet.LatLngBounds(routePointsWithCoords.map((p) => [p.lat, p.lon]));

    if (bounds.isValid() && routePointsWithCoords.length > 1){
      refMap.current?.fitBounds(bounds,{padding: [30, 30], maxZoom: 10});
    }else{
      refMap.current?.setView([37.5, 24], 8)
    }
    
  }, [routePointsWithCoords]);
  
  const addManualRoutePoint = (point: LatLngTuple, name: string, isStop: boolean) => {
    const cupPoint: CupPoint = {
      code: name,
      country: "",
      desc: "",
      elev: "",
      freq: "",
      name: name,
      rwdir: "",
      rwlen: "",
      style: "-1",
      ...getCupPointCoordsForLatLngTuple(point)
    } 
    insertRoutePoint!(cupPoint);
    if (isStop) {
      setAsIntermediateStopPoint!(cupPoint);
    }
  }

  return (
    <MapContainer scrollWheelZoom={true} ref={refMap} style={{ height: mapHeight ?? `500px` }} maxZoom={18}>
      <LayersControl position="topright">
        <LayersControl.BaseLayer checked name="Open Flight Maps">
          <TileLayer
            attribution='&copy; <a href="https://www.openflightmaps.org/">Open Flight Maps</a>'
            url="https://openflightmaps.avitracer.com/112022/{z}/{x}/{y}.png"
            maxNativeZoom={11}
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Location IQ">
          <TileLayer
            attribution='&copy; <a href="https://locationiq.com/">LocationIQ</a>'
            url="https://a-tiles.locationiq.com/v3/streets/r/{z}/{x}/{y}.png?key=pk.c304bbf8aaa13dce7edc7b861b2c8026"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Satellite Hybrid">
          <TileLayer url="https://api.mapbox.com/styles/v1/avitracer/cl4k53v3e001x14lkhn445epj/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYXZpdHJhY2VyIiwiYSI6ImNsZ3Q3ajU0dzEwcGozZW8yZ2NtNGptd28ifQ.3cjrtycY3_QvWjr_XZdiVQ" />
        </LayersControl.BaseLayer>
        <LayersControl.Overlay checked name="Heliports">
          <LayerGroup>
            {points.filter((p) => typeForCupPoint(p) === "Heliport" ).map((point, index) => (
              <RouteMarker key={`${point.code}${index}`} refMap={refMap} point={point} insertRoutePoint={insertRoutePoint} setAsIntermediateStopPoint={setAsIntermediateStopPoint} />
            ))}
          </LayerGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay checked name="Airports">
          <LayerGroup>
            {points.filter((p) => typeForCupPoint(p) === "Airport" ).map((point, index) => (
              <RouteMarker key={`${point.code}${index}`} refMap={refMap} point={point} insertRoutePoint={insertRoutePoint} setAsIntermediateStopPoint={setAsIntermediateStopPoint} />
            ))}
          </LayerGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay checked name="VFR Points">
          <LayerGroup>
            {points.filter((p) => typeForCupPoint(p) === "Waypoint" ).map((point, index) => (
              <RouteMarker key={`${point.code}${index}`} refMap={refMap} point={point} insertRoutePoint={insertRoutePoint} setAsIntermediateStopPoint={setAsIntermediateStopPoint} />
            ))}
          </LayerGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay name="NDB/VOR">
          <LayerGroup>
            {points.filter((p) => typeForCupPoint(p) === "NDB" || typeForCupPoint(p) === "VOR" ).map((point, index) => (
              <RouteMarker key={`${point.code}${index}`} refMap={refMap} point={point} insertRoutePoint={insertRoutePoint}/>
            ))}
          </LayerGroup>
        </LayersControl.Overlay>
      </LayersControl>

      {polylines}

      {routePointsWithCoords.length > 0 && (
        <CircleMarker color="green" radius={25} weight={5} center={[routePointsWithCoords[0].lat, routePointsWithCoords[0].lon]} />
      )}
      {routePointsWithCoords.length > 1 && (
        <CircleMarker color="red" radius={20} weight={5} center={[routePointsWithCoords.at(-1)!.lat, routePointsWithCoords.at(-1)!.lon]} />
      )}
      {routePointsWithCoords.length > 2 && (
        <>
          {routePointsWithCoords.slice(1, -1).map((point) => (
            <CircleMarker
              key={`${point.lat}${point.lon}${point.isStop}`}
              color={point.isStop ? "blue" : "magenta"}
              radius={point.isStop ? 20 : 15}
              weight={5}
              center={[point.lat, point.lon]}
            />
          ))}
        </>
      )}
      <AddManualPointComponent addManualRoutePoint={addManualRoutePoint} assetsPath={"/assets/brief/map/icons/"} canAddIntStop={setAsIntermediateStopPoint !== undefined} />
    </MapContainer>
  );
}

export default EntityRouteMapComponent;



interface RouteMarkerProps {
  refMap: React.RefObject<Leaflet.Map>
  point: CupPoint;
  insertRoutePoint?: (cupPoint: CupPoint) => void;
  setAsIntermediateStopPoint?: (cupPoint: CupPoint) => void;
}

function RouteMarker({ refMap, point, insertRoutePoint, setAsIntermediateStopPoint }: RouteMarkerProps) {

  const addPressed = () => {
    refMap.current!.eachLayer((layer) => {
      if (layer instanceof Leaflet.Marker) {
        layer.closePopup();
      }
    });
    insertRoutePoint!(point)
  }

  const insertIntermediatePressed = () => {
    refMap.current!.eachLayer((layer) => {
      if (layer instanceof Leaflet.Marker) {
        layer.closePopup();
      }
    });
    insertRoutePoint!(point)
    setAsIntermediateStopPoint!(point)
  }
  
  return (
    <Marker
      key={`${point.name}${point.code}${point.style}`}
      position={getLatLngTupleForCupPoint(point)}
      opacity={0.7}
      icon={icon({ iconSize: [25, 25], iconUrl: iconUrlForCupPoint(point) })}
      title={point.code}
    >
      {insertRoutePoint && (
        <Popup>
          <span className="fs-5 fw-semibold">{typeForCupPoint(point)}</span>
          {((point.style !== "5" && point.code.length > 0) || (point.style === "5" && point.code.length === 4)) && (
            <div>
              FPL Code: <span className="fw-semibold">{point.code}</span>
            </div>
          )}
          <div>
            Name: <span className="fw-semibold">{point.name}</span>
          </div>
          {point.style === "5" && (
            <>
              <div>
                Runway: <span className="fw-semibold">{runwayDirectionTextForCupPoint(point)} </span>
              </div>
              <div>
                Runway Length: <span className="fw-semibold">{point.rwlen}</span>
              </div>
              <div>Radio Frequencies: </div>
              <div>
                <span className="fw-semibold">{point.freq}</span>
              </div>
            </>
          )}
          <div className="py-2 d-grid">
            <Button variant="dark" onClick={addPressed}>
              Add to route
            </Button>
          </div>
          {setAsIntermediateStopPoint && (
            <div className="pb-2 d-grid">
              <Button variant="light" onClick={insertIntermediatePressed}>
                Add as Intermediate Stop
              </Button>
            </div>
          )}
        </Popup>
      )}
    </Marker>
  );
}