import { useLoaderData, useNavigate } from "react-router-dom";
import { EntityDisplayInfo } from "../../../components/EntityDetails/EntityDetailsDisplayInfo";
import EntityDetailsPage from "../../../components/EntityDetails/EntityDetailsPage";
import { Crew } from "../../../models/crew";
import { LoaderFunction } from "@remix-run/router"
import { AviTracerApi } from "../../../avitracerApi";
import { useAppDispatch } from "../../../store/hooks";
import { addCrew, archiveCrewWithId, editCrew } from "../../../store/crewSlice";

export const crewLoader: LoaderFunction = async (params) => {
  if (params.params["crewId"]) {
      return AviTracerApi.getCrewWithId(params.params["crewId"]!);
  }
  return null;
};

function CrewDetailsPage() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const loadedCrew = useLoaderData() as Crew;

  var crew: Crew = loadedCrew ?? {}

  const handleSaveCrew = async (newCrew: Crew) => {
    if (newCrew.id === undefined) {
      return await dispatch(addCrew(newCrew)).unwrap()
    }else{
      return await dispatch(editCrew(newCrew)).unwrap()
    }
  }

  const handleArchiveCrew = async (crew: Crew) => {
    if (crew.id) {
      return await dispatch(archiveCrewWithId(crew.id)).unwrap();
    }
  };

  const goBack =  () => {
      navigate("/crew")
  }

  return (
    <EntityDetailsPage
      entity={crew}
      handleClose={goBack}
      displayInfo={EntityDisplayInfo.Crew()}
      dispatchSaveEntity={handleSaveCrew}
      dispatchDeleteEntity={handleArchiveCrew}
    />
  );
}

export default CrewDetailsPage;