import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { Col, Row, Button } from "react-bootstrap";

interface ListEntitiesHeaderComponentProps {
  actionBtn?:{
    title: string,
    onClick: () => void
  }
  search?:{
    value: string,
    setValue: (value: string) => void
  }
  title: string;
  children?: JSX.Element;
}

function ListEntitiesHeaderComponent({actionBtn, search, title, children}: ListEntitiesHeaderComponentProps) {
  return (
    <>
      <div className="py-1 d-sm-none" />

      <Row className={"pt-5 pb-2 justify-content-between gy-2 align-items-center".concat( children ? " pb-lg-5" : " pb-md-5")}>
        <Col xs={{ span: "auto", order: "1" }}>
          <h2>{title}</h2>
        </Col>

        {search && (
          <Col xs={{ span: 12, order: "last" }} md={{ span: 5, order: "2" }} lg={{ span: 6, order: "2" }}>
            <div
              className="ps-2 pe-1 px-lg-3 py-1 py-md-3 rounded" 
              style={{ backgroundColor: "#F7F7F7" }}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
              <input
                value={search.value}
                onChange={(e) => search.setValue(e.target.value)}
                placeholder="Search"
                className="ps-3 borderless"
              ></input>
            </div>
          </Col>
        )}

        {children && (
          <Col xs={{ span: 12, order: "last" }} lg={{ span: 6, order: "3" }} xl={4}>
            {children}
          </Col>
        )}

        <Col xs={{ span: "auto", order: "4" }} md={"4"} lg={"3"}>
          {actionBtn && (
            <div className="d-grid g-2">
              <Button
                variant="dark"
                className="py-md-3"
                onClick={actionBtn.onClick}
              >
                {actionBtn.title}
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
}

export default ListEntitiesHeaderComponent;