import { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import { useLoaderData, useNavigate, useNavigationType } from "react-router-dom";
import { AviTracerApi } from "../../avitracerApi";
import Flight from "../../models/flight";
import LoadingComponent from "../../components/LoadingComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { getCrewLastNameWithInitials } from "../../models/crew";
import moment from "moment";

function FlyFlightWatchPage() {
  const navigate = useNavigate();
  const navigationType = useNavigationType()

  const loadedFlight = useLoaderData() as Flight;

  const [watchCookie, setWatchCookie] = useState<string>();
  const [watchCookieError, setWatchCookieError] = useState<string>();

  useEffect(() => {
    const getCookieAsync = async () => {
      try {
        const { cookie } = await AviTracerApi.getFlightWatchCookie(loadedFlight.id!);
        setWatchCookie(cookie);
        document.cookie = cookie;
      } catch (error: any) {
        setWatchCookieError(error);
      }
    };
    getCookieAsync();
  }, [loadedFlight.id]);

  const goBack = () => {
    if (navigationType === "PUSH") {
      navigate(-1);
    }else{
      navigate("..", { relative: "path" });
    }
  }

  return (
    <>
      <Container fluid className="fixed-top">
        <Row className="align-items-center">
          <Col xs={"auto"}>
            <Button variant="light" className="bg-white border my-3" onClick={goBack}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
          </Col>
          <Col xs={4}>
            <strong>{loadedFlight.aircraft.registration}</strong>
            <div>
              {loadedFlight.aircraft.registration.replace("-", "") !== loadedFlight.flightId && loadedFlight.flightId.concat(" ")}(
              {loadedFlight.flightCategory !== "Training" ? loadedFlight.flightCategory : loadedFlight.trainingFlightCategory!})
            </div>
          </Col>
          <Col>
            <strong>PIC: </strong> {getCrewLastNameWithInitials(loadedFlight.pic)}
            <div>
              {loadedFlight.trainingFlightCategory === "Dual" && loadedFlight.studentPilot && (
                <>
                  <strong>Student: </strong>
                  {getCrewLastNameWithInitials(loadedFlight.studentPilot)}
                </>
              )}
              {loadedFlight.trainingFlightCategory === "Solo" && loadedFlight.soloSupervisor && (
                <>
                  <strong>Solo Supervisor: </strong>
                  {getCrewLastNameWithInitials(loadedFlight.soloSupervisor)}
                </>
              )}
              {loadedFlight.trainingFlightCategory === "Checkride" && loadedFlight.sic && (
                <>
                  <strong>Flight Examiner: </strong>
                  {getCrewLastNameWithInitials(loadedFlight.sic)}
                </>
              )}
            </div>
          </Col>
          
        </Row>
      </Container>

      <div className="fixed-bottom bg-white  d-flex align-items-center flex-column">
        <div className="d-flex align-items-center">
          <div className="pe-2 fs-4">
            <strong>FPL:</strong>
          </div>
          <div>
            <div>
              <small>
                <strong>Takeoff: </strong>
                {moment(loadedFlight.departureDateTime).format("HH:mm")}
                {loadedFlight.fplFile.status === "delayed" && (
                  <>
                    {" "}
                    - <strong>DLA: </strong>
                    {moment(loadedFlight.fplFile.newTime).format("HH:mm")}
                  </>
                )}
              </small>
            </div>
            <div>
              <small>
                <strong>Total Time: </strong>
                {moment(loadedFlight.totalEETMins).format("HH:mm")}
                {" - "}
                <strong>Endurance: </strong>
                {moment(loadedFlight.enduranceMins).format("HH:mm")}
              </small>
            </div>
          </div>
        </div>
      </div>
      <Container fluid className="vh-100 vw-100 px-0" style={{ paddingTop: "65px" }}>
        {watchCookie && <iframe src="https://app.avitracer.com" title="AviTracer" width={"100%"} height={"100%"}></iframe>}
        {watchCookieError && <ErrorComponent error={watchCookieError} />}
        {watchCookie === undefined && watchCookieError === undefined && (
          <LoadingComponent text="Connecting to AviTracer Flight Watch Solutions..." />
        )}
      </Container>
    </>
  );
}

export default FlyFlightWatchPage;

function ErrorComponent({ error }: { error: string }) {
  return (
    <>
      <Row className="justify-content-center pt-5">
        {error !== "NO_DEVICE" ? (
          <Col xs={"10"} md={6}>
            <Alert className="text-center" variant="danger">
              {error}
            </Alert>
          </Col>
        ) : (
          <Col xs={"10"} md={6} lg={4}>
            <Alert className="text-center" variant="danger">
              This aircraft is not equipped with an AviTracer Flight Watch Device.
              <br />
              <br />
              Explore the cutting-edge features of <a href="https://avitracer.com">AviTracer Flight Watch Solutions</a>, and promptly
              request your device via <a href="https://avitracer.com/request-demo">Contact us</a> section.
            </Alert>
          </Col>
        )}
      </Row>
    </>
  );
}