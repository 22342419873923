import { EntityDetailsDisplayInfoFieldNumber, EntityDetailsDisplayInfoFieldText, getValitationRules } from "./EntityDetailsDisplayInfo"
import InputBox from "../InputBox/InputBox";
import { Control, FieldValues, useController } from "react-hook-form";

interface EntityInputBoxProps<Entity extends FieldValues> {
  displayInfoInput: EntityDetailsDisplayInfoFieldText<Entity> | EntityDetailsDisplayInfoFieldNumber<Entity>;
  control: Control<Entity, any>
}
  
function EntityInputBox<Entity extends FieldValues>({displayInfoInput, control}: EntityInputBoxProps<Entity>) {
  
  const {
    field,
    fieldState,
    formState: { isSubmitting, isValid },
  } = useController({
    name: displayInfoInput.key,
    control,
    rules: getValitationRules(displayInfoInput),
  });

  const disabled = (isValid && isSubmitting) || (displayInfoInput.disabled ?? false)

  return (
    <InputBox
      value={field.value}
      onChange={(s: any) => {}}
      field={field}
      fieldState={fieldState}
      isDisabled={ disabled}
      name={displayInfoInput.name}
      uppercase={(displayInfoInput.type === "text") ? displayInfoInput.uppercase : false}
      type={displayInfoInput.type}
    />
  );
}

export default EntityInputBox;