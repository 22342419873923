import { Control, FieldValues, useController } from "react-hook-form";
import { Crew } from "../../../models/crew";
import ListCrewPage from "../../../pages/organization/crew/ListCrewPage";
import ReadOnlyInputBox from "../../InputBox/ReadonlyInputBox";
import { EntityDetailsDisplayInfoFieldCrew, getValitationRules } from "../EntityDetailsDisplayInfo";

interface EntityCrewPickerProps<Entity extends FieldValues> {
    displayInfoInput: EntityDetailsDisplayInfoFieldCrew<Entity>;
    control: Control<Entity, any>
    setModalContent: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
}

function EntityCrewPicker<Entity extends FieldValues>({displayInfoInput, setModalContent, control}: EntityCrewPickerProps<Entity>) {
  const {
    field: { value, onChange, ref },
    fieldState,
    formState: { isSubmitting, isValid },
  } = useController({
    name: displayInfoInput.key,
    control,
    rules: getValitationRules(displayInfoInput),
  });

  const crewName: string = [
    (value as Crew)?.lastName,
    (value as Crew)?.firstName,
  ]
    .filter((n) => n)
    .join(" ");

  return (
    <ReadOnlyInputBox
      inputRef={ref}
      value={ value ? crewName : ""}
      isDisabled={(isValid && isSubmitting) || (displayInfoInput.disabled ?? false)}
      name={displayInfoInput.name}
      fieldState={fieldState}
      onClick={() => {
        setModalContent(
          <ListCrewPage
            onCrewSelected={(c) => {
              onChange(c);
              setModalContent(null);
            }}
          />
        );
      }}
      onClickDelete={() => {
        onChange(null);
      }}
    />
  );
}

export default EntityCrewPicker