import { Badge, Button, Col, Container, Row } from "react-bootstrap"
import { useLoaderData, useNavigate } from "react-router-dom"
import Flight, { FlightPostFlightData } from "../../models/flight"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faCircleUser, faLocationArrow } from '@fortawesome/free-solid-svg-icons'
import moment from "moment"
import { AviTracerApi } from "../../avitracerApi"
import { useRef, useState } from "react"
import { useAppDispatch } from "../../store/hooks"
import { pilotAcceptBriefing } from "../../store/flightSlice"
import SignatureCanvas from 'react-signature-canvas'
import { useForm } from "react-hook-form"
import EntityInputBox from "../../components/EntityDetails/EntityInputBox"
import EntityDateTimePicker from "../../components/EntityDetails/EntityDateTimePicker"
import { getDisplayableTextFromFlightPlanPoint } from "../../models/flightPlan"
import { getCrewFullName } from "../../models/crew"
import { getPassengerFullName } from "../../models/passenger"

function FlyFlightDetailsPage(){
    const navigate = useNavigate();
    const dispatch = useAppDispatch()

    const loadedFlight = useLoaderData() as Flight
    
    const [flight, setFlight] = useState<Flight>(loadedFlight)
    const signedPack = flight.briefingPilotAccept !== undefined

    const downloadDocPressed = async (docName: string) => {
      const pdfData = await AviTracerApi.getPdfDoc(flight.id!, docName)
      const pdfBlob = new Blob([pdfData], {type: 'application/pdf'});
      var pdfURL = window.URL.createObjectURL(pdfBlob);
  
      const link = document.createElement('a');
      link.download = flight.flightId + "-" + moment(flight.departureDateTime).utc().format("DD-MM-YY-HHmm") + `-${docName}.pdf` ;
      link.href = pdfURL;
      link.target = '_blank';
      document.body.appendChild(link);
      
      link.click();
    }

    const flightPlanStatusElement = () => {
      if (flight.fplFile.status === "filed"){
        return (
          <span className="text-success fw-semibold">
            Filed
          </span>
        )
      }else if (flight.fplFile.status === "delayed"){
        return (
          <span className="text-success fw-semibold">
            Delayed for {moment(flight.fplFile.newTime).utc().format("HH:mm")}
          </span>
        )
      }else{
        return (
          <span className="text-danger fw-semibold">Not filed</span>
        )
      }
    } 

    const briefingPackStatusElement = () => {
      if (flight.briefing?.status === "ready"){
        return (
          <>
            {flight.briefingPilotAccept !== undefined ? (
              <span className="text-success fw-semibold">Signed</span>
            ) : (
              <span className="text-danger fw-semibold">Not signed</span>
            )}
            <div className="d-flex flex-column align-items-start">
              <Button disabled={!signedPack} variant="link" className="px-0 py-1" onClick={() => downloadDocPressed("briefing-signed")}>
                Signed Briefing Pack
              </Button>
              <Button variant="link" className="px-0 py-1" onClick={() => downloadDocPressed("briefing")}>
                Full Briefing Pack
              </Button>
              <Button variant="link" disabled={flight.wb?.status !== "ok"} className="px-0 py-1" onClick={() => downloadDocPressed("wb")}>
                Weight & Balance
              </Button>
              <Button variant="link" className="px-0 py-1" onClick={() => downloadDocPressed("navLog")}>
                Navigation Log
              </Button>
              <Button variant="link" className="px-0 py-1" onClick={() => downloadDocPressed("icaoFpl")}>
                ICAO Flight Plan
              </Button>
              <Button variant="link" className="px-0 py-1" onClick={() => downloadDocPressed("genDec")}>
                General Declaration
              </Button>
              {flight.isCostSharing !== undefined && (
                <Button variant="link" className="px-0 py-1" onClick={() => downloadDocPressed("form731")}>
                  Form 731
                </Button>
              )}
            </div>
          </>
        );
      }else{
        return (
          <span className="text-danger fw-semibold">Not ready</span>
        )
      }
    } 


    const pilotAcceptBriefingPressed = async () => {
      if (signatureRef.current?.isEmpty()) {
        alert("Please sign in the above area.");
        return;
      }

      setIsAcceptingBriefing(true);

      const signatureDataUrl = signatureRef.current!.getTrimmedCanvas().toDataURL();

      try {
        const newFlight = await dispatch(pilotAcceptBriefing({ flightId: flight.id!, signatureBase64: signatureDataUrl, postFlightData })).unwrap();
        setFlight(newFlight);
      } catch (e: any) {
        alert(e.message);
      } finally {
        signatureRef.current!.clear();
        setShowSignaturePad(false);
        setIsAcceptingBriefing(false);
      }
    }
    
    const [isAcceptingBriefing, setIsAcceptingBriefing] = useState<boolean>(false)
    const [showSingaturePad, setShowSignaturePad] = useState<boolean>(false)
    const signatureRef = useRef<SignatureCanvas>(null)

    const [postFlightData, setPostFlightData] = useState<FlightPostFlightData>({})

    const [showPostFlightFields, setShowPostFlightFields] = useState<boolean>(false)

    if (showSingaturePad) {
      return (
        <SignPackComponent
          signatureRef={signatureRef}
          isAcceptingBriefing={isAcceptingBriefing}
          pilotAcceptBriefingPressed={pilotAcceptBriefingPressed}
          setShowSignaturePad={setShowSignaturePad}
          setShowPostFlightFields={setShowPostFlightFields}
        />
      );
    }else if (showPostFlightFields) {
      return (
        <PostFlightFieldsComponent
          flight={flight}
          setShowPostFlightFields={setShowPostFlightFields}
          setShowSignaturePad={setShowSignaturePad}
          fuelUnit={flight.aircraft.fuelUnit}
          postFlightData={postFlightData}
          setPostFlightData={setPostFlightData}
        />
      )
    } else {
      return (
        <>
          <Container>
            <div className="fixed-top">
              <Container className="d-flex justify-content-between">
                <Button variant="light" className="bg-white border mt-3" onClick={() => navigate("/fly")}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Button>
                <Button variant="light" className="border mt-3" onClick={() => navigate("./watch")}>
                  <FontAwesomeIcon icon={faLocationArrow} className="pe-2"/>
                  Live Tracking
                </Button>
              </Container>
            </div>
            <Row style={{ paddingTop: "65px" }}>
              <h1 className="fw-bold">
                {flight.flightId} {" - "} {moment(flight.departureDateTime).utc().format("HH:mm")}
              </h1>
            </Row>
            {flight.studentPilot !== undefined && (
              <Row className="py-2">
                <h2>Student</h2>
                <div>
                  <div className="d-flex align-items-center py-2 my-2">
                    <FontAwesomeIcon size="2x" color="#9B9B9B" icon={faCircleUser} className="pe-2" />
                    <span className="align-middle">
                      {getCrewFullName(flight.studentPilot)}
                    </span>
                  </div>
                </div>
              </Row>
            )}
            {flight.passengers.length > 0 && (
              <Row className="py-2">
                <h2>Passengers</h2>
                {flight.passengers.map((passenger) => {
                  return (
                    <div key={passenger.id!}>
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="d-flex align-items-center py-2 my-2">
                            <FontAwesomeIcon size="2x" color="#9B9B9B" icon={faCircleUser} className="pe-2" />
                            <span className="align-middle">{getPassengerFullName(passenger)}</span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          {flight.checkIns?.[passenger.id!] !== undefined && <Badge bg={"success"}>Boarded</Badge>}
                        </div>
                      </div>
                      <hr className="m-0" />
                    </div>
                  );
                })}
              </Row>
            )}
            <Row className="py-2">
              <Col xs={12}>
                <strong>Flight Plan:</strong> {flightPlanStatusElement()}
              </Col>
              <Col xs={12}>
                <strong>Briefing Pack:</strong> {briefingPackStatusElement()}
              </Col>
            </Row>
            <Row className="py-2">
              <h2>Route</h2>

              <Col xs={12}>
                {flight.route && (
                  <>
                    {[flight.route.departure, ...(flight.route.waypoints ?? []), flight.route.destination].map((w) => w.name).join(" ➜ ")}
                  </>
                )}
                {flight.customRoute && (
                  <>
                    {[getDisplayableTextFromFlightPlanPoint(flight.customRoute.departure), 
                      ...(flight.customRoute.waypoints ?? []).map((w) => w.name),
                      getDisplayableTextFromFlightPlanPoint(flight.customRoute.destination)
                      ].join(" ➜ ")}
                  </>
                )}
              </Col>
            </Row>
            <div style={{ height: "170px" }} />

            <div className="fixed-bottom bg-light">
              <div className="d-grid  gap-2 m-3 ">
                <Button
                  disabled={signedPack || flight.briefing?.status !== "ready"}
                  style={{ height: "60px" }}
                  className=" fw-bold"
                  variant="dark"
                  onClick={() => setShowPostFlightFields(true)}
                >
                  {signedPack ? "Briefing Pack Signed" : "Sign Briefing Pack"}
                </Button>
                <Button
                  disabled={flight.passengers.length === 0}
                  style={{ height: "60px", backgroundColor: "#f7f7f7", borderColor: "#dfdfdf" }}
                  className="text-dark fw-bold"
                  onClick={() => navigate("./scan")}
                >
                  Scan Passengers
                </Button>
              </div>
            </div>
          </Container>
        </>
      );
    }
}

export default FlyFlightDetailsPage

interface SignPackComponentProps {
  setShowSignaturePad: (show: boolean) => void;
  setShowPostFlightFields: (show: boolean) => void;
  pilotAcceptBriefingPressed: () => void;
  isAcceptingBriefing: boolean;
  signatureRef: React.RefObject<SignatureCanvas>
}

function SignPackComponent({ setShowSignaturePad, pilotAcceptBriefingPressed, isAcceptingBriefing, signatureRef, setShowPostFlightFields }: SignPackComponentProps) {
  return (
    <>
      <div className="fixed-top">
        <Container>
          <Button
            disabled={isAcceptingBriefing}
            variant="light"
            className="bg-white border mt-3"
            onClick={() => {setShowPostFlightFields(true); setShowSignaturePad(false)}}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
        </Container>
      </div>
      <div className="vw-100 vh-100 bg-white">
        <div className="bg-white w-100 h-100">
          <SignatureCanvas minWidth={2} maxWidth={5} ref={signatureRef} dotSize={3} canvasProps={{ className: "w-100 h-100" }} />
        </div>
      </div>
      <div className="fixed-bottom bg-light">
        <div className="d-flex">
          <div className="w-25 d-grid  gap-2 m-2">
            <Button
              disabled={isAcceptingBriefing}
              style={{ height: "60px" }}
              variant="light"
              className="fw-bold"
              onClick={() => {
                signatureRef.current!.clear();
              }}
            >
              Clear
            </Button>
          </div>
          <div className="w-75 d-grid gap-2 m-2">
            <Button
              disabled={isAcceptingBriefing}
              style={{ height: "60px" }}
              className="fw-bold"
              variant="dark"
              onClick={pilotAcceptBriefingPressed}
            >
              {isAcceptingBriefing ? "Saving..." : "Sign Briefing Pack"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

interface PostFlightFieldsComponentProps {
  flight: Flight
 setShowSignaturePad: (show: boolean) => void;
 setShowPostFlightFields: (show: boolean) => void;
 fuelUnit: string
 postFlightData: FlightPostFlightData
 setPostFlightData: React.Dispatch<React.SetStateAction<FlightPostFlightData>>
}

function PostFlightFieldsComponent({ flight, setShowSignaturePad, setShowPostFlightFields, fuelUnit, postFlightData, setPostFlightData }: PostFlightFieldsComponentProps) {

  const { control, watch } = useForm<FlightPostFlightData>({defaultValues: postFlightData});

  watch((data) => {
    setPostFlightData(data as FlightPostFlightData);
  })

  return (
    <>
      <div className="fixed-top">
        <Container>
          <Button variant="light" className="bg-white border mt-3" onClick={() => setShowPostFlightFields(false)}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
        </Container>
      </div>
      <Container style={{ paddingTop: "80px", paddingBottom:"100px" }}>
        <Row className="gy-2">
          <Col xs={12}>
            <h2>Actual Fuel</h2>
          </Col>
          <Col xs={12}>
            <EntityInputBox
              control={control}
              displayInfoInput={{
                key: "blockOffFuel",
                name: `Block Off Fuel (${fuelUnit})`,
                type: "number",
              }}
            />
          </Col>
          <Col xs={12}>
            <EntityInputBox
              control={control}
              displayInfoInput={{
                key: "takeoffFuel",
                name: `Take Off Fuel (${fuelUnit})`,
                type: "number",
              }}
            />
          </Col>
          <Col xs={12}>
            <EntityInputBox
              control={control}
              displayInfoInput={{
                key: "landingFuel",
                name: `Landing Fuel (${fuelUnit})`,
                type: "number",
              }}
            />
          </Col>
          <Col xs={12}>
            <EntityInputBox
              control={control}
              displayInfoInput={{
                key: "blockOnFuel",
                name: `Block On Fuel (${fuelUnit})`,
                type: "number",
              }}
            />
          </Col>
          </Row>
          <Row className="gy-2 pt-5">
          <Col xs={12}>
            <h2>Actual Flight Time</h2>
          </Col>
          <Col xs={12}>
            <EntityDateTimePicker
              control={control}
              displayInfoInput={{
                key: "blockOffTime",
                suggestedValue: flight.departureDateTime,
                name: "Block Off Time",
                timeName: "Block Off Time",
                type: "dateTime",
                useUtc: true,
                showOnlyTimePicker: true,
                timeFlatpickrOptions: {
                  dateFormat: "H:i",
                  time_24hr: true,
                  minuteIncrement: 1,
                },
              }}
            />
          </Col>
          <Col xs={12}>
            <EntityDateTimePicker
              control={control}
              displayInfoInput={{
                key: "takeoffTime",
                suggestedValue: moment(flight.departureDateTime).add(5, "minutes").toDate(),
                name: "Take off Time",
                timeName: "Take off Time",
                type: "dateTime",
                useUtc: true,
                showOnlyTimePicker: true,
                timeFlatpickrOptions: {
                  dateFormat: "H:i",
                  time_24hr: true,
                  minuteIncrement: 1,
                },
              }}
            />
          </Col>
          <Col xs={12}>
            <EntityDateTimePicker
              control={control}
              displayInfoInput={{
                key: "landingTime",
                suggestedValue: moment(flight.departureDateTime).add(flight.totalEETMins + 5, "minutes").toDate(),
                name: "Landing Time",
                timeName: "Landing Time",
                type: "dateTime",
                useUtc: true,
                showOnlyTimePicker: true,
                timeFlatpickrOptions: {
                  dateFormat: "H:i",
                  time_24hr: true,
                  minuteIncrement: 1,
                },
              }}
            />
          </Col>
          <Col xs={12}>
            <EntityDateTimePicker
              control={control}
              displayInfoInput={{
                key: "blockOnTime",
                suggestedValue: moment(flight.departureDateTime).add(flight.totalEETMins + 10, "minutes").toDate(),
                name: "Block On Time",
                timeName: "Block On Time",
                type: "dateTime",
                useUtc: true,
                showOnlyTimePicker: true,
                timeFlatpickrOptions: {
                  dateFormat: "H:i",
                  time_24hr: true,
                  minuteIncrement: 1,
                },
              }}
            />
          </Col>
        </Row>
      </Container>
      <div className="fixed-bottom bg-light">
        <div className="d-grid gap-2 m-2">
          <Button
            style={{ height: "60px" }}
            className="fw-bold"
            variant="dark"
            onClick={() => {
              setShowSignaturePad(true);
              setShowPostFlightFields(false);
            }}
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
}
