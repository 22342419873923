import { EntityDetailsDisplayInfo } from "../components/EntityDetails/EntityDetailsDisplayInfo"

export type Passenger = {
    id?: string
    firstName: string,
    lastName: string,
    
    //GenDec
    dob?: Date,
    idPassportNumber?: string
    idPassportCountryCode?: string
    signatureBase64?: string
    
    //Form 731
    relation?: string
    form731contactDetails?: string

    weight: number,
}

export function getPassengerFullName(passenger: Passenger): string {
    return [passenger.lastName, passenger.firstName].filter(x => x).join(' ')
}

export function getPassengerLastNameWithInitials(passenger: Passenger): string {
    return [passenger.lastName, passenger.firstName ? passenger.firstName[0] + "." : ""].filter(x => x).join(' ')
}

export function getPassengerDisplayInfo(): EntityDetailsDisplayInfo<Passenger> {
    return {
        entityName: "Passenger",
        sections: [
            {
                sectionName: "Personal Info",
                sectionId: "personalInfo",
                inputs: [
                    {
                        name: "Last Name",
                        key: "lastName",
                        type: "text",
                        required: true
                    },
                    {
                        name: "First Name",
                        key: "firstName",
                        type: "text"
                    }
                ]
            },
            {
                sectionName: "General Declaration",
                sectionId: "genDec",
                inputs: [
                    {
                        name: "Date of Birth",
                        key: "dob",
                        type: "date"
                    },
                    {
                        name: "ID/Passport Number",
                        key: "idPassportNumber",
                        type: "text",
                    },
                    {
                        name: "ID/Passport Country Code",
                        key: "idPassportCountryCode",
                        type: "text",
                    }

                ]
            },
            {
                sectionName: "Form 731 (Greece Only)",
                sectionId: "form731",
                inputs: [
                    {
                        name: "Relation to person/entity operating the aircraft",
                        key: "relation",
                        type: "text",
                    },
                    {
                        name: "Contact Details",
                        key: "form731contactDetails",
                        type: "text",
                    }
                ]
            },
            {
                sectionName: "Weight & Balance",
                sectionId: "wb",
                inputs: [
                    {
                        name: "Weight (kg)",
                        key: "weight",
                        type: "number",
                        required: true
                    }
                ]
            },
            {
                sectionName: "Signature",
                sectionId: "signature",
                inputs: [
                    {
                        name: "Signature",
                        key: "signatureBase64",
                        type: "signature",
                        entityType: "passenger",
                        required: true
                    }
                ]
            }
        ]
    }
}
