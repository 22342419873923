
import { Player } from "@lottiefiles/react-lottie-player";
import { Button, Col, Container, Row } from 'react-bootstrap';

function AccountPurchaseCreditsSuccessPage() {

  return (
    <Container>
      <Row style={{ height: "140px" }}></Row>
      <Row className="justify-content-center text-center">
        <Col>
          <h1>Thank you for your Payment!</h1>
          <small>Your payment has been successfully processed and the credits have been added to your account.</small>
          
          <Player
            autoplay
            loop
            src="/assets/brief/lottie/paymentOk.json"
            speed={1}
            background="transparent"
            style={{ height: "200px", width: "200px" }}
          />
          <Button className="pt-2" variant="link" href="/account">Go to My account</Button>
        </Col>
      </Row>
    </Container>
  );
}


export default AccountPurchaseCreditsSuccessPage