import { Player } from "@lottiefiles/react-lottie-player"
import { Button, Col, Container, Row } from "react-bootstrap"

function AccountPurchaseCreditsCancelPage() {
  return (
    <Container>
      <Row style={{ height: "140px" }}></Row>
      <Row className="justify-content-center text-center">
        <Col>
          <h1>Your payment was cancelled.</h1>
          <small>Please try again!</small>

          <Player
            className="pt-3"
            autoplay
            loop
            src="/assets/brief/lottie/paymentCancelled.json"
            speed={1}
            background="transparent"
            style={{ height: "200px", width: "200px" }}
          />
          <Button className="pt-3" variant="link" href="/account">Go to My account</Button>
        </Col>
      </Row>
    </Container>
  );
}

export default AccountPurchaseCreditsCancelPage