import { signOut } from "firebase/auth";
import { useEffect } from "react";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../components/LoadingComponent";

function Logout(){
    const navigate = useNavigate();

    useEffect(() => {
        const signout = async () => {
            await signOut(auth)
            navigate("/")
        }
        signout()
    },[navigate])

    return (
        <LoadingComponent text="Logging out..."/>
    )
}

export default Logout;
