import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import Header from "../../Header";

function RequestInfoLayout() {
    return (
      <>
        <Header />
        <Container>
          <Outlet />
        </Container>
      </>
    );
}

export default RequestInfoLayout;