import { EntityDetailsDisplayInfoFieldTimeWithAutoSet, getValitationRules } from "../EntityDetailsDisplayInfo"
import InputBox from "../../InputBox/InputBox";
import { Control, FieldValues, useController } from "react-hook-form";
import { Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalculator } from '@fortawesome/free-solid-svg-icons'
import FlatPicker from 'react-flatpickr'

interface EntityTimeWithAutoSetComponentProps<Entity extends FieldValues> {
      displayInfoInput: EntityDetailsDisplayInfoFieldTimeWithAutoSet<Entity>;
      control: Control<Entity, any>;
      edittedEntity: Entity;
};
  
function EntityTimeWithAutoSetComponent<Entity extends FieldValues>({displayInfoInput, control, edittedEntity}: EntityTimeWithAutoSetComponentProps<Entity>) {
  const {
    field,
    fieldState,
    formState: { isSubmitting, isValid },
  } = useController({
    name: displayInfoInput.key,
    control,
    rules: getValitationRules(displayInfoInput),
  });

  const disabled = (isValid && isSubmitting) || (displayInfoInput.disabled ?? false);

  const { onChange: fieldOnChange, value } = field;

  return (
    <Row className="g-0">
      <Col xs={4} className="no-right-radius">
        <InputBox
          value={value}
          onChange={(s: any) => {}}
          field={field}
          fieldState={{...fieldState, error: undefined}}
          isDisabled={disabled}
          name={displayInfoInput.name}
          type={"number"}
          labelStyle={{marginRight: "-16px"}}
        />
      </Col>
      <Col xs={3} md={4} style={{ borderLeft: "#DFDFDF 2px solid " }}>
        <FlatPicker
          value={value ? new Date(0, 0, 0, 0, value) : undefined}
          onClose={(s) => {
            if (s.length === 0) {
              fieldOnChange(60);
            } else {
              fieldOnChange(s[0].getMinutes() + s[0].getHours() * 60);
            }
          }}
          options={{
            defaultHour: 1,
            defaultMinute: 0,
            enableTime: true,
            noCalendar: true,
            static: true,
            time_24hr: true,
            minuteIncrement: 1,
          }}
          className="form-control bg-white"
          render={({ value, ...props }, refFlatPickr) => {
            return (
              <div className={"input-container "} style={{ backgroundColor: "#F7F7F7", borderRadius: "0px" }}>
                <input
                  ref={(r) => {
                    if (r !== null) {
                      refFlatPickr(r);
                    }
                  }}
                  disabled={disabled}
                  className="w-100 bg-transparent"
                  style={{ cursor: disabled ? "auto" : "pointer" }}
                  readOnly
                />
                <label className={value ? "filled" : ""}>HH:MM</label>
              </div>
            );
          }}
        />
      </Col>
      <Col xs={5} md={4}>
        <div className="d-grid h-100" style={{ backgroundColor: "#F7F7F7" }}>
          <Button
            disabled={disabled}
            className="m-2"
            variant="light"
            style={{ border: "#DFDFDF 2px solid", "--bs-btn-bg": "#FFF" }}
            onClick={() => fieldOnChange(displayInfoInput.getCalculatedValue(edittedEntity!))}
          >
            <FontAwesomeIcon icon={faCalculator} color="black" className="pe-2" />
            Set {displayInfoInput.getCalculatedValue(edittedEntity!)} mins
          </Button>
        </div>
      </Col>
      {fieldState.error && <small className="text-danger">{fieldState.error.message}</small>}
    </Row>
  );
}

export default EntityTimeWithAutoSetComponent;