import { Control, FieldValues, useController, UseFormSetValue } from "react-hook-form";
import { getFplCoordsFromRoutePoint, Route } from "../../../models/route";
import Flight from "../../../models/flight";
import ListRoutesPage from "../../../pages/organization/routes/ListRoutesPage";
import ReadOnlyInputBox from "../../InputBox/ReadonlyInputBox";
import { EntityDetailsDisplayInfoFieldRoute, getValitationRules } from "../EntityDetailsDisplayInfo";
import { Button, Col, Row } from "react-bootstrap";
import { getFPLPointFromRoutePoint } from "../../../models/flightPlan";

interface EntityRoutePickerProps<Entity extends FieldValues> {
    displayInfoInput: EntityDetailsDisplayInfoFieldRoute<Entity>;
    setModalContent: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
    control: Control<Entity, any>
    setValue: UseFormSetValue<Flight>
}

function EntityRoutePicker<Entity extends FieldValues>({displayInfoInput, setModalContent, control, setValue}: EntityRoutePickerProps<Entity>){

  const {
    field: { value, onChange, ref},
    fieldState,
    formState: { isSubmitting, isValid },
  } = useController({
    name: displayInfoInput.key,
    control,
    rules: getValitationRules(displayInfoInput),
  });


  const updateRoute = (route: Route) => {
    onChange(route);

    if (displayInfoInput.parentEntity === "Flight") {
      setValue("altn1", route.altn1);
      setValue("altn2", route.altn2);
      setValue("flightRules", "V");
      setValue("flightType", "G");
     
      setValue("passengersLeg.route", null as any);
      setValue("passengersLeg", undefined);
    }

    setModalContent(null);
  }

  const customRoutePressed = () => {
    const currRoute = value as Route;
    const customRoute: Flight["customRoute"] = {
      departure: currRoute?.departure ? getFPLPointFromRoutePoint(currRoute.departure) : {isIcao: true, icaoCode:"", coords:""},
      destination: currRoute?.destination ? getFPLPointFromRoutePoint(currRoute.destination) : {isIcao: true, icaoCode:"", coords:""},
      waypoints: currRoute?.waypoints.map((wp) => {
        return {
          isIcao: false,
          name: wp.name,
          coords: getFplCoordsFromRoutePoint(wp)
        }
      }) ?? [],
      intermediateStops: currRoute?.intermediateStops ?? [],
    }
    onChange(null);
    setValue(displayInfoInput.customRouteKey!, customRoute);
    setValue("passengersLeg.route", null as any);
    setValue("passengersLeg", undefined);
  }

  const routeName: string = (value as Route)?.name ?? "";

  const isDisabled = (isValid && isSubmitting) || (displayInfoInput.disabled ?? false)

  return (
    <Row>
      <Col xs={(displayInfoInput.customRouteKey) ? 8: 12} lg={(displayInfoInput.customRouteKey) ? 9 : 12}>
        <ReadOnlyInputBox
          inputRef={ref!}
          value={routeName}
          isDisabled={isDisabled}
          name={displayInfoInput.name}
          fieldState={fieldState}
          onClick={() => {
            setModalContent(<ListRoutesPage onRouteSelected={updateRoute} />);
          }}
        />
      </Col>
      {displayInfoInput.customRouteKey && (
        <Col xs={4} lg={3}>
          <Button disabled={isDisabled} variant="dark" onClick={customRoutePressed} className="h-100 w-100">
            <div>Custom</div> Route
          </Button>
        </Col>
      )}
    </Row>
  );
}

export default EntityRoutePicker
