import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../../css/navlink-underline.css";

interface ListFlightsTabsComponentProps {
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<"today" | "tomorrow" | "upcoming" | "archived">>
}

function ListFlightsTabsComponent({ selectedTab, setSelectedTab }: ListFlightsTabsComponentProps){

    return (
      <Row style={{height: "46px"}}>
        <nav className="nav">
          <Link
            to="/flights"
            onClick={(e) => {e.preventDefault(); setSelectedTab("today")}}
            className={"nav-link underline px-2 px-sm-3 ".concat(
              selectedTab === "today" ? " active" : ""
            )}
          >
            Today
          </Link>
          <Link
            to="/flights?tab=tomorrow"
            onClick={(e) => {e.preventDefault(); setSelectedTab("tomorrow")}}
            className={"nav-link underline px-2 px-sm-3 ".concat(
              selectedTab === "tomorrow" ? " active" : ""
            )}
          >
            Tomorrow
          </Link>
          <Link
            to="/flights?tab=upcoming"
            onClick={(e) => {e.preventDefault(); setSelectedTab("upcoming")}}
            className={"nav-link underline px-2 px-sm-3 ".concat(
              selectedTab === "upcoming" ? " active" : ""
            )}
          >
            Upcoming
          </Link>
          <Link
            to="/flights?tab=archived"
            onClick={(e) => {e.preventDefault(); setSelectedTab("archived")}}
            className={"nav-link underline px-2 px-sm-3 ".concat(
              selectedTab === "archived" ? " active" : ""
            )}
          >
            Archived
          </Link>
        </nav>
      </Row>
    );

}

export default ListFlightsTabsComponent
