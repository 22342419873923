import { Arrow, Circle, Layer, Line, Rect, Stage, Text } from "react-konva";
import useImage from "use-image";
import { AircraftWbLatPoint, AircraftWbLonPoint, AircraftWBPoint } from "../../../models/aircraft";
import { CalculatedFlightWbPoints } from "../../../models/flightWb";
import { useAppSelector } from "../../../store/hooks";
import { selectedOrganization } from "../../../store/organizationsSlice";


type AircraftWBGraphComponentProps = AircraftWBGraphLonComponentProps | AircraftWBGraphLatComponentProps

type AircraftWBGraphLonComponentProps = {
    type: "lon";
    aircraftWbPoints: AircraftWbLonPoint[];
} & AircraftWBGraphBaseComponentProps

type AircraftWBGraphLatComponentProps = {
  type: "lat";
  aircraftWbPoints: AircraftWbLatPoint[];
} & AircraftWBGraphBaseComponentProps

type AircraftWBGraphBaseComponentProps = {
  calculatedFlightWbPoints: CalculatedFlightWbPoints;
  size?: number //default 300
}

type BoxLimits = {
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
};

function AircraftWBGraph({ aircraftWbPoints, calculatedFlightWbPoints: calculatedWbPoints, size = 300, type }: AircraftWBGraphComponentProps) {

  const selectedOrg = useAppSelector(selectedOrganization);
  
  const height = size;
  const width = size;

  const isLatArm = type === "lat";
  const useMassOnLat = isLatArm && "mass" in aircraftWbPoints[0];

  var minX, maxX, minY, maxY, xSpan, ySpan;
  if (type === "lon") {
    minX = Math.min(...aircraftWbPoints.map((p) => p.arm)) ;
    maxX = Math.max(...aircraftWbPoints.map((p) => p.arm)) ;
    minY = Math.min(...aircraftWbPoints.map((p) => p.mass));
    maxY = Math.max(...aircraftWbPoints.map((p) => p.mass));
    xSpan = maxX - minX;
    ySpan = maxY - minY;
  }else{
    if (useMassOnLat) {
      minX = Math.min(...aircraftWbPoints.map((p) => p.latArm));
      maxX = Math.max(...aircraftWbPoints.map((p) => p.latArm));
      minY = Math.min(...aircraftWbPoints.map((p) => (p as any).mass));
      maxY = Math.max(...aircraftWbPoints.map((p) => (p as any).mass));
    } else {
      minX = Math.min(...aircraftWbPoints.map((p) => (p as any).arm));
      maxX = Math.max(...aircraftWbPoints.map((p) => (p as any).arm));
      minY = Math.min(...aircraftWbPoints.map((p) => p.latArm));
      maxY = Math.max(...aircraftWbPoints.map((p) => p.latArm));
    }
    
    xSpan = maxX - minX;
    ySpan = maxY - minY;
  }

  const boxLimits: BoxLimits = {
    minX: minX - xSpan * 0.1,
    maxX: maxX + xSpan * 0.1,
    minY: minY - ySpan * 0.1,
    maxY: maxY + ySpan * 0.1,
  };

  const translatePointToBox = (wbPoint: AircraftWBPoint, boxLimits: BoxLimits): [number, number] => {
    var x
    var y
    if (!isLatArm){ //lon
      x = (wbPoint as AircraftWbLonPoint).arm;  
      y = (wbPoint as AircraftWbLonPoint).mass
    }else{
      if (useMassOnLat){
        x = (wbPoint as AircraftWbLatPoint).latArm;  
        y = (wbPoint as any).mass
      }else{
        x = (wbPoint as any).arm;  
        y = (wbPoint as AircraftWbLatPoint).latArm
      }
    }
     
    const boxWidth = width * 0.85;
    const boxHeight = height * 0.85;

    const { minX, maxX, minY, maxY } = boxLimits;

    // calculate the translated x and y values
    const translatedX = ((x - minX) / (maxX - minX)) * boxWidth;
    const translatedY = ((maxY - y) / (maxY - minY)) * boxHeight;

    return [translatedX + width * 0.07, translatedY + height * 0.07];
  };

  const isLatArmInWbPointUp = isLatArm && !useMassOnLat && (selectedOrg?.isLatArmInWbPointUp ?? false);

  return (
    <Stage width={height} height={width} className="d-flex justify-content-center" style={{pointerEvents:"none"}}>
      <BgLayer
        height={height}
        width={width}
        isLatArm={isLatArm}
        isLatArmInWbPointUp={isLatArmInWbPointUp}
        useMassOnLat={useMassOnLat}
        zeroPosForLat={translatePointToBox({ arm: 0, latArm: 0, mass: 0 }, boxLimits)}
      />

      <LimitsLayer
        height={height}
        width={width}
        translatePointToBox={translatePointToBox}
        boxLimits={boxLimits}
        aircraftWbPoints={aircraftWbPoints}
        isLatArmInWbPointUp={isLatArmInWbPointUp}
      />

      {calculatedWbPoints.takeoff && (
        <PointDotLayer
          height={height}
          width={width}
          translatePointToBox={translatePointToBox}
          boxLimits={boxLimits}
          color="green"
          radius={7}
          data={calculatedWbPoints.takeoff}
          isLatArmInWbPointUp={isLatArmInWbPointUp}
        />
      )}

      {calculatedWbPoints.landing && (
        <PointDotLayer
          height={height}
          width={width}
          translatePointToBox={translatePointToBox}
          boxLimits={boxLimits}
          color="magenta"
          radius={5}
          data={calculatedWbPoints.landing}
          isLatArmInWbPointUp={isLatArmInWbPointUp}
        />
      )}

      {calculatedWbPoints.emptyFuel && (
        <PointDotLayer
          height={height}
          width={width}
          translatePointToBox={translatePointToBox}
          boxLimits={boxLimits}
          color="blue"
          radius={4}
          data={calculatedWbPoints.emptyFuel}
          isLatArmInWbPointUp={isLatArmInWbPointUp}
        />
      )}
    </Stage>
  );
};

export default AircraftWBGraph;

interface PointDotLayerProps {
    height: number;
    width: number;
    radius: number;
    translatePointToBox: (wbPoint: AircraftWBPoint, boxLimits: BoxLimits) => [number, number];
    boxLimits: BoxLimits;
    color: string;
    data: AircraftWBPoint;
    isLatArmInWbPointUp: boolean;
}

function PointDotLayer({ height, width, translatePointToBox, boxLimits, color, data, radius, isLatArmInWbPointUp }: PointDotLayerProps) {
  const [x, y] = translatePointToBox(data, boxLimits);

  return (
    <Layer rotationDeg={isLatArmInWbPointUp ? 90 : 0} offsetY={isLatArmInWbPointUp ? height : 0}>
      <Circle x={x} y={y} radius={radius} fill={color} />
    </Layer>
  );
}


interface LimitsLayerProps {
    height: number;
    width: number;
    translatePointToBox: (wbPoint: AircraftWBPoint, boxLimits: BoxLimits) => [number, number];
    boxLimits: BoxLimits;
    aircraftWbPoints: AircraftWBPoint[];
    isLatArmInWbPointUp: boolean;
}

function LimitsLayer({ height, width, translatePointToBox, boxLimits, aircraftWbPoints, isLatArmInWbPointUp }: LimitsLayerProps) {
  const points = aircraftWbPoints.map((p) => translatePointToBox(p, boxLimits)).flat();

  return (
    <Layer rotationDeg={isLatArmInWbPointUp ? 90 : 0} offsetY={isLatArmInWbPointUp ? height : 0}>
      <Line points={points} closed={true} stroke="black" strokeWidth={1} />
    </Layer>
  );
}

interface BgLayerProps {
    height: number;
    width: number;
    isLatArm: boolean;
    isLatArmInWbPointUp: boolean;
    useMassOnLat: boolean;
    zeroPosForLat?: [number, number];
}

function BgLayer({height, width, isLatArm, isLatArmInWbPointUp, useMassOnLat, zeroPosForLat = [50, 50]}:BgLayerProps) {

  const [bgImage, bgImageStatus] = useImage("/assets/brief/wb_graph_rect.png");

  return (
    <Layer>
      {bgImageStatus === "loaded" && (
        <Rect x={0} y={0} width={width} height={height} fillPatternImage={bgImage} fillPatternRepeat={"repeat"} />
      )}

      {!isLatArm /*lon*/ ? (
        <>
          <Text text={"mass"} y={12} x={5} />
          <Arrow points={[15, height - 15, 15, 30]} fill="black" stroke="black" />

          <Text text={"arm"} y={height - 22} x={width - 34} />
          <Arrow points={[15, height - 15, width - 40, height - 15]} fill="black" stroke="black" />
        </>
      ) : (
        /*lat*/
        <>
          {useMassOnLat ? (
            <>
              <Text text={"mass"} y={12} x={5} />
              <Arrow points={[15, height - 15, 15, 30]} fill="black" stroke="black" />

              <Text text={"lat\narm"} y={height - 28} x={width - 34} />
              <Arrow points={[15, height - 15, width - 40, height - 15]} fill="black" stroke="black" />

              <Line points={[zeroPosForLat[0], 0 , zeroPosForLat[0], height]} fill="gray" stroke={"gray"} dash={[5, 5]} />
              <Arrow points={[zeroPosForLat[0], 20 , zeroPosForLat[0], 0]} fill="gray" stroke="gray" />
              <Text text={"FWD"} y={10} x={zeroPosForLat[0] + 10} fill="gray" fontStyle="bold"/>
            </>
          ) : (
            <>
              {!isLatArmInWbPointUp ? (
                <>
                  <Text text={"lat arm"} y={12} x={5} />
                  <Arrow points={[15, height - 15, 15, 30]} fill="black" stroke="black" />

                  <Text text={"arm"} y={height - 22} x={width - 34} />
                  <Arrow points={[15, height - 15, width - 40, height - 15]} fill="black" stroke="black" />

                  <Line points={[0, zeroPosForLat[1], width, zeroPosForLat[1]]} fill="gray" stroke={"gray"} dash={[5, 5]} />
                  <Arrow points={[width, zeroPosForLat[1], width - 38, zeroPosForLat[1]]} fill="gray" stroke="gray" />
                  <Text text={"FWD"} y={zeroPosForLat[1] - 20} x={width - 30} fill="gray" fontStyle="bold" />
                </>
              ) : (
                <>
                  <Text text={"arm"} y={12} x={5} />
                  <Arrow points={[15, 30, 15, 40]} fill="black" stroke="black" />
                  <Line points={[15, 30, 15, height - 15]} fill="black" stroke="black" />

                  <Text text={"lat arm"} y={height - 22} x={width - 40} />
                  <Arrow points={[15, height - 15, width - 44, height - 15]} fill="black" stroke="black" />

                  <Line
                    points={[0, zeroPosForLat[1], width, zeroPosForLat[1]]}
                    rotationDeg={90}
                    offsetY={height}
                    fill="gray"
                    stroke={"gray"}
                    dash={[5, 5]}
                  />
                  <Arrow
                    points={[48, zeroPosForLat[1], 10, zeroPosForLat[1]]}
                    rotationDeg={90}
                    offsetY={height}
                    fill="gray"
                    stroke="gray"
                  />
                  <Text text={"FWD"} y={20} x={width / 2 - 10} fill="gray" fontStyle="bold" />
                </>
              )}
            </>
          )}
        </>
      )}

      <Text text="Takeoff" y={height - 12} x={20} fill={"green"} />
      <Circle x={15} y={height - 6} radius={3} fill={"green"} />

      <Text text="Landing" y={height - 12} x={105} fill={"magenta"} />
      <Circle x={100} y={height - 6} radius={3} fill={"magenta"} />

      <Text text="Empty Fuel" y={height - 12} x={180} fill={"blue"} />
      <Circle x={175} y={height - 6} radius={3} fill={"blue"} />
    </Layer>
  );
}
