import { Control, FieldValues, useController } from "react-hook-form";
import { EntityDetailsDisplayInfoFieldSignature } from "../EntityDetailsDisplayInfo";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { faArrowUpRightFromSquare, faSignature, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AviTracerApi } from "../../../avitracerApi";
import { useState } from "react";
import InputBox from "../../InputBox/InputBox";
import { useCopyToClipboard } from "usehooks-ts";

interface EntitySignatureComponentProps<Entity extends FieldValues> {
    displayInfoInput: EntityDetailsDisplayInfoFieldSignature<Entity>;
    control: Control<Entity, any>;
    entityId?: string;
}

function EntitySignatureComponent<Entity extends FieldValues>({ displayInfoInput, control, entityId }: EntitySignatureComponentProps<Entity>) {
  const {
    field: { value, onChange },
    formState: { isSubmitting, isValid }
  } = useController({
    name: displayInfoInput.key,
    control,
  });

  const copy = useCopyToClipboard()[1];

  const [reqUrl, setReqUrl] = useState<string>("");

  const requestGenerateUrlPressed = (withRedirect: boolean) => {
    const requestInfo = async () => {
      var url
      if (displayInfoInput.entityType === "crew") {
        url = (await AviTracerApi.crewRequestInfoGenerateUrl(entityId!!)).url;
      } else if (displayInfoInput.entityType === "passenger") {
        url = (await AviTracerApi.passengerRequestInfoGenerateUrl(entityId!!)).url;
      } else {
        throw new Error("Unknown entity type");
      }
      if (withRedirect) {
        window.location.href = url;
      }else{
        setReqUrl(url);
      }
      
    };
    requestInfo();
  };

  const disabled = (isValid && isSubmitting) || (displayInfoInput.disabled ?? false)

  const hasValue = value && value !== "CLEAR";

  return (
    <>
      {!entityId && (
        <Alert variant={"warning"}>
          To request a signature, you should first save the new {displayInfoInput.entityType}'s information.
        </Alert>
      )}
      {entityId && !hasValue && !reqUrl && (
        <Alert variant={"warning"}>
          <div>
            <div className="fw-bold">No signature registered</div>
            <small>
              {displayInfoInput.entityType === "crew" ? (
                <>
                  A signature is necessary for the General Declaration and Form731 documents. You can obtain the required signature by
                  requesting it through SMS/Email or sign directly.
                </>
              ) : (
                <>
                  A signature is necessary for the Form731 document. You can obtain the required signature by
                  requesting it through SMS/Email or sign directly.
                </>
              )}
            </small>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <Button variant="light" onClick={() => requestGenerateUrlPressed(false)} disabled={disabled}>
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} color="black" className="pe-2" />
              Request signature
            </Button>
            <Button variant="light" onClick={() => requestGenerateUrlPressed(true)} className="mx-2" disabled={disabled}>
              <FontAwesomeIcon icon={faSignature} color="black" className="pe-2" />
              Sign now
            </Button>
          </div>
        </Alert>
      )}
      {reqUrl && (
        <Row className="pt-3">
          <p className="fw-bold">
            Request your {displayInfoInput.entityType}'s info and signature by sharing the following link (via SMS, Email, etc.)
          </p>
          <Col>
            <InputBox value={reqUrl} onChange={() => {}} type="text" name="Request info link" isDisabled={true} />
          </Col>
          <Col xs={"auto"}>
            <Button disabled={disabled} variant="light" className="h-100" onClick={() => copy(reqUrl)}>
              <FontAwesomeIcon icon={faCopy} size="xl" />{" "}
              <div>
                <small>Copy</small>
              </div>
            </Button>
          </Col>
        </Row>
      )}
      {entityId && hasValue && (
        <Alert variant={"success"}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="fw-bold">The signature is registered</div>
            <div className="d-flex justify-content-end ">
              <Button variant="light" disabled={disabled} onClick={() => onChange("CLEAR")}>
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} color="black" className="pe-2" />
                Clear signature
              </Button>
            </div>
          </div>
        </Alert>
      )}
    </>
  );
}

export default EntitySignatureComponent;