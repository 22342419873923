import * as React from "react";
import { Container, Navbar } from "react-bootstrap";

import { Link } from "react-router-dom";

function Header(){
    return (
      <Container>
        <Navbar variant="light" expand="lg" className="d-flex justify-content-between">
          <HeaderLogo />
        </Navbar>
      </Container>
    );
}

export default Header

export function HeaderLogo() {
  return (
    <Link to="/">
      <div className="d-none d-sm-block">
        <svg width="148px" height="64px">
          <image href="/assets/brief/brief_logo.svg" width="100%" />
        </svg>
      </div>
      <div className="d-sm-none">
        <svg width="92px" height="40px">
          <image href="/assets/brief/brief_logo.svg" width="100%" />
        </svg>
      </div>
    </Link>
  );
}