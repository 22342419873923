import { readRemoteFile } from "react-papaparse";
import { RoutePoint } from "../models/route";
import { getCupPointCoords, getAllCupPoints } from "./CupPointUtils";

type IcaoAirport = {
    icao: string,
    name: string,
    latitude: number,
    longitude: number
}

var allIcaoAirports: IcaoAirport[] = [] 

export const getIcaoAirport = async (icao: string) => {
    if (icao.length !== 4) {
        return undefined;
    }
    if (allIcaoAirports.length === 0) {
        await loadIcaoAirports();
    }
    const icaoAirport = allIcaoAirports.find((airport) => airport.icao === icao);
    if (icaoAirport) {
        return icaoAirport;
    }
    const cupPoints = await getAllCupPoints();
    const cupPoint = cupPoints.find((point) => point.code === icao);
    if (cupPoint) {
        const {lat, lon } = getCupPointCoords(cupPoint);
        return {
            icao: cupPoint.code,
            name: cupPoint.name,
            latitude: lat,
            longitude: lon,
        } as IcaoAirport;
    }
    return undefined;
}

export const getRoutePointForIcaoAirportCode = async (icao: string) => {
    const airport = await getIcaoAirport(icao);
    if (!airport) {
        return undefined;
    }
    return {
        name: airport.icao,
        description: "", //can use name
        lat: airport.latitude,
        lon: airport.longitude,
        type: "airport",
      } as RoutePoint;
}

async function loadIcaoAirports() {
    return new Promise<void>((resolve, reject) => {
        readRemoteFile("/assets/brief/map/data/icaoAirports.csv", {
            download: true,
            header: true,
            complete: (results) => {
                allIcaoAirports = (results.data as any[]).map((i) => {
                    return {
                        icao: i.icao,
                        name: i.airport,
                        latitude: parseFloat(i.latitude),
                        longitude: parseFloat(i.longitude),
                    } as IcaoAirport;
                });
                resolve();
            },
            error: (err) => {
                reject(err);
            }
        });
    });
}