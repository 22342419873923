import { EntityDetailsDisplayInfoFieldBoolean } from "./EntityDetailsDisplayInfo";
import { Control, FieldValues, useController } from "react-hook-form";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { useRef } from "react";

interface EntityBooleanToggleProps<Entity extends FieldValues> {
    displayInfoInput: EntityDetailsDisplayInfoFieldBoolean<Entity>;
    control: Control<Entity, any>
  }

function EntityBooleanToggle<Entity extends FieldValues>({displayInfoInput, control}: EntityBooleanToggleProps<Entity>){

    const {
      field: {value, onChange, onBlur, ref: fieldRef},
      fieldState: { invalid, error },
      formState: { isSubmitting, isValid },
    } = useController({
      name: displayInfoInput.key,
      control,
      rules: {
        validate: (value) => {
          if (displayInfoInput.required ?? false) {
            return value !== undefined || "Required";
          }
          return true;
        }
      },
    });

    const displayedValue = (value === undefined) ? "" : ((value === true) ? "Yes" : "No")

    const isDisabled = (isValid && isSubmitting) || (displayInfoInput.disabled ?? false)
    return (
      <>
        <div
          className={
            (invalid ? "invalid " : "") + "input-container rounded-lg "
          }
        >
          <Dropdown
            onSelect={(newValue) => {
              onChange(newValue === "1" ? true : false);
            }}
            onToggle={(isOpen) => {
              if (isOpen) {
                onBlur();
              }
            }}
          >
            <Dropdown.Toggle
              ref={fieldRef}
              as={"input"}
              readOnly
              disabled={isDisabled}
              className="bg-transparent w-100"
              value={displayedValue}
              style={{ cursor: (isDisabled) ? "auto" : "pointer" }}
            />
            <Dropdown.Menu>
              <Dropdown.Item eventKey={"1"} active={value === true}>
                {"Yes"}
              </Dropdown.Item>
              <Dropdown.Item eventKey={"0"} active={value === false}>
                {"No"}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <label className={displayedValue && "filled"}>
            {displayInfoInput.name}
          </label>
          <FontAwesomeIcon
            className="position-absolute "
            style={{ right: "16px", top: "50%", transform: "translateY(-50%)" }}
            icon={faCaretDown}
          />
        </div>
        {error && <small className="text-danger">{error.message}</small>}
      </>
    );
}

export default EntityBooleanToggle