import { useLoaderData, useNavigate } from "react-router-dom";
import { EntityDisplayInfo } from "../../../components/EntityDetails/EntityDetailsDisplayInfo"
import EntityDetailsPage from "../../../components/EntityDetails/EntityDetailsPage"
import { Aircraft } from "../../../models/aircraft"
import { LoaderFunction } from "@remix-run/router"
import { AviTracerApi } from "../../../avitracerApi";
import { useAppDispatch } from "../../../store/hooks";
import { updateAircraft } from "../../../store/aircraftSlice";


export const aircraftLoader: LoaderFunction = async (params) => {
  if (params.params["aircraftId"]) {
      return AviTracerApi.getAircraftWithId(params.params["aircraftId"]!);
  }
  return null;
};


function AircraftDetailsPage() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate();

  const loadedAircraft = useLoaderData() as Aircraft;

  var aircraft: Aircraft = loadedAircraft ?? {}

  const handleSaveAircraft = async (aircraft: Aircraft) => {
    return await dispatch(updateAircraft(aircraft)).unwrap();
  };

  const goBack = () => {
      navigate("/aircraft");
  }

  return (
    <EntityDetailsPage
      entity={aircraft}
      handleClose={goBack}
      displayInfo={EntityDisplayInfo.Aircraft()}
      dispatchSaveEntity={handleSaveAircraft}
    />
  );
}

export default AircraftDetailsPage;