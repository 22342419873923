import { EntityDetailsDisplayInfo } from "../components/EntityDetails/EntityDetailsDisplayInfo"
import { CanEditSharedValues } from "./identifiable"

export type Crew = {
    id?: string
    uid?: string //only when User
    organizationStatus: "not-invited" | "invited" | "pilot" | "admin"

    type: "pilot" | "student" //default pilot

    firstName: string
    lastName: string
    nickName: string    

    flightPlanPicDetails: string

    dob: Date,
    idPassportNumber: string
    idPassportCountryCode: string
    signatureBase64?: string

    operatorDefaultNumber?: string,

    weight: number,

    email?: string,
    secondaryEmail?: string
} & CanEditSharedValues

export function getCrewFullName(crew: Crew): string {
    return [crew.lastName, crew.firstName].filter(x => x).join(' ')
}

export function getCrewLastNameWithInitials(crew: Crew): string {
    return [crew.lastName, crew.firstName ? crew.firstName[0] + "." : ""].filter(x => x).join(' ')
}

export function getCrewDisplayInfo(): EntityDetailsDisplayInfo<Crew> {
    return {
        entityName: "Crew",
        sections: [
            {
                sectionName: "Personal Info",
                sectionId: "personalInfo",
                containsSharedValues: true,
                inputs: [
                    {
                        name: "Last Name",
                        key: "lastName",
                        type: "text",
                        required: true
                    },
                    {
                        name: "First Name",
                        key: "firstName",
                        type: "text",
                        required: true
                    },
                ]
            },
            {
                sectionName: "Organization Info",
                sectionId: "organizationInfo",
                inputs: [
                    {
                        name: "Nick Name in Organization",
                        key: "nickName",
                        type: "text",
                    },
                    {
                        name: "Type",
                        key: "type",
                        type: "dropdown",
                        options: [
                            {
                                value: "pilot",
                                label: "Pilot"
                            },
                            {
                                value: "student",
                                label: "Student"
                            }
                        ]
                    }
                ]
            },
            {
                sectionName: "Contact Details",
                sectionId: "contactDetails",
                shouldDisplay: (crew: Crew) => crew.uid === undefined,
                containsSharedValues: true,
                inputs: [
                    {
                        name: "Email",
                        key: "email",
                        type: "text",
                        required: false,
                        disabled: false,
                        validateRules: {
                            pattern: {
                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: "Invalid email address"
                            }
                        }
                    },
                    {
                        name: "Secondary Email (CCed, in case the primary email is not always accessible)",
                        key: "secondaryEmail",
                        type: "text",
                        required: false,
                        disabled: false,
                        validateRules: {
                            pattern: {
                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: "Invalid email address"
                            }
                        }
                    }
                ]
            },
            {
                sectionName: "Contact Details",
                sectionId: "contactDetails",
                shouldDisplay: (crew: Crew) => crew.uid !== undefined,
                containsSharedValues: true,
                inputs: [
                    {
                        name: "Email",
                        key: "email",
                        type: "text",
                        required: false,
                        disabled: true
                    },
                    {
                        name: "Secondary Email (CCed, in case the primary email is not always accessible)",
                        key: "secondaryEmail",
                        type: "text",
                        required: false,
                        disabled: false
                    }
                ]
            },
            {
                sectionName: "Flight Plan Details",
                sectionId: "flightPlanPicDetails",
                inputs: [
                    {
                        name: "PIC Display Details",
                        key: "flightPlanPicDetails",
                        type: "text",
                        uppercase: true,
                        required: true,
                        validateRules:{
                            pattern:{
                                value: /^([0-9]|[ '()+,=.]|[A-Z]){1,50}$/,
                                message: "PIC Display Details can only contain Uppercase letters, numbers, spaces, and the following special characters: '()+,=."
                            }
                        }
                    },
                    {
                        name: "Operator Default Number",
                        key: "operatorDefaultNumber",
                        type: "text",
                        uppercase: true,
                        validateRules:{
                            pattern: {
                                value: /^[A-Z\d]{1,4}$/,
                                message: "Operator default number must be 1-4 letters or digits"
                            }
                        }
                    }
                ]
            },
            {
                sectionName: "General Declaration",
                sectionId: "genDec",
                containsSharedValues: true,
                inputs: [
                    {
                        name: "Date of Birth",
                        key: "dob",
                        type: "date"
                    },
                    {
                        name: "ID/Passport Number",
                        key: "idPassportNumber",
                        type: "text",
                    },
                    {
                        name: "ID/Passport Country Code",
                        key: "idPassportCountryCode",
                        type: "text",
                    }
                ]
            },
            {
                sectionName: "Weight & Balance",
                sectionId: "wb",
                containsSharedValues: true,
                inputs: [
                    {
                        name: "Weight (kg)",
                        key: "weight",
                        type: "number",
                        required: true
                    }
                ]
            },
            {
                sectionName: "Signature",
                sectionId: "signature",
                inputs: [
                    {
                        name: "Signature",
                        key: "signatureBase64",
                        type: "signature",
                        entityType: "crew",
                        required: true
                    }
                ]
            }
        ]
    }
}