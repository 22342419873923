import { Control, FieldValues, useController, UseFormSetValue } from "react-hook-form";
import { Aircraft } from "../../../models/aircraft";
import Flight from "../../../models/flight";
import ListAicraftPage from "../../../pages/organization/aircraft/ListAircraftPage";
import ReadOnlyInputBox from "../../InputBox/ReadonlyInputBox";
import { EntityDetailsDisplayInfoFieldAircraft, getValitationRules } from "../EntityDetailsDisplayInfo";


interface EntityAircraftPickerProps<Entity extends FieldValues> {
    displayInfoInput: EntityDetailsDisplayInfoFieldAircraft<Entity>;
    setModalContent: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
    control: Control<Entity, any>
    setValue: UseFormSetValue<Flight>
}

function EntityAircraftPicker<Entity extends FieldValues>({control, displayInfoInput, setModalContent, setValue}: EntityAircraftPickerProps<Entity>) {
  const {
    field: { value, onChange, ref},
    fieldState,
    formState: { isSubmitting, isValid },
  } = useController({
    name: displayInfoInput.key,
    control,
    rules: getValitationRules(displayInfoInput),
  });

    const aircraftName: string = (value as Aircraft)?.name

    const updateAircraft = (aircraft: Aircraft) => {
      onChange(aircraft);

      
      if (displayInfoInput.parentEntity === "Flight") {
        setValue('cruiseSpeed', aircraft.cruiseSpeed);
        setValue('equipmentCodes', aircraft.equipmentCodes);
        setValue('surveillanceEquipmentCodes', aircraft.surveillanceEquipmentCodes);
        setValue('field18Remarks', aircraft.field18Remarks);
        setValue('sarEquipment', aircraft.sarEquipment);
        setValue('operatorName', aircraft.operatorFullName);
        setValue('wb', { status: "not-calculated", loads: [] })
        setValue("fuelOnBoard", [])
      }
      
      setModalContent(null);
    }

    return (
      <>
        <ReadOnlyInputBox
          inputRef={ref}
          value={aircraftName ?? ""}
          isDisabled={(isValid && isSubmitting) || (displayInfoInput.disabled ?? false)}
          name={displayInfoInput.name}
          fieldState={fieldState}
          onClick={() => {
            setModalContent(
              <ListAicraftPage
                onAircraftSelected={updateAircraft}
              />
            );
          }}
        />
      </>
    );
}

export default EntityAircraftPicker;