import { useEffect, useMemo, useState } from "react";
import Header from "../../../Header";
import { Container, Row, Col, Nav, Form, Button, Alert } from "react-bootstrap";
import { FieldErrors, FieldValues, UseFormHandleSubmit, UseFormRegister, useForm } from "react-hook-form";
import { AviTracerApi } from "../../../avitracerApi";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getOrganizations, selectOrganization } from "../../../store/organizationsSlice";
import { auth } from "../../../firebase";

function CreateOrganizationPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [selectedTab, setSelectedTab] = useState<"organization" | "private">("private");

  useEffect(() => {
      dispatch(getOrganizations())
  }, [dispatch]);

  const {allOrganizations} = useAppSelector((state) => state.organizations)

  const {register, handleSubmit, formState, reset, setError, clearErrors} = useForm();

  const { isSubmitting, errors, isSubmitSuccessful } = formState
  const onOrgSubmitPressed = async (data: any) => {
    try {
      clearErrors();
      await AviTracerApi.requestOrganizationCreate(data);
    } catch (e: any) {
      setError("submit", {message: e})
      console.error(e);
    }
  };

  const onPrivateSubmitPressed = async (data: any) => {
    try {
      clearErrors();
      await AviTracerApi.createIndividualOrganization(data);
      const newOrgs = await dispatch(getOrganizations()).unwrap();
      const individualOrg = newOrgs.find((org) => org.id === auth.currentUser!.uid)
      if (individualOrg) {
        dispatch(selectOrganization(individualOrg))
      }
      
      navigate("/aircraft/add", {replace: true})
    } catch (e: any) {
      setError("submit", {message: e})
      console.error(e);
    }
  };

  const hasPrivateOrganization = useMemo(() => {
    return allOrganizations.some((org) => org.id === auth.currentUser?.uid)
  }, [allOrganizations])
    
  useEffect(() => {
    if (hasPrivateOrganization) {
      setSelectedTab("organization");
    }
  }, [hasPrivateOrganization]);

  useEffect(() => {
    reset();
  }, [selectedTab, reset]);

  return (
    <>
      <Header />
      <Container className="py-5">
        {isSubmitSuccessful ? (
          <>
            <Row className="justift-content-center text-center">
              <Col>
                <h2>Your request has been submitted.</h2>
                <h4>A member of our team will contact you shortly.</h4>
              </Col>
            </Row>
          </>
        ) : (
          <>
          {allOrganizations.length === 0 && (
            <Row className="justify-content-center text-center">
              <Col>
                <h2>It seems like you're not currently associated with any organization on the BRIEF platform.</h2>
                <div>
                 To get started, please reach out to your organization and ask them to send you an invitation, or create a new one.
                 </div>
              </Col>
            </Row>
            )}
            <Row className="justify-content-center pt-5">
              <Col xs={10} md={8} className="border rounded shadow-sm p-4">
                <Row>
                  <Col>
                  <div className="fw-bold">
If your organization doesn't exist in BRIEF, you can either request the establishment of a new one or, if you're a private pilot, submit your details to create an Individual team.
                </div>
                    <Nav variant="tabs" onSelect={(v) => setSelectedTab(v as "organization" | "private")} className="pt-2">
                    <Nav.Link active={selectedTab === "private"} eventKey={"private"}>
                        Individual
                      </Nav.Link>
                      <Nav.Link active={selectedTab === "organization"} eventKey={"organization"}>
                        Organization
                      </Nav.Link>
                    </Nav>
                  </Col>
                </Row>
                {errors && Object.keys(errors).length > 0 && (
                  <Row className="pt-4">
                    <Col>
                        <div className="alert alert-danger" role="alert">
                          {Object.values(errors)[0]?.message as string}
                        </div>
                    </Col>
                  </Row>
                )}
                <Row className="pt-2" >
                  <Col>
                    {selectedTab === "organization" && (
                      <OrganizationForm
                        onSubmitPressed={onOrgSubmitPressed}
                        register={register}
                        handleSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                        errors={errors}
                      />
                    )}
                    {selectedTab === "private" && (
                      <PrivateForm
                        onSubmitPressed={onPrivateSubmitPressed}
                        register={register}
                        handleSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                        hasPrivateOrganization={hasPrivateOrganization}
                        errors={errors}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
}

export default CreateOrganizationPage;

interface FormProps {
  onSubmitPressed: (data: any) => void;
  register: UseFormRegister<any>;
  handleSubmit: UseFormHandleSubmit<any, any>;
  isSubmitting: boolean;
  errors: FieldErrors<FieldValues>
}


function OrganizationForm({ onSubmitPressed, register, handleSubmit, isSubmitting, errors }: FormProps) {
  return (
    <Form onSubmit={handleSubmit(onSubmitPressed)}>
      <Form.FloatingLabel label={"Organization Name"} controlId="orgName" className="mb-3">
        <Form.Control
          disabled={isSubmitting}
          type="text"
          placeholder="Organization name"
          isInvalid={errors.orgName !== undefined}
          {...register("orgName", { required: "Organization name is required" })}
        />
      </Form.FloatingLabel>
      <Form.FloatingLabel label={"Website URL"} controlId="website" className="mb-3">
        <Form.Control disabled={isSubmitting} type="text" placeholder="Website URL" {...register("website")} />
      </Form.FloatingLabel>
      <Form.FloatingLabel label={"Main Country operating in"} controlId="country" className="mb-3">
        <Form.Control disabled={isSubmitting} type="text" placeholder="" {...register("country")} />
      </Form.FloatingLabel>
      <div className="mb-3">
        <Form.Label>Type of Operations: </Form.Label>
        <Form.Check>
          <Form.Check.Input disabled={isSubmitting} type="checkbox" value={"AOC"} {...register("operations")} />
          <Form.Check.Label>AOC</Form.Check.Label>
        </Form.Check>
        <Form.Check>
          <Form.Check.Input disabled={isSubmitting} type="checkbox" value={"Training"} {...register("operations")} />
          <Form.Check.Label>Training</Form.Check.Label>
        </Form.Check>
        <Form.Check>
          <Form.Check.Input disabled={isSubmitting} type="checkbox" value={"Airclub"} {...register("operations")} />
          <Form.Check.Label>Airclub</Form.Check.Label>
        </Form.Check>
        <Form.Check>
          <Form.Check.Input disabled={isSubmitting} type="checkbox" value={"Other"} {...register("operations")} />
          <Form.Check.Label>Other</Form.Check.Label>
        </Form.Check>
      </div>
      <Form.FloatingLabel label={"Number of aircraft"} controlId="numOfAircraft" className="mb-3">
        <Form.Control
          disabled={isSubmitting}
          type="number"
          placeholder="Number of aircraft"
          defaultValue={1}
          {...register("numOfAircraft")}
        />
      </Form.FloatingLabel>
      <Form.Control type="hidden" defaultValue="organization" {...register("type")} />
      <Button variant="dark" type="submit" disabled={isSubmitting}>
        Submit
      </Button>
    </Form>
  );
}

function PrivateForm({ onSubmitPressed, register, handleSubmit, isSubmitting, hasPrivateOrganization, errors }: FormProps & { hasPrivateOrganization: boolean }) {
    
  return (
    <>
      {hasPrivateOrganization ? (
        <Alert variant="danger">You have already created your individual Team.</Alert>
      ) : (
        <Form onSubmit={handleSubmit(onSubmitPressed)}>
          <Form.FloatingLabel label={"First Name"} controlId="firstName" className="mb-3">
            <Form.Control
              disabled={isSubmitting}
              placeholder=""
              type="text"
              isInvalid={errors.firstName !== undefined}
              {...register("firstName", { required: "First name is required" })}
            />
          </Form.FloatingLabel>
          <Form.FloatingLabel label={"Last Name"} controlId="lastName" className="mb-3">
            <Form.Control
              disabled={isSubmitting}
              placeholder=""
              type="text"
              isInvalid={errors.lastName !== undefined}
              {...register("lastName", { required: "Last name is required" })}
            />
          </Form.FloatingLabel>
          <Form.FloatingLabel
            label={"PIC Details in Flight Plan (Name and Mobile Phone)"}
            controlId="flightPlanPicDetails"
            className="mb-3"
          >
            <Form.Control
              disabled={isSubmitting}
              placeholder=""
              type="text"
              isInvalid={errors.flightPlanPicDetails !== undefined}
              {...register("flightPlanPicDetails", {
                 required: "PIC Details are required" ,
                 pattern: {
                    value: /^([0-9]|[ '()+,=.]|[A-Z]){1,50}$/,
                    message: "PIC Details can only contain Uppercase letters, numbers, spaces, and the following special characters: '()+,=."
                 }
                })}
            />
          </Form.FloatingLabel>
          <Form.FloatingLabel label={"Weight"} controlId="weight" className="mb-3">
            <Form.Control
              disabled={isSubmitting}
              placeholder=""
              type="number"
              isInvalid={errors.weight !== undefined}
              {...register("weight", {
                required: "Weight is required for W&B",
                min: { value: 0, message: "Weight should not be negative" },
              })}
            />
          </Form.FloatingLabel>
          <Button variant="dark" type="submit" disabled={isSubmitting}>
            Submit
          </Button>
        </Form>
      )}
    </>
  );
}