import { FuelConverter } from "../utils/FuelUtils";
import { Aircraft, AircraftWbLatPoint, AircraftWbLonPoint } from "./aircraft";
import Flight, { FlightFuelOnBoard, FlightWbLoad } from "./flight";

function calculateTakeoffWb(aircraft: Aircraft, wbLoads: FlightWbLoad[], fuelOnBoard: FlightFuelOnBoard[]): AircraftWbLonPoint & AircraftWbLatPoint {
  
  const emptyFuelWb = calculateEmptyFuelWb(aircraft, wbLoads)
  
  var totalMass = emptyFuelWb.mass
  totalMass += fuelOnBoard?.reduce((acc, f) => acc + f.mass, 0)
  
  var totalMoment = emptyFuelWb.arm * emptyFuelWb.mass // empty fuel moment
  totalMoment += fuelOnBoard?.reduce((acc, f) => acc + f.mass * f.station.arm, 0) // fuel moment
  const arm = totalMoment / totalMass

  var totalLatMoment = emptyFuelWb.latArm * emptyFuelWb.mass
  
  totalLatMoment += fuelOnBoard?.reduce((acc, f) => acc + f.mass * (f.station.latArm ?? 0), 0)
  const latArm = totalLatMoment / totalMass

  return { arm: Math.round(arm), mass: totalMass, latArm: Math.round(latArm) };
}

function calculateLandingWb(aircraft: Aircraft, wbLoads: FlightWbLoad[], fuelOnBoard: FlightFuelOnBoard[], tripNeededFuelMass: number): AircraftWbLonPoint & AircraftWbLatPoint & {landingFuelOnBoard: FlightFuelOnBoard[]} {
  
  const emptyFuelWb = calculateEmptyFuelWb(aircraft, wbLoads)
  
  var totalMass = emptyFuelWb.mass
  
  const burntFuelMass = tripNeededFuelMass
  const afterTripFuelOnBoard = burnFuel(fuelOnBoard!, burntFuelMass)

  totalMass += afterTripFuelOnBoard.reduce((acc, f) => acc + f.mass, 0)
  
  var totalMoment = emptyFuelWb.arm * emptyFuelWb.mass // empty fuel moment
  totalMoment += afterTripFuelOnBoard.reduce((acc, f) => acc + f.mass * f.station.arm, 0) // fuel moment 
  const arm = totalMoment / totalMass

  var totalLatMoment = emptyFuelWb.latArm * emptyFuelWb.mass
  totalLatMoment += afterTripFuelOnBoard.reduce((acc, f) => acc + f.mass * (f.station.latArm ?? 0), 0)
  const latArm = totalLatMoment / totalMass

  return { arm: Math.round(arm), mass: totalMass, latArm: Math.round(latArm), landingFuelOnBoard: afterTripFuelOnBoard};
}

function calculateEmptyFuelWb(aircraft: Aircraft, wbLoads: FlightWbLoad[]): AircraftWbLonPoint & AircraftWbLatPoint {
  
  var totalMass = aircraft.wb!.emptyMass.mass
  totalMass += wbLoads.reduce((acc, l) => acc + l.mass, 0)
  
  var totalMoment = aircraft.wb!.emptyMass.arm * aircraft.wb!.emptyMass.mass
  totalMoment += wbLoads.reduce((acc, l) => acc + l.mass * l.station.arm, 0)
  const arm = totalMoment / totalMass

  var totalLatMoment = (aircraft.wb!.emptyMass.latArm ?? 0) * aircraft.wb!.emptyMass.mass
  totalLatMoment += wbLoads.reduce((acc, l) => acc + l.mass * (l.station.latArm ?? 0), 0)
  const latArm = totalLatMoment / totalMass

  return { arm: Math.round(arm), mass: totalMass, latArm: Math.round(latArm) };
}

function burnFuel(fuelOnBoard: FlightFuelOnBoard[], fuelMassToBurn: number): FlightFuelOnBoard[] {

  var totalFuelMass = fuelOnBoard.reduce((total, tank) => total + tank.mass, 0);
  const newFuelOnBoard: FlightFuelOnBoard[] = JSON.parse(JSON.stringify(fuelOnBoard))

  while (fuelMassToBurn > 0 && totalFuelMass > 0) {
    const remainingTanks = newFuelOnBoard.filter(tank => tank.mass > 0);
    const burnRatePerTank = fuelMassToBurn / remainingTanks.length;

    for (let i = 0; i < remainingTanks.length; i++) {
      const tank = remainingTanks[i];
      const fuelToBurnFromTank = Math.min(tank.mass, burnRatePerTank);
      const newMass = tank.mass - fuelToBurnFromTank;

      newFuelOnBoard.find(t => t.station === tank.station)!.mass = Math.round(newMass);

      fuelMassToBurn -= fuelToBurnFromTank;
      totalFuelMass -= fuelToBurnFromTank;

      if (fuelMassToBurn <= 0 || totalFuelMass <= 0) {
        break;
      }
    }
  }

  return newFuelOnBoard;
}

type Point = {
  x: number;
  y: number;
};
type Edge = [Point, Point];
type Shape = Edge[];

function checkIsOutOfLimits(pointsArray: Point[], x: number, y: number): {x: boolean, y: boolean} {
  const shape: Shape = pointsArray.map((point, index) => [point, pointsArray[(index + 1) % pointsArray.length]]);
  const inOfX = shape.some(([p1, p2]) => {
    if ((p1.x < x && p2.x < x) || (p1.x > x && p2.x > x)) {
      return false;
    }
    const slope = (p2.y - p1.y) / (p2.x - p1.x);
    const yIntercept = p1.y - slope * p1.x;
    return y < slope * x + yIntercept;
  });
  if (!inOfX) {
    return { x: !inOfX, y: false };
  }
  const inOfY = shape.some(([p1, p2]) => {
    if ((p1.y < y && p2.y < y) || (p1.y > y && p2.y > y)) {
      return false;
    }
    const slope = (p2.y - p1.y) / (p2.x - p1.x);
    const xIntercept = p1.x - (p1.y - y) / slope;
    return x < xIntercept;
  });
  return { x: !inOfX, y: !inOfY };
}

export function generateSeatWbLoads(flight: Flight): FlightWbLoad[]{
  const acSeatStations = flight.aircraft.wb!.stations.filter(s => s.type === 'seat')
  const wbLoads: FlightWbLoad[] = []
  
  if (flight.studentPilot) {
    wbLoads.push({
      station: acSeatStations.find(s => s.id === 'pilot')!,
      mass: flight.studentPilot.weight,
      name: flight.studentPilot.lastName
    })
    wbLoads.push({
      station: acSeatStations.find(s => s.id === 'copilot')!,
      mass: flight.pic.weight,
      name: flight.pic.lastName
    })
  } else {
    wbLoads.push({
      station: acSeatStations.find(s => s.id === 'pilot')!,
      mass: flight.pic.weight,
      name: flight.pic.lastName
    })

    if (flight.sic) {
      wbLoads.push({
        station: acSeatStations.find(s => s.id === 'copilot')!,
        mass: flight.sic.weight,
        name: flight.sic.lastName
      })
    }
  }

  if (flight.deadheadCrew){
    var emptyStations = acSeatStations.filter(as => wbLoads.findIndex(wbLoad => wbLoad.station.id === as.id) === -1)
    if (emptyStations.length > 0){
      wbLoads.push({
        station: emptyStations[0],
        mass: flight.deadheadCrew.weight,
        name: flight.deadheadCrew.lastName
      })
    }
  }
  
  flight.passengers.forEach(p => {
    var emptyStations = acSeatStations.filter(as => wbLoads.findIndex(wbLoad => wbLoad.station.id === as.id) === -1)
    
    //move copilot seat to last
    const copilotIndex = emptyStations.findIndex(s => s.id === "copilot");
    if (copilotIndex !== -1) {
      const [copilotObj] = emptyStations.splice(copilotIndex, 1);
      emptyStations.push(copilotObj);
    }
    
    if (emptyStations.length === 0){
      throw new Error('No more seats available')
    }
    
    wbLoads.push({
      station: emptyStations[0],
      mass: p.weight,
      name: p.lastName
    })
  })
  
  return wbLoads
}

export function getCalculatedFlightWbPoints(aircraft: Aircraft, wbLoads: FlightWbLoad[], fuelOnBoard: FlightFuelOnBoard[], tripNeededFuelMass: number): CalculatedFlightWbPoints {
  return {
    takeoff: calculateTakeoffWb(aircraft, wbLoads, fuelOnBoard),
    landing: calculateLandingWb(aircraft, wbLoads, fuelOnBoard, tripNeededFuelMass),
    emptyFuel: calculateEmptyFuelWb(aircraft, wbLoads)
  }
}

export type CalculatedFlightWbPoints = {
  takeoff: AircraftWbLonPoint & AircraftWbLatPoint;
  landing: AircraftWbLonPoint & AircraftWbLatPoint & {landingFuelOnBoard: FlightFuelOnBoard[]};
  emptyFuel: AircraftWbLonPoint & AircraftWbLatPoint;
}

export function calculateOutOfLimitsTexts(calculatedFlightWbPoints: CalculatedFlightWbPoints, aircraft: Aircraft): string[] {
  
  const aircraftWbPoints = aircraft.wb!.envelopePoints

  const { x: armTakeoffOut, y: massTakeoffOut } = checkIsOutOfLimits(aircraftWbPoints.map(p => ({ x: p.arm, y: p.mass })), calculatedFlightWbPoints.takeoff.arm, calculatedFlightWbPoints.takeoff.mass);

  const { x: armLandingOut, y: massLandingOut } = checkIsOutOfLimits(aircraftWbPoints.map(p => ({ x: p.arm, y: p.mass })), calculatedFlightWbPoints.landing.arm, calculatedFlightWbPoints.landing.mass);


  var texts = []
  if (massTakeoffOut) texts.push('Takeoff Weight is out of limits')
  if (armTakeoffOut) texts.push('Takeoff CG is out of limits')
  if (massLandingOut) texts.push('Landing Weight is out of limits')
  if (armLandingOut) texts.push('Landing CG is out of limits')

  if (aircraft.wb!.latEnvelopePoints) {
    const aircraftLatWbPoints = aircraft.wb!.latEnvelopePoints
    const { x: latArmLandingOut } = checkIsOutOfLimits(
      aircraftLatWbPoints.map(p => ({ x: ("arm" in p) ? p.arm : p.mass, y: p.latArm })),
      ("arm" in aircraftLatWbPoints[0]) ? calculatedFlightWbPoints.landing.arm : calculatedFlightWbPoints.landing.mass,
      calculatedFlightWbPoints.landing.latArm);
    const { x: latArmTakeoffOut } = checkIsOutOfLimits(
      aircraftLatWbPoints.map(p => ({ x: ("arm" in p) ? p.arm : p.mass, y: p.latArm })),
      ("arm" in aircraftLatWbPoints[0]) ? calculatedFlightWbPoints.takeoff.arm : calculatedFlightWbPoints.takeoff.mass,
      calculatedFlightWbPoints.takeoff.latArm);

    if (latArmTakeoffOut) texts.push('Takeoff Lat CG is out of limits')
    if (latArmLandingOut) texts.push('Landing Lat CG is out of limits')
  }

  const landingFuel = calculatedFlightWbPoints.landing.landingFuelOnBoard.reduce((acc, f) => acc + f.mass, 0)
  const minFuel = FuelConverter.toMassUnit(aircraft, aircraft.fuelPerHour / 3) // need 20 mins 
  if (landingFuel < minFuel) texts.push('Landing fuel is too low')
  
  return texts
}

export function calculateWarningOutOfLimitsTexts(calculatedFlightWbPoints: CalculatedFlightWbPoints, aircraft: Aircraft): string[] {
  
  const aircraftWbPoints = aircraft.wb!.envelopePoints

  const { x: armEmptyFuelOut, y: massEmptyFuelOut } = checkIsOutOfLimits(aircraftWbPoints.map(p => ({ x: p.arm, y: p.mass })), calculatedFlightWbPoints.emptyFuel.arm, calculatedFlightWbPoints.emptyFuel.mass);

  var texts = []
  if (massEmptyFuelOut) texts.push('Empty Fuel Weight is out of limits')
  if (armEmptyFuelOut) texts.push('Empty Fuel CG is out of limits')

  if (aircraft.wb!.latEnvelopePoints) {
    const aircraftLatWbPoints = aircraft.wb!.latEnvelopePoints

    const { x: latArmEmptyFuelOut } = checkIsOutOfLimits(
      aircraftLatWbPoints.map(p => ({ x: ("arm" in p) ? p.arm : p.mass, y: p.latArm })),
      ("arm" in aircraftLatWbPoints[0]) ? calculatedFlightWbPoints.emptyFuel.arm : calculatedFlightWbPoints.emptyFuel.mass,
      calculatedFlightWbPoints.emptyFuel.latArm);

    if (latArmEmptyFuelOut) texts.push('Empty Fuel Lat CG is out of limits')
  }

  return texts
}