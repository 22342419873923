import { EntityDetailsDisplayInfoFieldFuelOnBoard } from "../EntityDetailsDisplayInfo";
import { Control, FieldValues, useController } from "react-hook-form";
import Flight, { FlightFuelOnBoard } from "../../../models/flight";
import InputBox from "../../InputBox/InputBox";
import { Col, Row } from "react-bootstrap";
import { FuelConverter } from "../../../utils/FuelUtils";

interface EntityFuelOnBoardComponentProps<Entity extends FieldValues> {
    displayInfoInput: EntityDetailsDisplayInfoFieldFuelOnBoard<Entity>;
    control: Control<Entity, any>;
    edittedFlight: Flight;
}


function EntityFuelOnBoardComponent<Entity extends FieldValues>({ displayInfoInput, control, edittedFlight }: EntityFuelOnBoardComponentProps<Entity>) {
  const { aircraft } = edittedFlight;

  const getFuelOnBoardValidateRules = () => {
    var v: any = {};
    aircraft.wb?.stations
      .filter((s) => s.type === "fuel")
      .forEach((s) => {
        v[s.id] = (value: FlightFuelOnBoard[]) => {
          const fuel = (value ?? []).find((f) => f.station.id === s.id)?.fuel ?? 0;
          if (fuel <= 0) {
            return "Fuel must be greater than 0";
          }
          return true;
        };
      });

    return v;
  };

  const {
    field: { value, onChange, ref },
    fieldState,
    formState: { isSubmitting, isValid },
  } = useController({
    name: displayInfoInput.key,
    control,
    rules: {
      validate: getFuelOnBoardValidateRules(),
    },
  });

  const fuelOnBoard: FlightFuelOnBoard[] = value ?? [];

  const toFuelUnit = (mass: number) => {
    return FuelConverter.toFuelUnit(aircraft, mass);
  };

  const toMassUnit = (fuel: number) => {
    return FuelConverter.toMassUnit(aircraft, fuel);
  };

  const stations = aircraft.wb?.stations.filter((s) => s.type === "fuel") ?? [{type: "fuel", id: "0", name: "Fuel", maxMass: FuelConverter.toMassUnit(aircraft, aircraft.totalFuelCapacity ?? 10000), arm: 0}];

  return (
    <div>
      {stations
        .filter((s) => s.type === "fuel")
        .map((aircraftStation) => {
          return (
            <div className="mb-3" key={aircraftStation.id}>
              <Row>
                <Col xs={(aircraft.fuelUnit === aircraft.massUnit) ? 12 : 6}>
                  <InputBox
                    inputRef={(fieldState.error?.type === aircraftStation.id) ? ref : undefined}
                    fieldState={
                      fieldState.error?.type === aircraftStation.id
                        ? fieldState
                        : undefined
                    }
                    value={(
                      fuelOnBoard.find(
                        (s) => s.station.id === aircraftStation.id
                      )?.fuel ?? 0
                    ).toString()}
                    isDisabled={(isValid && isSubmitting) || (displayInfoInput.disabled ?? false)}
                    name={aircraftStation.name + " (" + aircraft.fuelUnit + ") - Max: " + Math.round(toFuelUnit(aircraftStation.maxMass))}
                    type="number"
                    onChange={(newValue) => {
                      var newFuelOnBoard = fuelOnBoard.filter(
                        (f) => f.station.id !== aircraftStation.id
                      );
                      const maxedValue = Math.min(Math.round(toFuelUnit(aircraftStation.maxMass)), newValue)
                      newFuelOnBoard.push({
                        station: aircraftStation,
                        fuel: maxedValue,
                        mass: Math.round(toMassUnit(maxedValue)),
                      });
                      onChange(newFuelOnBoard);
                    }}
                  />
                </Col>
                <Col xs={6} className={(aircraft.fuelUnit === aircraft.massUnit) ? "d-none" : ""}>
                  <InputBox
                    value={(
                      fuelOnBoard.find(
                        (s) => s.station.id === aircraftStation.id
                      )?.mass ?? 0
                    ).toString()}
                    isDisabled={(isValid && isSubmitting) || (displayInfoInput.disabled ?? false)}
                    name={aircraftStation.name + " (" + aircraft.massUnit + ") - Max: " + aircraftStation.maxMass}
                    type="number"
                    onChange={(newValue) => {
                      var newFuelOnBoard = fuelOnBoard.filter(
                        (f) => f.station.id !== aircraftStation.id
                      );
                      const maxedValue = Math.min(aircraftStation.maxMass, newValue)
                      newFuelOnBoard.push({
                        station: aircraftStation,
                        mass: maxedValue,
                        fuel: Math.round(toFuelUnit(maxedValue)),
                      });
                      onChange(newFuelOnBoard);
                    }}
                  />
                </Col>
              </Row>
            </div>
          );
        })}
    </div>
  );
}

export default EntityFuelOnBoardComponent