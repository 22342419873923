import { EntityDetailsDisplayInfo } from "../components/EntityDetails/EntityDetailsDisplayInfo";
import { FlightPlanPoint, FlightPlanZZZZPoint } from "./flightPlan";
import { Passenger } from "./passenger";

type ToolsFlight = {
    id?: string, //not used

    departureDateTime: Date;

    registration: string;

    departure: FlightPlanPoint;
    destination: FlightPlanPoint;
    routePoints: FlightPlanZZZZPoint[];

    altn1?: string;

    crew: {
        pic:{
            firstName: string;
            lastName: string;
            dateOfBirth?: Date;
            passportNumber?: string;
            passportCountryCode?: string;
            signatureBase64?: string;
        },
        other?:{
            function: string;
            firstName: string;
            lastName: string;
            dateOfBirth?: Date;
            passportNumber?: string;
            passportCountryCode?: string;
        }
    }

    passengers: Passenger[];
    isCostSharing?: boolean

    aircraftTypeIcao?: string;
    cruiseSpeed?: {unit: "N" | "K" , value: number};
    cruiseLevel?: {unit: "VFR" | "F" | "A" | "S" | "M", value: number};
  
    fuelUnit?: "ltr" | "usgal" | "kg" | "lb",
    fuelPerHour?: number
    fuelOnBoard?: number,

    totalEETMins?: number
    enduranceMins?: number
}

export default ToolsFlight;

export function getToolsFlightDisplayInfo(): EntityDetailsDisplayInfo<ToolsFlight> {
    return {
        entityName: "ToolsFlight",
        entityCanBeDeleted: false,
        nextButtonTitle: "Done",
        hardcodedTitle: "Flight Details",
        sections: [
            {
                sectionName: "Date & Time",
                sectionId: "date",
                inputs: [
                    {
                        
                        name: "Departure Date (UTC)",
                        timeName: "Departure Time (UTC)",
                        key: "departureDateTime",
                        type: "dateTime",
                        defaultValue: getDefaultDateForFlightDateTimePicker(),
                        useUtc: true,
                        dateFlatpickrOptions: {
                            dateFormat: "d-m-Y",
                        },
                        timeFlatpickrOptions: {
                            dateFormat: "H:i",
                            time_24hr: true,
                            minuteIncrement: 5,
                        },

                    }
                ]
            },
            {
                sectionName: "Aircraft",
                sectionId: "aircraft",
                inputs: [
                    {
                        name: "Aircraft Registration",
                        key: "registration",
                        type: "text",
                        required: true,
                        uppercase: true,
                        validateRules: {
                            pattern: {
                                value: /^[A-Z0-9]{1,2}-[A-Z0-9]{1,5}$/,
                                message: "Please type your registration including the dash. Example: SX-DEMO"
                            }
                        }
                    },
                    {
                        name:"Aircraft Type (ICAO)",
                        key: "aircraftTypeIcao",
                        type: "text",
                        uppercase: true,
                        validateRules: {
                            pattern: {
                                value: /^[A-Z0-9]{1,4}$/,
                                message: "Please type the aircraft type ICAO code. Example: C172"
                            }
                        }
                    }
                ]
            },
            {
                sectionId:"crewpic",
                sectionName: "PIC",
                inputs: [
                    {
                        name: "Last Name",
                        key: "crew.pic.lastName",
                        type: "text",
                        required: true
                    },
                    {
                        name: "First Name",
                        key: "crew.pic.firstName",
                        type: "text",
                        required: true
                    },
                    {
                        name: "Date of Birth",
                        key: "crew.pic.dateOfBirth",
                        type: "date"
                    },
                    {
                        name: "Passport Number",
                        key: "crew.pic.passportNumber",
                        type: "text"
                    },
                    {
                        name: "Passport Country Code",
                        key: "crew.pic.passportCountryCode",
                        type: "text",
                        uppercase: true
                    }
                ]
            },
            {
                sectionId:"crewsic",
                sectionName: "Other Crew",
                inputs: [
                    {
                        name: "Function (K2, Student, Mech, etc.)",
                        key: "crew.other.function",
                        type: "text",
                        uppercase: true
                    },
                    {
                        name: "Last Name",
                        key: "crew.other.lastName",
                        type: "text"
                    },
                    {
                        name: "First Name",
                        key: "crew.other.firstName",
                        type: "text"
                    },
                    {
                        name: "Date of Birth",
                        key: "crew.other.dateOfBirth",
                        type: "date"
                    },
                    {
                        name: "Passport Number",
                        key: "crew.other.passportNumber",
                        type: "text"
                    },
                    {
                        name: "Passport Country Code",
                        key: "crew.other.passportCountryCode",
                        type: "text",
                        uppercase: true
                    }
                ]
            },
            {
                sectionId: "route",
                sectionName: "Route",
                inputs:[
                    {
                        name: "DEP",
                        key: "departure",
                        type: "flightPlanPoint",
                        required: true
                        
                    },
                    {
                        name: "DEST",
                        key: "destination",
                        type: "flightPlanPoint",
                        required: true
                        
                    },
                    {
                        name: "Route",
                        key: "routePoints",
                        type: "customRoute",
                        required: false
                    },
                    {
                        name: "Alternate 1",
                        key: "altn1",
                        type: "text",
                        uppercase: true
                    },
                    {
                        name: "Cruising Level",
                        key: "cruiseLevel",
                        type: "dropdownInput",
                        options: [
                            { value: "VFR", label: "(V) VFR", hardcodedInputValue: "VFR" },
                            { value: "F", label: "(F) Fl.Lvl (100ft)" },
                            { value: "A", label: "(A) Alt. (100ft)" },
                            { value: "S", label: "(S) Std. Lvl (Dm)" },
                            { value: "M", label: "(M) Metric (Dm)" },
                        ],
                        required: true
                    }
                ]
            },
            {
                sectionId:"passengers",
                sectionName: "Passengers",
                inputs: [
                    {
                        name: "Passengers",
                        key: "passengers",
                        type: "passengers",
                        requiredPassengerFields: ["lastName"],
                        showSignupModalForExistingPassengers: true
                    },
                    {
                        name: "Is cost sharing Flight",
                        key: "isCostSharing",
                        type: "boolean"
                    }
                ]
            },
            {
                sectionId: "endurance",
                sectionName: "Endurance",
                inputs: [
                    {
                        name: "Cruise Speed",
                        key: "cruiseSpeed",
                        type: "dropdownInput",
                        options: [
                            { value: "N", label: "Knots" },
                            { value: "K", label: "Km/h" },
                        ]
                    },
                    {
                        name: "Fuel Unit",
                        key: "fuelUnit",
                        type: "dropdown",
                        options: [
                            { value: "ltr", label: "Liters" },
                            { value: "usgal", label: "US Gallons" },
                            { value: "kg", label: "Kilograms" },
                            { value: "lb", label: "Pounds" },
                        ]
                    },
                    {
                        name: "Fuel Per Hour",
                        key: "fuelPerHour",
                        type: "number",
                        
                    },
                    {
                        name: "Fuel On Board",
                        key: "fuelOnBoard",
                        type: "number",
                    }
                ]
            }
        ]
    };
}

function getDefaultDateForFlightDateTimePicker() {
    const now = new Date();
    const d = new Date(now.getTime() + 25 * 60 * 1000);
    d.setMinutes(Math.ceil(d.getMinutes() / 5) * 5);
    return d;
}