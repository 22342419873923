import { EntityDetailsDisplayInfoFieldPersonOnBoard, getValitationRules } from "../EntityDetailsDisplayInfo";
import { Control, FieldValues, useController } from "react-hook-form";
import Flight from "../../../models/flight";
import InputBox from "../../InputBox/InputBox";
import { useEffect } from "react";

interface EntityPobComponentProps<Entity extends FieldValues> {
    displayInfoInput: EntityDetailsDisplayInfoFieldPersonOnBoard<Entity>;
    control: Control<Entity, any>
    edittedFlight: Flight;
}

function EntityPobComponent<Entity extends FieldValues>({ control, displayInfoInput, edittedFlight }: EntityPobComponentProps<Entity>) {
  const {
    field,
    fieldState,
    formState: { isSubmitting, isValid },
  } = useController({
    name: displayInfoInput.key,
    control,
    rules: getValitationRules(displayInfoInput),
  });

  const {value: fieldValue, onChange: fieldOnChange} = field
  const disabled = (isValid && isSubmitting) || (displayInfoInput.disabled ?? false);

  useEffect(() => {
    var newCount: number = fieldValue
    if (edittedFlight.passengersLeg?.route && edittedFlight.passengersLeg!.route.fromIndex > 0){
        newCount = [edittedFlight.pic, edittedFlight.sic, edittedFlight.deadheadCrew, edittedFlight.studentPilot].filter( (e) => e).length
    }else{
        newCount = [edittedFlight.pic, edittedFlight.sic, edittedFlight.deadheadCrew, edittedFlight.studentPilot].filter( (e) => e).length + (edittedFlight.passengers?.length ?? 0)
    }
    if (fieldValue !== newCount){
        fieldOnChange(newCount)
    }
  }, [edittedFlight.pic, edittedFlight.sic, edittedFlight.deadheadCrew, edittedFlight.studentPilot, edittedFlight.passengers, edittedFlight.passengersLeg, edittedFlight.passengersLeg?.route, fieldValue, fieldOnChange])

  return (
    <InputBox
      value={field.value}
      onChange={(s: any) => {}}
      field={field}
      fieldState={fieldState}
      isDisabled={disabled}
      name={displayInfoInput.name}
      type={"number"}
    />
  );
}

export default EntityPobComponent