import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { Col, Container, Row} from "react-bootstrap";
import { Passenger } from "../../../models/passenger";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getPassengers } from "../../../store/passengersSlice";
import { Outlet, useNavigate } from "react-router-dom";
import ListEntitiesTable from "../../../components/ListEntities/ListEntitiesTable";
import ListEntitiesHeaderComponent from "../../../components/ListEntities/ListEntitiesHeaderComponent";
import LoadingComponent from "../../../components/LoadingComponent";

interface ListPassengersPageProps {
  onPassengerSelected?: (passenger: Passenger) => void;
}

function ListPassengersPage({ onPassengerSelected }: ListPassengersPageProps) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const {allPassengers, status, loading } = useAppSelector((state) => state.passengers)

  const [searchValue, setSearchValue] = useState("")


  useEffect(() => {
    if (status === "idle"){
      dispatch(getPassengers())
    }

  },[dispatch, status])


  return (
    <Container fluid className="vh-100 px-0 px-md-2">
      <ListEntitiesHeaderComponent
        actionBtn={!onPassengerSelected ? { title: "Add Passenger", onClick: () => navigate("add") } : undefined}
        search={{ value: searchValue, setValue: setSearchValue }}
        title="Passengers"
      />

      {loading ? (
        <LoadingComponent text="Loading passengers..." />
      ) : (
        <Row className="overflow-auto" style={{ height: "calc(100% - 140px)" }}>
          <Col>
            <PassengersTable data={allPassengers} searchValue={searchValue} onPassengerSelected={onPassengerSelected} />
          </Col>
        </Row>
      )}

      <Outlet />
    </Container>
  );
}

export default ListPassengersPage;


interface PassengersTableProps{
  data: Passenger[],
  searchValue: string,
  onPassengerSelected?: (passenger: Passenger) => void;
}

function PassengersTable({data, searchValue, onPassengerSelected }: PassengersTableProps){
    const columnHelper = createColumnHelper<Passenger>()

    const columns = [
      columnHelper.accessor("lastName", {
        header: () => "Last Name",
        cell: (info) => info.getValue(),
        sortingFn:"alphanumeric"
      }),
      columnHelper.accessor("firstName", {
        header: () => "First Name",
        cell: (info) => info.getValue(),
        sortingFn:"alphanumeric"
      }),
      columnHelper.accessor("weight", {
        header: () => "Weight (kg)",
        cell: (info) => info.getValue()?.toString() ?? "",
        sortingFn:"alphanumeric"
      }),
    ];

    return (
      <ListEntitiesTable
        data={data}
        columns={columns}
        searchValue={searchValue}
        onRowClick={onPassengerSelected}
      />
    );
}
