import { LoaderFunction, useLoaderData, useNavigate } from "react-router-dom";
import { AviTracerApi } from "../../../avitracerApi";
import { EntityDisplayInfo } from "../../../components/EntityDetails/EntityDetailsDisplayInfo";
import EntityDetailsPage from "../../../components/EntityDetails/EntityDetailsPage";
import { Route } from "../../../models/route";
import { useAppDispatch } from "../../../store/hooks";
import { archiveRouteWithId, editRoute } from "../../../store/routesSlice";

export const routeLoader: LoaderFunction = async (params) => {
  return AviTracerApi.getRouteWithId(params.params["routeId"]!);
};
  

function RouteDetailsPage() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate();

  const loadedRoute = useLoaderData() as Route;

  const goBack = () => {
    navigate("/routes");
  };

  const handleSaveRoute = async (newRoute: Route) => {
    return await dispatch(editRoute(newRoute)).unwrap()
  };

  const handleArchiveRoute = async (route: Route) => {
    if (route.id) {
      return await dispatch(archiveRouteWithId(route.id)).unwrap();
    }
  };

  return (
    <EntityDetailsPage
      entity={loadedRoute}
      handleClose={goBack}
      displayInfo={EntityDisplayInfo.Route()}
      dispatchSaveEntity={handleSaveRoute}
      dispatchDeleteEntity={handleArchiveRoute}
    />
  );
}

export default RouteDetailsPage