import TextAreaBox from "../InputBox/TextAreaBox";
import { EntityDetailsDisplayInfoFieldText, getValitationRules } from "./EntityDetailsDisplayInfo";
import { Control, FieldValues, useController } from "react-hook-form";

interface EntityTextAreaBoxProps<Entity extends FieldValues> {
    displayInfoInput: EntityDetailsDisplayInfoFieldText<Entity>;
    control: Control<Entity, any>
  }
  
  
function EntityTextAreaBox<Entity extends FieldValues>({ displayInfoInput, control}: EntityTextAreaBoxProps<Entity>) {
    
  const {
    field: {value, onChange, onBlur, ref},
    fieldState,
    formState: { isSubmitting, isValid },
  } = useController({
    name: displayInfoInput.key,
    control,
    rules: getValitationRules(displayInfoInput),
  });

  const disabled = (isValid && isSubmitting) || (displayInfoInput.disabled ?? false)

    return (
      <TextAreaBox
        isDisabled={disabled}
        value={value ?? ""}
        onChange={(newValue: string) => {
          onChange(newValue)
        }}
        name={displayInfoInput.name}
        uppercase={displayInfoInput.uppercase}
        fieldState={fieldState}
        field={{onBlur, ref}}
      />
    );
}

export default EntityTextAreaBox;