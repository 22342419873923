import { LoaderFunction, useLoaderData, useNavigate } from "react-router-dom";
import { AviTracerApi } from "../../avitracerApi";
import { UserData, UserInvite } from "../../models/userInvite";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useAppDispatch } from "../../store/hooks";
import { clearSelectedOrganization } from "../../store/organizationsSlice";
import { useState } from "react";
import EntityDetailsPage from "../../components/EntityDetails/EntityDetailsPage";
import { EntityDisplayInfo } from "../../components/EntityDetails/EntityDetailsDisplayInfo";
import Header from "../../Header";
import moment from "moment";
import LoadingComponent from "../../components/LoadingComponent";

export const userInviteLoader: LoaderFunction = async (params) => {
    return AviTracerApi.getUserInvite(params.params["inviteId"]!);
};

function UserInviteDetailsPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  const userInvite = useLoaderData() as UserInvite;
  const [crew, setCrew] = useState<UserData>(userInvite.crew);

  const [showEditCrewModal, setShowEditCrewModal] = useState(false);

  const [ acceptingInvite, setAcceptingInvite ] = useState(false);

  const [allowOrganizationToEdit, setAllowOrganizationToEdit] = useState(true);

  const acceptInvite = async () => {
    setAcceptingInvite(true);

    const updatedUserData: UserData = {
      firstName: crew.firstName,
      lastName: crew.lastName,
      weight: crew.weight,
      dob: crew.dob,
      idPassportNumber: crew.idPassportNumber,
      idPassportCountryCode: crew.idPassportCountryCode,
    }

    await AviTracerApi.acceptUserInvite(userInvite.id, updatedUserData, allowOrganizationToEdit);
    
    setAcceptingInvite(false);
    dispatch(clearSelectedOrganization())
    navigate("/", { replace: true })
  }

  const handleEdittedCrew = async (crew: UserData) => {
    setCrew(crew);
    return crew
  };

  return (
    <>
      <div className="bg-white">
        <Header />
      </div>
      {acceptingInvite ? (
        <LoadingComponent text={"Accepting..."} />
      ) : (
        <Container>
          <Row className="justify-content-center">
            <Col xs={11} md={10} lg={8} xl={6} className=" border rounded-lg  bg-white p-3 p-lg-5">
              <Row>
                <Col>
                  <h2>Team Invitation</h2>
                  <div className="fs-4 pt-3">
                    <strong>{userInvite?.organizationName}</strong> has invited you to join their team in BRIEF.
                  </div>
                </Col>
              </Row>
              <Row className="pt-3 gy-2">
                <Col>The following details have been provided by the organization:</Col>
                <div className="w-100" />
                <Col xs={5}>
                  <div>Last Name:</div>
                  <div>First Name:</div>
                  <div>Weight:</div>
                  <div>Date of Birth:</div>
                  <div>ID/Passport number:</div>
                  <div>ID/Passport country:</div>
                </Col>
                <Col className="fw-bold">
                  <div>{crew.lastName}</div>
                  <div>{crew.firstName}</div>
                  <div>{crew.weight}</div>
                  <div>{crew.dob && moment(crew.dob).utc().format("DD/MM/YYYY")}</div>
                  <div>{crew.idPassportNumber}</div>
                  <div>{crew.idPassportCountryCode}</div>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  Allow organization to change my Details:
                  {allowOrganizationToEdit ? (
                    <Button className="ms-2" size="sm" variant="success" onClick={() => setAllowOrganizationToEdit(false)} >
                      Yes
                    </Button>
                  ) : (
                    <Button className="ms-2" size="sm" variant="danger" onClick={() => setAllowOrganizationToEdit(true)} >
                      No
                    </Button>
                  )}
                </Col>
              </Row>
              <hr />
              <Row className="justify-content-center pt-3 gy-2">
                <Col xs={12} xl={6}>
                  <div className="d-grid">
                    <Button variant={"light"} onClick={() => setShowEditCrewModal(true)}>
                      Edit your info
                    </Button>
                  </div>
                </Col>
                <div className="w-100" />
                <Col xs={12} xl={6}>
                  <div className="d-grid">
                    <Button variant="dark" onClick={acceptInvite}>
                      Accept
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          {showEditCrewModal && (
            <EntityDetailsPage
              entity={crew}
              displayInfo={EntityDisplayInfo.InvitedUserData()}
              handleClose={() => {
                setShowEditCrewModal(false);
              }}
              dispatchSaveEntity={handleEdittedCrew}
            />
          )}
        </Container>
      )}
    </>
  );
}

export default UserInviteDetailsPage;