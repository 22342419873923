import { createListenerMiddleware, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { createAppAsyncThunk } from './hooks'
import { Organization } from '../models/organization'
import { AviTracerApi } from '../avitracerApi'
import { AppDispatch, RootState } from './store'
import { resetAircraftStateForOrganizationChange } from './aircraftSlice'
import { resetPassengersStateForOrganizationChange } from './passengersSlice'
import { resetCrewStateForOrganizationChange } from './crewSlice'
import { resetRoutesStateForOrganizationChange } from './routesSlice'
import { fileFpl, requestBriefingCreation, resetFlightsStateForOrganizationChange } from './flightSlice'
import { fileFlightPlanFpl, resetFlightPlansForOrganizationChange } from './flightPlansSlice'

interface OrganizationsState {
    allOrganizations: Organization[]
    selectedOrganization?: Organization
    selectedOrganizationId?: string
    userHasNoOrganizations: boolean
}

const initialState: OrganizationsState = {
    allOrganizations: [],
    selectedOrganization: undefined,
    selectedOrganizationId: localStorage.getItem("selectedOrganizationId") ? localStorage.getItem("selectedOrganizationId")! : undefined,
    userHasNoOrganizations: false
}

export const getOrganizations = createAppAsyncThunk(
    "organizations/fetch",
    async () => {
        return AviTracerApi.getOrganizations()
    }
)

export const organizationsSlice = createSlice({
    name: "organizations",
    initialState,
    reducers:{
        selectOrganization: (state, action: PayloadAction<Organization>) => {
            state.selectedOrganization = action.payload
            state.selectedOrganizationId = action.payload.id
            localStorage.setItem("selectedOrganizationId", action.payload.id)
        },
        clearSelectedOrganization: (state) =>{
            localStorage.removeItem("selectedOrganizationId")
            state.allOrganizations = []
            state.selectedOrganization = undefined
            state.selectedOrganizationId = undefined
            state.userHasNoOrganizations = false
        }
        
    }, extraReducers: (builder) => {
        builder.addCase(getOrganizations.fulfilled, (state, action) => {
            const allOrgs = action.payload
            if (allOrgs.length === 0) {
                state.userHasNoOrganizations = true
                return
            }
            
            state.userHasNoOrganizations = false
            state.allOrganizations = allOrgs

            const savedOrganizationId = localStorage.getItem("selectedOrganizationId")
            if (savedOrganizationId && allOrgs.findIndex(o => o.id === savedOrganizationId) > -1) {
                state.selectedOrganization = allOrgs.find(o => o.id === savedOrganizationId)
                state.selectedOrganizationId = savedOrganizationId
            } else {
                const firstOrganization = allOrgs[0]
                state.selectedOrganization = firstOrganization
                state.selectedOrganizationId = firstOrganization.id
                localStorage.setItem("selectedOrganizationId", firstOrganization.id)
            }
        })
    }

})  

export const selectedOrganization = (state: RootState) => state.organizations.selectedOrganization

export const { selectOrganization, clearSelectedOrganization } = organizationsSlice.actions

export default organizationsSlice.reducer

export const clearStoreAfterOrganizationChange = createListenerMiddleware()
clearStoreAfterOrganizationChange.startListening({
    matcher: isAnyOf(selectOrganization, clearSelectedOrganization),
    effect: (action, store) => {
        store.dispatch(resetFlightsStateForOrganizationChange())
        store.dispatch(resetFlightPlansForOrganizationChange())
        store.dispatch(resetAircraftStateForOrganizationChange())   
        store.dispatch(resetCrewStateForOrganizationChange())
        store.dispatch(resetPassengersStateForOrganizationChange())
        store.dispatch(resetRoutesStateForOrganizationChange())
    }
})

export const refreshOrganizationCredits = createListenerMiddleware()
refreshOrganizationCredits.startListening({
    matcher: isAnyOf(fileFlightPlanFpl.fulfilled, fileFpl.fulfilled, requestBriefingCreation.fulfilled),
    effect: async (action, store) => {
        (store.dispatch as AppDispatch)(getOrganizations())
    }
})