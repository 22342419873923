import { useEffect, useState } from "react";
import { AircraftFullWbProfileInfo, AircraftWbProfile } from "../../../models/aircraft";
import { AviTracerApi } from "../../../avitracerApi";
import LoadingComponent from "../../../components/LoadingComponent";
import ListEntitiesTable from "../../../components/ListEntities/ListEntitiesTable";
import { createColumnHelper } from "@tanstack/react-table";
import { Container } from "react-bootstrap";
import ListEntitiesHeaderComponent from "../../../components/ListEntities/ListEntitiesHeaderComponent";
import { FuelConverter } from "../../../utils/FuelUtils";


interface ListAicraftWbProfilesPageProps {
  onProfileSelected: (profile: AircraftWbProfile) => void;    
}

function ListAicraftWbProfilesPage({onProfileSelected}: ListAicraftWbProfilesPageProps) {
  const [allProfiles, setAllProfiles] = useState<AircraftFullWbProfileInfo[]>([]);

  useEffect(() => {
    const fetchProfiles = async () => {
      const profiles = await AviTracerApi.getAllAircraftWbProfiles();
      setAllProfiles(profiles);
    };
    fetchProfiles();
  }, []);

  const [searchValue, setSearchValue] = useState<string>("");

  const columnHelper = createColumnHelper<AircraftFullWbProfileInfo>();

  const columns = [
    columnHelper.accessor("profileName", {
      header: "Profile Name",
      sortingFn: "alphanumeric",
    }),
    columnHelper.accessor("manufacturer", {
      header: "Manufacturer",
      sortingFn: "alphanumeric",
    }),
    columnHelper.accessor("model", {
      header: "Model",
      sortingFn: "alphanumeric",
    }),
    columnHelper.accessor("icaoType", {
      header: "ICAO Type",
      sortingFn: "alphanumeric",
    }),
    columnHelper.display({
      id: "fuelLtr",
      header: "Fuel (Ltr)",
      cell: (i) => {
        const totalMass = i.row.original.stations.filter((s) => s.type === "fuel").reduce((acc, s) => acc + s.maxMass, 0);
        return Math.round(FuelConverter.toFuel(totalMass, "kg", i.row.original.fuelType, "ltr"));
      },
    }),
    columnHelper.accessor("maxMass", {
      header: "Max Mass (kg)",
      sortingFn: "alphanumeric",
    }),
  ];

  return (
    <Container fluid className="vh-100 px-0 px-md-2">
      <ListEntitiesHeaderComponent
        title="Select W&B Profile"
        search={{ value: searchValue, setValue: setSearchValue }}
        actionBtn={{
          title: "Request New Profile",
          onClick: () => {
            window.location.href = "mailto:support@avitracer.com";
          },
        }}
      />
      {allProfiles.length > 0 ? (
        <ListEntitiesTable data={allProfiles} columns={columns} searchValue={searchValue} onRowClick={onProfileSelected} />
      ) : (
        <LoadingComponent text="Loading W&B profiles..." />
      )}
    </Container>
  );
}

export default ListAicraftWbProfilesPage;