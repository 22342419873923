import { useEffect, useState } from "react";
import { Col, Modal, Row, Spinner, Stack } from "react-bootstrap";
import { LoaderFunction, useLoaderData, useNavigate } from "react-router";
import { useInterval } from "usehooks-ts";
import { AviTracerApi } from "../../../avitracerApi";
import { EntityDisplayInfo } from "../../../components/EntityDetails/EntityDetailsDisplayInfo";
import EntityDetailsPage from "../../../components/EntityDetails/EntityDetailsPage";
import { calculateDistanceBetweenRoutePoints, ImportedRoute, Route } from "../../../models/route";
import { useAppDispatch } from "../../../store/hooks";
import { createRoute } from "../../../store/routesSlice";
import SelectOrganizationModal from "../selectOrganization/SelectOrganizationModal";

export const importRouteLoader: LoaderFunction = async (params) => {
    return AviTracerApi.getImportedRoute(params.params["importedRouteId"]!);
};

function ImportRoutePage() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate();

  const loadedImportedRoute = useLoaderData() as ImportedRoute;

  const [importedRoute, setImportedRoute] = useState<ImportedRoute | undefined>((loadedImportedRoute.aftnAddresses) ? loadedImportedRoute : undefined);

  useEffect(() => {
    if (importedRoute && importedRoute.distanceNM === undefined){
      setImportedRoute((r) => ({...r!, distanceNM: calculateDistanceBetweenRoutePoints(r!.departure, r!.destination, r!.fixes)}))
    }
    
  }, [importedRoute]);

  const [hasSelectedOrganization, setHasSelectedOrganization] = useState(false);

  useInterval(async () => {
    const updatedRoute = await AviTracerApi.getImportedRoute(loadedImportedRoute.id);
    if (updatedRoute.aftnAddresses) {
       setImportedRoute(updatedRoute);
    }
    
  }, (importedRoute?.aftnAddresses === undefined) ? 2000 : null);

  const handleSaveRoute = async (importedRoute: ImportedRoute) => {
    const route : Route = {
      name: importedRoute.name,
      departure: importedRoute.departure,
      destination: importedRoute.destination,
      waypoints: importedRoute.fixes,
      intermediateStops: importedRoute.intermediateStops ?? [],
      distanceNM: importedRoute.distanceNM,
      aftnAddresses: importedRoute.aftnAddresses
    }

    await dispatch(createRoute(route)).unwrap();
    
    return importedRoute //not needed
  };

  return (
    <>
      {hasSelectedOrganization ? (
        <>
          {importedRoute ? (
            <EntityDetailsPage
              entity={importedRoute}
              handleClose={() => navigate("/routes")}
              displayInfo={EntityDisplayInfo.ImportedRoute()}
              dispatchSaveEntity={handleSaveRoute}
            />
          ) : (
            <Modal show centered>
              <Modal.Body>
                <Row className="justify-content-center">
                  <Col xs={1}>
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col>
                    <p className="text-center">Finding AFTN Addresses...</p>
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
          )}
        </>
      ) : (
        <SelectOrganizationModal
          hideModal={() => setHasSelectedOrganization(true)}
        />
      )}
    </>
  );
}

export default ImportRoutePage;