export interface Identifiable{
    id?: string
}

export interface CanEditSharedValues{
    canEditSharedValues: boolean //current user has permissions to edit shared values of entity
}

export function implementsCanEditSharedValues(obj: any): obj is CanEditSharedValues{
    return obj.canEditSharedValues !== undefined
}