import { Button, Col, Row } from "react-bootstrap";
import InputBox from "../../InputBox/InputBox";
import { EntityDetailsDisplayInfoFieldFlightPlanPoint } from "../EntityDetailsDisplayInfo";
import { Control, FieldValues, UseFormSetValue, useController} from "react-hook-form";
import { FlightPlanPoint, FlightPlanZZZZPoint } from "../../../models/flightPlan";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons';
import RouteMapPage from "../../../pages/organization/routes/RouteMapPage";
import { useEffect, useState } from "react";
import { getIcaoAirport } from "../../../utils/IcaoDbUtils";
import Flight, { FlightPassengerLeg } from "../../../models/flight";
import { convertLatLonToGeoPoint } from "../../../models/route";

interface EntityFlightPlanPointPickerProps<Entity extends FieldValues> {
    displayInfoInput: EntityDetailsDisplayInfoFieldFlightPlanPoint<Entity>;
    setModalContent: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
    control: Control<Entity, any>
    setValue?: UseFormSetValue<Flight>
    passengersLegValue?: FlightPassengerLeg;
}

function EntityFlightPlanPointPicker<Entity extends FieldValues>({
  displayInfoInput,
  setModalContent,
  control,
  setValue,
  passengersLegValue
}: EntityFlightPlanPointPickerProps<Entity>): JSX.Element | null {

  const {
    field,
    fieldState,
    formState: { isSubmitting, isValid },
  } = useController({
    name: displayInfoInput.key,
    control,
    rules: {
      validate: {
        required: (v) => {
          if (displayInfoInput.required && !v) return "Required";
          return true;
        },
        validIcao: (v: FlightPlanPoint) => {
          if (v.isIcao && v.icaoCode !== "ZZZZ") {
            if (v.icaoCode.length !== 4) return "Invalid ICAO";
            if (!/^[A-Z]+$/.test(v.icaoCode)) return "Invalid ICAO";
          }
          return true;
        },
        validNonIcaoName: (v: FlightPlanPoint) => {
          if (v.isIcao === false) {
            if (!v.name) return "Required name";
            if (!(/^([0-9]|[ ]|[A-Z]){1,50}$/.test(v.name))) return "Invalid name format. Allowed 0-9, A-Z";
            return true
          }
          if (v.isIcao && v.icaoCode === "ZZZZ") {
            return "Required name";
          }
          return true;
        },
        validNonIcaoCoords: (v: FlightPlanPoint) => {
          if (v.isIcao === false) {
            if (!v.coords) return "Required coords";
            if (!(/\d{4}[NS]\d{5}[EW]/.test(v.coords))) return "Invalid coords format. Example: 3756N02356E";
            return true
          }
          return true;
        }
      },
    },
  });

  const [icaoAirportName, setIcaoAirportName] = useState<string>("");

  const isDisabled = (isValid && isSubmitting) || (displayInfoInput.disabled ?? false);

  const isZZZZ = (field.value?.isIcao === false || field.value?.icaoCode === "ZZZZ") ?? false;

  const updateIcaoAirportName = async (icaoCode: string) => {
    if (icaoCode.length === 4 && /^[A-Z]+$/.test(icaoCode)) {
      const icaoAirport = await getIcaoAirport(icaoCode);
      if (icaoAirport) {
        setIcaoAirportName(icaoAirport.name);
        if (field.value.coords === ""){
          field.onChange({
            isIcao: true,
            coords: convertLatLonToGeoPoint(icaoAirport.latitude, icaoAirport.longitude),
            icaoCode: icaoAirport.icao,
          });
        }
      } else {
        setIcaoAirportName("Not ICAO airport");
      }
    } else {
      if (icaoAirportName !== "Invalid ICAO identifier"){
        setIcaoAirportName("Invalid ICAO identifier")
      }
    }
  }

  const clearPassengerLegIfNeeded = () => {
    if (setValue && passengersLegValue) {
      setValue("passengersLeg.route", null as any);
      setValue("passengersLeg", undefined);
    }
  }

  const setToZZZZ = () => {
    field.onChange({
        isIcao: false,
        name: "",
        coords: ""
    })
    updateIcaoAirportName("");
    openMap();
    clearPassengerLegIfNeeded()
  }

  const openMap = () => {
    setModalContent(<RouteMapPage selectZZZZPoint={(point: FlightPlanZZZZPoint) => {
      field.onChange(point);
      setModalContent(null);
    }} />);
  }

  useEffect(() => {
    if (field.value?.icaoCode) {
      updateIcaoAirportName(field.value.icaoCode);
    }
  })

  return (
    <>
      <Row className="gx-3 gy-1">
        <Col xs={8} md={3}>
          <InputBox
            value={isZZZZ ? "ZZZZ" : field.value?.icaoCode ?? ""}
            isDisabled={isDisabled}
            name={displayInfoInput.name + " ICAO"}
            uppercase={true}
            fieldState={{ error: undefined, isTouched: false, isDirty: false, invalid: fieldState.error?.type === "validIcao" ?? false }}
            type={"text"}
            inputRef={field.ref}
            onChange={(v) => {
              field.onChange({
                isIcao: true,
                icaoCode: v,
                coords: "",
              });
              updateIcaoAirportName(v);
              clearPassengerLegIfNeeded();
            }}
          />
        </Col>
        {!isZZZZ && (
          <>
            <Col xs={{ span: 4 }} md={{ span: 2, order: "last" }}>
              <div className="d-grid h-100">
                <Button variant="light" style={{ border: "#DFDFDF 2px solid", "--bs-btn-bg": "#FFF" }} onClick={setToZZZZ}>
                  <FontAwesomeIcon icon={faMapLocationDot} size="xl" /> ZZZZ
                </Button>
              </div>
            </Col>
            <Col xs={{ span: 12 }} md={{ span: 7 }}>
              <InputBox value={icaoAirportName} isDisabled name={displayInfoInput.name + " Name"} type="text" onChange={() => {}} />
            </Col>
          </>
        )}
        {isZZZZ && (
          <>
            <Col xs={4} md={{ span: 2, order: "last" }}>
              <div className="d-grid h-100">
                <Button variant="light" style={{ border: "#DFDFDF 2px solid", "--bs-btn-bg": "#FFF" }} onClick={openMap}>
                  <FontAwesomeIcon icon={faMapLocationDot} size="xl" /> ZZZZ
                </Button>
              </div>
            </Col>
            <Col xs={6} md={4}>
              <InputBox
                value={(field.value as FlightPlanZZZZPoint)?.name ?? ""}
                isDisabled={isDisabled}
                name={"ZZZZ Name"}
                uppercase={true}
                type={"text"}
                fieldState={{
                  error: undefined,
                  isTouched: false,
                  isDirty: false,
                  invalid: fieldState.error?.type === "validNonIcaoName" ?? false,
                }}
                onChange={(v) => {
                  field.onChange({
                    isIcao: false,
                    coords: field.value.coords,
                    name: v,
                  });
                  clearPassengerLegIfNeeded();
                }}
              />
            </Col>
            <Col xs={6} md={3}>
              <InputBox
                value={(field.value as FlightPlanZZZZPoint)?.coords ?? ""}
                isDisabled={isDisabled}
                name={"Coords"}
                uppercase={true}
                type={"text"}
                fieldState={{
                  error: undefined,
                  isTouched: false,
                  isDirty: false,
                  invalid: fieldState.error?.type === "validNonIcaoCoords" ?? false,
                }}
                onChange={(v) => {
                  field.onChange({
                    isIcao: false,
                    name: field.value.name,
                    coords: v,
                  });
                  clearPassengerLegIfNeeded();
                }}
              />
            </Col>
          </>
        )}
      </Row>
      {fieldState.error && <small className="text-danger">{fieldState.error.message}</small>}
    </>
  );
}

export default EntityFlightPlanPointPicker;