import { decodeToken } from "react-jwt";
import { LoaderFunction, useLoaderData } from "react-router-dom";
import { AviTracerApi } from "../../avitracerApi";
import { Passenger } from "../../models/passenger";
import { Organization } from "../../models/organization";
import { Button, Col, Row } from "react-bootstrap";
import { useRef, useState } from "react";
import InputBox from "../../components/InputBox/InputBox";
import FlatPicker from 'react-flatpickr'
import moment from "moment";
import SignatureCanvas from 'react-signature-canvas'

export const requestInfoPassengerLoader: LoaderFunction = async (params) => {
    const url = params.request.url;
    const jwtToken = url.split("?token=")[1];
  
    const info: any = decodeToken(jwtToken);

    if (info.type !== "passenger") {
      return null;
    }
  
    const passenger = await AviTracerApi.passengerRequestInfoGetData(jwtToken);
  
    return { passenger, jwt: jwtToken, organization: info.organization };
  };

function RequestInfoPassengerPage() {

  const { passenger, jwt, organization } = useLoaderData() as { passenger: Passenger; jwt: string; organization: Organization };

  const [currentPage, setCurrentPage] = useState<"intro" | "info" | "signature" | "thankyou">("intro");

  const [edittedPassenger, setEdittedPassenger] = useState<Passenger>(passenger);
 
  const signatureRef = useRef<SignatureCanvas>(null);

  const [saving, setSaving] = useState<boolean>(false);
  const savePressed = () => {
    setSaving(true);
    const save = async () => {
      const signatureDataUrl = signatureRef.current!.getTrimmedCanvas().toDataURL();
      await AviTracerApi.passengerRequestInfoSubmit(edittedPassenger, signatureDataUrl, jwt);
      setCurrentPage("thankyou");
    };
    save();
  }

  return (
    <>
      {currentPage === "signature" ? (
        <SignatureComponent signatureRef={signatureRef} saving={saving} savePressed={savePressed} />
      ) : (
        <Row className="justify-content-center pt-5">
          <Col xs={11} md={10} lg={6} className=" border rounded-lg  bg-white">
            {currentPage === "intro" && <IntroPageComponent organization={organization} setCurrentPage={setCurrentPage} />}
            {currentPage === "info" && (
              <InfoPageComponent
                edittedPassenger={edittedPassenger}
                setEdittedPassenger={setEdittedPassenger}
                setCurrentPage={setCurrentPage}
              />
            )}
            {currentPage === "thankyou" && <ThankYouComponent />}
          </Col>
        </Row>
      )}
    </>
  );
}

export default RequestInfoPassengerPage


interface IntroPageComponentProps {
  organization: Organization;
  setCurrentPage: (currentPage: "intro" | "info" | "signature" | "thankyou") => void;
}

function IntroPageComponent({ organization, setCurrentPage }: IntroPageComponentProps) {
  return (
    <>
      <Row className="justify-content-center pt-5 pb-3">
        <Col xs={10}>
          <h3>{organization.name} kindly requests you validate your information and add your signature.</h3>
          <small>
            Dear passenger,
            <br />
            In preparation for your upcoming flight, {organization.name} kindly asks you to complete the passenger information on the
            following page and provide your signature in accordance with Greek Law 1599/1986 for General Aviation Flights.
            <br />
            This declaration confirms that the flight is not of a commercial nature, and no fare (fee) has been paid.
          </small>
        </Col>
      </Row>
      <Row className="justify-content-center pt-4">
        <Col xs={6}>
          <div className="d-grid py-3">
            <Button
              onClick={() => {
                setCurrentPage("info");
              }}
              variant="dark"
            >
              Continue
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
}


interface InfoPageComponentProps {
  edittedPassenger: Passenger;
  setEdittedPassenger: (edittedPassenger: Passenger) => void;  
  setCurrentPage: (currentPage: "intro" | "info" | "signature" | "thankyou") => void;
}

function InfoPageComponent({ edittedPassenger, setEdittedPassenger, setCurrentPage }: InfoPageComponentProps) {

  const utcOffset = moment(edittedPassenger.dob).utcOffset()

  return (
    <>
      <Row className="justify-content-center pt-3 gy-3">
        <Col xs={"10"}>
          <h4 className="pt-3">Your info</h4>
        </Col>
        <Col xs={"10"}>
          <InputBox
            name="First name"
            isDisabled={false}
            value={edittedPassenger.firstName}
            onChange={(value) => {
              setEdittedPassenger({ ...edittedPassenger, firstName: value });
            }}
          />
        </Col>
        <Col xs={"10"}>
          <InputBox
            name="Last name"
            isDisabled={false}
            value={edittedPassenger.lastName}
            onChange={(value) => {
              setEdittedPassenger({ ...edittedPassenger, lastName: value });
            }}
          />
        </Col>
        <Col xs={"10"}>
          <InputBox
            name="Passport number"
            isDisabled={false}
            value={edittedPassenger.idPassportNumber ?? ""}
            onChange={(value) => {
              setEdittedPassenger({ ...edittedPassenger, idPassportNumber: value });
            }}
          />
        </Col>
        <Col xs={"10"}>
          <InputBox
            name="Passport country"
            isDisabled={false}
            value={edittedPassenger.idPassportCountryCode ?? ""}
            onChange={(value) => {
              setEdittedPassenger({ ...edittedPassenger, idPassportCountryCode: value });
            }}
          />
        </Col>
        <Col xs={"10"}>
          <InputBox
            name="Contact Details (Phone)"
            isDisabled={false}
            value={edittedPassenger.form731contactDetails ?? ""}
            onChange={(value) => {
              setEdittedPassenger({ ...edittedPassenger, form731contactDetails: value });
            }}
          />
        </Col>
        <Col xs={"10"}>
          <InputBox
            name="Weight (kg)"
            isDisabled={false}
            type="number"
            value={String(edittedPassenger.weight)}
            onChange={(value) => {
              setEdittedPassenger({ ...edittedPassenger, weight: value });
            }}
          />
        </Col>
        <Col xs={10}>
          <FlatPicker
            value={edittedPassenger.dob}
            onChange={(s) => {
              setEdittedPassenger({ ...edittedPassenger, dob: moment(s[0]).add(utcOffset, "minutes").toDate() });
            }}
            options={{
              static: true,
            }}
            className="form-control bg-white"
            render={({ value, ...props }, refFlatPickr) => {
              return (
                <Row className="g-0">
                  <Col>
                    <div className={"input-container rounded-lg"}>
                      <input
                        ref={(r) => {
                          if (r !== null) {
                            refFlatPickr(r);
                          }
                        }}
                        disabled={false}
                        style={{ backgroundColor: "#F7F7F7", width: "100%", cursor: "pointer" }}
                      />
                      <label className={value ? "filled" : ""}>Date of Birth</label>
                    </div>
                  </Col>
                </Row>
              );
            }}
          />
        </Col>
      </Row>
      <Row className="justify-content-center pt-4">
        <Col xs={6}>
          <div className="d-grid py-3">
            <Button
              onClick={() => {
                setCurrentPage("signature");
              }}
              variant="dark"
            >
              Continue
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
}


interface SignatureComponentProps {
  signatureRef: React.RefObject<SignatureCanvas>;
  saving: boolean;
  savePressed: () => void;
}

function SignatureComponent({ signatureRef, saving, savePressed }: SignatureComponentProps) {
  
  return (
    <div>
      <center>
        <h1>Sign here</h1>
      </center>
      <div className="vw-50 vh-100 ">
        <div className="bg-white w-100 h-50 bg-white border rounded-lg">
          <SignatureCanvas minWidth={2} maxWidth={5} ref={signatureRef} dotSize={3} canvasProps={{ className: "w-100 h-100" }} />
        </div>
      </div>
      <div className="fixed-bottom bg-light">
        <div className="d-flex">
          <div className="w-25 d-grid  gap-2 m-2">
            <Button
              disabled={saving}
              style={{ height: "60px" }}
              variant="light"
              className="fw-bold"
              onClick={() => {
                signatureRef.current!.clear();
              }}
            >
              Clear
            </Button>
          </div>
          <div className="w-75 d-grid gap-2 m-2">
            <Button disabled={saving} style={{ height: "60px" }} className="fw-bold" variant="dark" onClick={savePressed}>
              {saving ? "Saving..." : "Save signature"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function ThankYouComponent() {
  return (
    <Row className="justify-content-center pt-5 pb-5 text-center">
      <Col xs={10}>
        <h3>Thank you for submitting your info.</h3>
        You can now close your browser window.
      </Col>
    </Row>
  );
}