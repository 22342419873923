import { createSlice } from "@reduxjs/toolkit";
import { AviTracerApi } from "../avitracerApi";
import { Crew } from "../models/crew";
import { createAppAsyncThunk } from "./hooks";
import { RootState } from "./store";

interface CrewState {
    allCrew: Crew[],
    status: "idle" | "success"
    loading: boolean
}

const initialState: CrewState = {
    allCrew: [],
    status: "idle",
    loading: false
}

export const getCrew = createAppAsyncThunk(
    "crew/fetch",
    async () => {
        return AviTracerApi.getCrew()
    }
)

export const addCrew = createAppAsyncThunk(
    "crew/add",
    async (crew: Crew) => {
        return AviTracerApi.addCrew(crew)
    }
)

export const editCrew = createAppAsyncThunk(
    "crew/edit",
    async (crew: Crew) => {
        return AviTracerApi.editCrew(crew)
    }
)

export const archiveCrewWithId = createAppAsyncThunk(
    "crew/archiveWithId",
    async (id: Crew["id"]) => {
        return AviTracerApi.archiveCrew(id)
    }
)

export const inviteCrew = createAppAsyncThunk(
    "crew/invite",
    async (crew: Crew) => {
        return AviTracerApi.inviteCrew(crew)
    }
)

export const crewSlice = createSlice({
    name: "crew",
    initialState,
    reducers:{
        resetCrewStateForOrganizationChange: (state) => {
            state.allCrew = []
            state.status = "idle"
            state.loading = false
        }
    }, extraReducers: (builder) => {
        builder.addCase(getCrew.fulfilled, (state, action) => {
            state.allCrew = action.payload
            state.status = "success"
            state.loading = false
        })
        builder.addCase(addCrew.fulfilled, (state, action) => {
            state.allCrew.push(action.payload)
        })  
        builder.addCase(editCrew.fulfilled, (state, action) => {
            const index = state.allCrew.findIndex((c) => c.id === action.payload.id)
            if (index === -1){
                state.allCrew.push(action.payload)
            }else{
                state.allCrew[index] = action.payload
            }
        })
        builder.addCase(inviteCrew.fulfilled, (state, action) => {
            const index = state.allCrew.findIndex((c) => c.id === action.payload.id)
            if (index === -1){
                state.allCrew.push(action.payload)
            }else{
                state.allCrew[index] = action.payload
            }
        })
        builder.addCase(archiveCrewWithId.fulfilled, (state, action) => {
            state.allCrew = state.allCrew.filter((p) => p.id !== action.meta.arg!)
        })
        builder.addCase(getCrew.pending, (state) => {
            state.loading = true
        })
    }
})

export const allCrewState = (state: RootState) => state.crew.allCrew

export const { resetCrewStateForOrganizationChange } = crewSlice.actions

export default crewSlice.reducer