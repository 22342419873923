import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import { auth } from './firebase';
import { useAppDispatch } from './store/hooks';
import { clearSelectedOrganization } from './store/organizationsSlice';
import LoadingComponent from './components/LoadingComponent';

function App() {
  const navigate = useNavigate();
  const [loadedAuth, setLoadedAuth] = useState(false)
  const dispatch = useAppDispatch();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user){
        dispatch(clearSelectedOrganization())
      }
      setLoadedAuth(true)
    })

  }, [dispatch, navigate]);

  return (
    <>
        {(loadedAuth) ? <Outlet /> : <LoadingComponent text='Loading BRIEF...'/>}
    </>
  );
}

export default App;
