import { useLoaderData, useNavigate } from "react-router-dom";
import { AviTracerApi } from "../../../avitracerApi";
import { useAppDispatch } from "../../../store/hooks";
import BaseFplPage from "../BaseFplPage";
import FlightPlan from "../../../models/flightPlan";
import { cancelFlightPlanFpl, delayFlightPlanFpl, fileFlightPlanFpl } from "../../../store/flightPlansSlice";

function FlightPlanFplPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loadedFlightPlan = useLoaderData() as FlightPlan;

  const getPdfDoc = async () => {
    const pdfData = await AviTracerApi.getFlightPlanPdfDoc(loadedFlightPlan.id!, "icaoFpl");
    return pdfData;
  };

  const fileFplUsingAviTracer = async () => {
    try {
      await dispatch(fileFlightPlanFpl(loadedFlightPlan.id!)).unwrap();
    } catch (e: any) {
      alert(e.message);
    }
  };

  const refileFpl = async () => {
    try {
      await AviTracerApi.refileFlightPlanFpl(loadedFlightPlan.id!);
    } catch (e: any) {
      alert(e.message);
    }
  };

  const chgFpl = async (f15: string, f18: string) => {
    try {
      await AviTracerApi.chgFlightPlanFpl(loadedFlightPlan.id!, f15, f18);
    } catch (e: any) {
      alert(e);
    }
  }

  const cancelFiledFpl = async () => {
    try {
      await dispatch(cancelFlightPlanFpl(loadedFlightPlan.id!)).unwrap();
    } catch (e: any) {
      alert(e.message);
    }
  };

  const delayFiledFpl = async (newTime: Date) => {
    try {
      const params = { flightPlanId: loadedFlightPlan.id!, newTime: newTime };

      await dispatch(delayFlightPlanFpl(params)).unwrap();
    } catch (e: any) {
      alert(e.message);
    }
  };

  const goBack = () => {
    navigate("/fpls");
  };

  return (
    <BaseFplPage
      data={{
        fplFile: loadedFlightPlan.fplFile,
        flightPlanPicDetails: loadedFlightPlan.picFplDetails,
        departureDateTime: new Date(loadedFlightPlan.departureDateTime),
        flightId: loadedFlightPlan.flightId ?? loadedFlightPlan.aircraftRegistration,
      }}
      getPdfDoc={getPdfDoc}
      fileFplUsingAviTracerAction={fileFplUsingAviTracer}
      refileFplAction={refileFpl}
      chgFplAction={chgFpl}
      cancelFiledFplAction={cancelFiledFpl}
      delayFiledFplAction={delayFiledFpl}
      goBack={goBack}
    />
  );
}

export default FlightPlanFplPage;
