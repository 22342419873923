import { Button, Container, Navbar } from "react-bootstrap"
import { Outlet } from "react-router-dom"
import { HeaderLogo } from "../../Header";


function ToolsFreeLayout() {
  return (
    <Container fluid="xl">
      <Navbar fixed="top" variant="light" expand="lg" className="d-flex justify-content-between bg-white">
        <Container>
          <HeaderLogo />
          <Button variant="dark" className="fw-bold" href="https://brief.avitracer.com/auth/register" target="_blank">
            Create your account
          </Button>
        </Container>
      </Navbar>
      <div style={{ height: "80px" }}></div>
      <Outlet />
    </Container>
  );
}

export default ToolsFreeLayout