import { LatLngTuple } from "leaflet";
import { readRemoteFile } from "react-papaparse";

export type CupPoint = {
    code: string,
    country: string,
    desc: string,
    elev: string,
    freq: string,
    lat: string,
    lon: string,
    name: string,
    rwdir: string,
    rwlen: string,
    style: string,
}

var allCupPoints: CupPoint[] = [] 

export const getCupPointCoords = (point: CupPoint) => {
    if (point.style === "heli") {
        return { lat: parseFloat(point.lat), lon: parseFloat(point.lon) }
    }
    const lat = parseFloat(point.lat.slice(0, 2)) + parseFloat(point.lat.slice(2, 7)) / 60;
    const lon = parseFloat(point.lon.slice(0, 3)) + parseFloat(point.lon.slice(3, 8)) / 60;

    return { lat, lon };
};

export const getAllCupPoints = async () => {
    if (allCupPoints.length === 0) {
        allCupPoints = await loadCupPoints();
    }
    return allCupPoints;
}

const loadCupPoints = async () => {
    return new Promise<CupPoint[]>((resolve, reject) => {
        readRemoteFile("/assets/brief/map/data/lg.cup", {
            download: true,
            header: true,
            complete: (results) => {
                const cupPoints = results.data as CupPoint[];
                const formattedCupPoints = cupPoints.map((point) => {
                    if ((typeForCupPoint(point) === "Airport" && point.code.length !== 4) || point.code.length === 0) {
                        point.code = point.name.toUpperCase()
                    }

                    return point;
                });
                resolve(formattedCupPoints);
            },
            error: (err) => {
                reject(err);
            }
        });
    });
}

const assetsPath = "/assets/brief/map/icons/";
export const iconUrlForCupPoint = (point: CupPoint) => {
    const type = typeForCupPoint(point);
    if (type === "Airport") {
        return assetsPath + "marker_airport.png";
    } else if (type === "NDB") {
        return assetsPath + "marker_ndb.png";
    } else if (type === "VOR") {
        return assetsPath + "marker_vor.png";
    } else if (type === "Heliport") {
        return assetsPath + "marker_heli.png";
    }
    return assetsPath + "marker_point.png";
};

export type CupPointType = "Airport" | "NDB" | "VOR" | "Heliport" | "Waypoint";
export const typeForCupPoint = (point: CupPoint): CupPointType => {
    if (point.style === "5" || point.style === "2") {
        return "Airport";
    } else if (point.style === "10") {
        return "NDB";
    } else if (point.style === "9") {
        return "VOR";
    } else if (point.style === "heli") {
        return "Heliport";
    }
    return "Waypoint";
};

export const runwayDirectionTextForCupPoint = (point: CupPoint) => {
    const deg = parseInt(point.rwdir);
    const dir = Math.abs(Math.round(deg / 10));
    return `${dir.toString().padStart(2, "0")}/${dir + 18}`;
};

export const getLatLngTupleForCupPoint = (point: CupPoint) => {
    if (point.style === "heli") {
        return [parseFloat(point.lat), parseFloat(point.lon)] as LatLngTuple;
    }
    const lat = parseFloat(point.lat.slice(0, 2)) + parseFloat(point.lat.slice(2, 7)) / 60;
    const lon = parseFloat(point.lon.slice(0, 3)) + parseFloat(point.lon.slice(3, 8)) / 60;

    return [lat, lon] as LatLngTuple;
}

export const getCupPointCoordsForLatLngTuple = (latLng: LatLngTuple) => {
    const lat = latLng[0];
    const lon = latLng[1];
    const latDeg = Math.floor(lat);
    const latMin = Math.round((lat - latDeg) * 60 * 1000) / 1000;
    const lonDeg = Math.floor(lon);
    const lonMin = Math.round((lon - lonDeg) * 60 * 1000) / 1000;
    return {
        lat: `${latDeg.toString().padStart(2, "0")}${latMin.toString().padStart(7, "0")}`,
        lon: `${lonDeg.toString().padStart(3, "0")}${lonMin.toString().padStart(7, "0")}`
    }
}