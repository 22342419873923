import { flexRender, useReactTable } from "@tanstack/react-table";
import { createColumnHelper, getCoreRowModel } from "@tanstack/table-core";
import { Alert, Button, Col, Row, Table } from "react-bootstrap";
import { RoutePoint } from "../../models/route";
import { EntityDetailsDisplayInfoFieldIntermediateStop } from "./EntityDetailsDisplayInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSquareCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { useEffect, useState } from "react";
import { Control, FieldError, FieldValues, useController } from "react-hook-form";
import InputBox from "../InputBox/InputBox";

interface EntityIntermediateStopsProps<Entity extends FieldValues> {
  displayInfoInput: EntityDetailsDisplayInfoFieldIntermediateStop<Entity>;
  setSideAlertContent: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
  control: Control<Entity, any>
  edittedEntity: Entity;
}

function EntityIntermediateStops<Entity extends FieldValues>({ displayInfoInput, setSideAlertContent, control, edittedEntity}: EntityIntermediateStopsProps<Entity>) {
  const {
    field,
    formState: { isSubmitting, isValid },
  } = useController({
    name: displayInfoInput.key,
    control,
  });

  const allFixes = edittedEntity[displayInfoInput.dataKey] as RoutePoint[];
  const isDisabled = (isValid && isSubmitting) || (displayInfoInput.disabled ?? false)

  const getFixesNamesValidateRules =  () => {
    var v: any = {};

    (allFixes ?? []).forEach((_,index) => {
      v[`F-${index}`] = (rp: RoutePoint[]) => {
        if (!rp[index].name.match(/^([0-9]|[ ]|[A-Z]){1,50}$/g)) {
          return "Invalid characters. Allowed characters: A-Z, 0-9"
        }
        return true;
      };
    });

    return v;
  };

  const {
    field: {onChange: fixesOnChange},
    fieldState: {error}
  } = useController({
    name: displayInfoInput.dataKey,
    control,
    rules: {
      validate: getFixesNamesValidateRules(),
    }
  })
  
  return (
    <>
      {!isDisabled && (
        <Alert variant="warning">
          After creating a Route, it's not possible to edit the names of the Waypoints.
          <div>Please ensure that the names listed below are accurate and can be displayed in both the Flight Plan and Briefing Pack.</div>
        </Alert>
      )}
      <ListIntermediateStopsTable
        value={field.value}
        setSideAlertContent={setSideAlertContent}
        onChange={field.onChange}
        fixesOnChange={fixesOnChange}
        displayInfoInput={displayInfoInput}
        allFixes={allFixes}
        isDisabled={isDisabled}
        error={error}
      />
    </>
  );
}
  
export default EntityIntermediateStops;

interface ListIntermediateStopsTableProps<Entity extends FieldValues> {
  displayInfoInput: EntityDetailsDisplayInfoFieldIntermediateStop<Entity>;
  setSideAlertContent: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
  allFixes: RoutePoint[];
  value: RoutePoint[];
  onChange: (newValue: RoutePoint[]) => void;
  fixesOnChange: (newValue: RoutePoint[]) => void;
  isDisabled: boolean;
  error: FieldError | undefined
}
  
function ListIntermediateStopsTable<Entity extends FieldValues>({displayInfoInput, setSideAlertContent, value, onChange, fixesOnChange, allFixes, isDisabled, error}: ListIntermediateStopsTableProps<Entity>) {
  type RoutePointStop = { isStop: boolean } & RoutePoint;

  const columnHelper = createColumnHelper<RoutePointStop>();

  const allStops: RoutePoint[] = (value as RoutePoint[]) ?? [];

  const initialData = allFixes.map((routePoint: RoutePoint) => {
    return {
      ...routePoint,
      isStop: allStops.find((stop) => stop.name === routePoint.name) !== undefined,
    };
  });

  const [data, setData] = useState(initialData);

  useEffect(() => {
    fixesOnChange(data)
  }, [data, fixesOnChange])

  const columns = [
    columnHelper.accessor("isStop", {
      header: "Int. Stop",
      cell: (info) => {
        if (info.getValue()) {
          return <FontAwesomeIcon icon={faSquareCheck} size={"xl"} />;
        } else {
          return <FontAwesomeIcon icon={faSquare} size={"xl"} />;
        }
      },
    }),
    columnHelper.accessor("name", {
      header: "Name",
      cell: (info) => info.getValue(),
    }),
  ];

  if (!isDisabled){
    columns.push(
      columnHelper.display({
        id: "actions",
        header: "Actions",
        cell: (info) => (
          <>
            <Button
              variant="light"
              onClick={(e) => {
                e.stopPropagation();
                setSideAlertContent(
                  <EditFixNameModal
                    setSideAlertContent={setSideAlertContent}
                    oldName={info.row.original.name}
                    onSavePressed={(newName) => {
                      setData((prevData) => {
                        return prevData.map((routePoint) => {
                          if (routePoint.name === info.row.original.name) {
                            return { ...routePoint, name: newName };
                          } else {
                            return routePoint;
                          }
                        });
                      });
                    }}
                  />
                );
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </>
        ),
      })
    );
  }

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    const newStops = data.filter((routePoint) => routePoint.isStop).map((routePoint) => {
      const {isStop, ...routePointNoIsStop} = routePoint
      return routePointNoIsStop
    }) as RoutePoint[];

    onChange(newStops)
  },[data, displayInfoInput.key, onChange])

  const toggleSelection = (routePointStop: RoutePointStop) => {
    setData((prevData) => {
      return prevData.map((routePoint) => {
        if (routePoint.name === routePointStop.name) {
          return { ...routePoint, isStop: !routePoint.isStop };
        } else {
          return routePoint;
        }
      })
    })  
  };

  return (
    <>
      <Table hover={!isDisabled} style={{ borderCollapse: "separate", borderSpacing: "0" }}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  style={{
                    borderTop: "none",
                    position: "sticky",
                    top: "0",
                    zIndex: "1",
                    background: "white",
                  }}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} onClick={() => !isDisabled && toggleSelection(row.original)}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  style={{ verticalAlign: "middle" }}
                  className={cell.column.id === "name" && error?.type === `F-${row.index}` ? "text-danger fw-bold" : ""}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      {error && <small className="text-danger">{error.message}</small>}
    </>
  );
}

interface EditFixNameModalProps {
  setSideAlertContent: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
  oldName: string;
  onSavePressed: (newName: string) => void;
}

function EditFixNameModal({ setSideAlertContent, oldName, onSavePressed }: EditFixNameModalProps) {
  
  const [name, setName] = useState(oldName);

  const savePressed = () => {
    onSavePressed(name);
    setSideAlertContent(null);
  };

  return (
    <>
      <Row>
        <Col>
          <h2>Edit Fix Name</h2>
        </Col>
      </Row>
      <Row className="pt-3">
        <Col>
          <strong>You're only able to modify the name of a Fix in your Flight Plan</strong>, but please note that you cannot edit its
          coordinates directly. If you want to use a different Point, you'll need to reimport your route.
        </Col>
      </Row>
      <Row className="pt-5 pb-3">
        <Col>
          <InputBox type="text" value={name} name="Name" isDisabled={false} uppercase={true} onChange={(v) => setName(v)} />
        </Col>
      </Row>
      <Row className="pt-4 justify-content-center gy-2">
        <Col xs={12}>
          <div className="d-grid g-2">
            <Button onClick={savePressed} variant="dark">
              Save name
            </Button>
          </div>
        </Col>
        <Col xs={12}>
          <div className="d-grid g-2">
            <Button variant="light" onClick={() => setSideAlertContent(null)}>
              Cancel
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
}