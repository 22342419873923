import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { signInWithEmailAndPassword  } from 'firebase/auth';
import { auth, getFirebaseAuthErrorMessage } from '../../firebase';
import { FirebaseError } from "firebase/app";
import { useEffect } from "react";

function Login(){
  const navigate = useNavigate();

  const LoginFormSchema = z.object({
    email: z.string().email(),
    password: z.string().min(6, { message: "Password must be at least 6 characters" }),
  });

  type LoginFormSchemaType = z.infer<typeof LoginFormSchema>;

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<LoginFormSchemaType>({ resolver: zodResolver(LoginFormSchema) });

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has("redirectTo") && searchParams.get("redirectTo") === "/") {
      searchParams.delete("redirectTo");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const onLogin: SubmitHandler<LoginFormSchemaType> = async (data) => {
    await signInWithEmailAndPassword(auth, data.email, data.password)
      .then((user) => {})
      .catch((error: FirebaseError) => {
        setError("root.serverError", { message: getFirebaseAuthErrorMessage(error) });
      });
  };

  const errorMessage = [errors.email, errors.password, errors.root?.serverError]
    .filter((e) => e !== undefined)
    .map((e) => e!.message)
    .map((e) => (
      <>
        {e}
        <br />
      </>
    ));

  return (
    <>
      <Container className="pt-5">
        <Row>
          <Col xs={12}>
            <FreeToolsComponent />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={11} md={10} lg={6} className=" border rounded-lg  bg-white">
            <Row className="justify-content-center pt-5 pb-3">
              <Col xs={10}>
                <h2>Login</h2>
                <Form className="py-2" onSubmit={handleSubmit(onLogin)}>
                  <Form.Group className="" controlId="email">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control type="email" {...register("email")} disabled={isSubmitting}></Form.Control>
                  </Form.Group>
                  <Form.Group className="" controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" {...register("password")} disabled={isSubmitting}></Form.Control>
                  </Form.Group>

                  {errorMessage.length > 0 && (
                    <Alert variant="danger" className="mt-2">
                      {errorMessage}
                    </Alert>
                  )}
                  <div className="d-grid g-2 pt-4">
                    <Button type="submit" className="btn-dark" disabled={isSubmitting}>
                      {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status"></span> : "Login"}
                    </Button>
                  </div>
                </Form>
                <div className="pt-2">
                  <Link to={{pathname: "/auth/forgot-pass", search: window.location.search}} relative={"path"} className="text-dark">
                    Forgotten password?
                  </Link>
                </div>
              </Col>
              <Col xs={12}>
                <Row className="pt-4">
                  <Col xs={5}>
                    <hr />
                  </Col>
                  <Col xs={2}>
                    <center>or</center>
                  </Col>
                  <Col xs={5}>
                    <hr />
                  </Col>
                </Row>
              </Col>
              <Col xs={12}>
                <Row className="justify-content-center">
                  <Col xs={6}>
                    <div className="d-grid py-3">
                      <Button 
                      onClick={() => navigate({pathname: "/auth/register", search: window.location.search})}
                      variant="light">Create your account</Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login

function FreeToolsComponent() {
  return (
    <Row className="justify-content-center pb-3">
      <Col xs={11} md={10} lg={6} className="border rounded-lg bg-light">
        <Row className="justify-content-center py-2">
          <Col xs={10}>
            <Row className="align-items-center">
              <Col xs={4}>
                <img src="/assets/brief/tools/brief-freedocs.png" alt="Flight docs" className="w-100" />
              </Col>

              <Col xs={8}>
                <h2>Free tools</h2>
                <p>
                  Prepare the <strong>GenDec, Form731</strong> and <strong>ICAO Flight Plan</strong> of your next flight for free.
                </p>
                <div className="d-grid">
                  <Button variant="dark" href="/tools/flightdocs">
                    Prepare your next flight
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}