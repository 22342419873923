import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { Container, Row, Col, OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import { Outlet } from "react-router";
import ListEntitiesHeaderComponent from "../../../components/ListEntities/ListEntitiesHeaderComponent";
import ListEntitiesTable from "../../../components/ListEntities/ListEntitiesTable";
import { Route } from "../../../models/route";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getRoutes } from "../../../store/routesSlice";
import LoadingComponent from "../../../components/LoadingComponent";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";


interface ListRoutesPageProps {
  onRouteSelected?: (route: Route) => void;
}

function ListRoutesPage({onRouteSelected}: ListRoutesPageProps){
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { allRoutes, status, loading } = useAppSelector((state) => state.routes);
  const [searchValue, setSearchValue] = useState("")
  
  useEffect(() => {
    if (status === "idle") {
      dispatch(getRoutes());
    }
  }, [dispatch, status]);

  return (
    <Container fluid className="vh-100 px-0 px-md-2">
      <ListEntitiesHeaderComponent 
      actionBtn={{ title: "Create Route", onClick: () => {navigate("/routes/map")}}}
      title="Routes" 
      search={{ value: searchValue, setValue: setSearchValue }}
      />

      {loading ? (
        <LoadingComponent text="Loading routes..." />
      ) : (
        <Row className="overflow-auto" style={{ height: "calc(100% - 140px)" }}>
          <Col>
            <RoutesTable data={allRoutes} searchValue={searchValue} onRouteSelected={onRouteSelected} />
          </Col>
        </Row>
      )}

      <Outlet />
    </Container>
  );
}

export default ListRoutesPage;

interface RoutesTableProps {
    data: Route[];
    searchValue: string,
    onRouteSelected?: (route: Route) => void;
}

function RoutesTable({ data, searchValue, onRouteSelected }: RoutesTableProps) {
    const columnHelper = createColumnHelper<Route>()

    const isSmallerThanMd = useMediaQuery('(max-width: 768px)')

    var columns = [
      columnHelper.accessor("name", {
        header: () => "Name",
        cell: (info) => info.getValue(),
        sortingFn: "alphanumeric",
      }),
      columnHelper.accessor((row) => [row.departure.name].join(" "), {
        id: "departure",
        header: () => "Departure",
        cell: (info) => info.getValue(),
        sortingFn: "alphanumeric",
      }),
      columnHelper.accessor((row) => [row.destination.name].join(" "), {
        id: "destination",
        header: () => "Destination",
        cell: (info) => info.getValue(),
        sortingFn: "alphanumeric",
      })
    ];

    if (!isSmallerThanMd) {
      columns = columns.concat([
        columnHelper.accessor((row) => row.intermediateStops.map((w) => w.name).join(" "), {
          id: "intermediateStops",
          header: () => "Int. Stops",
          cell: (info) => info.getValue(),
          enableSorting: false,
        }),
        columnHelper.accessor((row) => `${row.waypoints?.length ?? 0}`, {
            id: "waypoints",
            header: () => "Waypoints",
            cell: (info) => <WaypointsCellContentComponent info={info} />,
            enableSorting: false,
          }),
          columnHelper.accessor((row) => `${row.aftnAddresses?.length ?? 0}`, {
            id: "aftnAddresses",
            header: () => "AFTNs",
            cell: (info) => <AftnAddressesCellContentComponent info={info} />,
            enableSorting: false,
          })
      ])
    }

    return (
        <ListEntitiesTable
            columns={columns}
            data={data}
            searchValue={searchValue}
            onRowClick={onRouteSelected}
        />
    )
}

function AftnAddressesCellContentComponent({info}: { info: CellContext<Route, string> }) {

  return (
    <OverlayTrigger
      placement="left"
      overlay={
        <Tooltip id="button-tooltip-2">
          <div className="p-1 px-3 fs-6">
            {(info.row.original.aftnAddresses === undefined || info.row.original.aftnAddresses.length === 0) && <div >No AFTN</div>}
            {info.row.original.aftnAddresses.map((aftn, index) => {
              return (
                <div key={index}>
                  {aftn}
                </div>
              );
            })}
          </div>
        </Tooltip>
      }
    >
      <Badge pill bg="secondary">
        {info.row.original.aftnAddresses?.length ?? 0}
      </Badge>
    </OverlayTrigger>
  );
}

function WaypointsCellContentComponent({info}: { info: CellContext<Route, string> }) {

  return (
    <OverlayTrigger
      placement="left"
      overlay={
        <Tooltip id="button-tooltip-2">
          <div className="p-1 px-3 fs-6">
            {(info.row.original.waypoints === undefined || info.row.original.waypoints.length === 0) && <div >No waypoints</div>}
            {info.row.original.waypoints?.map((p, index) => {
              return (
                <div key={index}>
                  {p.name}
                </div>
              );
            })}
          </div>
        </Tooltip>
      }
    >
      <Badge pill bg="secondary">
        {info.row.original.waypoints?.length ?? 0}
      </Badge>
    </OverlayTrigger>
  );
}
