import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from "react-bootstrap"
import { useAppSelector } from "../../store/hooks"
import { selectedOrganization } from "../../store/organizationsSlice"

interface SideMenuOrganizationComponentProps {
  presentSelectModal: () => void
}

function SideMenuOrganizationComponent({presentSelectModal}: SideMenuOrganizationComponentProps) {
  const selectedOrg = useAppSelector(selectedOrganization);

  return (
    <>
      <div className="bg-white rounded py-2 ps-3 pe-2" onClick={() => presentSelectModal()}>
        <Row className="justify-content-between g-0 align-items-center">
          <Col xs={11}>
            <Row>
              <Col className="font-weight-light text-muted">
                <small>Team</small>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="font-weight-bold">{selectedOrg?.name}</Col>
            </Row>
          </Col>
          <Col>
            <FontAwesomeIcon icon={faCaretDown} />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SideMenuOrganizationComponent
