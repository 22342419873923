import { Alert, Button, Col, Container, Modal, Row } from "react-bootstrap"
import InputBox from "../../../components/InputBox/InputBox";
import { useState } from "react";
import { AviTracerApi } from "../../../avitracerApi";
import { useAppDispatch } from "../../../store/hooks";
import { useNavigate } from "react-router-dom";
import { createAircraft, createAircraftFromShared } from "../../../store/aircraftSlice";

function AddAircraftPage() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [registration, setRegistration] = useState<string>("");
  const [aircraftIcaoType, setAircraftIcaoType] = useState<string>();
  const [isChecking, setIsChecking] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const [isCreating, setIsCreating] = useState<boolean>(false)

  const checkAircraftRegistration = async () => {
    setIsChecking(true);
    try {
      const { icaoType } = await AviTracerApi.getAircraftInfoByRegistration(registration);
      setAircraftIcaoType(icaoType);
    } catch (error: any) {
      setError(error);
    } finally {
      setIsChecking(false);
    }
  };

  const proceedPressed = () => {
    if (error === "NOT_FOUND") {
      createNewAircraft();
    } else if ((aircraftIcaoType?.length ?? 0) > 0){
      createAircraftFromSharedAction();
    }
  }

  const createAircraftFromSharedAction = async () => {
    setIsCreating(true);
    try {
      const aircraft = await dispatch(createAircraftFromShared(registration)).unwrap();
      navigate(`/aircraft/${aircraft.id}`);
    } catch (error: any) {
      setError(error.message);
    }finally {
      setIsCreating(false);
    }
  };

  const createNewAircraft = async () => {
    setIsCreating(true);
    try {
      const aircraft = await dispatch(createAircraft(registration)).unwrap();
      navigate(`/aircraft/${aircraft.id}`);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsCreating(false);
    }
  }

  return (
    <Modal show size="lg">
      <Modal.Body>
        <Container>
          <Row className="align-items-center">
            <Col>
              <h1>Add Aircraft</h1>
            </Col>
          </Row>
          <Row className="pt-2">
            <Col>
              <div>Please enter your aircraft registration.</div>
              <small>
                If the aircraft is already in our BRIEF system, it will be automatically imported into your account. Otherwise, you'll need
                to provide its details on the next page.
              </small>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col>
              <InputBox
                value={registration}
                isDisabled={isChecking || isCreating}
                name={"Registration (ex. SX-DEMO or D-ABCD)"}
                uppercase={true}
                type={"text"}
                onChange={(v) => {
                  setError(undefined);
                  setAircraftIcaoType(undefined);
                  setRegistration(v);
                }}
              />
            </Col>
          </Row>

          <Row className="pt-4 justify-content-center">
            {error ? (
              <>
                <Col xs={12}>
                  <Alert variant="danger">
                    {error === "NOT_FOUND" ? (
                      <>
                        <strong>Aircraft does not exist in BRIEF.</strong>
                        <br />
                        You can create {registration} by proceeding to the next page.
                      </>
                    ) : (
                      error
                    )}
                  </Alert>
                </Col>
              </>
            ) : (
              <>
                {aircraftIcaoType ? (
                  <>
                    <Col xs={12}>
                      <Alert variant="success">
                        <strong>Aircraft found in BRIEF!</strong>
                        <br />
                        {registration} is set as a <strong>{aircraftIcaoType}</strong> in our database.
                        <br />
                        If the above info are wrong, please contact us at <a href="mailto:support@avitracer.com">support@avitracer.com</a>.
                      </Alert>
                    </Col>
                  </>
                ) : (
                  <Col xs={"auto"}>
                    <Button disabled={isChecking} variant="dark" onClick={checkAircraftRegistration}>
                      {isChecking ? "Checking..." : "Check Registration"}
                    </Button>
                  </Col>
                )}
              </>
            )}
          </Row>
        </Container>

        <hr />
        <Container className="">
          <Row className="justify-content-center">
            <Col xs={"4"}>
              <Button variant="light" className="w-100 border" onClick={() => navigate("/aircraft")}>
                Cancel
              </Button>
            </Col>

            <Col xs={"4"}>
              <Button
                disabled={
                  isChecking || isCreating || registration.length === 0 || (error && error !== "NOT_FOUND") || (!error && !aircraftIcaoType)
                }
                variant="dark"
                className="w-100 "
                onClick={proceedPressed}
              >
                Proceeed
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default AddAircraftPage;