import { onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom"
import { auth } from "../../firebase";
import Header from "../../Header"

function UnauthenticatedLayout() {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams()

    useEffect(() => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          navigate({ pathname: "/", search: searchParams.toString() });
        }
      });
    }, [navigate, searchParams]);

  return (
    <>
      <div className="bg-white">
        <Header />
      </div>
      <Outlet />
    </>
  );
}

export default UnauthenticatedLayout