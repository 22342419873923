import { useEffect, useState } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { FieldValues } from "react-hook-form";
import { useMatches, useNavigate } from "react-router-dom";
import Flight from "../../../models/flight";
import { EntityDetailsDisplayInfoFieldWb } from "../EntityDetailsDisplayInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretRight, faCalculator } from '@fortawesome/free-solid-svg-icons'

interface EntityFlightWbComponentProps<Entity extends FieldValues> {
    displayInfoInput: EntityDetailsDisplayInfoFieldWb<Entity>;
    flight: Flight;
    handleSaveFlight: (flight: Flight) => Promise<Flight>;
    clearWbCalculation: () => void;
    skipWbCalculation: () => void;
}

function EntityFlightWbComponent<Entity extends FieldValues>({ displayInfoInput, flight, handleSaveFlight, clearWbCalculation, skipWbCalculation}: EntityFlightWbComponentProps<Entity>) {
    const navigate = useNavigate();
    const matches = useMatches();

    const saveFlightAndOpenWb = async () => {

      if (flight.id === undefined || flight.briefing!.status === "not-started" || flight.briefing!.status === "error") {
        const savedFlight = await handleSaveFlight(flight);
        if (matches[matches.length - 1].pathname.endsWith("add")){
          navigate(`../${savedFlight.id}/wb`)
          return
        }
      }
      navigate(`wb`)
    }

    const { aircraft, passengers, fuelOnBoard, pic, sic, deadheadCrew, studentPilot, route } = flight;
    
    const [okState, setOkState] = useState<{
      aircraft: Flight["aircraft"];
      passengers: Flight["passengers"];
      fuelOnBoard: Flight["fuelOnBoard"];
      pic: Flight["pic"];
      sic: Flight["sic"];
      deadheadCrew: Flight["deadheadCrew"];
      studentPilot: Flight["studentPilot"];
      route: Flight["route"];
    } | undefined>();

    useEffect(() => {
      if ((flight.wb?.status === "ok" || flight.wb?.status === "error") && okState === undefined) {
        setOkState({
          aircraft,
          passengers,
          fuelOnBoard,
          pic,
          sic,
          deadheadCrew,
          studentPilot,
          route,
        });
      } else if (
        okState !== undefined &&
        (flight.wb?.status === "ok" || flight.wb?.status === "error") &&
        (okState.aircraft?.id !== aircraft?.id ||
          !(
            okState.passengers.length === passengers.length &&
            okState.passengers.every((p, i) => p.id === passengers[i].id)
          ) ||
          !(
            okState.fuelOnBoard.length === fuelOnBoard.length &&
            okState.fuelOnBoard.every((f, i) =>
                f.mass === fuelOnBoard[i].mass &&
                f.station.id === fuelOnBoard[i].station.id
            )
          ) ||
          okState.pic?.id !== pic?.id ||
          okState.sic?.id !== sic?.id ||
          okState.deadheadCrew?.id !== deadheadCrew?.id ||
          okState.studentPilot?.id !== studentPilot?.id ||
          okState.route?.id !== route?.id)
      ) {
        clearWbCalculation();
        setOkState(undefined);
      }
    }, [aircraft, flight, fuelOnBoard, passengers, pic,  sic, deadheadCrew, studentPilot, route, okState, clearWbCalculation])

    const calculateWbBtnElement = (
      <Button variant="light" onClick={() => saveFlightAndOpenWb()}>
        <FontAwesomeIcon icon={faCalculator} color="black" className="pe-2" /> Calculate W&B{" "}
        <FontAwesomeIcon icon={faCaretRight} color="black" />
      </Button>
    )

    return (
      <>
        {aircraft.wb && aircraft.wb.profileName && aircraft.wb.emptyMass ? (
          <>
            <Row>
              <div className="fw-bold">
                {(flight.wb === undefined || flight.wb.status === "not-calculated") && (
                  <Alert variant={"danger"}>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Weight and Balance not calculated yet.</span>
                      {calculateWbBtnElement}
                    </div>
                  </Alert>
                )}
                {flight.wb?.status === "error" && (
                  <Alert variant={"danger"}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex flex-column justify-content-between">
                        {flight.wb.errors!.map((error, index) => (
                          <span key={index}>• {error}</span>
                        ))}
                      </div>
                      {calculateWbBtnElement}
                    </div>
                  </Alert>
                )}
                {flight.wb?.status === "ok" && (
                  <Alert variant={"success"}>
                    <div className="d-flex align-items-center justify-content-between">
                      Weight and Balance OK.
                      {calculateWbBtnElement}
                    </div>
                  </Alert>
                )}
              </div>
            </Row>
          </>
        ) : (
          <Alert variant={flight.wb?.status === "no-airctaft-data" ? "warning" : "danger"}>
            <div className="d-flex flex-column justify-content-between">
              <strong>Missing Aircraft's Weight and Balance info.</strong>
              {flight.wb?.status !== "no-airctaft-data" && (
                <Row className="d-flex align-items-center justify-content-between">
                  <Col>
                    <small>Please fill in the Profile Name and Empty Mass on Aircraft's page, or skip it for this flight.</small>
                  </Col>
                  <Col xs={"auto"}>
                    <Button variant="light" onClick={() => skipWbCalculation()}>
                      Skip W&B
                    </Button>
                  </Col>
                </Row>
              )}
            </div>
          </Alert>
        )}
      </>
    );
}

export default EntityFlightWbComponent