import { EntityDetailsDisplayInfoFieldDate, getValitationRules } from "./EntityDetailsDisplayInfo";
import FlatPicker from 'react-flatpickr'
import { Control, FieldValues, useController } from "react-hook-form";
import { useRef } from "react";
import moment from "moment";
import { Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from '@fortawesome/free-solid-svg-icons'

interface EntityDatePickerProps<Entity extends FieldValues> {
    displayInfoInput: EntityDetailsDisplayInfoFieldDate<Entity>;
    control: Control<Entity, any>
}

function EntityDatePicker<Entity extends FieldValues>({ displayInfoInput, control}: EntityDatePickerProps<Entity>) {

  //TODO: Autocloses when edit year or month
  
  const {
    field: { value, onChange, onBlur, ref },
    fieldState: { invalid, error },
    formState: { isSubmitting, isValid },
  } = useController({
    name: displayInfoInput.key,
    control,
    rules: getValitationRules(displayInfoInput),
  });
  
    const utcOffset = (displayInfoInput.useUtc) ? moment(value).utcOffset() : 0;
    const entityValue = (value) ? moment(value).subtract(utcOffset, 'minutes').toDate() : undefined

    const isDisabled = (isValid && isSubmitting) || (displayInfoInput.disabled ?? false);
    const isInvalidClass = invalid ? " invalid" : "";

    const fp = useRef(null);
    return (
      <FlatPicker
      ref={fp}
        value={entityValue}
        onOpen={(s) => onBlur()}
        onChange={(s) =>
          onChange(moment(s[0]).add(utcOffset, 'minutes').toDate())
        }
        options={{
          ...displayInfoInput.flatpickrOptions,
          defaultDate: displayInfoInput.defaultValue,
          static: true,
        }}
        className="form-control bg-white"
        render={({ value, ...props }, refFlatPickr) => {
          return (
            <Row className="g-0">
              <Col>
                <div className={"input-container rounded-lg".concat(isInvalidClass)}>
                  <input
                    ref={(r) => {
                      if (r !== null) {
                        refFlatPickr(r);
                        ref(r);
                      }
                    }}
                    disabled={isDisabled}
                    style={{ backgroundColor: "#F7F7F7", width: "100%", cursor: (isDisabled) ? "auto" : "pointer" }}
                  />
                  <label className={value ? "filled" : ""}>{displayInfoInput.name}</label>
                </div>
                {error && <small className="text-danger">{error.message}</small>}
              </Col>
              {value && (
                <Col xs={2} className="ps-3">
                  <div className="input-container rounded-lg">
                    <Button
                      disabled={isDisabled}
                      variant="link"
                      onClick={() => {
                        onChange(null);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} color="black" />
                    </Button>
                  </div>
                </Col>
              )}
            </Row>
          );
        }}
      />
    );
}

export default EntityDatePicker;