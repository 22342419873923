import { LoaderFunction, useLoaderData, useLocation, useNavigate } from "react-router-dom"
import { AviTracerApi } from "../../../avitracerApi";
import { useAppDispatch } from "../../../store/hooks";
import FlightPlan from "../../../models/flightPlan";
import { createFlightPlan, deleteFlightPlan, editFlightPlan } from "../../../store/flightPlansSlice";
import EntityDetailsPage from "../../../components/EntityDetails/EntityDetailsPage";
import { EntityDisplayInfo } from "../../../components/EntityDetails/EntityDetailsDisplayInfo";

export const flightPlanLoader: LoaderFunction = async (params) => {
    return AviTracerApi.getFlightPlanWithId(params.params["fplId"]!);
}

function FlightPlanDetailsPage() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
  
    const location = useLocation()
    const quickAddFlightPlan = location.state?.flightPlan as FlightPlan
    

    const loadedFlightPlan = useLoaderData() as FlightPlan;

    const flightPlan: FlightPlan = loadedFlightPlan ?? quickAddFlightPlan ?? {};

    const handleSaveFlightPlan = async (flightPlan: FlightPlan) => {
        if (!flightPlan.id) {
            return await dispatch(createFlightPlan(flightPlan)).unwrap()
        }else{
            return await dispatch(editFlightPlan(flightPlan)).unwrap()
        }
    };

    const handleDeleteFlightPlan = async (flightPlan: FlightPlan) => {
        await dispatch(deleteFlightPlan(flightPlan)).unwrap()
    };

    const goBack = () => {
        navigate("/fpls")
    }

    return (
        <EntityDetailsPage
            entity={flightPlan}
            handleClose={goBack}
            displayInfo={EntityDisplayInfo.FlightPlan()}
            dispatchSaveEntity={handleSaveFlightPlan}
            dispatchDeleteEntity={handleDeleteFlightPlan}
        />
    )
}

export default FlightPlanDetailsPage