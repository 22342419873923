import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth, getFirebaseAuthErrorMessage } from '../../firebase';

function ForgotPass(){

    const ForgotPassFormSchema = z.object({
        email: z.string().email()
    });

    type ForgotPassFormSchemaType = z.infer<typeof ForgotPassFormSchema>;

    const {register, handleSubmit, setError, formState: {errors, isSubmitting}} = useForm<ForgotPassFormSchemaType>({resolver: zodResolver(ForgotPassFormSchema)})

    const onForgotPass: SubmitHandler<ForgotPassFormSchemaType> = async (data) => {
        
        await sendPasswordResetEmail(auth, data.email).then(( ) => {
            console.log("Sent");  
        }).catch((error) => {
            setError("root.serverError",{ message: getFirebaseAuthErrorMessage(error)})
        })
    };

    const errorMessage = [errors.email, errors.root?.serverError].filter((e) => e !== undefined).map((e) => e!.message).map((e) => 
        <> {e}<br/> </>
    )

    return (
      <Container className="pt-5">
        <Row className="justify-content-center">
          <Col xs={11} md={10} lg={6} className="border rounded-lg bg-white">
            <Row className="justify-content-center py-5">
              <Col xs={10}>
                <h2>Forgot Password</h2>
                <Form className="pt-2" onSubmit={handleSubmit(onForgotPass)}>
                  <Form.Group className="" controlId="email">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control {...register("email")} disabled={isSubmitting}></Form.Control>
                  </Form.Group>
                  {errorMessage.length > 0 && (
                    <Alert variant="danger" className="pt-2">
                      {errorMessage}
                    </Alert>
                  )}
                  <div className="d-grid g-2 pt-4">
                    <Button type="submit" className="btn-dark" disabled={isSubmitting}>
                      {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status"></span> : "Submit"}
                    </Button>
                  </div>
                </Form>
                <div className="pt-2">
                  <Link
                    to={{
                      pathname: "/auth/login",
                      search: window.location.search,
                    }}
                    className="text-dark"
                  >
                    Back to Login
                  </Link>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
}

export default ForgotPass