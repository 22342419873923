
export default AirctaftSarEquipment

type AirctaftSarEquipment = {
  survivalEquipment: {
    general: boolean,
    polar: boolean,
    desert: boolean,
    maritime: boolean,
    jungle: boolean
  },
  aircraftColour: string,
  lifejackets: {
    general: boolean,
    light: boolean,
    fluores: boolean,
    uhf: boolean,
    vhf: boolean
  },
  emergencyRadio: {
    elba: boolean,
    vhf: boolean,
    uhf: boolean
  },
  dinghies: {
    number: number,
    capacity: number,
    colour: string,
    cover: boolean
  },
  remarks: string,
}


export const emptyAircraftSarEquipment: AirctaftSarEquipment = {
  survivalEquipment: {
    general: false,
    polar: false,
    desert: false,
    maritime: false,
    jungle: false
  },
  aircraftColour: "",
  lifejackets: {
    general: false,
    light: false,
    fluores: false,
    uhf: false,
    vhf: false
  },
  emergencyRadio: {
    elba: false,
    vhf: false,
    uhf: false
  },
  dinghies: {
    number: 0,
    capacity: 0,
    colour: "",
    cover: false
  },
  remarks: "",
}


export function getFormattedAircraftSaRString(sar: AirctaftSarEquipment): string | never {
  if (!sar){
    return ""
  }
  var sarTokens: string[] = []
  if (sar.survivalEquipment && sar.survivalEquipment.general) {
    const eq = sar.survivalEquipment
    var surEqStr = "S/"
    if (eq.polar) {
      surEqStr += "P"
    }
    if (eq.desert) {
      surEqStr += "D"
    }
    if (eq.maritime) {
      surEqStr += "M"
    }
    if (eq.jungle) {
      surEqStr += "J"
    }
    sarTokens.push(surEqStr)
  }

  if (!sar.aircraftColour) {
    throw new Error("You need to fill in Aircraft Colour.")
  } else {
    const aValue = `A/${sar.aircraftColour}`
    testValue(aValue, /^A\/([0-9]|[ '()+,=?./]|[A-Z]){1,50}$/, "Invalid Aircraft Colour format. Expected format: digits, spaces, punctuation marks, and capital letters, up to a maximum of 50 characters.")
    sarTokens.push(aValue)
  }

  if (sar.lifejackets.general) {
    const lifejackets = sar.lifejackets
    var jackersStr = "J/"
    if (lifejackets.light) {
      jackersStr += "L"
    }
    if (lifejackets.fluores) {
      jackersStr += "F"
    }
    if (lifejackets.uhf) {
      jackersStr += "U"
    }
    if (lifejackets.vhf) {
      jackersStr += "V"
    }
    sarTokens.push(jackersStr)
  }

  if (sar.emergencyRadio.elba || sar.emergencyRadio.vhf || sar.emergencyRadio.uhf) {
    const radio = sar.emergencyRadio
    var radioStr = "R/"
    if (radio.elba) {
      radioStr += "E"
    }
    if (radio.vhf) {
      radioStr += "V"
    }
    if (radio.uhf) {
      radioStr += "U"
    }

    if (radioStr === "R/") {
      throw new Error("Need to define at least one from 'ELBA', 'VHF', 'UHF' in for the Emergency Radio.")
    }

    sarTokens.push(radioStr)
  }else{
    throw new Error("Need to define at least one from 'ELBA', 'VHF', 'UHF' in for the Emergency Radio.")
  }

  if (sar.dinghies.number || sar.dinghies.capacity) {
    const d = sar.dinghies
    if (!d.number) {
      throw new Error("Need to define the number of dinghies.")
    }
    if (!d.capacity) {
      throw new Error("Need to define the capacity of dinghies.")
    }

    if (d.number < 0 || d.number > 99) {
      throw new Error("Invalid dinghies number. Must be between 0 and 99.")
    }
    if (d.capacity < 0 || d.capacity > 999) {
      throw new Error("Invalid dinghies capacity. Must be between 0 and 999.")
    }

    var dValue = `D/${d.number} ${d.capacity}`
    if (d.cover) {
      dValue += " C"
    }
    if (d.colour) {
      testValue(d.colour, /^([0-9]|[ '()+,=?./]|[A-Z]){1,50}$/, "Invalid dinghies colour format. Expected format: digits, spaces, punctuation marks, and capital letters, up to a maximum of 50 characters.")
      dValue += ` ${d.colour}`
    }
    sarTokens.push(dValue)
  }

  if (sar.remarks) {
    testValue(sar.remarks, /^([0-9]|[ '()+,=?./]|[A-Z]){1,100}$/, "Invalid SAR remarks format. Expected format: digits, spaces, punctuation marks, and capital letters, up to a maximum of 100 characters.")
    sarTokens.push(`N/${sar.remarks}`)
  }

  return sarTokens.join(" ")
}

function testValue(value: string, regex: RegExp, msg: string): void | never {
  if (!regex.test(value)) {
    throw new Error(msg);
  }
}
