import { useLoaderData, useNavigate } from "react-router-dom";
import Flight from "../../../models/flight";
import { Badge, Button, Offcanvas, Row } from "react-bootstrap";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleUser } from '@fortawesome/free-solid-svg-icons'
import { getPassengerFullName } from "../../../models/passenger";

function FlightEmbarkationStatusPage() {
  const navigate = useNavigate();

  const flight = useLoaderData() as Flight;

  const [show, setShow] = useState(true);

  const goBack = () => {
    navigate("/flights");
  };

  return (
    <Offcanvas backdrop="static" show={show} onExited={goBack} placement={"end"} className="offcanvas-size-lg">
      <Button variant="close" className="position-absolute top-0 end-0 p-4" onClick={() => setShow(false)} />
      <Offcanvas.Body>
        <h2>Embarkation Status</h2>
        {flight.passengers.length === 0 && <div>The Flight has no passengers.</div>}
        {flight.passengers.length > 0 && (
          <Row className="py-2 pt-3">
            {flight.passengers.map((passenger) => {
              return (
                <div key={passenger.id!}>
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="d-flex align-items-center py-2 my-2">
                        <FontAwesomeIcon size="2x" color="#9B9B9B" icon={faCircleUser} className="pe-2" />
                        <span className="align-middle">{getPassengerFullName(passenger)}</span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      {flight.checkIns?.[passenger.id!] !== undefined && <Badge bg={"success"}>Boarded</Badge>}
                    </div>
                  </div>
                  <hr className="m-0" />
                </div>
              );
            })}
          </Row>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default FlightEmbarkationStatusPage;