import { Passenger } from "../../../models/passenger"
import { useAppDispatch } from "../../../store/hooks"
import { archivePassengerWithId, savePassenger } from "../../../store/passengersSlice"
import EntityDetailsPage from "../../../components/EntityDetails/EntityDetailsPage"
import { EntityDisplayInfo } from "../../../components/EntityDetails/EntityDetailsDisplayInfo"
import { useLoaderData, useNavigate } from "react-router"
import { LoaderFunction } from "@remix-run/router"
import { AviTracerApi } from "../../../avitracerApi"


export const passengerLoader: LoaderFunction = async (params) => {
  if (params.params["passengerId"]) {
      return AviTracerApi.getPassengerWithId(params.params["passengerId"]!);
  }
  return null;
};


function PassengerDetailsPage(){
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const loadedPassenger = useLoaderData() as Passenger;

  var passenger: Passenger = loadedPassenger ?? {}

  const handleSavePassenger = async (newPassenger: Passenger) => {
    return await dispatch(savePassenger(newPassenger)).unwrap()
  }

  const handleArchivePassenger = async (passenger: Passenger) => {
    if (passenger.id){
      return await dispatch(archivePassengerWithId(passenger.id)).unwrap()
    }
  }

  const goBack = () => {
    navigate("/passengers")
  }
  
  return (
    <EntityDetailsPage
      entity={passenger}
      handleClose={goBack}
      displayInfo={EntityDisplayInfo.Passenger()}
      dispatchSaveEntity={handleSavePassenger}
      dispatchDeleteEntity={handleArchivePassenger}
    />
  );
}

export default PassengerDetailsPage