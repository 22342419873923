import { Badge, Button, Col, Container, Dropdown, Offcanvas, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import FlightPlan, { getDisplayableTextFromFlightPlanPoint } from "../../../models/flightPlan"
import ListEntitiesHeaderComponent from "../../../components/ListEntities/ListEntitiesHeaderComponent"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { Outlet, useMatch, useNavigate, useSearchParams } from "react-router-dom"
import LoadingComponent from "../../../components/LoadingComponent"
import { createColumnHelper } from "@tanstack/react-table"
import ListEntitiesTable from "../../../components/ListEntities/ListEntitiesTable"
import moment from "moment"
import { useEffect, useState } from "react"
import { getFlightPlans, getFlightPlansInRange } from "../../../store/flightPlansSlice"
import FlatPicker from 'react-flatpickr'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarDays, faCircleDown, faXmark } from '@fortawesome/free-solid-svg-icons'
import { Player } from "@lottiefiles/react-lottie-player";
import ListFlightsTabsComponent from "../flights/ListFlightsTabsComponent"
import { AviTracerApi } from "../../../avitracerApi"
import { PassengersCellContentComponent } from "../flights/ListFlighsPage"
import { selectedOrganization } from "../../../store/organizationsSlice"
import { Organization } from "../../../models/organization"
import { getPassengerLastNameWithInitials } from "../../../models/passenger"

function ListFlightPlansPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const match = useMatch("/fpls");

  const [archivedRange, setArchivedRange] = useState<{ from: Date; to: Date }>({
    from: searchParams.has("from")
      ? moment(searchParams.get("from") as string)
          .utc()
          .toDate()
      : moment().subtract(10, "day").toDate(),
    to: searchParams.has("to")
      ? moment(searchParams.get("to") as string)
          .utc()
          .toDate()
      : moment().subtract(1, "day").toDate(),
  });

  const [selectedTab, setSelectedTab] = useState<"today" | "tomorrow" | "upcoming" | "archived">(
    (searchParams.get("tab") as "today" | "tomorrow" | "upcoming" | "archived") || "today"
  );

  const { allFlightPlans, status, loading } = useAppSelector((state) => state.flightPlans);
  const [filteredFlightPlans, setFilteredFlightPlans] = useState<FlightPlan[]>([]);

  useEffect(() => {
    if (status === "idle") {
      dispatch(getFlightPlans());
    }
  }, [dispatch, status]);

  useEffect(() => {
    if (match) {
      if (selectedTab === "today") {
        setSearchParams({}, { replace: true });
      } else if (selectedTab === "tomorrow") {
        setSearchParams(
          {
            tab: "tomorrow",
          },
          { replace: true }
        );
      } else if (selectedTab === "upcoming") {
        setSearchParams(
          {
            tab: "upcoming",
          },
          { replace: true }
        );
      } else if (selectedTab === "archived") {
        setSearchParams(
          {
            tab: "archived",
            from: moment(archivedRange.from).format("Y-M-D"),
            to: moment(archivedRange.to).format("Y-M-D"),
          },
          { replace: true }
        );
      }
    }
  }, [selectedTab, archivedRange, setSearchParams, match]);

  useEffect(() => {
    if (selectedTab === "archived") {
      const getFlightPlansInArchivedRange = async () => {
        await dispatch(getFlightPlansInRange(archivedRange)).unwrap();
      };
      getFlightPlansInArchivedRange();
    }
  }, [dispatch, archivedRange, selectedTab]);

  useEffect(() => {
    var from: Date;
    var to: Date;

    if (selectedTab === "today") {
      from = moment().utc().startOf("date").toDate();
      to = moment().utc().endOf("date").toDate();
    } else if (selectedTab === "tomorrow") {
      from = moment().utc().add(1, "day").startOf("date").toDate();
      to = moment().utc().add(1, "day").endOf("date").toDate();
    } else if (selectedTab === "upcoming") {
      from = moment().utc().add(1, "day").startOf("date").toDate();
      to = moment().utc().add(1, "year").endOf("date").toDate();
    } else if (selectedTab === "archived") {
      from = moment(archivedRange.from).utc().startOf("date").toDate();
      to = moment(archivedRange.to).utc().endOf("date").toDate();
    }
    setFilteredFlightPlans(
      allFlightPlans.filter((f) => {
        const currDate = new Date(f.departureDateTime);
        return currDate >= from && currDate <= to;
      })
    );
  }, [allFlightPlans, archivedRange, selectedTab]);

  return (
    <Container fluid className="vh-100 px-0 px-md-2">
      <ListEntitiesHeaderComponent actionBtn={{ title: "Create FPL", onClick: () => navigate("quickadd") }} title="Quick FPLs">
        <>
          {selectedTab === "archived" && (
            <FlatPicker
              options={{
                mode: "range",
                maxDate: moment().subtract(1, "day").toDate(),
              }}
              value={[archivedRange.from, archivedRange.to]}
              onChange={(dates) => {
                if (dates.length === 2) {
                  setArchivedRange({ from: moment(dates[0]).utc().toDate(), to: moment(dates[1]).utc().toDate() });
                }
              }}
              render={({ value, onChange }, ref) => (
                <div className="p-3 rounded-lg" style={{ backgroundColor: "#F7F7F7" }}>
                  <FontAwesomeIcon icon={faCalendarDays} />
                  <input ref={ref} value={""} onChange={(e) => {}} placeholder="Search" className="ps-3 borderless"></input>
                </div>
              )}
            />
          )}
        </>
      </ListEntitiesHeaderComponent>

      <ListFlightsTabsComponent selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

      {loading ? (
        <LoadingComponent text="Loading FPLs..." />
      ) : (
        <>
          {filteredFlightPlans.length === 0 ? (
            <NoFlightPlansComponent />
          ) : (
            <Row className="overflow-auto" style={{ height: "calc(100% - 186px)" }}>
              <Col>
                <FlightPlansTable data={filteredFlightPlans} />
              </Col>
            </Row>
          )}
        </>
      )}

      <Outlet context={{setSelectedTab}}/>
    </Container>
  );
}

export default ListFlightPlansPage;

interface FlightPlansTableProps {
  data: FlightPlan[];
}

function FlightPlansTable({ data }: FlightPlansTableProps) {
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<FlightPlan>();

  const selectedOrg = useAppSelector(selectedOrganization);
  
  const downloadPdfDoc = async (flightPlan: FlightPlan, docName: "icaoFpl" | "genDec" | "form731") => {
    const pdfData = await AviTracerApi.getFlightPlanPdfDoc(flightPlan.id!, docName)
    const pdfBlob = new Blob([pdfData], {type: 'application/pdf'});
    var pdfURL = window.URL.createObjectURL(pdfBlob);

    const link = document.createElement('a');
    link.download = (flightPlan.flightId ?? flightPlan.aircraftRegistration) + "-" + moment(flightPlan.departureDateTime).utc().format("DD-MM-YY-HHmm") + "-" + docName + '.pdf';
    link.href = pdfURL;
    link.target = '_blank';
    document.body.appendChild(link);
    
    link.click();
  }

  const fplFileStatusBadgeForFlightPlan = (flightPlan: FlightPlan, selectedOrg?: Organization) => {
    if (flightPlan.fplFile.status === "error") {
      return (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="button-tooltip-2">
              <div className="p-1 px-3">{flightPlan.fplFile.error}</div>
            </Tooltip>
          }
        >
          <Badge bg="danger">Error</Badge>
        </OverlayTrigger>
      );
    }else if (flightPlan.fplFile.status === "not-filed") {
      return <Badge bg="secondary" >Not filed</Badge>;
    }else if (flightPlan.fplFile.status === "ready-to-file") {
      if (selectedOrg?.billing.canFileFpl) {
        return <Badge bg="warning" text="dark">Ready to File</Badge>;
      }else{
        return <Badge bg="dark" text="light">No credits</Badge>;
      }
    }else if (flightPlan.fplFile.status === "filed") {
      return <Badge bg="success">Filed</Badge>;
    }else if (flightPlan.fplFile.status === "delayed") {
      return (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="button-tooltip-2">
              <div className="p-1 px-3">{moment(flightPlan.fplFile.newTime).utc().format("HH:mm") + " UTC"}</div>
            </Tooltip>
          }
        >
          <Badge bg="info" text="dark">Delayed</Badge>
        </OverlayTrigger>
      );
    }
    return <></>;
  }

  const columns = [
    columnHelper.accessor((row) => moment(row.departureDateTime).toDate(), {
      id: "departureDateTime",
      header: () => "Date & Time",
      sortingFn: "datetime",
      cell: (info) => (
        <>
          {moment(info.getValue()).utc().format("D/M/YY")} <strong>{moment(info.getValue()).utc().format("HH:mm")}</strong>
        </>
      ),
    }),
    columnHelper.accessor("aircraftRegistration", {
      header: () => "Aircraft",
      cell: (info) => {
        return (
          <div>
            {info.row.original.flightId && <strong>{info.row.original.flightId}</strong>}
            <div>
              {info.getValue()} ({info.row.original.aircraftTypeIcao})
            </div>
          </div>
        );  
      },
      sortingFn: "alphanumeric",
    }),
    columnHelper.accessor("picFplDetails", {
      header: () => "PIC",
      cell: (info) => info.getValue(),
      sortingFn: "alphanumeric",
    }),
    columnHelper.accessor("passengers", {
      header: () => "PAX",
      cell: (info) => {
        const passengers = info.row.original.passengers;
        const textValue = passengers.length > 0 ? getPassengerLastNameWithInitials(passengers[0]) : "No PAX"

        return (
          <PassengersCellContentComponent textValue={textValue} passengers={passengers} />
        )
      },
      sortingFn: "alphanumeric",
    }),
    columnHelper.display({
      id:"route",
      header: "Route",
      cell: (info) => {
        return getDisplayableRouteForFlightPlan(info.row.original, false);
      }
    }),

    columnHelper.accessor((row) => row.fplFile?.status.toLowerCase(), {
      id: "fplStatus",
      header: () => "Flight Plan",
      cell: (info) => fplFileStatusBadgeForFlightPlan(info.row.original, selectedOrg),
      sortingFn: (a, b) => {
        const statusOrder: FlightPlan["fplFile"]["status"][] = ["error", "ready-to-file", "filed", "delayed"];
        const aValue = a.original.fplFile?.status;
        const bValue = b.original.fplFile?.status;
        if (statusOrder.indexOf(aValue) < statusOrder.indexOf(bValue)) return -1;
        if (statusOrder.indexOf(aValue) > statusOrder.indexOf(bValue)) return 1;
        return 0;
      },
    }),
    columnHelper.display({
      id: "actions",
      enableSorting: false,
      cell: (info) => (
        <div className="d-flex justify-content-end">
          <Dropdown
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Dropdown.Toggle
              size="sm"
              variant="light"
              id="actions-dropdown"
              className="me-3"
              style={{ border: "#dfdfdf", borderWidth: "1px", borderStyle: "solid" }}
            >
              Actions
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Header>Flight Plan</Dropdown.Header>
              {info.row.original.fplFile.status !== "error" && (
                <Dropdown.Item onClick={() => navigate(`/fpls/${info.row.original.id!}/fpl`)}>Show Flight Plan</Dropdown.Item>
              )}
              <Dropdown.Item
                disabled={!(info.row.original.fplFile.status === "ready-to-file")}
                onClick={() => navigate(`/fpls/${info.row.original.id!}/fpl`)}
              >
                File
              </Dropdown.Item>
              <Dropdown.Item
                disabled={!(info.row.original.fplFile.status === "filed" || info.row.original.fplFile.status === "delayed")}
                onClick={() => navigate(`/fpls/${info.row.original.id!}/fpl?delay`)}
              >
                Delay
              </Dropdown.Item>
              <Dropdown.Item
                disabled={!(info.row.original.fplFile.status === "filed" || info.row.original.fplFile.status === "delayed")}
                onClick={() => navigate(`/fpls/${info.row.original.id!}/fpl?cancel`)}
              >
                Cancel
              </Dropdown.Item>
              <Dropdown.Divider />

              <Dropdown.Header>Flight Docs</Dropdown.Header>
              <Dropdown.Item
                disabled={info.row.original.fplFile.status === "error"}
                onClick={() => downloadPdfDoc(info.row.original, "icaoFpl")}
              >
                ICAO Plan
              </Dropdown.Item>
              <Dropdown.Item 
                disabled={info.row.original.pic === undefined}
                onClick={() => downloadPdfDoc(info.row.original, "genDec")}>GenDec{info.row.original.pic === undefined && " - Missing PIC"}</Dropdown.Item>
              <Dropdown.Item
                disabled={info.row.original.pic === undefined}
                onClick={() => downloadPdfDoc(info.row.original, "form731")}>Form 731 {info.row.original.pic === undefined && " - Missing PIC"}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
    }),
  ];

  const [showActionsOffcanvas, setShowActionsOffcanvas] = useState(false);
  const [offCanvasFlightPlan, setOffCanvasFlightPlan] = useState<FlightPlan | undefined>(undefined);

  const closeOffCanvasAndAction = (action: () => void) => {
    setShowActionsOffcanvas(false);
    action();
  }

  return (
    <>
      <ListEntitiesTable
        data={data}
        columns={columns}
        mobileCellElement={(flightPlan, onCellClick) =>
          ListFlightPlansMobileCell({
            flightPlan,
            selectedOrg: selectedOrg!,
            fplFileStatusBadgeForFlightPlan,
            onCellClick,
            showActionsOffcanvas,
            setShowActionsOffcanvas,
            setOffCanvasFlightPlan,
          })
        }
      />

      <Offcanvas show={showActionsOffcanvas} placement="bottom" className="offcanvas-vertical-size-xl bg-transparent" backdrop={"static"}>
        {offCanvasFlightPlan && (
          <>
            <Offcanvas.Header className="bg-transparent">
              <div className="d-flex justify-content-center w-100">
                <Button className="rounded-circle " variant="light" size="lg" onClick={() => setShowActionsOffcanvas(false)}>
                  <FontAwesomeIcon icon={faXmark} />
                </Button>
              </div>
            </Offcanvas.Header>
            <Offcanvas.Body className="bg-white">
              <Row className="gy-2 gx-2 justify-content-center">
                <Col xs={"auto"}>
                  <span className="fs-3 fw-medium">Flight Plan</span>
                </Col>
                {offCanvasFlightPlan.fplFile.status !== "error" && (
                  <Col xs={12}>
                    <div className="d-grid">
                      <Button
                        variant="dark"
                        onClick={() => closeOffCanvasAndAction(() => navigate(`/fpls/${offCanvasFlightPlan.id!}/fpl`))}
                      >
                        Show Flight Plan
                      </Button>
                    </div>
                  </Col>
                )}
                <Col xs={12}>
                  <div className="d-grid">
                    <Button
                      variant="dark"
                      disabled={!(offCanvasFlightPlan.fplFile.status === "ready-to-file")}
                      onClick={() => closeOffCanvasAndAction(() => navigate(`/fpls/${offCanvasFlightPlan.id!}/fpl`))}
                    >
                      File
                    </Button>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="d-grid">
                    <Button
                      variant="dark"
                      disabled={!(offCanvasFlightPlan.fplFile.status === "filed" || offCanvasFlightPlan.fplFile.status === "delayed")}
                      onClick={() => closeOffCanvasAndAction(() => navigate(`/fpls/${offCanvasFlightPlan.id!}/fpl?delay`))}
                    >
                      Delay
                    </Button>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="d-grid">
                    <Button
                      variant="dark"
                      disabled={!(offCanvasFlightPlan.fplFile.status === "filed" || offCanvasFlightPlan.fplFile.status === "delayed")}
                      onClick={() => closeOffCanvasAndAction(() => navigate(`/fpls/${offCanvasFlightPlan.id!}/fpl?cancel`))}
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className="gy-2 gx-2 justify-content-center pt-4">
                <Col xs={"auto"}>
                  <span className="fs-3 fw-medium">Flight Docs</span>
                </Col>

                <Col xs={12}>
                  <div className="d-grid">
                    <Button
                      variant="dark"
                      disabled={offCanvasFlightPlan.fplFile.status === "error"}
                      onClick={() => closeOffCanvasAndAction(() => downloadPdfDoc(offCanvasFlightPlan, "icaoFpl"))}
                    >
                      <FontAwesomeIcon icon={faCircleDown} /> ICAO Plan
                    </Button>
                  </div>
                </Col>

                <Col xs={6}>
                  <div className="d-grid">
                    <Button
                      variant="dark"
                      disabled={offCanvasFlightPlan.pic === undefined}
                      onClick={() => closeOffCanvasAndAction(() => downloadPdfDoc(offCanvasFlightPlan, "genDec"))}
                    >
                      <FontAwesomeIcon icon={faCircleDown} /> GenDec
                    </Button>
                  </div>
                </Col>

                <Col xs={6}>
                  <div className="d-grid">
                    <Button
                      variant="dark"
                      disabled={offCanvasFlightPlan.pic === undefined}
                      onClick={() => closeOffCanvasAndAction(() => downloadPdfDoc(offCanvasFlightPlan, "form731"))}
                    >
                      <FontAwesomeIcon icon={faCircleDown} /> Form 731
                    </Button>
                  </div>
                </Col>
              </Row>
            </Offcanvas.Body>
          </>
        )}
      </Offcanvas>
    </>
  );
}


interface ListFlightPlansMobileCellProps {
  flightPlan: FlightPlan;
  selectedOrg: Organization;
  fplFileStatusBadgeForFlightPlan: (flightPlan: FlightPlan, selectedOrg?: Organization) => JSX.Element;
  onCellClick: () => void;
  showActionsOffcanvas: boolean;
  setShowActionsOffcanvas: React.Dispatch<React.SetStateAction<boolean>>;
  setOffCanvasFlightPlan: React.Dispatch<React.SetStateAction<FlightPlan | undefined>>;
}

function ListFlightPlansMobileCell({
  flightPlan,
  selectedOrg,
  fplFileStatusBadgeForFlightPlan,
  onCellClick,
  showActionsOffcanvas,
  setShowActionsOffcanvas,
  setOffCanvasFlightPlan,
}: ListFlightPlansMobileCellProps) {
  return (
    <>
      <Row
        className="align-items-center"
        onClick={(e) => {
          e.stopPropagation();
          onCellClick();
        }}
      >
        <Col xs={8}>
          <Row>
            <Col>
              {
                <span className="fs-3">
                  <strong>{moment(flightPlan.departureDateTime).utc().format("D/M/YY")}</strong>
                  {" - "}
                  {moment(flightPlan.departureDateTime).utc().format("HH:mm")}
                </span>
              }
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>{flightPlan.aircraftRegistration}</strong>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>PIC:</strong> {flightPlan.picFplDetails}
            </Col>
          </Row>
          <Row>
            <Col>{getDisplayableRouteForFlightPlan(flightPlan, true)}</Col>
          </Row>
        </Col>
        <Col xs={4}>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setOffCanvasFlightPlan(flightPlan);
                setShowActionsOffcanvas(true);
              }}
              variant="light"
              className="mb-2"
              style={{ border: "#dfdfdf", borderWidth: "1px", borderStyle: "solid" }}
            >
              Actions
            </Button>
            <div>
              <center>
                <small>Flight Plan</small>
                <br />
                {fplFileStatusBadgeForFlightPlan(flightPlan, selectedOrg)}
              </center>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

function NoFlightPlansComponent() {
  return (
    <div className="d-flex flex-column align-items-center pt-5">
      <Player
        autoplay
        loop
        src="/assets/brief/lottie/no_flights.json"
        speed={1.5}
        background="transparent"
        style={{ height: "300px", width: "300px" }}
      />
      <strong>No Quick FPLs in this period</strong>
    </div>
  );
}

function getDisplayableRouteForFlightPlan(flightPlan: FlightPlan, oneline: boolean) {
  var pointNames: string[];
  pointNames = [
    getDisplayableTextFromFlightPlanPoint(flightPlan.departure), 
    getDisplayableTextFromFlightPlanPoint(flightPlan.destination)
  ];
  return (
    <>
      <strong>Custom{oneline && <>: </>}</strong>
      {oneline ? (
        <>{pointNames.join(" - ")}</>
      ) : (
        <>
          {pointNames.map((p) => {
            return <div key={p}>{p}</div>;
          })}
        </>
      )}
    </>
  );
}