import { createSlice } from "@reduxjs/toolkit";
import { AviTracerApi } from "../avitracerApi";
import { Route } from "../models/route";
import { createAppAsyncThunk } from "./hooks";

interface RoutesState {
    allRoutes: Route[],
    status: "idle" | "success"
    loading: boolean
}

const initialState: RoutesState = {
    allRoutes: [],
    status: "idle",
    loading: false
}

export const getRoutes = createAppAsyncThunk(
    "routes/fetch",
    async () => {
        return AviTracerApi.getRoutes()
    }
)

export const createRoute = createAppAsyncThunk(
    "routes/create",
    async (route: Route) => {
        return AviTracerApi.createRoute(route)
    }
)

export const editRoute = createAppAsyncThunk(
    "routes/edit",
    async (route: Route) => {
        return AviTracerApi.editRoute(route)
    }
)

export const archiveRouteWithId = createAppAsyncThunk(
    "routes/archive",
    async (routeId: string) => {
        return AviTracerApi.archiveRouteWithId(routeId)
    }
)

export const routesSlice = createSlice({
    name: "routes",
    initialState,
    reducers:{
        resetRoutesStateForOrganizationChange: (state) => {
            state.allRoutes = []
            state.status = "idle"
            state.loading = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getRoutes.fulfilled, (state, action) => {
            state.allRoutes = action.payload
            state.status = "success"
            state.loading = false
        })
        builder.addCase(editRoute.fulfilled, (state, action) => {
            const index = state.allRoutes.findIndex(route => route.id === action.payload.id)
            if (index === -1){
                state.allRoutes.push(action.payload)
            }else{
                state.allRoutes[index] = action.payload
            }
        })
        builder.addCase(archiveRouteWithId.fulfilled, (state, action) => {
            state.allRoutes = state.allRoutes.filter(route => route.id !== action.meta.arg!)
        })
        builder.addCase(createRoute.fulfilled, (state, action) => {
            state.allRoutes.push(action.payload)
        })
        builder.addCase(getRoutes.pending, (state, action) => {
            state.loading = true
        })
    }
})

export const { resetRoutesStateForOrganizationChange } = routesSlice.actions

export default routesSlice.reducer