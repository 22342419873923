import { LoaderFunction, redirect, useLoaderData } from "react-router-dom";
import LoadingComponent from "../../components/LoadingComponent";
import { AviTracerApi } from "../../avitracerApi";
import { Col, Row } from "react-bootstrap";

export const shortlinkExpanderLoader: LoaderFunction = async (params) => {
    
    const token = params.params.token;
    if (!token) {
        return "You link is invalid or has expired. Please contact your organization for a new link."
    };

    try {
        const {url} = await AviTracerApi.shortLinkExpandToFullUrl(token);
        return redirect(url);
    }catch(e) {
        return "You link is invalid or has expired. Please contact your organization for a new link."
    }
}

function ShortlinkExpanderPage() {

    const errorStr = useLoaderData() as string;

    return (
      <>
        {errorStr ? (
          <Row>
            <Col className="text-center">{errorStr}</Col>
          </Row>
        ) : (
          <LoadingComponent text="Loading..." />
        )}
      </>
    );
}

export default ShortlinkExpanderPage;