import { useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { EntityDetailsDisplayInfoFieldDropdown, getValitationRules } from "../EntityDetailsDisplayInfo";
import "../../../css/dropdown-black.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { Control, FieldValues, useController } from "react-hook-form";

interface EntityDropdownProps<Entity extends FieldValues> {
    displayInfoInput: EntityDetailsDisplayInfoFieldDropdown<Entity>;
    control: Control<Entity, any>
}

function EntityDropdown<Entity extends FieldValues>({displayInfoInput, control}: EntityDropdownProps<Entity>){

  const {
    field,
    fieldState: { invalid, error },
    formState: { isSubmitting, isValid },
  } = useController({
    name: displayInfoInput.key,
    control,
    rules: getValitationRules(displayInfoInput),
  });

  const currentEntityValue = field.value;
  var currentEntityLabel =
    displayInfoInput.options.find(
      (option) => option.value === currentEntityValue
    )?.label || "";

  const inputRef = useRef<HTMLInputElement>(null);
  field.ref(inputRef.current)

  const isDisabled = (isValid && isSubmitting) || (displayInfoInput.disabled ?? false)
  return (
    <>
      <div
        className={(invalid ? "invalid " : "") + "input-container rounded-lg "}
      >
        <Dropdown
          onSelect={(newValue) => {
            field.onChange(newValue);
          }}
          onToggle={(isOpen) => {
            if (isOpen) {
              field.onBlur();
            } else {
              setTimeout(() => {
                inputRef.current?.blur();
              }, 100);
            }
          }}
        >
          <Dropdown.Toggle
            ref={inputRef}
            as={"input"}
            readOnly
            disabled={isDisabled}
            className="bg-transparent w-100"
            value={currentEntityLabel}
            style={{ cursor: (isDisabled) ? "auto" : "pointer" }}
          />
          <Dropdown.Menu>
            {displayInfoInput.options.map(({ value, label }) => {
              return (
                <Dropdown.Item
                  key={value}
                  eventKey={value}
                  active={value === currentEntityValue}
                >
                  {label}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>

        <label className={currentEntityValue && "filled"}>
          {displayInfoInput.name}
        </label>
        <FontAwesomeIcon
          className="position-absolute "
          style={{ right: "16px", top: "50%", transform: "translateY(-50%)" }}
          icon={faCaretDown}
        />
      </div>
      {error && <small className="text-danger">{error.message}</small>}
    </>
  );
}

export default EntityDropdown