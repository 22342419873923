import { createSlice } from "@reduxjs/toolkit";
import { AviTracerApi } from "../avitracerApi";
import { Aircraft } from "../models/aircraft";
import { createAppAsyncThunk } from "./hooks";

interface AircraftState {
    allAircraft: Aircraft[],
    status: "idle" | "success"
    loading: boolean
}

const initialState: AircraftState = {
    allAircraft: [],
    status: "idle",
    loading: false
}

export const getAircraft = createAppAsyncThunk(
    "aircraft/getAircraft",
    async () => {
        return AviTracerApi.getAircraft()
    }
)

export const createAircraft = createAppAsyncThunk(
    "aircraft/createAircraft",
    async (registration: string) => {
        return AviTracerApi.createAircraft(registration)
    }
)

export const createAircraftFromShared = createAppAsyncThunk(
    "aircraft/createAircraftFromShared",
    async (registration: string) => {
        return AviTracerApi.createAircraftFromShared(registration)
    }
)

export const updateAircraft = createAppAsyncThunk(
    "aircraft/updateAircraft",
    async (aircraft: Aircraft) => {
        return AviTracerApi.updateAircraft(aircraft)
    }
)

export const aircraftSlice = createSlice({
    name: "aircraft",
    initialState,
    reducers: {
        resetAircraftStateForOrganizationChange: (state) => {
            state.allAircraft = []
            state.status = "idle"
            state.loading = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAircraft.fulfilled, (state, action) => {
            state.allAircraft = action.payload
            state.status = "success"
            state.loading = false
        })
        builder.addCase(createAircraft.fulfilled, (state, action) => {
            state.allAircraft.push(action.payload)
        })
        builder.addCase(createAircraftFromShared.fulfilled, (state, action) => {
            state.allAircraft.push(action.payload)
        })
        builder.addCase(updateAircraft.fulfilled, (state, action) => {
            const index = state.allAircraft.findIndex((a) => a.id === action.payload.id)
            if (index !== -1){
                state.allAircraft[index] = action.payload
            }
        })
        builder.addCase(getAircraft.pending, (state) => {
            state.loading = true
        })
    }
});

export const { resetAircraftStateForOrganizationChange } = aircraftSlice.actions

export default aircraftSlice.reducer