import { EntityDetailsDisplayInfo } from "../components/EntityDetails/EntityDetailsDisplayInfo"

export type UserData = {
    id?: string, //not used - Only to be used as Identifiable

    firstName: string,
    lastName: string,

    dob: Date,
    idPassportNumber: string
    idPassportCountryCode: string

    weight: number,
}

export type UserInvite = {
    id: string
    organizationName: string
    role: "admin" | "pilot"
    crew: UserData
}


export function getInvitedUserDataDisplayInfo(): EntityDetailsDisplayInfo<UserData> {
    return {
        entityName: "Crew",
        sections: [
            {
                sectionName: "Personal Info",
                sectionId: "personalInfo",
                inputs: [
                    {
                        name: "Last Name",
                        key: "lastName",
                        type: "text",
                        required: true
                    },
                    {
                        name: "First Name",
                        key: "firstName",
                        type: "text",
                        required: true
                    }
                ]
            },
            {
                sectionName: "General Declaration",
                sectionId: "genDec",
                inputs: [
                    {
                        name: "Date of Birth",
                        key: "dob",
                        type: "date"
                    },
                    {
                        name: "ID/Passport Number",
                        key: "idPassportNumber",
                        type: "text",
                    },
                    {
                        name: "ID/Passport Country Code",
                        key: "idPassportCountryCode",
                        type: "text",
                    }
                ]
            },
            {
                sectionName: "Weight & Balance",
                sectionId: "wb",
                inputs: [
                    {
                        name: "Weight (kg)",
                        key: "weight",
                        type: "number",
                        required: true
                    }
                ]
            }
        ]
    }
}