import React, { useEffect, useMemo, useState } from "react";
import { Alert, Button, Col, FormCheck, FormGroup, Row } from "react-bootstrap";
import { Control, FieldValues, useController } from "react-hook-form";
import AirctaftSarEquipment, { emptyAircraftSarEquipment, getFormattedAircraftSaRString } from "../../../models/aircraftSar";
import InputBox from "../../InputBox/InputBox";
import ReadOnlyInputBox from "../../InputBox/ReadonlyInputBox";
import { EntityDetailsDisplayInfoFieldAircraftSar } from "../EntityDetailsDisplayInfo";

interface EntityAircraftSarComponentProps<Entity extends FieldValues> {
    displayInfoInput: EntityDetailsDisplayInfoFieldAircraftSar<Entity>;
    control: Control<Entity, any>
    setModalContent: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
}


function EntityAircraftSarComponent<Entity extends FieldValues>({displayInfoInput, control, setModalContent}: EntityAircraftSarComponentProps<Entity>) {
  const {
    field: { value, onChange, ref },
    fieldState,
    formState: { isSubmitting, isValid },
  } = useController({
    name: displayInfoInput.key,
    control,
    rules: {
      validate: {
        required: (v) => {
          if (v === undefined) {
            return "Required";
          }
          try {
            const str = getFormattedAircraftSaRString(v as AirctaftSarEquipment);
            if (str === "") {
              return "Required";
            }
          } catch (error: any) {
            return error.message as string;
          }

          return true;
        },
      },
    },
  });

  const currValue = useMemo(() => {
    try {
      return getFormattedAircraftSaRString(value as AirctaftSarEquipment) ?? "";
    } catch (error) {
      return "";
    }
  }, [value]);

  return (
    <ReadOnlyInputBox
      inputRef={ref}
      isDisabled={(isValid && isSubmitting) || (displayInfoInput.disabled ?? false)}
      value={currValue}
      fieldState={fieldState}
      onClick={() => {
        setModalContent(
          <EditAircraftSarPage
            displayInfoInput={displayInfoInput}
            value={value as AirctaftSarEquipment}
            handleClose={() => setModalContent(null)}
            onChange={onChange}
          />
        );
      }}
      name={displayInfoInput.name}
    />
  );
}

export default EntityAircraftSarComponent

interface EditAircraftSarPageProps<Entity extends FieldValues> {
    displayInfoInput: EntityDetailsDisplayInfoFieldAircraftSar<Entity>;
    value: AirctaftSarEquipment;
    handleClose: () => void;
    onChange: (...event: any[]) => void
}

function EditAircraftSarPage<Entity extends FieldValues>({onChange, value, displayInfoInput, handleClose}: EditAircraftSarPageProps<Entity>){
  const [sar, setSar] = useState<AirctaftSarEquipment>(
    (value as AirctaftSarEquipment) ??
      emptyAircraftSarEquipment
  );

  const [showDinghies, setShowDinghies] = useState<boolean>(sar.dinghies.number > 0);
  
  const [sarTextError, setSarTextError] = useState<string>();

  const saveSarAndClose = () => {
    try {
      getFormattedAircraftSaRString(sar); //check if ok
      onChange(sar);
      handleClose();
    } catch (error) {
      setSarTextError((error as Error).message);
    }
  };

  const toggleDisplayDinghies = () => {
    if (showDinghies) {
      setSar((prevSar) => {
        return {
          ...prevSar,
          dinghies: {
            cover: false,
            capacity: 0,
            number: 0,
            colour: "",
          },
        };
      });
    }else{
      setSar((prevSar) => {
        return {
          ...prevSar,
          dinghies: {
            cover: false,
            capacity: 1,
            number: 1,
            colour: "",
          },
        };
      });
    }
    setShowDinghies(!showDinghies);
  }

  useEffect(() => {
    if (sar.survivalEquipment.general === false) {
      setSar((prevSar) => {
        return {
          ...prevSar,
          survivalEquipment: {
            general: false,
            polar: false,
            desert: false,
            maritime: false,
            jungle: false
          },
        };
      });
    }
  }, [sar.survivalEquipment.general])

  useEffect(() => {
    if (sar.lifejackets.general === false) {
      setSar((prevSar) => {
        return {
          ...prevSar,
          lifejackets: {
            general: false,
            light: false,
            fluores: false,
            uhf: false,
            vhf: false
          }
        }
      })
    }
  }, [sar.lifejackets.general])


  return (
    <>
      <Row className="p-4">
        <Col>
          {sarTextError && <Alert variant="danger">{sarTextError}</Alert>}
          <Row>
            <Col xs={12}>
              <FormCheck>
                <FormCheck.Input
                  type="checkbox"
                  checked={sar.survivalEquipment.general}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSar({
                      ...sar,
                      survivalEquipment: {
                        ...sar.survivalEquipment,
                        general: e.target.checked,
                      },
                    })
                  }
                />
                <FormCheck.Label>Survival Equipment</FormCheck.Label>
              </FormCheck>
            </Col>
            {sar.survivalEquipment.general && (
              <>
                <Col xs={6}>
                  <FormCheck>
                    <FormCheck.Input
                      type="checkbox"
                      checked={sar.survivalEquipment.polar}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setSar({
                          ...sar,
                          survivalEquipment: {
                            ...sar.survivalEquipment,
                            polar: e.target.checked,
                          },
                        });
                      }}
                    />
                    <FormCheck.Label>Polar</FormCheck.Label>
                  </FormCheck>
                </Col>
                <Col xs={6}>
                  <FormCheck>
                    <FormCheck.Input
                      type="checkbox"
                      checked={sar.survivalEquipment.desert}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setSar({
                          ...sar,
                          survivalEquipment: {
                            ...sar.survivalEquipment,
                            desert: e.target.checked,
                          },
                        });
                      }}
                    />
                    <FormCheck.Label>Desert</FormCheck.Label>
                  </FormCheck>
                </Col>
                <Col xs={6}>
                  <FormCheck>
                    <FormCheck.Input
                      type="checkbox"
                      checked={sar.survivalEquipment.maritime}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setSar({
                          ...sar,
                          survivalEquipment: {
                            ...sar.survivalEquipment,
                            maritime: e.target.checked,
                          },
                        });
                      }}
                    />
                    <FormCheck.Label>Maritime</FormCheck.Label>
                  </FormCheck>
                </Col>
                <Col xs={6}>
                  <FormCheck>
                    <FormCheck.Input
                      type="checkbox"
                      checked={sar.survivalEquipment.jungle}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setSar({
                          ...sar,
                          survivalEquipment: {
                            ...sar.survivalEquipment,
                            jungle: e.target.checked,
                          },
                        });
                      }}
                    />
                    <FormCheck.Label>Jungle</FormCheck.Label>
                  </FormCheck>
                </Col>
              </>
            )}
          </Row>
          <hr />

          <Row>
            <Col xs={12}>
              <FormCheck>
                <FormCheck.Input
                  type="checkbox"
                  checked={sar.lifejackets.general}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSar({
                      ...sar,
                      lifejackets: {
                        ...sar.lifejackets,
                        general: e.target.checked,
                      },
                    })
                  }
                />
                <FormCheck.Label>Life Jackets</FormCheck.Label>
              </FormCheck>
            </Col>
            {sar.lifejackets.general && (
              <>
                <Col xs={6}>
                  <FormCheck>
                    <FormCheck.Input
                      type="checkbox"
                      checked={sar.lifejackets.light}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSar({
                          ...sar,
                          lifejackets: {
                            ...sar.lifejackets,
                            light: e.target.checked,
                          },
                        })
                      }
                    />
                    <FormCheck.Label>Light</FormCheck.Label>
                  </FormCheck>
                </Col>
                <Col xs={6}>
                  <FormCheck>
                    <FormCheck.Input
                      type="checkbox"
                      checked={sar.lifejackets.fluores}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSar({
                          ...sar,
                          lifejackets: {
                            ...sar.lifejackets,
                            fluores: e.target.checked,
                          },
                        })
                      }
                    />
                    <FormCheck.Label>Fluores</FormCheck.Label>
                  </FormCheck>
                </Col>
                <Col xs={6}>
                  <FormCheck>
                    <FormCheck.Input
                      type="checkbox"
                      checked={sar.lifejackets.uhf}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSar({
                          ...sar,
                          lifejackets: {
                            ...sar.lifejackets,
                            uhf: e.target.checked,
                          },
                        })
                      }
                    />
                    <FormCheck.Label>UHF</FormCheck.Label>
                  </FormCheck>
                </Col>
                <Col xs={6}>
                  <FormCheck>
                    <FormCheck.Input
                      type="checkbox"
                      checked={sar.lifejackets.vhf}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSar({
                          ...sar,
                          lifejackets: {
                            ...sar.lifejackets,
                            vhf: e.target.checked,
                          },
                        })
                      }
                    />
                    <FormCheck.Label>VHF</FormCheck.Label>
                  </FormCheck>
                </Col>
              </>
            )}
          </Row>
          <hr />
          <Row>
            <Col xs={12}>
              <FormCheck>
                <FormCheck.Label>Emergency Radio</FormCheck.Label>
              </FormCheck>
            </Col>

            <Col xs={4}>
              <FormCheck>
                <FormCheck.Input
                  type="checkbox"
                  checked={sar.emergencyRadio.uhf}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSar({
                      ...sar,
                      emergencyRadio: {
                        ...sar.emergencyRadio,
                        uhf: e.target.checked,
                      },
                    })
                  }
                />
                <FormCheck.Label>UHF</FormCheck.Label>
              </FormCheck>
            </Col>
            <Col xs={4}>
              <FormCheck>
                <FormCheck.Input
                  type="checkbox"
                  checked={sar.emergencyRadio.vhf}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSar({
                      ...sar,
                      emergencyRadio: {
                        ...sar.emergencyRadio,
                        vhf: e.target.checked,
                      },
                    })
                  }
                />
                <FormCheck.Label>VHF</FormCheck.Label>
              </FormCheck>
            </Col>
            <Col xs={4}>
              <FormCheck>
                <FormCheck.Input
                  type="checkbox"
                  checked={sar.emergencyRadio.elba}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSar({
                      ...sar,
                      emergencyRadio: {
                        ...sar.emergencyRadio,
                        elba: e.target.checked,
                      },
                    })
                  }
                />
                <FormCheck.Label>ELBA</FormCheck.Label>
              </FormCheck>
            </Col>
          </Row>
          <hr />

          <Row className="gy-3">
            <Col xs={12} >
              <FormCheck>
                <FormCheck.Input
                  type="checkbox"
                  checked={showDinghies}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    toggleDisplayDinghies();
                  }}
                />
                <FormCheck.Label>Dinghies</FormCheck.Label>
              </FormCheck>
            </Col>
            {showDinghies && (
              <>
                <Col xs={5}>
                  <InputBox
                    type="number"
                    value={`${sar.dinghies.number ?? 1}`}
                    onChange={(v) =>
                      setSar({
                        ...sar,
                        dinghies: { ...sar.dinghies, number: v },
                      })
                    }
                    isDisabled={false}
                    name={"Number"}
                  />
                </Col>
                <Col xs={5}>
                  <InputBox
                    type="number"
                    value={`${sar.dinghies.capacity ?? 1}`}
                    onChange={(v) =>
                      setSar({
                        ...sar,
                        dinghies: { ...sar.dinghies, capacity: v },
                      })
                    }
                    isDisabled={false}
                    name={"Number"}
                  />
                </Col>
                <Col xs={2} className="text-center">
                  <FormGroup>
                    <Row>
                      <Col>
                        <label htmlFor="dinghiesCover">Cover</label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormCheck.Input
                          id="dinghiesCover"
                          className="position-static ml-0 mt-2"
                          checked={sar.dinghies.cover}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setSar({
                              ...sar,
                              dinghies: {
                                ...sar.dinghies,
                                cover: e.target.checked,
                              },
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup>
                    <InputBox
                      value={sar.dinghies.colour}
                      onChange={(v) => {
                        setSar({
                          ...sar,
                          dinghies: {
                            ...sar.dinghies,
                            colour: v,
                          },
                        });
                      }}
                      isDisabled={false}
                      name="Dinghies Colour"
                      uppercase
                    />
                  </FormGroup>
                </Col>
              </>
            )}
          </Row>
          <hr />

          <Row>
            <Col xs={12}>
              <FormGroup>
                <InputBox
                  value={sar.aircraftColour}
                  onChange={(v) => setSar({ ...sar, aircraftColour: v })}
                  isDisabled={false}
                  name="Aircraft Colour and Markings"
                  uppercase
                />
              </FormGroup>
            </Col>
          </Row>
          <hr />

          <Row>
            <Col xs={12}>
              <FormGroup>
                <InputBox
                  value={sar.remarks}
                  onChange={(v) => setSar({ ...sar, remarks: v })}
                  isDisabled={false}
                  name="Remarks"
                  uppercase
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="" style={{ height: "70px", borderCollapse: "separate" }}>
        <Col className="bg-white">
          <Row className="h-100 align-items-center justify-content-center">
            <Col xs={6}>
              <div className="d-grid g-2">
                <Button
                  disabled={false}
                  onClick={() => saveSarAndClose()}
                  variant="dark"
                >
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}