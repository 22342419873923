import { Player } from "@lottiefiles/react-lottie-player";

interface LoadingComponentProps {
    text: string;
} 

function LoadingComponent({ text }: LoadingComponentProps) {
  return (
    <div className="d-flex flex-column align-items-center pt-5">
      <Player
        autoplay
        loop
        src="/assets/brief/lottie/loading.json"
        speed={1.5}
        background="transparent"
        style={{ height: "200px", width: "200px" }}
      />
      <strong>{text}</strong>
    </div>
  );
}

export default LoadingComponent;
