import { Cell, createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { LatLngTuple, icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import * as Leaflet from 'leaflet'
import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import { CircleMarker, LayersControl, MapContainer, Marker, Polyline, Popup, TileLayer, Tooltip, useMapEvent } from "react-leaflet"
import { usePapaParse } from 'react-papaparse';
import { RoutePoint, calculateDistanceBetweenRoutePoints, convertLatLonToGeoPoint } from '../../../models/route';
import InputBox from '../../../components/InputBox/InputBox';
import { AviTracerApi } from '../../../avitracerApi';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { FlightPlanZZZZPoint } from '../../../models/flightPlan';
import { getAllCupPoints } from '../../../utils/CupPointUtils';
import "./RouteMapPage.css"

///.cup format
type Point = {
    code: string,
    country: string,
    desc: string,
    elev: string,
    freq: string,
    lat: string,
    lon: string,
    name: string,
    rwdir: string,
    rwlen: string,
    style: string,
}

interface RouteMapPageProps {
  selectZZZZPoint?: (point: FlightPlanZZZZPoint) => void
}

function RouteMapPage({selectZZZZPoint}: RouteMapPageProps) {
  
  const [routeName, setRouteName] = useState<string>("");
  const [routeNameError, setRouteNameError] = useState<string | undefined>();

  const [routePoints, setRoutePoints] = useState<(RoutePoint & { isStop: boolean })[]>([]);

  const { readRemoteFile } = usePapaParse();

  const [points, setPoints] = useState<Point[]>([]);
  const [manualAddedPoints, setManualAddedPoints] = useState<(RoutePoint & { isStop: boolean })[]>([]);

  useEffect(() => {
    const getPointsAsync = async () => {
      const cupPoints = await getAllCupPoints();
      setPoints(cupPoints);
    };
    getPointsAsync();
  }, [readRemoteFile]);

  const createPressed = async () => {
    if (routeName.length === 0) {
      setRouteNameError("Required")
      return;
    }
    setRouteNameError(undefined)

    if (routePoints.length < 2) {
      alert("Please add the Departure and Destination of the route.");
      return;
    }
    const r = await AviTracerApi.createImportedRouteFromMap(routeName, routePoints[0], routePoints.at(-1)!, routePoints.slice(1, -1));
    window.location.replace(r.url);
  };

  const removeRoutePoint : (index: number) => void = (index: number) => {
    const countInRoutePoints = routePoints.filter((p) => p.lat === routePoints[index].lat && p.lon === routePoints[index].lon).length;
    if (countInRoutePoints === 1) {
      setManualAddedPoints(manualAddedPoints.filter((p, i) => p.lat !== routePoints[index].lat && p.lon !== routePoints[index].lon));
    }
    setRoutePoints(routePoints.filter((p, i) => i !== index));
  };

  const toggleStop = (routePoint: RoutePoint) => {
    setRoutePoints(
      routePoints.map((p) => {
        if (p.lat === routePoint.lat && p.lon === routePoint.lon) {
          return {
            ...p,
            isStop: !p.isStop,
          };
        }
        return p;
      })
    );
  };

  return (
    <>
      <Container fluid className='ps-md-0'>
        {selectZZZZPoint && <h2 className="ps-3 py-2">Select ZZZZ Point</h2>}
        <Row className="gy-3">
          <Col xs={12} className='d-md-none'>
            <AddRouteAndCloseBtnComponent />
          </Col>
          <Col xs={12} md={selectZZZZPoint ? 12 : 7} xxl={selectZZZZPoint ? 12 : 9} className='route-map-page-map-height'>
            {points && points.length > 0 && (
              <MapComponent
                selectZZZZPoint={selectZZZZPoint}
                points={points}
                setPoints={setPoints}
                routePoints={routePoints}
                setRoutePoints={setRoutePoints}
                manualAddedPoints={manualAddedPoints}
                setManualAddedPoints={setManualAddedPoints}
              />
            )}
          </Col>
          {!selectZZZZPoint && (
            <Col xs={12} md={5} xxl={3}>
              <div className="d-flex flex-column justify-content-between" style={{ 

                }}>
                <Row className="gy-md-3 gy-2">
                  <Col xs={12} className="d-none d-md-block">
                    <AddRouteAndCloseBtnComponent />
                  </Col>
                  <Col xs={12}>
                    <InputBox
                      value={routeName}
                      isDisabled={false}
                      name={"Route Name"}
                      type="text"
                      onChange={(t) => setRouteName(t)}
                      invalidError={routeNameError}
                    />
                  </Col>
                  <Col xs={12} className="route-map-page-list-height overflow-y-scroll">
                    <RoutePointsListComponent routePoints={routePoints} removeRoutePoint={removeRoutePoint} toggleStop={toggleStop} />
                  </Col>
                  <Col xs={12} className="my-auto"></Col>
                </Row>
                <Row className="d-none d-md-block">
                  <Col xs={12}>
                    <CreateRouteBtnComponent createPressed={createPressed} />
                  </Col>
                </Row>
              </div>
            </Col>
          )}
        </Row>
      </Container>
      <div className="d-md-none fixed-bottom px-3">
        <CreateRouteBtnComponent createPressed={createPressed} />
      </div>
    </>
  );
}

export default RouteMapPage


interface MapComponentProps {
  
  selectZZZZPoint?: (point: FlightPlanZZZZPoint) => void
  points: Point[]
  setPoints: (points: Point[]) => void
  routePoints: (RoutePoint & {isStop:boolean}) []
  setRoutePoints: (points: (RoutePoint & {isStop:boolean}) []) => void
  manualAddedPoints: (RoutePoint & {isStop:boolean}) []
  setManualAddedPoints: (points: (RoutePoint & {isStop:boolean}) []) => void

}

function MapComponent({selectZZZZPoint, points, setPoints , routePoints, setRoutePoints, manualAddedPoints, setManualAddedPoints}: MapComponentProps) {
  
  const assetsPath = "/assets/brief/map/icons/";

  const refMap = useRef<Leaflet.Map>(null);

  const addRoutePoint = (point: Point, isStop: boolean) => {
    refMap.current!.eachLayer((layer) => {
      if (layer instanceof Leaflet.Marker) {
        layer.closePopup();
      }
    });

    const coords = toCoords(point);
    setRoutePoints([
      ...routePoints,
      {
        name:
          (point.style !== "5" && point.code.length > 0) || (point.style === "5" && point.code.length === 4)
            ? point.code
            : point.name.toUpperCase(),
        lat: coords[0],
        lon: coords[1],
        isStop: isStop,
      },
    ]);
  };

  const addManualRoutePoint = (point: LatLngTuple, name: string, isStop: boolean) => {
    setRoutePoints([
      ...routePoints,
      {
        name,
        lat: point[0],
        lon: point[1],
        isStop,
      },
    ]);
    setManualAddedPoints([
      ...manualAddedPoints,
      {
        name,
        lat: point[0],
        lon: point[1],
        isStop,
      },
    ]);
  };

  const iconUrlForPoint = (point: Point) => {
    if (point.style === "5") {
      return assetsPath + "marker_airport.png";
    } else if (point.style === "10") {
      return assetsPath + "marker_ndb.png";
    } else if (point.style === "9") {
      return assetsPath + "marker_vor.png";
    } else if (point.style === "heli") {
      return assetsPath + "marker_heli.png";
    }
    return assetsPath + "marker_point.png";
  };

  const typeForPoint = (point: Point) => {
    if (point.style === "5") {
      return "Airport";
    } else if (point.style === "10") {
      return "NDB";
    } else if (point.style === "9") {
      return "VOR";
    } else if (point.style === "heli") {
      return "Heliport";
    }
    return "Waypoint";
  };

  const runwayDirectionTextForPoint = (point: Point) => {
    const deg = parseInt(point.rwdir);
    const dir = Math.abs(Math.round(deg / 10));
    return `${dir.toString().padStart(2, "0")}/${dir + 18}`;
  };

  const toCoords = (point: Point) => {
    if (point.style === "heli") {
      return [parseFloat(point.lat), parseFloat(point.lon)] as LatLngTuple;
    }
    const lat = parseFloat(point.lat.slice(0, 2)) + parseFloat(point.lat.slice(2, 7)) / 60;
    const lon = parseFloat(point.lon.slice(0, 3)) + parseFloat(point.lon.slice(3, 8)) / 60;

    return [lat, lon] as LatLngTuple;
  };
  
  return (
    <MapContainer
    center={[37.5, 24]}
    zoom={9}
    scrollWheelZoom={true}
    ref={refMap}
    style={{ height: "100%" }}
  >
    <LayersControl position="topright">
      <LayersControl.BaseLayer checked name="Open Flight Maps">
        <TileLayer
          attribution='&copy; <a href="https://www.openflightmaps.org/">Open Flight Maps</a>'
          url="https://openflightmaps.avitracer.com/112022/{z}/{x}/{y}.png"
          maxNativeZoom={11}
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="Location IQ">
        <TileLayer
          attribution='&copy; <a href="https://locationiq.com/">LocationIQ</a>'
          url="https://a-tiles.locationiq.com/v3/streets/r/{z}/{x}/{y}.png?key=pk.c304bbf8aaa13dce7edc7b861b2c8026"
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="Satellite Hybrid">
        <TileLayer url="https://api.mapbox.com/styles/v1/avitracer/cl4k53v3e001x14lkhn445epj/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYXZpdHJhY2VyIiwiYSI6ImNsZ3Q3ajU0dzEwcGozZW8yZ2NtNGptd28ifQ.3cjrtycY3_QvWjr_XZdiVQ" />
      </LayersControl.BaseLayer>
    </LayersControl>
    {points.map((point) => (
      <Marker
        key={`${point.name}${point.code}${point.style}`}
        position={toCoords(point)}
        opacity={0.7}
        icon={icon({ iconSize: [25, 25], iconUrl: iconUrlForPoint(point) })}
      >
        <Popup>
          <span className="fs-5 fw-semibold">{typeForPoint(point)}</span>
          {((point.style !== "5" && point.code.length > 0) || (point.style === "5" && point.code.length === 4)) && (
            <div>
              FPL Code: <span className="fw-semibold">{point.code}</span>
            </div>
          )}
          <div>
            Name: <span className="fw-semibold">{point.name}</span>
          </div>
          {point.style === "5" && (
            <>
              <div>
                Runway: <span className="fw-semibold">{runwayDirectionTextForPoint(point)} </span>
              </div>
              <div>
                Runway Length: <span className="fw-semibold">{point.rwlen}</span>
              </div>
              <div>Radio Frequencies: </div>
              <div>
                <span className="fw-semibold">{point.freq}</span>
              </div>
            </>
          )}
          {selectZZZZPoint ? (
            <div className="py-2 d-grid">
              <Button variant="dark" onClick={() => selectZZZZPoint({ isIcao: false, name:point.name.toUpperCase(), coords: convertLatLonToGeoPoint( toCoords(point)[0], toCoords(point)[1] )})}>
                Use as ZZZZ Point
              </Button>
            </div>
          ) : (
            <>
              <div className="py-2 d-grid">
                <Button variant="dark" onClick={() => addRoutePoint(point, false)}>
                  Add to route
                </Button>
              </div>
              <div className="d-grid">
                <Button variant="light" onClick={() => addRoutePoint(point, true)}>
                  Add as Intermediate Stop
                </Button>
              </div>
            </>
          )}
        </Popup>
      </Marker>
    ))}

    {manualAddedPoints.map((point) => (
      <Marker
        key={`${point.lat}${point.lon}${point.name}`}
        position={[point.lat, point.lon]}
        icon={icon({ iconSize: [25, 25], iconUrl: assetsPath + "marker_user.png" })}
      >
        <Popup>
          <div className="py-2 d-grid">
            <Button variant="dark" size={"sm"} onClick={() => setRoutePoints([...routePoints, { ...point, isStop: false }])}>
              Add to route
            </Button>
          </div>
          <div className="d-grid">
            <Button variant="light" size={"sm"} onClick={() => setRoutePoints([...routePoints, { ...point, isStop: true }])}>
              Add as Intermediate Stop
            </Button>
          </div>
        </Popup>
      </Marker>
    ))}

    <Polyline
      pathOptions={{ color: "magenta", weight: 5 }}
      positions={[routePoints.map((point) => [point.lat, point.lon] as LatLngTuple)]}
    />

    {routePoints.length > 0 && (
      <CircleMarker color="green" radius={25} weight={5} center={[routePoints[0].lat, routePoints[0].lon]} />
    )}
    {routePoints.length > 1 && (
      <CircleMarker color="red" radius={20} weight={5} center={[routePoints.at(-1)!.lat, routePoints.at(-1)!.lon]} />
    )}
    {routePoints.length > 2 && (
      <>
        {routePoints.slice(1, -1).map((point) => (
          <CircleMarker
            key={`${point.lat}${point.lon}${point.isStop}`}
            color={point.isStop ? "blue" : "magenta"}
            radius={point.isStop ? 20 : 15}
            weight={5}
            center={[point.lat, point.lon]}
          />
        ))}
      </>
    )}

    <AddManualPointComponent addManualRoutePoint={addManualRoutePoint} assetsPath={assetsPath} selectZZZZPoint={selectZZZZPoint} canAddIntStop={true}/>
  </MapContainer>
  )
}

function AddRouteAndCloseBtnComponent() {
  const navigate = useNavigate();

  return (
    <Row>
      <Col xs={10}>
        <div className="pt-3 ps-3 ps-md-0 fs-2 fw-semibold">Add Route</div>
      </Col>
      <Col xs={2}>
        <Button variant="close" className="w-100 h-100" onClick={() => navigate("/routes")}></Button>
      </Col>
    </Row>
  );
}


interface CreateRouteBtnComponentProps {
  createPressed: () => void    
}

function CreateRouteBtnComponent({createPressed}: CreateRouteBtnComponentProps) {
  return (
    <div className="d-grid">
      <Button className="mb-3" variant="dark" onClick={createPressed}>
        Create route
      </Button>
    </div>
  );
}

interface AddManualPointComponentProps {
    addManualRoutePoint: (point: LatLngTuple, name: string, isStop: boolean) => void
    assetsPath: string
    selectZZZZPoint?: (point: FlightPlanZZZZPoint) => void
    canAddIntStop: boolean
}

export function AddManualPointComponent({addManualRoutePoint, assetsPath, selectZZZZPoint, canAddIntStop}: AddManualPointComponentProps) {

    const [point, setPoint] = useState<LatLngTuple | null>(null);

    useMapEvent("contextmenu", (e) => {
      setPoint([e.latlng.lat, e.latlng.lng]);
    });

    const cancelPressed = () => {
      setPoint(null);
      setCode("");
      setCodeError(undefined);
    };

    const addPressed = (isStop: boolean) => {
      setCodeError(undefined);
      if (code.length === 0) {
        setCodeError("Code is required");
        return;
      }

      addManualRoutePoint(point!, code, isStop);
      setPoint(null);
      setCode("");
      setCodeError(undefined);
    };

    const [code, setCode] = useState<string>("");

    const [codeError, setCodeError] = useState<string | undefined>();

    return (
      <>
        {point && (
          <Marker position={point} icon={icon({ iconSize: [25, 25], iconUrl: assetsPath + "marker_user.png", tooltipAnchor: [0, -15] })}>
            <Tooltip opacity={1} permanent={true} interactive={true} direction="top">
              <div className="d-flex flex-column gap-2 p-2" style={{ width: "300px" }}>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="fs-5 fw-semibold">Manual Waypoint</span>
                  <Button variant="close" onClick={cancelPressed} />
                </div>
                {selectZZZZPoint ? (
                  <div className="py-2 d-grid">
                    <Button
                      variant="dark"
                      onClick={() => selectZZZZPoint({ isIcao: false, name: "", coords: convertLatLonToGeoPoint(point[0], point[1]) })}
                    >
                      Use as ZZZZ Point
                    </Button>
                  </div>
                ) : (
                  <>
                    <InputBox
                      value={code}
                      uppercase={true}
                      isDisabled={false}
                      name={"FPL Code"}
                      type="text"
                      invalidError={codeError}
                      onChange={(t) => setCode(t)}
                    />
                    <div className="py-2 d-grid">
                      <Button variant="dark" onClick={() => addPressed(false)}>
                        Add waypoint
                      </Button>
                    </div>
                    {canAddIntStop && (
                      <div className="d-grid">
                        <Button variant="light" onClick={() => addPressed(true)}>
                          Add Intermediate Stop
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </Tooltip>
          </Marker>
        )}
      </>
    );
}

interface RoutePointsListComponentProps {
    routePoints: (RoutePoint & {isStop:boolean}) []
    removeRoutePoint: (index: number) => void
    toggleStop: (routePoint: RoutePoint) => void
}

function RoutePointsListComponent({routePoints, removeRoutePoint, toggleStop}: RoutePointsListComponentProps) {

    const columnHelper = createColumnHelper<RoutePoint & {isStop:boolean}>()

    const columns = [
      columnHelper.accessor("name", {
        id: "name",
        header: "FPL Code",
        cell: (info) => <div className="fw-bold">{info.getValue()}</div>,
      }),
      columnHelper.accessor("isStop", {
        header:"Interm.",
        cell: (info) => {
          if (info.getValue()) {            
            return <div className="text-center"><FontAwesomeIcon icon={faSquareCheck} size={"xl"} onClick={()=> toggleStop(info.row.original)} /></div>;
          } else {
            return <div className="text-center"><FontAwesomeIcon icon={faSquare} size={"xl"} onClick={()=> toggleStop(info.row.original)}/></div>;
          }
        }
      }),
      columnHelper.display({
        id: "distances",
        header: () => (
          <>
            <small className="text-center">
              <div>Dist</div>
              <div>Leg/Tot</div>
            </small>
          </>
        ),
        cell: (info) => (
          <>
            <div className="text-center">
              <div>
                {info.row.index > 0 ? calculateDistanceBetweenRoutePoints(routePoints[info.row.index - 1], info.row.original, []) : "0"}
              </div>
              <div>
                {calculateDistanceBetweenRoutePoints(
                  routePoints[0],
                  info.row.original,
                  info.row.index > 1 ? routePoints.slice(1, info.row.index) : []
                )}
              </div>
            </div>
          </>
        ),
      }),
      columnHelper.display({
        id: "actions",
        cell: (info) => (
          <>
            <Button size={"sm"} variant="light" onClick={() => removeRoutePoint(info.row.index)}>
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </>
        ),
      }),
    ];

    const table = useReactTable({
        data: routePoints,
        columns,
        getCoreRowModel: getCoreRowModel()
    })

    const getColorForCell = (cell: Cell<RoutePoint & {isStop: boolean;}, unknown>) => {
      if (cell.column.id !== "name"){
        return "#333"
      }
      if (cell.row.index === 0){
        return "green"
      }
      if (cell.row.index === routePoints.length - 1){
        return "red"
      }
      if (cell.row.original.isStop){
        return "blue"
      }else{
        return "magenta"
      }
    }

    return (
      <>
        <Table hover>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.depth}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td 
                  key={cell.id} 
                  style={{color: getColorForCell(cell), verticalAlign: "middle"}}
                  >{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
}