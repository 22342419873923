import { createSlice } from "@reduxjs/toolkit";
import { AviTracerApi } from "../avitracerApi";
import { Passenger } from "../models/passenger";
import { createAppAsyncThunk } from "./hooks";
import { RootState } from "./store";

interface PassengersState {
    allPassengers: Passenger[],
    status: "idle" | "success"
    loading: boolean
}

const initialState: PassengersState = {
    allPassengers: [],
    status: "idle",
    loading: false
}

export const getPassengers = createAppAsyncThunk(
    "passengers/fetch",
    async () => {
        return AviTracerApi.getPassengers()
    }
)

export const savePassenger = createAppAsyncThunk(
    "passengers/save",
    async (passenger: Passenger) => {
        return AviTracerApi.savePassenger(passenger)
    }
)

export const archivePassengerWithId = createAppAsyncThunk(
    "passengers/archiveWithId",
    async (id: Passenger["id"]) => {
        return AviTracerApi.archivePassenger(id)
    }
)

export const passengersSlice = createSlice({
    name: "passengers",
    initialState,
    reducers:{
        resetPassengersStateForOrganizationChange: (state) => {
            state.allPassengers = []
            state.status = "idle"
            state.loading = false
        }
    }, extraReducers: (builder) => {
        builder.addCase(getPassengers.fulfilled, (state, action) => {
            state.allPassengers = action.payload
            state.status = "success"
            state.loading = false
        })
        builder.addCase(savePassenger.fulfilled, (state, action) => {
            const index = state.allPassengers.findIndex((p) => p.id === action.payload.id)
            if (index === -1){
                state.allPassengers.push(action.payload)
            }else{
                state.allPassengers[index] = action.payload
            }
        })
        builder.addCase(archivePassengerWithId.fulfilled, (state, action) => {
            state.allPassengers = state.allPassengers.filter((p) => p.id !== action.meta.arg!)
        })
        builder.addCase(getPassengers.pending, (state) => {
            state.loading = true
        })
    }
})

export const allPassengersState = (state: RootState) => state.passengers.allPassengers

export const { resetPassengersStateForOrganizationChange } = passengersSlice.actions

export default passengersSlice.reducer