import { useEffect, useState } from "react";
import { Html5Qrcode, Html5QrcodeScannerState } from 'html5-qrcode';
import { Button, Col, Offcanvas, Row, Toast, ToastContainer } from "react-bootstrap";
import { AviTracerApi } from "../../avitracerApi";
import { Passenger, getPassengerFullName } from "../../models/passenger";
import moment from "moment";
import { useLoaderData, useNavigate } from "react-router-dom";
import Flight from "../../models/flight";
import { useWindowSize } from "usehooks-ts";

function FlyScanBoardingPassPage() {
  const navigate = useNavigate();

  const loadedFlight = useLoaderData() as Flight;

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showPassengerModal, setShowPassengerModal] = useState(false);

  const [passenger, setPassenger] = useState<Passenger & { previousCheckIn: { time: Date; checkedInByUid: string } }>();
  const [previousCheckInDateTime, setPreviousCheckInDateTime] = useState<Date | undefined>();

  const [scanPaused, setScanPaused] = useState<boolean>(false)

  const submitSerialNumber = async (serialNumber: string) => {
    setScanPaused(true)
    setErrorMessage(undefined);
    setPreviousCheckInDateTime(undefined)
    setShowSuccessToast(false);
    try {
      const passenger = await AviTracerApi.checkInBoardingPass(loadedFlight.id!,serialNumber);

      setPassenger(passenger);
      setShowPassengerModal(true);
      console.log("passenger: ", passenger);
      if (passenger.previousCheckIn) {
        setErrorMessage(
          `${getPassengerFullName(passenger)} has already checked in at ${moment(passenger.previousCheckIn.time).format("HH:mm DD/MM/YYYY")}`
        );
        setPreviousCheckInDateTime(
          new Date(passenger.previousCheckIn.time)
        );
      } else {
        setShowSuccessToast(true);
      }
    } catch (err: any) {
      console.log("Submit Error: ", err);
      setErrorMessage(err);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setScanPaused(false)
    }
  };

  const goBack = async () => {
    navigate("/fly/" + loadedFlight.id!);
  };


  return (
    <>
      <Html5QrcodePlugin
        onQrCodeFound={submitSerialNumber}
        scanPaused={scanPaused}
      />
      <Offcanvas
        show={showPassengerModal}
        placement="bottom"
        className="rounded-top"
        onExited={() => {
          setPassenger(undefined);
          setShowSuccessToast(false);
          setErrorMessage(undefined);
          setScanPaused(false);
        }}
      >
        <Offcanvas.Body>
          <Row>
            <Col xs={12}>
              <h2 className="fw-bold">
                { passenger && getPassengerFullName(passenger)}
              </h2>
            </Col>
            <Col xs={2}>
              <strong>ID: </strong>
            </Col>
            <Col xs={10}>{passenger?.idPassportNumber ?? "Not provided"}</Col>
            <Col xs={2}>
              <strong>Weight: </strong>
            </Col>

            <Col xs={10}>
              {passenger?.weight !== undefined ? (
                <> {passenger?.weight} kg</>
              ) : (
                "Not provided"
              )}
            </Col>
            {previousCheckInDateTime && (
              <Col xs={12}>
                <small className="text-danger">
                  Already checked in at{" "}
                  {moment(previousCheckInDateTime).format("HH:mm DD/MM/YY")}.
                </small>
              </Col>
            )}
          </Row>

          <div className="fixed-bottom">
            <div className="d-flex">
              <div className="w-25 d-grid  gap-2 m-2">
                <Button style={{ height: "60px" }} variant="light" className="fw-bold" onClick={() => {setScanPaused(false); goBack();}}>
                  Done
                </Button>
              </div>
              <div className="w-75 d-grid  gap-2 m-2">
                <Button style={{ height: "60px" }} className="bg-dark fw-bold" onClick={() => setShowPassengerModal(false)}>
                  Scan Next
                </Button>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <div className="fixed-bottom">
        <div className="d-grid  gap-2 m-3">
          <Button
            style={{ height: "60px" }}
            className="bg-dark fw-bold"
            onClick={goBack}
          >
            Done
          </Button>
        </div>
      </div>
      <ToastContainer className="p-3" position={"top-center"}>
        <Toast
          show={errorMessage !== undefined}
          delay={15000}
          autohide
          onClose={() => setErrorMessage(undefined)}
          className="bg-danger text-white"
        >
          <Toast.Body>{errorMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
      {showSuccessToast && passenger && (
        <ToastContainer className="p-3" position={"top-center"}>
          <Toast
            show={showSuccessToast}
            delay={15000}
            autohide
            onClose={() => setShowSuccessToast(false)}
            className="bg-success text-white"
          >
            <Toast.Body>
              {`${getPassengerFullName(passenger)} checked in successfully!`}
            </Toast.Body>
          </Toast>
        </ToastContainer>
      )}
    </>
  );
}

export default FlyScanBoardingPassPage

interface Html5QrcodePluginProps {
  onQrCodeFound: (code: string) => void
  scanPaused: boolean
}

function Html5QrcodePlugin({onQrCodeFound, scanPaused}: Html5QrcodePluginProps) {


    const [html5Qrcode, setHtml5Qrcode] = useState<Html5Qrcode>();

    
    useEffect(() => {
      if (html5Qrcode === undefined){
        return
      }
      if (scanPaused && html5Qrcode.getState() === Html5QrcodeScannerState.SCANNING){
        html5Qrcode.pause()
      }else if (!scanPaused && html5Qrcode.getState() === Html5QrcodeScannerState.PAUSED){
        html5Qrcode.resume()
      }
    }, [html5Qrcode, scanPaused])

    const { width, height } = useWindowSize()

    useEffect(() => {
      if (html5Qrcode === undefined) {
        return;
      }
      
      try {
        html5Qrcode!.start(
          { facingMode: "environment" },
          {
            fps: 100,
            aspectRatio: Math.ceil((height / width) * 100) / 100,
          },
          (qrCode) => {
            console.log("QR Code detected: ", qrCode);
            onQrCodeFound(qrCode);
          },
          (errorMessage) => {
              // console.log("ScanError: ", errorMessage);
          }
        );
      } catch (err) {
        console.log("Error: ", err);
      }
    }, [height, html5Qrcode, onQrCodeFound, width]);

    useEffect(() => {
      const verbose = false;

      const codeReader = new Html5Qrcode("boarding-pass-scanner", verbose);
      setHtml5Qrcode(codeReader);

      return () => {

        if (codeReader.getState() === Html5QrcodeScannerState.SCANNING){
          codeReader.stop().then(() => {
            codeReader.clear();
          });
        }else{
          codeReader.clear();
        }
      };
    }, []);

    return (
      <>
        <div id={"boarding-pass-scanner"} />
      </>
    );
}