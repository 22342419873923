import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { Badge, Button, Col, Container, Offcanvas, Row } from "react-bootstrap";
import { Link, Outlet, useNavigate } from "react-router-dom";
import ListEntitiesHeaderComponent from "../../../components/ListEntities/ListEntitiesHeaderComponent";
import ListEntitiesTable from "../../../components/ListEntities/ListEntitiesTable";
import { Crew } from "../../../models/crew";
import { getCrew, inviteCrew } from "../../../store/crewSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import LoadingComponent from "../../../components/LoadingComponent";

interface ListCrewPageProps {
  onCrewSelected?: (crew: Crew) => void;
}

function ListCrewPage({ onCrewSelected }: ListCrewPageProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate()

  const { allCrew, status, loading } = useAppSelector((state) => state.crew);
  const [ inviteCrew, setInviteCrew ] = useState<Crew | undefined>(undefined);

  const [ filteredCrew, setFilteredCrew ] = useState<Crew[]>(allCrew);

  const [ searchValue, setSearchValue ] = useState("");

  const [ selectedTab, setSelectedTab ] = useState< "pilot" | "student" >("pilot");

  useEffect(() => {
    if (status === "idle") {
      dispatch(getCrew());
    }
  }, [dispatch, status]);

  useEffect(() => {
    setFilteredCrew(allCrew.filter((crew) => crew.type === selectedTab));
  }, [allCrew, selectedTab]);

  return (
    <Container fluid className="vh-100 px-0 px-md-2">
      <ListEntitiesHeaderComponent
        actionBtn={
          !onCrewSelected
            ? {
                title: "Add Crew",
                onClick: () => navigate("add"),
              }
            : undefined
        }
        search={{ value: searchValue, setValue: setSearchValue }}
        title="Crew"
      />

      <Row style={{height: "46px"}}>
        <nav className="nav">
          <Link
            to="/crew"
            onClick={(e) => {
              e.preventDefault();
              setSelectedTab("pilot");
            }}
            className={"nav-link underline".concat(selectedTab === "pilot" ? " active" : "")}
          >
            Pilot
          </Link>
          <Link
            to="/crew"
            onClick={(e) => {
              e.preventDefault();
              setSelectedTab("student");
            }}
            className={"nav-link underline".concat(selectedTab === "student" ? " active" : "")}
          >
            Student
          </Link>
        </nav>
      </Row>

      {loading ? (
        <LoadingComponent text="Loading crew..." />
      ) : (
        <Row className="overflow-auto" style={{ height: "calc(100% - 186px)" }}>
          <Col>
            <ListCrewTable data={filteredCrew} searchValue={searchValue} onCrewSelected={onCrewSelected} setInviteCrew={setInviteCrew} />
          </Col>
        </Row>
      )}

      <Outlet />
      {inviteCrew && <InviteOffcanvasComponent close={() => setInviteCrew(undefined)} crew={inviteCrew} />}
    </Container>
  );
}

export default ListCrewPage;



interface ListCrewTableProps{
  data: Crew[],
  searchValue: string
  onCrewSelected?: (crew: Crew) => void;
  setInviteCrew: React.Dispatch<React.SetStateAction<Crew | undefined>>
}

function ListCrewTable({data, searchValue, onCrewSelected, setInviteCrew}: ListCrewTableProps){
    const columnHelper = createColumnHelper<Crew>()

    const columns = [
      columnHelper.accessor("lastName", {
        header: () => "Last Name",
        sortingFn: "alphanumeric",
      }),
      columnHelper.accessor("firstName", {
        header: () => "First Name",
        sortingFn: "alphanumeric",
      }),
      columnHelper.accessor("nickName", {
        header: () => "Nick Name",
        sortingFn: "alphanumeric",
      }),
      columnHelper.display({
        id: "inviteStatus",
        header: () => "Team Status",
        cell: (info) => {
          const crew = info.cell.row.original;
          const status = crew.organizationStatus;
          if (status === "admin" || status === "pilot") return <Badge bg={"success"}>{status.charAt(0).toUpperCase() + status.slice(1)}</Badge>;
          if (status === "invited") {
            return (
              <>
                <Badge bg={"warning"} text={"dark"}>
                  Invited
                </Badge>
                <Button
                  size="sm"
                  variant="link"
                  onClick={(e) => {
                    e.stopPropagation();
                    setInviteCrew(crew);
                  }}
                >
                  Resend
                </Button>
              </>
            );
          }
          if (crew.email) {
            return (
              <Button
                size="sm"
                variant="light"
                onClick={(e) => {
                  e.stopPropagation();
                  setInviteCrew(crew);
                }}
                style={{ border: "#dfdfdf", borderWidth: "1px", borderStyle: "solid" }}
              >
                Send Invite
              </Button>
            );
          } else {
            return <Badge bg={"danger"}>No email</Badge>;
          }
         
        },
      }),
    ];

    return (
      <ListEntitiesTable
        data={data}
        columns={columns}
        searchValue={searchValue}
        onRowClick={onCrewSelected}
      />
    );
}

interface InviteOffcanvasComponentProps {
  close: () => void;
  crew: Crew;
}

function InviteOffcanvasComponent({ close, crew }: InviteOffcanvasComponentProps) {
  const dispatch = useAppDispatch();

  const [show, setShow] = useState(true);
  const [sendingInvite, setSendingInvite] = useState(false);

  const sendInvite = async () => {
    setSendingInvite(true);
    await dispatch(inviteCrew(crew));
    setShow(false);
  };

  return (
    <Offcanvas show={show} placement="end" onExited={close}>
      <Offcanvas.Body>
        {sendingInvite ? (
          <LoadingComponent text="Sending invite..." />
        ) : (
          <Container>
            <Row>
              <Col>
                <h2>Pilot invite</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  When you invite a crew member to be a pilot in your organization, they will be able to access the flights that the pilot
                  is assigned to.
                </p>
                <p>
                  An invitation will be sent by BRIEF to: <br />
                  <strong>{crew.email!}</strong>
                </p>
              </Col>
            </Row>
            <Row className="pt-4 justify-content-center gy-2">
              <Col xs={12}>
                <div className="d-grid g-2">
                  <Button disabled={false} onClick={sendInvite} variant="dark">
                    Send invite
                  </Button>
                </div>
              </Col>
              <Col xs={12}>
                <div className="d-grid g-2">
                  <Button disabled={false} variant="light" onClick={() => setShow(false)}>
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}