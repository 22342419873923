import { Control, FieldValues, Path, PathValue, UseFormResetField, UseFormSetFocus, UseFormSetValue, useController } from "react-hook-form";
import { EntityDetailsDisplayInfoFieldPassengersLeg } from "../EntityDetailsDisplayInfo";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import ReadOnlyInputBox from "../../InputBox/ReadonlyInputBox";
import EntityDateTimePicker from "../EntityDateTimePicker";
import EntityInputBox from "../EntityInputBox";
import Flight, { FlightPassengerLeg } from "../../../models/flight";
import TextAreaBox from "../../InputBox/TextAreaBox";
import { RoutePoint, getFplCoordsFromRoutePoint } from "../../../models/route";
import { Aircraft } from "../../../models/aircraft";
import { useEffect, useState } from "react";

export interface EntityPassengersLegComponentProps<Entity extends FieldValues> {
  displayInfoInput: EntityDetailsDisplayInfoFieldPassengersLeg<Entity>;
  control: Control<Entity, any>;
  setModalContent: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
  setValue: UseFormSetValue<Flight>;
  resetField: UseFormResetField<Entity>;
  setFocus: UseFormSetFocus<Entity>;
  aircraft?: Aircraft;
  flightDepartureDateTime: Date;
  route: {
    departure: RoutePoint;
    intermediateStops: RoutePoint[];
    destination: RoutePoint;
  }
  passengersLegValue?: FlightPassengerLeg;
}

function EntityPassengersLegComponent<Entity extends FieldValues>({
  displayInfoInput,
  control,
  setModalContent,
  setValue,
  resetField,
  setFocus,
  aircraft,
  flightDepartureDateTime,
  route,
  passengersLegValue,
}: EntityPassengersLegComponentProps<Entity>) {
  const {
    field: { value: passengersLegRouteValue, onChange: passengersLegRouteOnChange, ref: passengersLegRouteRef },
    fieldState: passengerLegRouteFieldState,
    formState: { isSubmitting, isValid },
  } = useController({
    name: displayInfoInput.key.concat(".route") as Path<Entity>,
    control,
    rules: {
      required: (route.intermediateStops.length > 0) ? "Required" : undefined
    }
  });

  const showFromIntermediateFields = passengersLegRouteValue && (passengersLegRouteValue as FlightPassengerLeg["route"]).fromIndex > 0;
  const showToIntermediateFields =
    passengersLegRouteValue && (passengersLegRouteValue as FlightPassengerLeg["route"]).toIndex !== route.intermediateStops.length + 1;

  const getIntermediateRmkText = () => {
    if (!passengersLegValue) {
      return "";
    }
    const passengersLegRoute = passengersLegValue.route;
    var text = "";
    if (showFromIntermediateFields) {
      text += `INTERMEDIATE STOP AT ${passengersLegRoute.from.name} ${getFplCoordsFromRoutePoint(passengersLegRoute.from as RoutePoint)} `;

      if (passengersLegValue.delayAtFrom) {
        text += "FOR " + passengersLegValue.delayAtFrom + " MINS ";
      }

      if (passengersLegValue.changePaxAtFrom && passengersLegValue.changePaxAtFrom > 0) {
        text += "EMBARKING " + passengersLegValue.changePaxAtFrom + " PAX ";
      } else if (passengersLegValue.changePaxAtFrom && passengersLegValue.changePaxAtFrom < 0) {
        text += "DISEMBARKING " + Math.abs(passengersLegValue.changePaxAtFrom) + " PAX ";
      }
    }

    if (showToIntermediateFields) {
      text += `INTERMEDIATE STOP AT ${passengersLegRoute.to.name} ${getFplCoordsFromRoutePoint(passengersLegRoute.to as RoutePoint)} `;

      if (passengersLegValue.delayAtTo) {
        text += "FOR " + passengersLegValue.delayAtTo + " MINS ";
      }

      if (passengersLegValue.changePaxAtTo && passengersLegValue.changePaxAtTo > 0) {
        text += "EMBARKING " + passengersLegValue.changePaxAtTo + " PAX ";
      } else if (passengersLegValue.changePaxAtTo && passengersLegValue.changePaxAtTo < 0) {
        text += "DISEMBARKING " + Math.abs(passengersLegValue.changePaxAtTo) + " PAX ";
      }
    }

    return text.replace(/_/g, " ");
  };

  const overwriteFlightRmkField = () => {
    const remarks = [aircraft?.field18Remarks, getIntermediateRmkText()].filter((e) => e).join(" ");
    setValue("field18Remarks", remarks);
    setFocus("field18Remarks" as Path<Entity>);
  };

  const passengerLegRouteDisplayValue: string = passengersLegRouteValue
    ? `${(passengersLegRouteValue as FlightPassengerLeg["route"]).from.name} - ${(passengersLegRouteValue as FlightPassengerLeg["route"]).to.name}`
    : "";


  const isDisabled = (isValid && isSubmitting) || (displayInfoInput.disabled ?? false);

  return (
    <>
      <div className="mt-3">
        <ReadOnlyInputBox
          inputRef={passengersLegRouteRef!}
          value={passengerLegRouteDisplayValue}
          isDisabled={isDisabled}
          name={"Leg(s) with Passengers (Boarding Pass)"}
          fieldState={passengerLegRouteFieldState}
          onClick={() => {
            setModalContent(
              <CharterRouteModalContent
                route={route}
                onCharterRouteSelected={(charterRoute) => {
                  passengersLegRouteOnChange(charterRoute);

                  resetField("passengersLeg.boardingTime" as Path<Entity>, {
                    defaultValue: charterRoute.fromIndex > 0 ? (flightDepartureDateTime as PathValue<Entity, Path<Entity>>) : undefined,
                  });
                  resetField("passengersLeg.delayAtFrom" as Path<Entity>, { defaultValue: undefined });
                  resetField("passengersLeg.delayAtTo" as Path<Entity>, { defaultValue: undefined });
                  resetField("passengersLeg.changePaxAtFrom" as Path<Entity>, { defaultValue: undefined });
                  resetField("passengersLeg.changePaxAtTo" as Path<Entity>, { defaultValue: undefined });

                  setModalContent(null);
                }}
              />
            );
          }}
        />
      </div>
      {showFromIntermediateFields && (
        <>
          <div className="mt-3">
            <EntityDateTimePicker
              control={control}
              displayInfoInput={{
                type: "dateTime",
                defaultValue: flightDepartureDateTime,
                dateFlatpickrOptions: {
                  dateFormat: "d-m-Y",
                },
                timeFlatpickrOptions: {
                  dateFormat: "H:i",
                  time_24hr: true,
                  minuteIncrement: 5,
                },
                disabled: isDisabled,
                useUtc: true,
                showOnlyTimePicker: true,
                timeName: "Boarding Time (UTC) At " + (passengersLegRouteValue as FlightPassengerLeg["route"]).from.name,
                name: "Boarding Date",
                key: "passengersLeg.boardingTime" as Path<Entity>,
                validateRules: {
                  validate: {
                    inFuture: (value) => {
                      if ((value as Date) < new Date(flightDepartureDateTime)) {
                        return "Boarding Time must be after the Departure Time";
                      }

                      return true;
                    },
                  },
                  required: showFromIntermediateFields ? "Required" : undefined,
                },
              }}
            />
          </div>
          <div className="mt-3">
            <EntityInputBox
              control={control}
              displayInfoInput={{
                type: "number",
                name: "Standby time at " + (passengersLegRouteValue as FlightPassengerLeg["route"]).from.name + " (mins)",
                key: "passengersLeg.delayAtFrom" as Path<Entity>,
                disabled: isDisabled,
                defaultValue: 0,
                validateRules: {
                  validate: {
                    positive: (value) => {
                      if (value < 0) {
                        return `Standby time at ${(passengersLegRouteValue as FlightPassengerLeg["route"]).from.name} must be positive`;
                      }
                    },
                  },
                  required: showFromIntermediateFields ? "Required" : undefined,
                },
              }}
            />
          </div>
          <div className="mt-3">
            <EntityInputBox
              control={control}
              displayInfoInput={{
                type: "number",
                name:
                  "Pax number change at " + (passengersLegRouteValue as FlightPassengerLeg["route"]).from.name + " (- Disebark, + Embark)",
                key: "passengersLeg.changePaxAtFrom" as Path<Entity>,
                disabled: isDisabled,
                defaultValue: 0,
                validateRules: {
                  required: showFromIntermediateFields ? "Required" : undefined,
                },
              }}
            />
          </div>
        </>
      )}
      {showToIntermediateFields && (
        <>
          <div className="mt-3">
            <EntityInputBox
              control={control}
              displayInfoInput={{
                type: "number",
                name: "Standby time at " + (passengersLegRouteValue as FlightPassengerLeg["route"]).to.name + " (mins)",
                key: "passengersLeg.delayAtTo" as Path<Entity>,
                disabled: isDisabled,
                defaultValue: 0,
                validateRules: {
                  validate: {
                    positive: (value) => {
                      if (value < 0) {
                        return `Standby time at ${(passengersLegRouteValue as FlightPassengerLeg["route"]).to.name} must be positive`;
                      }
                    },
                  },
                  required: showToIntermediateFields ? "Required" : undefined,
                },
              }}
            />
          </div>
          <div className="mt-3">
            <EntityInputBox
              control={control}
              displayInfoInput={{
                type: "number",
                name:
                  "Pax number change at " + (passengersLegRouteValue as FlightPassengerLeg["route"]).to.name + " (- Disebark, + Embark)",
                key: "passengersLeg.changePaxAtTo" as Path<Entity>,
                disabled: isDisabled,
                defaultValue: 0,
                validateRules: {
                  required: showToIntermediateFields ? "Required" : undefined,
                },
              }}
            />
          </div>
        </>
      )}
      {(showFromIntermediateFields || showToIntermediateFields) && (
        <>
          <div className="mt-3">
            <Row className="align-items-center">
              <Col xs={9}>
                <TextAreaBox
                  isDisabled={true}
                  value={getIntermediateRmkText()}
                  onChange={() => {}}
                  name={"Intermediate Stop(s) /RMK"}
                  uppercase={true}
                />
              </Col>
              <Col xs={3}>
                <Button onClick={overwriteFlightRmkField} disabled={isDisabled} variant="dark">
                  Overwrite Field 18 /RMK
                </Button>
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
}

export default EntityPassengersLegComponent;

interface CharterRouteModalContentProps {
  route: {
    departure: RoutePoint;
    intermediateStops: RoutePoint[];
    destination: RoutePoint;
  };
  onCharterRouteSelected: (selection: { from: RoutePoint; fromIndex: number; to: RoutePoint; toIndex: number }) => void;
}

function CharterRouteModalContent({ route, onCharterRouteSelected }: CharterRouteModalContentProps) {
  const [availableCharterRoutes, setAvailableCharterRoutes] = useState<
    { from: RoutePoint; fromIndex: number; to: RoutePoint; toIndex: number }[] | undefined
  >();

  useEffect(() => {
    const points = [route.departure, ...route.intermediateStops, route.destination];

    const startPoints = points.map((point, index) => {
      return {
        index,
        routePoint: point,
      };
    });
    const endPoints = points.map((point, index) => {
      return {
        index,
        routePoint: point,
      };
    });
    const permutations = startPoints
      .map((startPoint) => {
        return endPoints.map((endPoint) => {
          return {
            from: startPoint.routePoint,
            fromIndex: startPoint.index,
            to: endPoint.routePoint,
            toIndex: endPoint.index,
          };
        });
      })
      .flat()
      .filter((permutation) => permutation.fromIndex < permutation.toIndex);

    setAvailableCharterRoutes(permutations);
  }, [route]);

  return (
    <Container fluid>
      <Row className="py-5" style={{ height: "140px" }}>
        <Col xs={12}>
          <h2>Leg(s) with Passengers</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table hover>
            <thead>
              <tr>
                <th>From</th>
                <th>To</th>
              </tr>
            </thead>
            <tbody>
              {availableCharterRoutes?.map((charterRoute) => {
                return (
                  <tr key={`${charterRoute.fromIndex}-${charterRoute.toIndex}`} onClick={() => onCharterRouteSelected(charterRoute)}>
                    <td>{charterRoute.from.name}</td>
                    <td>{charterRoute.to.name}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
