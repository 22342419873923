import { EntityDetailsDisplayInfo } from "../components/EntityDetails/EntityDetailsDisplayInfo"
import AirctaftSarEquipment from "./aircraftSar"
import { CanEditSharedValues } from "./identifiable"

export type Aircraft = {
    id: string,

    registration: string,
    icaoType: string,

    numOfEngines: string,
    cruiseSpeed: {unit: "N" | "K" , value: number},
    
    massUnit: "kg" | "lb"
    fuelUnit: "ltr" | "usgal" | "kg" | "lb",
    fuelType: "avgas" | "jetA1"
    fuelPerHour: number, // in fuelUnit
    totalFuelCapacity?: number, // in fuelUnit

    equipmentCodes: string,
    surveillanceEquipmentCodes: string
    field18Remarks: string,
    sarEquipment: AirctaftSarEquipment,

    wb?: {
        emptyMass: {
            mass: number,
            arm: number,
            latArm?: number
        },
    } & AircraftWbProfile


    //Organization properties
    name: string 
    operatorCode?: string,
    operatorFullName?: string,
    operatorDefaultNumber?: string,
    defaultFlightIdType: "reg" | "aircraft" | "crew" | "custom",
} & CanEditSharedValues

export type AircraftWbProfile = {
    profileName: string
    maxMass: number
    envelopePoints: AircraftWbLonPoint[]
    latEnvelopePoints?: AircraftWbLatPoint[]
    stations: AircraftWbStation[]
};

export type AircraftFullWbProfileInfo = {
    manufacturer: string,
    model: string,
    icaoType: string,
    description: string,
    fuelType: "avgas" | "jetA1",
} & AircraftWbProfile

export type AircraftWBPoint = 
    AircraftWbLonPoint | AircraftWbLatPoint
    ;

export type AircraftWbLonPoint = {
    mass: number;
    arm: number;
}

export type AircraftWbLatPoint = {
    arm: number;
    latArm: number;
}
    |
{
    mass: number;
    latArm: number;
}

export type AircraftWbStation = {
    id: string;
    name: string;
    maxMass: number;
    arm: number;
    latArm?: number;
    type: "seat" | "baggage" | "fuel";
}

export function getAircraftDisplayInfo(): EntityDetailsDisplayInfo<Aircraft> {
    return {
        entityName: "Aircraft",
        sections: [
            {
                sectionName: "Organization Details",
                sectionId: "general",
                inputs: [
                    {
                        name: "Name in Organization",
                        key: "name",
                        type: "text",
                        required: true
                    },
                    {
                        name: "Default Flight ID Type",
                        key: "defaultFlightIdType",
                        type: "dropdown",
                        options: [
                            { value: "reg", label: "Registration" },
                            { value: "aircraft", label: "Operator + Aircraft" },
                            { value: "crew", label: "Operator + Crew" },
                            { value: "custom", label: "Custom" },
                        ],
                        required: true
                    },
                    {
                        name: "Operator 3 Letter Code",
                        key: "operatorCode",
                        type: "text",
                        uppercase: true,
                        validateRules: {
                            maxLength: {
                                value: 3,
                                message: "Operator code must be 3 letters"
                            },
                            minLength: {
                                value: 3,
                                message: "Operator code must be 3 letters"
                            }
                        }
                    },
                    {
                        name: "Operator Full FPL Name",
                        key: "operatorFullName",
                        type: "text",
                        uppercase: true
                    },
                    {
                        name: "Operator Default Number",
                        key: "operatorDefaultNumber",
                        type: "text",
                        uppercase: true,
                        validateRules:{
                            pattern: {
                                value: /^[A-Z\d]{1,4}$/,
                                message: "Operator default number must be 1-4 letters or digits"
                            }
                        }
                    }
                ]
            },
            {
                sectionName: "Aircraft Info",
                sectionId: "aircraftInfo",
                containsSharedValues: true,
                inputs: [
                    {
                        name: "Registration",
                        key: "registration",
                        type: "text",
                        uppercase: true,
                        disabled: true,
                        required: true
                    },
                    {
                        name: "ICAO Type",
                        key: "icaoType",
                        type: "text",
                        uppercase: true,
                        validateRules: {
                            pattern: {
                                value: /^[A-Z][A-Z0-9]{1,3}$/,
                                message: "ICAO type must be 1 letter followed by 1-3 letters or digits"
                            }
                        },
                        required: true
                    }
                ]
            },
            {
                sectionName: "Performance",
                sectionId: "performance",
                containsSharedValues: true,
                inputs: [
                    {
                        name: "Number of Engines",
                        key: "numOfEngines",
                        type: "dropdown",
                        options: [
                            { value: "1", label: "1" },
                            { value: "2", label: "2" },
                        ],
                        required: true
                    },
                    {
                        name: "Cruise Speed",
                        key: "cruiseSpeed",
                        type: "dropdownInput",
                        options: [
                            { value: "N", label: "Knots" },
                            { value: "K", label: "Km/h" },
                        ],
                        required: true
                    }
                ]
            },
            {
                sectionName: "Fuel",
                sectionId: "fuel",
                shouldDisplay: (aircraft) => (aircraft.wb?.profileName?.length ?? 0) === 0,
                containsSharedValues: true,
                inputs: [
                    {
                        name: "Fuel Type",
                        key: "fuelType",
                        type: "dropdown",
                        options: [
                            { value: "avgas", label: "Avgas" },
                            { value: "jetA1", label: "Jet A1" },
                        ],
                        required: true
                    },
                    {
                        name: "Fuel Unit",
                        key: "fuelUnit",
                        type: "dropdown",
                        options: [
                            { value: "ltr", label: "Liters" },
                            { value: "usgal", label: "US Gallons" },
                            { value: "kg", label: "Kilograms" },
                            { value: "lb", label: "Pounds" },
                        ],
                        required: true
                    },
                    {
                        name: "Fuel Per Hour in Fuel Unit",
                        key: "fuelPerHour",
                        type: "number",
                        required: true
                    },
                    {
                        name: "Total Fuel Capacity in Fuel Unit (Optional when W&B is set)",
                        key: "totalFuelCapacity",
                        type: "number",
                        required: true
                    }
                ]
            },
            {
                sectionName: "Fuel",
                sectionId: "fuel",
                shouldDisplay: (aircraft) => (aircraft.wb?.profileName?.length ?? 0) > 0,
                containsSharedValues: true,
                inputs: [
                    {
                        name: "Fuel Type",
                        key: "fuelType",
                        type: "dropdown",
                        options: [
                            { value: "avgas", label: "Avgas" },
                            { value: "jetA1", label: "Jet A1" },
                        ],
                        required: true
                    },
                    {
                        name: "Fuel Unit",
                        key: "fuelUnit",
                        type: "dropdown",
                        options: [
                            { value: "ltr", label: "Liters" },
                            { value: "usgal", label: "US Gallons" },
                            { value: "kg", label: "Kilograms" },
                            { value: "lb", label: "Pounds" },
                        ],
                        required: true
                    },
                    {
                        name: "Fuel Per Hour in Fuel Unit",
                        key: "fuelPerHour",
                        type: "number",
                        required: true
                    }
                ]
            },
            {
                sectionName: "Weight and Balance",
                sectionId: "weightAndBalance",
                containsSharedValues: true,
                inputs: [
                    {
                        name: "Profile Name",
                        key: "wb.profileName",
                        type: "aircraftWbProfileName",
                    },
                    {
                        name: "Mass Unit",
                        key: "massUnit",
                        type: "dropdown",
                        options: [
                            { value: "kg", label: "Kilograms" },
                            { value: "lb", label: "Pounds" },
                        ],
                        required: true
                    },
                    {
                        name: "Empty Mass in Mass Unit",
                        key: "wb.emptyMass.mass",
                        type: "number"
                    },
                    {
                        name: "Empty Arm",
                        key: "wb.emptyMass.arm",
                        type: "number"
                    },
                    {
                        name: "Empty Lat Arm",
                        key: "wb.emptyMass.latArm",
                        type: "number"
                    }
                ]
            },
            {
                sectionName: "Flight Plan",
                sectionId: "flightPlan",
                containsSharedValues: true,
                inputs: [
                    {
                        name: "Equipment Codes",
                        key: "equipmentCodes",
                        type: "text",
                        uppercase: true,
                        validateRules: {
                            pattern: {
                                value: /^((A|B|C|D|E1|E2|E3|F|G|H|I|J1|J2|J3|J4|J5|J6|J7|K|L|M1|M2|M3|O|P1|P2|P3|P4|P5|P6|P7|P8|P9|R|S|T|U|V|W|X|Y|Z)+)$|^N$/,
                                message: "Invalid equipment code"
                            }
                        }
                    },
                    {
                        name: "Surveillance Equipment Codes",
                        key: "surveillanceEquipmentCodes",
                        type: "text",
                        uppercase: true,
                        validateRules: {
                            pattern: {
                                value: /^((A|C|E|H|I|L|P|S|X|B1|B2|U1|U2|V1|V2|D1|G1)+)$|^N$/,
                                message: "Invalid surveillance equipment code"
                            }
                        }
                    },
                    {
                        name: "Field 18 Remarks",
                        key: "field18Remarks",
                        type: "text",
                        uppercase: true,
                        validateRules: {
                            pattern: {
                                value: /^([0-9]|[ '()+,=?./]|[A-Z])+$/,
                                message: "Invalid characters. Supported characters are 0-9, A-Z, space, '()+,=?./"
                            }
                        }
                    },
                    {
                        name: "SAR Equipment",
                        key: "sarEquipment",
                        type: "aircraftSar",
                        required: true
                    }
                ]
            }
        ]
    }
}