import React from 'react';
import ReactDOM from 'react-dom/client';
import {RouterProvider} from "react-router-dom";
import { store } from './store/store'
import { Provider } from 'react-redux'
import { router } from "./router"
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/offcanvas.css';
import "flatpickr/dist/flatpickr.css";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
     <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
